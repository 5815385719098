import {WorkTaskTemplateSearchResultDto} from '../data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchResultDto';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';

export class SelectWorkTaskTemplateForm {
    workTaskTypeCode: WorkTaskTypeCode | null = null;
    workTaskTemplate: WorkTaskTemplateSearchResultDto | null = null;

    constructor(data?: Partial<SelectWorkTaskTemplateForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
