import {defineStore} from 'pinia';

export const useViewCropVarietyModalStore = defineStore('viewCropVarietyModal', {
    state: () => ({
        isVisible: false,
        cropVarietyId: null as number | null,
    }),
    actions: {
        open(cropVarietyId: number) {
            // Close modal first (if it's already open) so that the watch is triggered when it's reopened
            this.close();

            if (cropVarietyId !== null) {
                this.cropVarietyId = cropVarietyId;
                this.isVisible = true;
            }
        },
        close() {
            this.isVisible = false;
            this.cropVarietyId = null;
        },
    },
});
