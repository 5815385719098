import {GetSeasonsResponse} from '@/models/api/responses/data-source/GetSeasonsResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useSeasons() {
    const apiService = inject('apiService') as ApiService;
    const seasons = ref<GetSeasonsResponse[]>([]);
    const seasonsWithNull = ref<SelectListItem[]>([]);

    /**
     * Get list of seasons.
     */
    const getSeasons = async () => {
        seasons.value = await apiService.get('data-source/seasons');

        // Add null value
        seasonsWithNull.value = seasons.value.map((season) => ({
            value: season.seasonId,
            title: season.seasonName,
        }));

        seasonsWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getSeasons,
        seasons,
        seasonsWithNull,
    };
}
