<template>
    <v-toolbar v-if="displayToolbar" class="swatchA2BG">
        <FontAwesomeIcon :icon="['fal', 'binoculars']" size="xl" class="swatchWHT ml-3" />
        <v-toolbar-title class="swatchWHT task-data-table-title"
            >{{ $t('TaskList') + ` (${resultsList.length})` }}
        </v-toolbar-title>

        <v-tabs v-model="tab" bg-color="swatchA2" selected-class="swatchWHTBG swatchBLK">
            <v-tab :value="false">
                <FontAwesomeIcon :icon="['fal', 'clipboard-check']" size="xl" class="mr-2" />
                {{ $t('CompletedTasks') + ` (${completedTasks.length})` }}
            </v-tab>
            <v-tab :value="true">
                <FontAwesomeIcon :icon="['fal', 'clipboard-list']" size="xl" class="mr-2" />
                {{ $t('IncompleteTasks') + ` (${incompletedTasks.length})` }}
            </v-tab>
        </v-tabs>

        <v-spacer></v-spacer>

        <v-btn
            class="v-btn--custom mr-2"
            rounded="1"
            @click.stop="manageWorkTaskModalStore.open(ManageWorkTaskModalMode.Create, null, null)">
            <template v-slot:prepend>
                <FontAwesomeIcon :icon="['fal', 'plus']" size="xl" />
            </template>
            {{ $t('Add') }}
        </v-btn>

        <v-btn :class="filterButtonClass" rounded="1" @click="switchBackToSearch">
            <template v-slot:prepend>
                <FontAwesomeIcon :icon="['fal', 'filter']" size="xl" v-if="isFilterApplied" />
                <FontAwesomeIcon :icon="['fac', 'filter-check']" size="xl" v-else />
            </template>
            {{ $t('Filters') }}
        </v-btn>

        <v-menu open-on-hover :close-on-content-click="true" location="bottom">
            <template v-slot:activator="{props}">
                <v-btn class="ml-2 task-operations-activator" rounded="1" v-bind="props">
                    <template v-slot:append>
                        <FontAwesomeIcon :icon="['fal', 'bars']" size="xl" />
                    </template>
                    {{ $t('Operations') }}
                </v-btn>
            </template>
            <v-list>
                <v-list-item>
                    <v-list-item-title>
                        <v-btn @click="manageWorkTaskModalStore.open(ManageWorkTaskModalMode.Create, null, null)">
                            {{ $t('CreateTask') }}
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                    <v-list-item-title>
                        <v-btn @click="selectWorkTaskTemplateModalStore.open(null)">
                            {{ $t('CreateTaskFromTemplate') }}
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="userStore.hasRoleLevel(RoleCode.PlansAndTemplates, 2)">
                    <v-list-item-title>
                        <v-btn @click="applyWorkTaskPlanModalStore.open(null)">
                            {{ $t('CreateTasksFromPlan') }}
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>
                        <v-btn @click="exportTaskList">
                            {{ $t('ExportList') }}
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-toolbar>

    <v-data-table
        class="task-table-list"
        :headers="headers"
        :items="tab ? incompletedTasks : completedTasks"
        :items-per-page="configStore.defaultListItemNumberPerPage"
        @click:row="handleResultClick">
        <template v-slot:[`item.workTaskTypeCode`]="{item}">
            <v-row>
                <v-col cols="12" align="center">
                    <WorkTaskTypeChip :workTaskTypeCode="item.workTaskTypeCode" class="shrink-component" />
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`item.workTaskName`]="{item}">
            <div class="tableCell">
                <b class="swatchA1 slightlyLarger"> {{ item.workTaskName }} </b>
            </div>
        </template>

        <template v-slot:[`item.farmSiteName`]="{item}">
            <v-col col="12" align="center" v-if="item.farmSiteId">
                <FarmSiteChip :farm-site-abbreviation="item.farmSiteAbbreviation" />
            </v-col>
        </template>

        <template v-slot:[`item.farmFieldCode`]="{item}">
            <v-row align="center">
                <span style="width: 25px; display: flex; align-items: center; justify-content: center">
                    <SvgLoader
                        v-if="item.farmFieldPolygonImageUrl"
                        :url="item.farmFieldPolygonImageUrl"
                        :color="item.farmFieldColorHexCode ?? '#B8E531'" />

                    <SvgLoader v-else url="Field_UnknownShape.svg" :color="item.farmFieldColorHexCode ?? '#B8E531'" />
                </span>
                <span class="swatchA1 slightlyLarger pl-2 farm-field-cell">
                    {{
                        item.farmFieldCode +
                        (item.farmFieldCode && item.farmFieldName ? ' - ' : '') +
                        item.farmFieldName
                    }}
                </span>
            </v-row>
        </template>

        <template v-slot:[`item.farmFieldPrimaryCropcode`]="{item}">
            <v-row v-if="item.farmFieldPrimaryCropcode">
                <v-col cols="12" align="center">
                    <CropIcon :cropCode="item.farmFieldPrimaryCropcode" :is-show-crop-name="true" />
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`item.workTaskStatusCode`]="{item}">
            <v-row>
                <v-col cols="12" align="center">
                    <WorkTaskStatus
                        :work-task="item"
                        v-if="
                            item.isCompleted || item.isCancelled || item.isPaused || item.isStarted || item.isOverdue
                        " />
                    <div class="task-status-chip swatchG7BG swatchG3" v-else>
                        {{ $t('Incomplete') }}
                    </div>
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`item.dueDateLatest`]="{item}">
            <v-row class="swatchA1">
                <FontAwesomeIcon
                    :icon="['fas', 'calendar-circle-exclamation']"
                    class="swatchRED pr-3"
                    size="xl"
                    v-if="isOverDue(item.dueDate)" />
                <FontAwesomeIcon :icon="['fas', 'calendar-day']" class="swatchG3 pr-3" size="xl" v-else />
                {{ formatDateOnly(item.dueDate) }}
            </v-row>
        </template>

        <template v-slot:[`item.createdDate`]="{item}">
            <v-row class="swatchA1">
                {{ formatDate(item.startedDate) }}
            </v-row>
            <v-row>
                <UserChip
                    v-if="item.startedUserId"
                    :user="{
                        userId: item.startedUserId,
                        username: `${item.startedUserGivenName} ${item.startedUserSurname}`,
                        profilePicture: '',
                    }" />
            </v-row>
        </template>

        <template v-slot:[`item.modifiedDate`]="{item}">
            <v-row class="swatchA1">
                {{ formatDate(item.completedDate ?? item.cancelledDate ?? item.modifiedDate) }}
            </v-row>
            <v-row>
                <UserChip
                    v-if="item.completedUserId"
                    :user="{
                        userId: item.completedUserId,
                        username: `${item.completedUserGivenName} ${item.completedUserSurname}`,
                        profilePicture: '',
                    }" />
                <UserChip
                    v-else-if="item.cancelledUserId"
                    :user="{
                        userId: item.cancelledUserId,
                        username: `${item.cancelledUserGivenName} ${item.cancelledUserSurname}`,
                        profilePicture: '',
                    }" />
                <UserChip
                    v-else-if="item.modifiedUserId"
                    :user="{
                        userId: item.modifiedUserId,
                        username: `${item.modifiedUserGivenName} ${item.modifiedUserSurname}`,
                        profilePicture: '',
                    }" />
            </v-row>
        </template>

        <template v-slot:[`item.workTaskInstructions`]="{item}">
            <WorkTaskSummary :work-task="item"></WorkTaskSummary>
        </template>

        <template v-slot:[`item.action`]="{item}">
            <div style="text-align: center" v-if="item.workTaskId">
                <WorkTaskOperationsMenu :work-task="item" :menuText="$t('Action')" />
            </div>
        </template>
    </v-data-table>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import i18n from '@/i18n';
import {format} from 'date-fns';
import SvgLoader from '@/components/SvgLoader.vue';
import UserChip from '@/components/UserChip.vue';
import WorkTaskStatus from '@/components/work-tasks/WorkTaskStatus.vue';
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
import {ManageWorkTaskModalMode} from '@/enums/modal-modes/manage-work-task-modal-mode';
const manageWorkTaskModalStore = useManageWorkTaskModalStore();
import FarmSiteChip from '@/components/other-chips/FarmSiteChip.vue';
import {useViewWorkTaskModalStore} from '@/stores/modals/view-work-task-modal-store';
const viewWorkTaskModalStore = useViewWorkTaskModalStore();
import WorkTaskSummary from '@/views/work-tasks/WorkTaskSummary.vue';
import {useConfigStore} from '@/stores/config-store';
const configStore = useConfigStore();
import WorkTaskOperationsMenu from '@/components/operations-menus/WorkTaskOperationsMenu.vue';
import {useUserStore} from '@/stores/user-store';
const userStore = useUserStore();
import {RoleCode} from '@/enums/role-code';
import {useSelectWorkTaskTemplateModalStore} from '@/stores/modals/select-work-task-template-modal-store';
const selectWorkTaskTemplateModalStore = useSelectWorkTaskTemplateModalStore();
import {useApplyWorkTaskPlanModalStore} from '@/stores/modals/apply-work-task-plan-modal-store';
const applyWorkTaskPlanModalStore = useApplyWorkTaskPlanModalStore();

const props = withDefaults(
    defineProps<{
        resultsList: WorkTaskSearchResultDto[];
        isFilterApplied?: boolean;
        showFarmSite?: boolean;
        displayToolbar?: boolean;
        openTaskDefault?: boolean;
    }>(),
    {
        resultsList: () => [] as WorkTaskSearchResultDto[],
        isFilterApplied: false,
        showFarmSite: false,
        displayToolbar: true,
        openTaskDefault: true,
    },
);

const tab = computed(() => (props.openTaskDefault === false ? false : true));

const completedTasks = computed(() => props.resultsList.filter((e) => e.isCompleted || e.isCancelled));
const incompletedTasks = computed(() => props.resultsList.filter((e) => !e.isCompleted && !e.isCancelled));

const headers = computed(() => {
    const reportHeaders = [
        {title: i18n.global.t('Task'), key: 'workTaskTypeCode', sortable: true},
        {title: i18n.global.t('Name'), key: 'workTaskName', sortable: true},
        {title: i18n.global.t('Field'), key: 'farmFieldCode', sortable: true},
        {title: i18n.global.t('Crop'), key: 'farmFieldPrimaryCropcode', sortable: false},
        {title: i18n.global.t('Status'), key: 'workTaskStatusCode', sortable: true},
        {title: i18n.global.t('Due'), key: 'dueDateLatest', sortable: true},
        {title: i18n.global.t('Started'), key: 'createdDate', sortable: true},
        {
            title: tab.value ? i18n.global.t('LastAction') : i18n.global.t('Concluded'),
            key: 'modifiedDate',
            sortable: true,
        },
        {title: i18n.global.t('Summary'), key: 'workTaskInstructions', sortable: false},
        {title: i18n.global.t('Action'), key: 'action', sortable: false},
    ];

    props.showFarmSite &&
        reportHeaders.splice(2, 0, {title: i18n.global.t('Site'), key: 'farmSiteName', sortable: true});

    return reportHeaders;
});

/**
 * Handle click event on a result.
 */
const handleResultClick = (event: MouseEvent, row: {item: WorkTaskSearchResultDto}) => {
    viewWorkTaskModalStore.open(row.item.workTaskId);
};

const filterButtonClass = computed(() => (props.isFilterApplied ? ['v-btn--custom'] : ['swatchB6BG', 'swatchWHT']));

const emit = defineEmits<{
    (event: 'switch-to-search'): void;
    (event: 'export-task-list'): void;
    (event: 'view-list-on-home', isFutureTask: boolean): void;
}>();

const switchBackToSearch = () => {
    emit('switch-to-search');
};
const exportTaskList = () => {
    emit('export-task-list');
};

const formatDate = (date: Date | null): string => (!date ? '' : format(date, 'dd MMM yyyy HH:mm'));

const formatDateOnly = (date: Date | null): string => (!date ? '' : format(new Date(date), 'dd MMM yyyy'));

const isOverDue = (date: Date | null): boolean => (!date ? false : new Date(date) < new Date());
</script>

<style lang="scss">
@import '@/assets/scss/swatches.scss';

.v-btn.task-operations-activator {
    border: 1px solid $swatchG5 !important;
    background-color: $swatchGA !important;
    color: $swatchG1 !important;

    &:hover {
        color: $swatchWHT !important;
        background-color: $swatchB6 !important;
    }
}

.task-table-list .v-table__wrapper thead {
    background-color: $swatchG9;
}

.task-table-list .v-table__wrapper {
    overflow-y: hidden;
}

.farm-field-cell {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tas-data-table-title {
    font-size: 0.5rem;
    max-width: 250px;
}

.task-table-list .task-status-chip {
    padding: 1px 5px 1px 5px;
    height: 26px;
    border-radius: 5px;
    margin-left: auto;
    text-align: center;
    font-family: 'Open Sans Condensed', sans-serif;
}

.shrink-component {
    transform: scale(0.8);
    display: inline-block;
}
</style>
