import {SeedRateUnitCode} from '@/enums/seed-rate-unit-code';

export class SaveWorkTaskCropVarietyPreferenceDto {
    workTaskCropVarietyPreferenceId: number | null = null;
    cropVarietyId!: number;
    seedOriginBlockId: number | null = null;
    seedRate!: number;
    seedRateUnitCode!: SeedRateUnitCode;

    constructor(data?: Partial<SaveWorkTaskCropVarietyPreferenceDto>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
