<template>
    <div class="dropdown">
        <v-menu open-on-hover offset-y :open-delay="0">
            <template v-slot:activator="{props}">
                <v-btn v-bind="props" class="swatchG2BG swatchWHT" style="height: 40px">
                    <FontAwesomeIcon :icon="['fal', 'language']" size="xl" />
                    <span class="pl-2 language-abbr">{{ selectedLanguage }}</span>
                </v-btn>
            </template>
            <v-list class="swatchG2BG swatchWHT">
                <v-list-item v-for="locale in locales" :key="locale.localeCode" @click="changeLanguage(locale)">
                    <v-list-item-title class="language-list"
                        ><span class="language-abbr">{{ locale.localeDisplayAbbreviation }}</span>
                        <span class="pl-4">{{ locale.localeDisplayName }}</span></v-list-item-title
                    >
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script lang="ts">
import {useLocaleCodeStore} from '@/stores/locale-code-store';
import {useUserStore} from '@/stores/user-store';
import {defineComponent, computed, inject} from 'vue';
import i18n from '@/i18n';
import ApiService from '@/services/api-service';
import {LocaleCode} from '@/enums/locale-code';
import {GetLocaleCodeSettingsResponse} from '@/models/api/responses/system/GetLocaleCodeSettingsResponse';
import {LocaleCultureCode} from '../models/system/LocaleCultureCode';

export default defineComponent({
    name: 'LanguageSelector',
    created() {
        this.init();
    },
    setup() {
        const localeCodestore = useLocaleCodeStore();
        const userStore = useUserStore();
        const apiService = inject('apiService') as ApiService;

        const selectedLanguage = computed(() => {
            //if the current locale is null or the local list is empty, set to default
            if (!i18n.global.locale || !(localeCodestore.locales && localeCodestore.locales.length > 0)) return 'EN';
            else {
                const currentLocale = localeCodestore.locales.filter((e) => e.localeCultureCode === i18n.global.locale);
                if (currentLocale.length > 0) return currentLocale[0].localeDisplayAbbreviation;
                else return 'EN';
            }
        });
        const isLocaleCodeLoading = computed(() => !(localeCodestore.locales && localeCodestore.locales.length > 0));
        const locales = computed(() => useLocaleCodeStore().locales);

        /**
         * Initialization.
         */
        async function init() {
            //Call API to get local codes if they have not been loaded yet
            if (isLocaleCodeLoading.value) {
                await localeCodestore.fetchLocaleCodeSettings(apiService);
            }
            const localStoredLocale = localStorage.getItem('languagePreference') as LocaleCultureCode | null;
            if (!i18n.global.locale) {
                i18n.global.locale = localStoredLocale ? localStoredLocale : 'en-AU';
            }
        }

        /**
         * Change language and update user preference.
         */
        async function changeLanguage(locale: GetLocaleCodeSettingsResponse) {
            if (i18n.global.locale != locale.localeCultureCode) {
                localStorage.setItem(
                    'languagePreference',
                    locale.localeCultureCode !== null || locale.localeCultureCode !== ''
                        ? locale.localeCultureCode
                        : 'en-AU',
                );
                const currentLocale = localStorage.getItem('languagePreference') as LocaleCultureCode | null;

                const newUserLocale = locales.value.filter((e) => e.localeCultureCode === currentLocale);

                //If user has changed its locale and it's different from the previous one
                if (
                    newUserLocale.length > 0 &&
                    userStore.isLoggedIn &&
                    userStore.user &&
                    userStore.user.localeCode !== newUserLocale[0].localeCode
                ) {
                    try {
                        const params = {
                            LocaleCode: newUserLocale[0].localeCode,
                            UserId: userStore.user.userId,
                        };
                        const response = await apiService.post('users/update-locale', params);
                        if (!response) throw new Error('Failed to set user locale.');
                        else userStore.user.localeCode = newUserLocale[0].localeCode as LocaleCode;
                    } catch (error) {
                        console.error('Failed to set user locale', error);
                    }
                }

                window.location.reload();
            }
        }
        return {
            //variables
            locales,
            selectedLanguage,
            isLocaleCodeLoading,
            //functions
            changeLanguage,
            init,
        };
    },
});
</script>
<style>
.language-list:hover {
    text-decoration: underline;
}
.language-abbr {
    font-weight: bold;
}
</style>
