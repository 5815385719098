import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, { onClick: _ctx.handleClick }, {
    prepend: _withCtx(() => [
      _createVNode(_component_FontAwesomeIcon, {
        icon: ['fal', 'arrow-rotate-left'],
        size: "xl"
      })
    ]),
    default: _withCtx(() => [
      _createTextVNode(" " + _toDisplayString(_ctx.$t('Back')), 1)
    ]),
    _: 1
  }, 8, ["onClick"]))
}