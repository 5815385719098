<template>
    <div v-if="workTask" style="display: flex; align-items: center">
        <WorkTaskTypeChip :workTaskTypeCode="workTask.workTaskTypeCode" />

        <div v-if="workTask.workTaskName" class="ml-6">
            <span class="task-and-field-name">"{{ workTask.workTaskName }}"</span>
        </div>

        <div class="ml-2">
            <IdIcon>{{ workTask.workTaskId }}</IdIcon>
        </div>

        <div class="ml-10">
            <WorkTaskDateIcon :work-task="workTask" />
        </div>
        <div class="ml-2 swatchA1"><WorkTaskDate :work-task="workTask" /></div>

        <div class="ml-4">
            <WorkTaskStatus
                v-if="workTask.isStarted || workTask.isPaused || workTask.isCancelled || workTask.isOverdue"
                :work-task="workTask" />
            <WorkTaskDaysUntilDue v-else :work-task="workTask" />
        </div>

        <div v-if="showOperationsMenu" style="margin-left: auto">
            <WorkTaskOperationsMenu :work-task="workTask" />
        </div>
    </div>
    <div style="display: flex; align-items: center" class="mt-2">
        <FarmSiteChip
            v-if="farmField.farmSiteAbbreviation && farmField.farmSiteName"
            :farm-site-abbreviation="farmField.farmSiteAbbreviation"
            :farm-site-name="farmField.farmSiteName" />

        <div v-if="farmField" class="ml-6">
            <span class="task-and-field-name">{{ `${farmField.farmFieldCode} - ${farmField.farmFieldName}` }}</span>
        </div>

        <div v-if="farmField.cropCode" class="ml-6" style="display: flex; align-items: center; gap: 0.5rem">
            <CropIcon
                v-if="farmField.cropCode"
                :cropCode="farmField.cropCode"
                iconHeight="45"
                iconWidth="45"
                iconSize="25" />

            <FarmFieldAgeIcon
                v-if="farmField.cropYear != null"
                iconHeight="45"
                iconWidth="45"
                :fieldAge="farmField.cropYear.toString()" />

            <v-btn
                v-if="showViewFieldButton"
                class="v-btn--custom ml-2"
                @click="viewFarmFieldModalStore.open(farmField.farmFieldId)">
                <template v-slot:prepend>
                    <FontAwesomeIcon :icon="['fal', 'arrow-circle-right']" size="xl" />
                </template>
                {{ $t('View') }}
            </v-btn>
        </div>

        <div
            v-if="farmField.farmFieldSize != null && workTask"
            style="margin-left: auto; display: flex; align-items: center; gap: 0.5rem">
            <RowChip
                v-if="workTask.workTaskTypeCode == WorkTaskTypeCode.Plant && farmField.farmFieldNumRows"
                :numberOfRows="farmField.farmFieldNumRows"
                :rowDirection="farmField.farmFieldRowDirection ? farmField.farmFieldRowDirection : undefined" />

            <FieldSizeChip class="mr-5" :farmFieldSize="farmField.farmFieldSize" />
        </div>
    </div>

    <div
        v-if="showCreatedAndModifiedDetails && workTask"
        :class="{'mt-4': workTask.createdUserId || workTask.modifiedUserId}">
        <!-- Created -->
        <div v-if="workTask.createdUserId" class="flex-layout fill flex-layout-space-05">
            <div class="formHead" style="width: 12rem">{{ $t('Created') }}</div>
            <div class="formModifyGroup">
                <UserChip
                    :user="
                        UserHelper.userChipType(
                            workTask.createdUserId,
                            UserHelper.userFullName(workTask.createdUserGivenName, workTask.createdUserSurname),
                        )
                    " />

                <span class="swatchA1 ml-4">{{ formatDate(workTask.createdDate) }}</span>
            </div>
        </div>

        <!-- Last Modified -->
        <div v-if="workTask.modifiedUserId" class="flex-layout fill flex-layout-space-05">
            <div class="formHead" style="width: 12rem">{{ $t('LastModified') }}</div>
            <div class="formModifyGroup">
                <UserChip
                    :user="
                        UserHelper.userChipType(
                            workTask.modifiedUserId,
                            UserHelper.userFullName(workTask.modifiedUserGivenName, workTask.modifiedUserSurname),
                        )
                    " />

                <span class="swatchA1 ml-4">{{ formatDate(workTask.modifiedDate) }}</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {defineProps} from 'vue';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {format} from 'date-fns';
import FarmSiteChip from '@/components/other-chips/FarmSiteChip.vue';
import FarmFieldAgeIcon from '@/components/FarmFieldAgeIcon.vue';
import UserChip from '@/components/UserChip.vue';
import FieldSizeChip from '@/components/other-chips/FieldSizeChip.vue';
import RowChip from '@/components/other-chips/RowChip.vue';
import WorkTaskOperationsMenu from '@/components/operations-menus/WorkTaskOperationsMenu.vue';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {useViewFarmFieldModalStore} from '@/stores/modals/view-farm-field-modal-store';
import WorkTaskDateIcon from '@/components/work-tasks/WorkTaskDateIcon.vue';
import WorkTaskDate from '@/components/work-tasks/WorkTaskDate.vue';
import WorkTaskStatus from '@/components/work-tasks/WorkTaskStatus.vue';
import WorkTaskDaysUntilDue from '@/components/work-tasks/WorkTaskDaysUntilDue.vue';
const viewFarmFieldModalStore = useViewFarmFieldModalStore();
import UserHelper from '@/helpers/UserHelper';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';

withDefaults(
    defineProps<{
        workTask?: WorkTaskSearchResultDto;
        farmField: FarmFieldSearchResultDto;
        showOperationsMenu?: boolean;
        showCreatedAndModifiedDetails?: boolean;
        showViewFieldButton?: boolean;
    }>(),
    {
        showOperationsMenu: true,
        showCreatedAndModifiedDetails: true,
        showViewFieldButton: true,
    },
);

const formatDate = (date: Date | null): string | null => {
    if (!date) return null;
    return format(date, 'dd MMMM yyyy');
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.task-and-field-name {
    font-size: 14pt;
    color: $swatchA1;
    font-weight: bold;
}
</style>
