import {ApplicationRateUnitCode} from '@/enums/application-rate-unit-code';

export class SaveWorkTaskTemplateProductDto {
    workTaskTemplateProductId: number | null = null;
    productId!: number;
    plannedApplicationRate!: number;
    plannedApplicationRateUnitCode!: ApplicationRateUnitCode;
    isFertilizer: boolean = false;
    isSpray: boolean = false;

    constructor(data?: Partial<SaveWorkTaskTemplateProductDto>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
