<template>
    <v-card class="task-metric-panel pa-3" width="200px">
        <v-row class="pa-3"
            ><v-select
                v-model="selectedPeriod"
                :items="periodOptions"
                item-title="periodOptionName"
                item-value="periodOptionValue"
                :label="$t('Period')"
                max-width="133"
                class="mb-3 swatchWHT"
                hide-details />
            <v-btn icon @click="close" size="small" class="transparent-btn">
                <v-icon size="x-large" style="opacity: 1">mdi-close</v-icon>
            </v-btn>
        </v-row>

        <v-select
            v-model="selectedMetric"
            :items="metricOptions"
            item-title="metricOptionName"
            item-value="metricOptionValue"
            :label="$t('Metric')"
            hide-details
            class="mb-3 swatchWHT" />

        <LoadingSymbol v-if="isLoading" />
        <div v-for="(taskType, index) in taskTypeData" :key="index" v-else class="mt-3">
            <task-type-metric
                v-if="taskType.workTaskTypeCode && taskType.totalArea !== null"
                :work-task-type-code="taskType.workTaskTypeCode"
                :total-area="taskType.totalArea"
                :historic-data="taskType.timeSeriesData"
                :is-hide="false" />
        </div>
    </v-card>
</template>

<script lang="ts">
import {defineComponent, ref, computed, inject, onMounted, watch} from 'vue';
import TaskTypeMetric from './TaskTypeMetric.vue';
import i18n from '@/i18n';
import ApiService from '@/services/api-service.js';
import {useFarmStore} from '@/stores/farm-store';
import {GetWorkTaskTypeHistoryRequest} from '@/models/api/requests/work-tasks/GetWorkTaskTypeHistoryRequest';
import {GetWorkTaskTypeHistoryResponse} from '@/models/data-transfer-objects/work-tasks/GetWorkTaskTypeHistoryResponse';
import {WorkTaskTypeHistoryDto} from '@/models/data-transfer-objects/work-tasks/WorkTaskTypeHistoryDto';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
import {useActionWorkTaskModalStore} from '@/stores/modals/action-work-task-modal-store';
import {useClearTasksModalStore} from '@/stores/modals/clear-tasks-modal-store';
import {useManageFarmFieldModalStore} from '@/stores/modals/manage-farm-field-modal-store';
import {useDeleteFarmFieldModalStore} from '@/stores/modals/delete-farm-field-modal-store';

export default defineComponent({
    name: 'TaskMetricsDashboard',
    components: {
        TaskTypeMetric,
    },
    setup() {
        // Services
        const apiService = inject('apiService') as ApiService;
        const farmStore = useFarmStore();
        const homeDashboardStore = useHomeDashboardStore();
        const deleteFarmFieldModalStore = useDeleteFarmFieldModalStore();
        const manageWorkTaskModalStore = useManageWorkTaskModalStore();
        const actionWorkTaskModalStore = useActionWorkTaskModalStore();
        const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();
        const manageFarmFieldModalStore = useManageFarmFieldModalStore();
        const clearTasksModalStore = useClearTasksModalStore();

        const isLoading = ref<boolean>(false);
        const periodOptions = computed(() => [
            {
                periodOptionName: i18n.global.t('Yesterday'),
                periodOptionValue: 'Yesterday',
            },
            {
                periodOptionName: i18n.global.t('Last7Days'),
                periodOptionValue: 'Last7Days',
            },
            {
                periodOptionName: i18n.global.t('Last30Days'),
                periodOptionValue: 'Last30Days',
            },
            {
                periodOptionName: i18n.global.t('Last60Days'),
                periodOptionValue: 'Last60Days',
            },
            {
                periodOptionName: i18n.global.t('Last90Days'),
                periodOptionValue: 'Last90Days',
            },
            {
                periodOptionName: i18n.global.t('Last6Months'),
                periodOptionValue: 'Last6Months',
            },
            {
                periodOptionName: i18n.global.t('LastYear'),
                periodOptionValue: 'LastYear',
            },
        ]);

        const metricOptions = computed(() => [
            {
                metricOptionName: i18n.global.t('TasksCompleted'),
                metricOptionValue: 'TasksCompleted',
            },
        ]);

        // Default selected options
        const selectedPeriod = ref<string>('Last30Days');
        const selectedMetric = ref<string>('TasksCompleted');
        const taskTypeData = ref<WorkTaskTypeHistoryDto[]>([]);

        const close = () => {
            homeDashboardStore.hideTaskMetrics();
            isLoading.value = false;
            selectedPeriod.value = 'Last30Days';
            selectedMetric.value = 'TasksCompleted';
        };

        const updateTimeSeriesData = async () => {
            isLoading.value = true;
            const searchResults = (await apiService.post('work-tasks/work-task-history', {
                periodType: selectedPeriod.value,
                farmSiteId: farmStore.selectedSiteId,
            } as GetWorkTaskTypeHistoryRequest)) as GetWorkTaskTypeHistoryResponse;

            if (searchResults.workTaskTypeHistories.length > 0) {
                taskTypeData.value = searchResults.workTaskTypeHistories;
            }
            isLoading.value = false;
        };
        onMounted(async () => {
            await updateTimeSeriesData();
        });

        watch([() => selectedPeriod.value, () => farmStore.selectedSiteId], async (val, oldVal) => {
            if (val !== oldVal) await updateTimeSeriesData();
        });

        watch(
            [
                () => manageWorkTaskModalStore.savedCounter,
                () => deleteOrCancelWorkTaskModalStore.savedCounter,
                () => actionWorkTaskModalStore.savedCounter,
                () => manageFarmFieldModalStore.savedCounter,
                () => deleteFarmFieldModalStore.deletedCounter,
                () => clearTasksModalStore.deletedCounter,
            ],
            async () => {
                await updateTimeSeriesData();
            },
            {deep: true},
        );

        return {
            selectedPeriod,
            selectedMetric,
            periodOptions,
            metricOptions,
            homeDashboardStore,
            close,
            isLoading,
            taskTypeData,
        };
    },
});
</script>

<style lang="scss">
@import '../assets/scss/swatches.scss';

.task-metric-panel {
    background-color: rgba($swatchBLK, 0.25) !important;
    border: 1px solid $swatchG1;
    min-height: 740px;
}

.task-metric-panel .v-select__selection {
    font-size: 14px;
    font-family: 'Open Sans Condensed', sans-serif;
}

.task-metric-panel .transparent-btn {
    background-color: transparent;
    border: 1px solid $swatchWHT;
    color: $swatchWHT;
}
</style>
