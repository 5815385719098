import { ArtifactDto } from "@/models/data-transfer-objects/system/ArtifactDto";
import { UserChipType } from "@/models/users/UserChipType";

class UserHelper {
    /**
     * Creates the user's chip type for the user chip.
     */
    public static userChipType(userId: number | null, userFullName: string | null, userImage: ArtifactDto | null = null): UserChipType | undefined {
        const imageUrl = userImage ? userImage.artifactUrl : null;
        
        const userChip: UserChipType = {
            userId: userId ?? 0,
            username: `${userFullName ?? ''}`.trim(),
            profilePicture: `${imageUrl ?? ''}`,
        };
        return userChip;
    }

    public static userFullName(userGivenName: string | null, userSurname: string | null): string {
        return `${userGivenName ?? ''} ${userSurname ?? ''}`.trim()
    }
}

export {UserHelper};
export default UserHelper;