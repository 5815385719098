<template>
    <v-chip variant="flat" :size="size" density="comfortable" :color="lighterColor" pill class="pr-5 noSelection">
        <v-avatar start>
            <CircleIcon :style="{height: '40px', width: '40px'}" :class="iconClass">
                <FontAwesomeIcon :icon="['fal', 'stopwatch']" :size="iconSize" />
            </CircleIcon>
        </v-avatar>

        <div class="chip-text">
            <span v-if="numberOfDaysDueAfterPreviousTask != null">
                {{ WorkTaskPlanHelper.getDueAfterText(numberOfDaysDueAfterPreviousTask) }}
            </span>
        </div>
    </v-chip>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue';
import {getSwatchColor, lightenColor} from '@/helpers/SwatchHelper';
import {SwatchCode} from '@/enums/swatch-code';
import WorkTaskPlanHelper from '@/helpers/WorkTaskPlanHelper';

export default defineComponent({
    props: {
        numberOfDaysDueAfterPreviousTask: Number,
        size: {
            type: String,
            default: 'default',
        },
        iconSize: {
            type: String,
            default: null,
        },
    },
    setup() {
        const color = computed<SwatchCode>(() => {
            return SwatchCode.SwatchG2;
        });

        const lighterColor = computed<string>(() => {
            const hexColor = getSwatchColor(color.value);
            return lightenColor(hexColor, 15);
        });

        const iconClass = computed<string>(() => {
            return `bg-${color.value}`;
        });

        return {
            iconClass,
            lighterColor,
            WorkTaskPlanHelper,
        };
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.chip-text {
    font-size: 12pt;
    color: $swatchWHT;
}
</style>
