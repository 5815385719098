import {GetFarmBlocksResponse} from '@/models/api/responses/data-source/GetFarmBlocksResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useFarmBlocks() {
    const apiService = inject('apiService') as ApiService;
    const farmBlocks = ref<GetFarmBlocksResponse[]>([]);
    const farmBlocksWithNull = ref<SelectListItem[]>([]);

    /**
     * Get list of farm blocks.
     */
    const getFarmBlocks = async () => {
        farmBlocks.value = await apiService.get('data-source/farm-blocks');

        // Add null value
        farmBlocksWithNull.value = farmBlocks.value.map((farmBlock) => ({
            value: farmBlock.farmBlockId,
            title: farmBlock.farmBlockFullName,
        }));

        farmBlocksWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getFarmBlocks,
        farmBlocks,
        farmBlocksWithNull,
    };
}
