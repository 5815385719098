<template>
    <v-tooltip bottom>
        <template v-slot:activator="{props}">
            <v-chip v-bind="props" variant="flat" size="xlarge" color="white" class="farm-block-chip pr-5">
                <v-avatar start>
                    <CircleIcon
                        :style="{height: iconHeight + 'px', width: iconWidth + 'px', fontSize: iconSize + 'pt'}"
                        :class="iconClass">
                        <FontAwesomeIcon :icon="[iconFamily, iconCode]" :class="backgroundClass" />
                    </CircleIcon>
                </v-avatar>

                <div class="chip-text">
                    <span class="farm-block-code">{{ farmBlockCode }}</span>
                    <br />
                    <span class="crop-variety">{{ cropVariety }}</span>
                </div>
            </v-chip>
        </template>
        <slot name="helpText"></slot>
        {{ name }}
    </v-tooltip>
</template>

<script lang="ts">
import {PropType, defineComponent, computed} from 'vue';
import {getCropByCode, Crop} from '@/services/crops-service';
import {CropCode} from '@/enums/crop-code';

export default defineComponent({
    props: {
        farmBlockCode: String as PropType<string>,
        cropCode: String as PropType<CropCode>,
        cropVariety: String as PropType<string>,
        primary: {
            type: Boolean,
            default: false,
        },
        iconHeight: {
            type: String,
            default: '36',
        },
        iconWidth: {
            type: String,
            default: '36',
        },
        iconSize: {
            type: String,
            default: '18',
        },
    },
    setup(props) {
        const crop = computed<Crop | undefined>(() => {
            return getCropByCode(props.cropCode as CropCode);
        });

        const iconFamily = computed<string>(() => {
            return crop.value?.iconFamily || 'fas';
        });

        const iconCode = computed<string>(() => {
            return crop.value?.iconCode || 'question';
        });

        const name = computed<string | null>(() => {
            return crop.value?.getCropName() || null;
        });

        const iconClass = computed<string>(() =>
            props.cropCode == CropCode.SugarCane ? 'primary-icon' : 'secondary-icon',
        );

        const backgroundClass = computed<string>(() =>
            props.cropCode == CropCode.SugarCane ? 'primary-background' : 'secondary-background',
        );
        return {
            iconFamily,
            iconCode,
            name,
            iconClass,
            backgroundClass,
        };
    },
});
</script>

<style lang="scss" scoped>
@import '../assets/scss/swatches.scss';

.farm-block-chip {
    cursor: pointer;
    font-size: 11pt;
    color: $swatchA2 !important;

    .chip-text {
        /* Ensures that line breaks are respected */
        white-space: pre-line;
    }

    .farm-block-code {
        font-weight: bold;
    }

    .crop-variety {
        font-weight: bold;
        font-size: 10pt;
    }

    .primary-background {
        background-color: rgba($swatchA2, 0.8);
        color: $swatchWHT;
    }
    .secondary-background {
        background-color: rgba($swatchA5, 0.8);
        color: $swatchBLK;
    }
    .primary-icon {
        background-color: $swatchA2 !important;
    }
    .secondary-icon {
        background-color: $swatchA5 !important;
    }
}
</style>
