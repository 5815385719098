<template>
    <div class="resetForm">
        <!-- Login Form -->
        <v-card outlined class="transparent-card">
            <v-toolbar flat color="primary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'unlock']" size="xl" class="mr-2" />
                    {{ panelTitle }}
                </v-toolbar-title>
            </v-toolbar>
            <v-form ref="form" class="pa-4" v-model="valid">
                <div v-if="resetResponse === 1">
                    <table style="width: 80%" class="tblForm">
                        <tr>
                            <td class="tableCell padding" style="width: 40%">
                                <div
                                    class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                    <span> {{ $t('NewPassword') }}</span>
                                    <HelpIcon>
                                        <template v-slot:helpText>
                                            <div>
                                                {{ $t('ChangePassword_NewPassword_HelpText') }}
                                            </div>
                                            <ul>
                                                <li>{{ passwordValidator.getLengthRequirements() }}</li>
                                                <li
                                                    v-for="requirement in passwordValidator.getCharacterRequirements()"
                                                    :key="requirement">
                                                    {{ requirement }}
                                                </li>
                                            </ul>
                                        </template>
                                    </HelpIcon>
                                </div>
                            </td>
                            <td style="width: 60%">
                                <v-text-field
                                    variant="outlined"
                                    v-model="newPassword"
                                    required
                                    type="password"
                                    :rules="newPasswordRules" />
                            </td>
                        </tr>

                        <tr>
                            <td class="tableCell padding">
                                <div
                                    class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                    <span> {{ $t('ReEnterNewPassword') }}</span>
                                    <HelpIcon :help-text="$t('RepeatYourPassword')" />
                                </div>
                            </td>
                            <td>
                                <v-text-field
                                    variant="outlined"
                                    v-model="repeatPassword"
                                    required
                                    type="password"
                                    :rules="reapeatPasswordRules" />
                            </td>
                        </tr>
                    </table>

                    <v-alert v-if="errorMessage" type="error" :value="true">
                        {{ errorMessage }}
                    </v-alert>
                </div>
                <div class="pa-4" v-else>{{ panelMessage }}</div>
            </v-form>
            <v-card-actions class="justify-space-between swatchG9BG">
                <v-btn color="primary" @click="router.push({name: 'UserLogin'})">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Login') }}
                </v-btn>

                <v-spacer />

                <v-btn color="primary" @click="savePassword" :disabled="!valid" v-if="resetResponse === 1">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'floppy-disk']" size="xl" :value="2134" />
                    </template>
                    {{ $t('SavePassword') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script lang="ts">
import {defineComponent, inject, ref, onMounted, watch} from 'vue';
import {useConfigStore} from '@/stores/config-store';
import {useUserStore} from '@/stores/user-store';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {byPrefixAndName} from '@awesome.me/kit-b64aff0d40/icons';
import ApiService from '@/services/api-service';
import router from '@/router';
import {useRoute} from 'vue-router';
import axios from 'axios';
import PasswordValidator from '@/helpers/PasswordValidator';
import i18n from '@/i18n';

export default defineComponent({
    components: {
        FontAwesomeIcon,
    },
    setup() {
        // Stores
        const configStore = useConfigStore();
        const userStore = useUserStore();

        // Services
        const apiService = inject('apiService') as ApiService;

        // Form fields
        const newPassword = ref<string>('');
        const repeatPassword = ref<string>('');
        const passwordValidator = ref<PasswordValidator>(new PasswordValidator());
        const form = ref();
        const valid = ref<boolean>(true);
        const errorMessage = ref('');
        const panelMessage = ref<string>('');
        const panelTitle = ref<string>('');
        const regexPassword = new RegExp(configStore.regexPassword);
        const passwordResetHours = ref<number>(configStore.passwordResetHoursValid);
        const resetResponse = ref<number | null>(null);

        // Rules
        const newPasswordRules = ref<Array<(v: string) => true | string>>([
            (v) => !!v || i18n.global.t('YourNewPasswordIsRequired'),
            (v) =>
                (regexPassword.test(v) && passwordValidator.value.isValid(v)) || i18n.global.t('WrongPasswordFormat'),
        ]);

        const reapeatPasswordRules = ref<Array<(v: string) => true | string>>([
            (v) => !!v || i18n.global.t('PleaseReEnterYourNewPassword'),
            (v) =>
                (regexPassword.test(v) && passwordValidator.value.isValid(v)) || i18n.global.t('WrongPasswordFormat'),
            (v) => v === newPassword.value || i18n.global.t('NewPasswordDoesNotMatch'),
        ]);

        watch([newPassword, repeatPassword], ([newEmail, newSurname], [oldEmail, oldSurname]) => {
            if (newEmail != oldEmail || newSurname != oldSurname) {
                errorMessage.value = '';
            }
        });

        const route = useRoute();
        async function validateResetToken() {
            try {
                resetResponse.value = await apiService.post('users/validate-reset-token', {
                    UserId: route.params.userID,
                    ResetTokenString: route.params.resetToken,
                });

                switch (
                    resetResponse.value //Valid Password reset request (for checking the validity of the link before showing the reset password form)
                ) {
                    //reset link passes validation
                    case 1:
                        //Hide Response Panel
                        panelTitle.value = i18n.global.t('ResetPassword');
                        break;

                    //Valid Password reset request but time period has lapsed
                    case 2:
                        //Change the Response panel header
                        panelTitle.value = i18n.global.t('ConfirmPasswordReset_LapsedResetLink_PanelTitle');

                        //Show error message
                        panelMessage.value = i18n.global.t('ConfirmPasswordReset_LapsedResetLink_PanelMessage');
                        break;

                    //Invalid Password reset request
                    default:
                        //Change the Response panel header
                        panelTitle.value = i18n.global.t('InvalidPasswordResetLink');

                        //Show invalid reset link message
                        panelMessage.value = i18n.global.t('ConfirmPasswordReset_InvalidResetLink_PanelMessage');
                        break;
                }
            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    // Generic error message if the error structure is not as expected
                    panelTitle.value = i18n.global.t('PasswordReset');
                    panelMessage.value =
                        i18n.global.t('ConfirmPasswordReset_ResetFail_PanelMessage1') +
                        '\n\n' +
                        i18n.global.t('ConfirmPasswordReset_ResetFail_PanelMessage2');
                }
            }
        }

        /**
         * Call API to attempt to save user password
         */
        async function savePassword() {
            // Perform final client side validation of form
            await validate();

            if (valid.value) {
                try {
                    resetResponse.value = await apiService.post('users/confirm-password-reset', {
                        UserId: route.params.userID,
                        ResetTokenString: route.params.resetToken,
                        NewPassword: newPassword.value,
                    });
                    switch (
                        resetResponse.value //Valid Password reset request (for checking the validity of the link before showing the reset password form)
                    ) {
                        case 1:
                            //Hide Response Panel
                            panelTitle.value = i18n.global.t('ResetPassword');
                            errorMessage.value = i18n.global.t('WrongPasswordFormat');
                            break;

                        //Valid Password reset request but time period has lapsed
                        case 2:
                            //Change the Response panel header
                            panelTitle.value = i18n.global.t('ConfirmPasswordReset_LapsedResetLink_PanelTitle');

                            //Show error message
                            panelMessage.value = i18n.global.t('ConfirmPasswordReset_LapsedResetLink_PanelMessage');
                            break;

                        //Valid Password reset request, and the password reset is successful
                        case 3:
                            //Change the Response panel header
                            panelTitle.value = i18n.global.t('PasswordResetSuccess');

                            //Show error message
                            panelMessage.value = i18n.global.t('ConfirmPasswordReset_ResetSuccess_PanelMessage');
                            break;

                        //Invalid Password reset request
                        default:
                            //Change the Response panel header
                            panelTitle.value = i18n.global.t('InvalidPasswordResetLink');

                            //Show invalid reset link message
                            panelMessage.value = i18n.global.t('ConfirmPasswordReset_InvalidResetLink_PanelMessage');
                            break;
                    }
                } catch (error) {
                    if (axios.isAxiosError(error) && error.response) {
                        // Generic error message if the error structure is not as expected
                        panelTitle.value = i18n.global.t('PasswordReset');
                        panelMessage.value =
                            i18n.global.t('ConfirmPasswordReset_ResetFail_PanelMessage1') +
                            '\n\n' +
                            i18n.global.t('ConfirmPasswordReset_ResetFail_PanelMessage2');
                    }
                }
            }
        }

        /**
         * Validate the form.
         */
        const validate = async (): Promise<boolean> => {
            if (form.value) {
                const {valid} = await form.value.validate();
                return valid;
            }
            return false;
        };

        onMounted(() => {
            validateResetToken();
        });

        return {
            // FontAwesome
            byPrefixAndName,

            // Stores
            userStore,
            configStore,

            // Services
            apiService,
            router,

            // Form fields
            newPassword,
            repeatPassword,
            passwordValidator,
            form,
            valid,
            errorMessage,
            resetResponse,
            passwordResetHours,
            panelMessage,
            panelTitle,

            // Rules
            newPasswordRules,
            reapeatPasswordRules,

            // Functions
            savePassword,
        };
    },
});
</script>

<style scoped>
body.resetForm {
    background-image: none;
}

.resetForm {
    width: 50rem;
    margin: 0px auto;
}
</style>
