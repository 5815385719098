<template>
    <v-toolbar class="swatchA2BG">
        <FontAwesomeIcon :icon="['fac', 'crop-variety']" size="xl" class="swatchWHT ml-3" />
        <v-toolbar-title class="swatchWHT crop-data-table-title"
            >{{ $t('CropsAndCropVarieties') + ` (${resultsList.length})` }}
        </v-toolbar-title>
        <v-tabs v-model="tab" bg-color="swatchA2" selected-class="swatchWHTBG swatchBLK" class="crop-variety-tab">
            <v-tab value="PrimaryCropVarieties">
                <FontAwesomeIcon :icon="['fac', 'sugarcane']" size="xl" class="mr-2" />
                {{ $t('PrimaryCropVarieties') + ` (${primaryList.length})` }}
            </v-tab>
            <v-tab value="SecondaryCropVarieties">
                <FontAwesomeIcon :icon="['fac', 'soybeans']" size="xl" class="mr-2" />
                {{ $t('SecondaryCropVarieties') + ` (${secondaryList.length})` }}
            </v-tab>
        </v-tabs>

        <v-spacer></v-spacer>

        <v-btn :class="filterButtonClass" rounded="1" @click="switchBackToSearch">
            <template v-slot:prepend>
                <FontAwesomeIcon :icon="['fal', 'filter']" size="xl" v-if="isFilterApplied" />
                <FontAwesomeIcon :icon="['fac', 'filter-check']" size="xl" v-else />
            </template>
            {{ $t('Filters') }}
        </v-btn>
    </v-toolbar>

    <v-tabs-window v-model="tab" style="overflow: visible">
        <v-tabs-window-item value="PrimaryCropVarieties">
            <CropVarietyTable
                :filtered-list="primaryList"
                :is-primary="true"
                :key="'' + primaryList.length + (filterCropTypeId ?? '-1')" />
        </v-tabs-window-item>
        <v-tabs-window-item value="SecondaryCropVarieties">
            <CropVarietyTable
                :filtered-list="secondaryList"
                :key="'' + secondaryList.length + (filterCropTypeId ?? '-1')" />
        </v-tabs-window-item>
    </v-tabs-window>
</template>

<script setup lang="ts">
import {ref, computed} from 'vue';
import {CropVarietyDto} from '@/models/data-transfer-objects/search/crop-variety-search/CropVarietySearchDto';
import CropVarietyTable from '@/components/CropVarietyTable.vue';

const props = withDefaults(
    defineProps<{
        resultsList: CropVarietyDto[];
        isFilterApplied: boolean;
    }>(),
    {
        resultsList: () => [] as CropVarietyDto[],
        isFilterApplied: false,
    },
);

const emit = defineEmits<{
    (event: 'switch-to-search'): void;
}>();

const switchBackToSearch = () => {
    emit('switch-to-search');
};

const tab = ref<string>('PrimaryCropVarieties');

const filterCropTypeId = ref<number | null>(null);

const primaryList = computed(() =>
    props.resultsList.filter(
        (e) => e.isPrimary && (filterCropTypeId.value === null || e.cropTypeId === filterCropTypeId.value),
    ),
);
const secondaryList = computed(() =>
    props.resultsList.filter(
        (e) => !e.isPrimary && (filterCropTypeId.value === null || e.cropTypeId === filterCropTypeId.value),
    ),
);

const filterButtonClass = computed(() => (props.isFilterApplied ? ['v-btn--custom'] : ['swatchB6BG', 'swatchWHT']));
</script>
<style scoped lang="scss">
.crop-data-table-title {
    font-size: 1rem;
    max-width: 300px;
}
</style>
