import { WorkTaskDueDateTiming } from '@/enums/work-task-due-date-timing';
import {defineStore} from 'pinia';

export const useRescheduleWorkTaskModalStore = defineStore('rescheduleWorkTaskModal', {
    state: () => ({
        isVisible: false,
        workTaskId: null as number | null,
        dueDateTiming: null as WorkTaskDueDateTiming | null,
        dueDateLatest: null as Date | null,
        savedCounter: 0,
    }),
    actions: {
        open(workTaskId: number, dueDateTiming: WorkTaskDueDateTiming | null = null, dueDateLatest: Date | null = null) {
            // Close modal first (if it's already open) so that the watch is triggered when it's reopened
            this.close();

            this.workTaskId = workTaskId;
            this.dueDateTiming = dueDateTiming;
            this.dueDateLatest = dueDateLatest;
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
            this.workTaskId = null;
            this.dueDateTiming = null;
            this.dueDateLatest = null;
        },
    },
});
