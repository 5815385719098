<template>
    <div>
        <v-dialog v-model="isVisible">
            <v-card>
                <v-form ref="form" class="pa-4" style="overflow-y: scroll; height: 100%" @keyup.enter="search">
                    <work-task-search-parameters v-model:searchParameters="localSearchParametersWorkTask" />
                </v-form>
                <v-card-actions class="swatchG9BG">
                    <v-btn @click="isVisible = false">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                        </template>
                        {{ $t('Cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="search" :disabled="isLoading">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'search']" size="xl" />
                        </template>
                        {{ $t('Search') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <LoadingSymbol v-if="isLoading" />

        <work-task-search-results
            v-else
            @switch-to-search="openSearchModal"
            @export-task-list="exportTaskList"
            :open-task-default="defaultTab"
            :is-filter-applied="isFilterApplied"
            :results-list="searchResultsWorkTask" />
        <v-snackbar v-model="snackbar.show" timeout="4000">
            {{ snackbar.text }}
        </v-snackbar>
    </div>
</template>

<script setup lang="ts">
import {computed, ref, inject, onMounted, watch} from 'vue';
import ApiService from '@/services/api-service.js';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {SearchWorkTasksResponse} from '@/models/api/responses/search/SearchWorkTasksResponse';
import WorkTaskSearchParameters from '@/views/search/WorkTaskSearchParameters.vue';
import WorkTaskSearchResults from '@/views/search/WorkTaskSearchResults.vue';
import {useFarmStore} from '@/stores/farm-store';
const farmStore = useFarmStore();
import i18n from '@/i18n';
import {format} from 'date-fns';
import {useRoute} from 'vue-router';
const route = useRoute();
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
const homeDashboardStore = useHomeDashboardStore();
import router from '@/router';

// Services
const apiService = inject('apiService') as ApiService;

//Local variables
const isVisible = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const defaultTab = ref<boolean>(true);
const searchParametersWorkTask = ref(new SearchWorkTasksRequest());
const localSearchParametersWorkTask = ref(new SearchWorkTasksRequest());
const searchResultsWorkTask = ref<WorkTaskSearchResultDto[]>([]);
const snackbar = ref({
    show: false,
    text: '',
});

const isFilterApplied = computed(
    () =>
        !!(
            !searchParametersWorkTask.value.isQuickSearch &&
            (searchParametersWorkTask.value.farmFieldIdList === null ||
                searchParametersWorkTask.value.farmFieldIdList.length === 0) &&
            (searchParametersWorkTask.value.workTaskDetails === null ||
                searchParametersWorkTask.value.workTaskDetails === '') &&
            (searchParametersWorkTask.value.workTaskStatus === null ||
                searchParametersWorkTask.value.workTaskStatus === '') &&
            (searchParametersWorkTask.value.workTaskTypeCodeList === null ||
                searchParametersWorkTask.value.workTaskTypeCodeList.length === 0) &&
            (searchParametersWorkTask.value.userIdList === null ||
                searchParametersWorkTask.value.userIdList.length === 0) &&
            searchParametersWorkTask.value.farmSiteIdList !== null &&
            searchParametersWorkTask.value.farmSiteIdList.length === 1 &&
            searchParametersWorkTask.value.farmSiteIdList[0] === farmStore.selectedSiteId &&
            (searchParametersWorkTask.value.dateRaisedFrom === null ||
                searchParametersWorkTask.value.dateRaisedFrom === '') &&
            (searchParametersWorkTask.value.dateRaisedTo === null ||
                searchParametersWorkTask.value.dateRaisedTo === '') &&
            (searchParametersWorkTask.value.dateDueFrom === null ||
                searchParametersWorkTask.value.dateDueFrom === '') &&
            (searchParametersWorkTask.value.dateDueTo === null || searchParametersWorkTask.value.dateDueTo === '') &&
            (searchParametersWorkTask.value.dateConcludedFrom === null ||
                searchParametersWorkTask.value.dateConcludedFrom === '') &&
            (searchParametersWorkTask.value.dateConcludedTo === null ||
                searchParametersWorkTask.value.dateConcludedTo === '')
        ),
);

/**
 * Open search modal
 */
async function openSearchModal() {
    isVisible.value = true;
}

/**
 * Save the modal.
 */
const search = async () => {
    isLoading.value = true;

    searchParametersWorkTask.value = Object.assign(new SearchWorkTasksRequest(), localSearchParametersWorkTask.value);
    const response: SearchWorkTasksResponse = await apiService.post(
        'search/work-tasks',
        searchParametersWorkTask.value,
    );

    if (searchParametersWorkTask.value && searchParametersWorkTask.value.workTaskStatus) {
        if (
            searchParametersWorkTask.value.workTaskStatus.includes('Completed') ||
            searchParametersWorkTask.value.workTaskStatus.includes('Cancelled')
        )
            defaultTab.value = false;
        else if (
            searchParametersWorkTask.value.workTaskStatus.includes('Overdue') ||
            searchParametersWorkTask.value.workTaskStatus.includes('Incomplete') ||
            searchParametersWorkTask.value.workTaskStatus.includes('Due')
        )
            defaultTab.value = true;
    }

    if (response && response.workTasks && response.workTasks.length > 0) {
        searchResultsWorkTask.value = response.workTasks;
    }

    searchParametersWorkTask.value.mixtureIdList = [];

    isLoading.value = false;
    isVisible.value = false;
};

//Export Task List to CSV file
const exportTaskList = async () => {
    try {
        const fileName = i18n.global.t('Tasks') + '_' + format(new Date(), 'yyyyMMddHHmm') + '.csv';
        await apiService.downloadFilePost('search/work-tasks/export', searchParametersWorkTask.value, null, fileName);
    } catch (error) {
        // Show fail feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ExportCSVError');
        isLoading.value = false;
    }
};

const loadTaskList = async (val: string | string[] | null) => {
    const isFilterFromHome = !!homeDashboardStore.workTaskListFilter;
    if (homeDashboardStore.workTaskListFilter) {
        localSearchParametersWorkTask.value = {...homeDashboardStore.workTaskListFilter};
        homeDashboardStore.workTaskListFilter = null;
        defaultTab.value = homeDashboardStore.isTaskFutureMode;
    } else localSearchParametersWorkTask.value = new SearchWorkTasksRequest();
    if (farmStore.selectedSiteId) localSearchParametersWorkTask.value.farmSiteIdList = [farmStore.selectedSiteId];
    if (homeDashboardStore.parameterFarmFields.length > 0) {
        localSearchParametersWorkTask.value.farmFieldIdList = [...homeDashboardStore.parameterFarmFields];
        homeDashboardStore.parameterFarmFields = [];
    }
    if (val && Number.isInteger(Number(val))) {
        localSearchParametersWorkTask.value.mixtureIdList = [Number(val)];
    }
    await search();

    //reset search parameter after searching
    if (isFilterFromHome) {
        localSearchParametersWorkTask.value = new SearchWorkTasksRequest();
        if (farmStore.selectedSiteId) localSearchParametersWorkTask.value.farmSiteIdList = [farmStore.selectedSiteId];
    } else {
        localSearchParametersWorkTask.value = Object.assign(
            new SearchWorkTasksRequest(),
            searchParametersWorkTask.value,
        );
        // Remove mixtureId
        if (val && Number.isInteger(Number(val))) {
            localSearchParametersWorkTask.value.mixtureIdList = [];
            router.replace({
                name: 'WorkTaskList',
                params: {mixtureId: undefined},
            });
        }
    }
};

onMounted(async () => {
    await loadTaskList(route.params.mixtureId);
});

import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();
import {useDeleteUserModalStore} from '@/stores/modals/delete-user-modal-store';
const deleteUserModalStore = useDeleteUserModalStore();

watch(
    [() => deleteOrCancelWorkTaskModalStore.savedCounter, () => deleteUserModalStore.deletedCounter],
    async () => {
        await loadTaskList(route.params.mixtureId);
    },
    {deep: true},
);

watch(
    () => route.params.mixtureId,
    async (val) => {
        if (val && val !== '') await loadTaskList(val);
    },
);

//Watch for the changes to work task List filter
watch(
    () => homeDashboardStore.workTaskListFilter,
    async (val) => {
        if (val) {
            await loadTaskList(null);
            homeDashboardStore.workTaskListFilter = null;
        }
    },
    {deep: true},
);
</script>
