import {HomeDashboardMapSettingCode} from '@/enums/home-dashboard/home-dashboard-map-setting-code';

export class HomeDashboardMapSetting {
    settingCode!: HomeDashboardMapSettingCode;
    settingName: string = '';
    hasOpacity = false;
    isVisible = true;
    opacity: number | null = null;

    constructor(data?: Partial<HomeDashboardMapSetting>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
