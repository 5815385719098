import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e4f3d6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pr-2",
  style: {"padding-left":"5px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_CircleIcon = _resolveComponent("CircleIcon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock(_component_v_tooltip, { bottom: "" }, {
    activator: _withCtx(({props}) => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.backgroundClass)
      }, [
        _createVNode(_component_CircleIcon, _mergeProps(props, {
          style: {height: _ctx.iconHeight + 'px', width: _ctx.iconWidth + 'px', fontSize: _ctx.iconSize + 'pt'},
          class: _ctx.iconClass
        }), {
          default: _withCtx(() => [
            _createVNode(_component_FontAwesomeIcon, {
              icon: [_ctx.iconFamily, _ctx.iconCode],
              class: _normalizeClass(_ctx.backgroundClass)
            }, null, 8, ["icon", "class"])
          ]),
          _: 2
        }, 1040, ["style", "class"]),
        (_ctx.displayText && _ctx.displayText != '')
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.displayText), 1))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "helpText", {}, undefined, true),
      _createTextVNode(" " + _toDisplayString(_ctx.name), 1)
    ]),
    _: 3
  }))
}