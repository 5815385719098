<template>
    <div class="d-flex justify-center swatchA1">
        <v-progress-circular :size="90" class="ma-4"> {{ $t('Loading_Ellipsis') }} </v-progress-circular>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'LoadingSymbol',
});
</script>
