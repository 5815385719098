<template>
    <v-toolbar class="swatchA2BG">
        <FontAwesomeIcon :icon="['fal', 'list-timeline']" size="xl" class="swatchWHT ml-3" />
        <v-toolbar-title class="swatchWHT task-data-table-title"
            >{{ $t('TaskPlans') + ` (${resultsList.length})` }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
            class="v-btn--custom mr-2"
            rounded="1"
            @click="manageWorkTaskPlanModalStore.open(ManageWorkTaskPlanModalMode.Create)">
            <template v-slot:prepend>
                <FontAwesomeIcon :icon="['fal', 'plus']" size="xl" />
            </template>
            {{ $t('Add') }}
        </v-btn>

        <v-btn
            :class="filterButtonClass"
            rounded="1"
            @click="switchBackToSearch"
            v-if="userStore.hasRoleLevel(RoleCode.PlansAndTemplates, 3)">
            <template v-slot:prepend>
                <FontAwesomeIcon :icon="['fal', 'filter']" size="xl" v-if="isFilterApplied" />
                <FontAwesomeIcon :icon="['fac', 'filter-check']" size="xl" v-else />
            </template>
            {{ $t('Filters') }}
        </v-btn>
    </v-toolbar>

    <v-data-table
        class="plan-table-list"
        :headers="headers"
        :items="resultsList"
        :items-per-page="configStore.defaultListItemNumberPerPage"
        @click:row="handleResultClick">
        <template v-slot:[`item.workTaskPlanName`]="{item}">
            <div class="tableCell">
                <b class="swatchA1 slightlyLarger"> {{ item.workTaskPlanName }} </b>
            </div>
        </template>

        <template v-slot:[`item.workTaskPlanItems`]="{item}">
            <v-row v-if="item.workTaskPlanItems && item.workTaskPlanItems.length > 0" no-gutters>
                <span
                    class="pr-1"
                    v-for="workTaskPlanItem in item.workTaskPlanItems"
                    :key="workTaskPlanItem.workTaskPlanItemId">
                    <WorkTaskTypeIcon :workTaskTypeCode="workTaskPlanItem.workTaskTypeCode" />
                </span>
            </v-row>
        </template>

        <template v-slot:[`item.createdDate`]="{item}">
            <v-row class="swatchA1">
                {{ formatDateOnly(item.createdDate) }}
                <span class="pr-4"></span>
                <UserChip
                    v-if="item.createdUserId"
                    :user="{
                        userId: item.createdUserId,
                        username: `${item.createdUserGivenName} ${item.createdUserSurname}`,
                        profilePicture: '',
                    }" />
            </v-row>
        </template>

        <template v-slot:[`item.modifiedDate`]="{item}">
            <v-row class="swatchA1">
                {{ formatDateOnly(item.modifiedDate) }}
                <span class="pr-4"></span>
                <UserChip
                    v-if="item.modifiedUserId"
                    :user="{
                        userId: item.modifiedUserId,
                        username: `${item.modifiedUserGivenName} ${item.modifiedUserSurname}`,
                        profilePicture: '',
                    }" />
            </v-row>
        </template>

        <template v-slot:[`item.numberOfTimesApplied`]="{item}">
            <v-col align="center" v-if="item.numberOfTimesApplied !== null"
                ><WorkTaskPlanNumberOfTimesAppliedChip :number-of-times-applied="item.numberOfTimesApplied"
            /></v-col>
        </template>
        <template v-slot:[`item.action`]="{item}">
            <v-col align="center" v-if="item.numberOfTimesApplied !== null"
                ><WorkTaskPlanOperationsMenu :work-task-plan="item" :menu-text="$t('Action')"
            /></v-col>
        </template>
    </v-data-table>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import i18n from '@/i18n';
import {format} from 'date-fns';
import UserChip from '@/components/UserChip.vue';
import {useViewWorkTaskPlanModalStore} from '@/stores/modals/view-work-task-plan-modal-store';
const viewWorkTaskPlanModalStore = useViewWorkTaskPlanModalStore();
import {useConfigStore} from '@/stores/config-store';
const configStore = useConfigStore();
import {useManageWorkTaskPlanModalStore} from '@/stores/modals/manage-work-task-plan-modal-store';
const manageWorkTaskPlanModalStore = useManageWorkTaskPlanModalStore();
import {ManageWorkTaskPlanModalMode} from '@/enums/modal-modes/manage-work-task-plan-modal-mode';
import {WorkTaskPlanSearchResultDto} from '@/models/data-transfer-objects/search/work-task-plan-search/WorkTaskPlanSearchResultDto';
import WorkTaskPlanNumberOfTimesAppliedChip from '@/components/other-chips/WorkTaskPlanNumberOfTimesAppliedChip.vue';
import WorkTaskPlanOperationsMenu from '@/components/operations-menus/WorkTaskPlanOperationsMenu.vue';
import {useUserStore} from '@/stores/user-store';
const userStore = useUserStore();
import {RoleCode} from '@/enums/role-code';

const props = withDefaults(
    defineProps<{
        resultsList: WorkTaskPlanSearchResultDto[];
        isFilterApplied?: boolean;
    }>(),
    {
        resultsList: () => [] as WorkTaskPlanSearchResultDto[],
        isFilterApplied: false,
    },
);

const headers = computed(() => [
    {title: i18n.global.t('TaskPlanName'), key: 'workTaskPlanName', sortable: true},
    {title: i18n.global.t('Summary'), key: 'workTaskPlanItems', sortable: true},
    {title: i18n.global.t('Created'), key: 'createdDate', sortable: true},
    {title: i18n.global.t('LastModified'), key: 'modifiedDate', sortable: false},
    {title: i18n.global.t('TimesUsed'), key: 'numberOfTimesApplied', sortable: true},
    {title: i18n.global.t('Action'), key: 'action', sortable: false},
]);

/**
 * Handle click event on a result.
 */
const handleResultClick = (event: MouseEvent, row: {item: WorkTaskPlanSearchResultDto}) => {
    viewWorkTaskPlanModalStore.open(row.item.workTaskPlanId);
};

const filterButtonClass = computed(() => (props.isFilterApplied ? ['v-btn--custom'] : ['swatchB6BG', 'swatchWHT']));

const emit = defineEmits<{
    (event: 'switch-to-search'): void;
}>();

const switchBackToSearch = () => {
    emit('switch-to-search');
};

const formatDateOnly = (date: Date | null): string => (!date ? '' : format(new Date(date), 'dd MMM, yyyy'));
</script>

<style lang="scss">
@import '@/assets/scss/swatches.scss';

.plan-table-list .v-table__wrapper thead {
    background-color: $swatchG9;
}

.plan-table-list .v-table__wrapper {
    overflow-y: hidden;
}

.shrink-component {
    transform: scale(0.8);
    display: inline-block;
}
</style>
