<template>
    <v-tooltip bottom v-if="fieldAge === '1'">
        <template v-slot:activator="{props}">
            <svg
                v-bind="props"
                :width="iconWidth"
                :height="iconHeight"
                xmlns:ns0="http://www.w3.org/2000/svg"
                id="Layer_2"
                viewBox="0 0 300 300">
                <g id="Layer_1-2">
                    <circle class="cls-3" cx="150" cy="150" r="150" />
                    <path
                        class="cls-2"
                        d="M146,12.24v123.9c0,3.08-3.33,5-6,3.46l-107.31-61.95c-1.99-1.15-2.61-3.75-1.34-5.67C55.32,35.59,95.58,10.87,141.76,8.24c2.3-.13,4.24,1.7,4.24,4Z" />
                    <path
                        class="cls-1"
                        d="M267.31,77.65l-107.31,61.95c-2.67,1.54-6-.39-6-3.46V12.24c0-2.3,1.94-4.13,4.24-4,46.18,2.64,86.44,27.35,110.42,63.74,1.27,1.92.65,4.52-1.34,5.67Z"
                        style="fill: rgb(0, 128, 78)" />
                    <path
                        class="cls-2"
                        d="M292,150c0,22.93-5.44,44.59-15.09,63.77-1.03,2.05-3.59,2.81-5.58,1.66l-107.33-61.97c-2.67-1.54-2.67-5.39,0-6.93l107.33-61.97c1.99-1.15,4.55-.39,5.58,1.66,9.65,19.17,15.09,40.84,15.09,63.77Z" />
                    <path
                        class="cls-2"
                        d="M268.66,228.02c-23.98,36.4-64.24,61.11-110.42,63.74-2.3.13-4.24-1.7-4.24-4v-123.9c0-3.08,3.33-5,6-3.46l107.31,61.95c1.99,1.15,2.61,3.75,1.34,5.67Z" />
                    <path
                        class="cls-2"
                        d="M146,163.86v123.9c0,2.3-1.94,4.13-4.24,4-46.18-2.64-86.44-27.35-110.42-63.74-1.27-1.92-.65-4.52,1.34-5.67l107.31-61.95c2.67-1.54,6,.39,6,3.46Z" />
                    <path
                        class="cls-2"
                        d="M136,153.46l-107.33,61.97c-1.99,1.15-4.55.39-5.58-1.66-9.65-19.17-15.09-40.84-15.09-63.77s5.44-44.59,15.09-63.77c1.03-2.05,3.59-2.81,5.58-1.66l107.33,61.97c2.67,1.54,2.67,5.39,0,6.93Z" />
                </g>
                <text x="100" y="220" font-size="200" class="svg-text noSelection">1</text>
            </svg>
        </template>
        {{ $t('FieldAgeIcon_FirstYear_HelpText') }}
    </v-tooltip>
    <v-tooltip bottom v-if="fieldAge === '2'">
        <template v-slot:activator="{props}">
            <svg
                v-bind="props"
                :width="iconWidth"
                :height="iconHeight"
                xmlns:ns0="http://www.w3.org/2000/svg"
                id="Layer_2"
                viewBox="0 0 300 300">
                <g id="Layer_1-2">
                    <circle class="cls-3" cx="150" cy="150" r="150" />
                    <path
                        class="cls-2"
                        d="M146,12.24v123.9c0,3.08-3.33,5-6,3.46l-107.31-61.95c-1.99-1.15-2.61-3.75-1.34-5.67C55.32,35.59,95.58,10.87,141.76,8.24c2.3-.13,4.24,1.7,4.24,4Z" />
                    <path
                        class="cls-1"
                        d="M267.31,77.65l-107.31,61.95c-2.67,1.54-6-.39-6-3.46V12.24c0-2.3,1.94-4.13,4.24-4,46.18,2.64,86.44,27.35,110.42,63.74,1.27,1.92.65,4.52-1.34,5.67Z" />
                    <path
                        class="cls-2"
                        d="M292,150c0,22.93-5.44,44.59-15.09,63.77-1.03,2.05-3.59,2.81-5.58,1.66l-107.33-61.97c-2.67-1.54-2.67-5.39,0-6.93l107.33-61.97c1.99-1.15,4.55-.39,5.58,1.66,9.65,19.17,15.09,40.84,15.09,63.77Z" />
                    <path
                        class="cls-2"
                        d="M268.66,228.02c-23.98,36.4-64.24,61.11-110.42,63.74-2.3.13-4.24-1.7-4.24-4v-123.9c0-3.08,3.33-5,6-3.46l107.31,61.95c1.99,1.15,2.61,3.75,1.34,5.67Z" />
                    <path
                        class="cls-2"
                        d="M146,163.86v123.9c0,2.3-1.94,4.13-4.24,4-46.18-2.64-86.44-27.35-110.42-63.74-1.27-1.92-.65-4.52,1.34-5.67l107.31-61.95c2.67-1.54,6,.39,6,3.46Z" />
                    <path
                        class="cls-2"
                        d="M136,153.46l-107.33,61.97c-1.99,1.15-4.55.39-5.58-1.66-9.65-19.17-15.09-40.84-15.09-63.77s5.44-44.59,15.09-63.77c1.03-2.05,3.59-2.81,5.58-1.66l107.33,61.97c2.67,1.54,2.67,5.39,0,6.93Z" />
                </g>
                <text x="100" y="220" font-size="200" class="svg-text noSelection">2</text>
            </svg>
        </template>
        {{ $t('FieldAgeIcon_SecondYear_HelpText') }}
    </v-tooltip>
    <v-tooltip bottom v-if="fieldAge === '3'">
        <template v-slot:activator="{props}">
            <svg
                v-bind="props"
                :width="iconWidth"
                :height="iconHeight"
                xmlns:ns0="http://www.w3.org/2000/svg"
                id="Layer_2"
                viewBox="0 0 300 300">
                <g id="Layer_1-2">
                    <circle class="cls-3" cx="150" cy="150" r="150" />
                    <path
                        class="cls-2"
                        d="M146,12.24v123.9c0,3.08-3.33,5-6,3.46l-107.31-61.95c-1.99-1.15-2.61-3.75-1.34-5.67C55.32,35.59,95.58,10.87,141.76,8.24c2.3-.13,4.24,1.7,4.24,4Z" />
                    <path
                        class="cls-1"
                        d="M267.31,77.65l-107.31,61.95c-2.67,1.54-6-.39-6-3.46V12.24c0-2.3,1.94-4.13,4.24-4,46.18,2.64,86.44,27.35,110.42,63.74,1.27,1.92.65,4.52-1.34,5.67Z" />
                    <path
                        class="cls-2"
                        d="M292,150c0,22.93-5.44,44.59-15.09,63.77-1.03,2.05-3.59,2.81-5.58,1.66l-107.33-61.97c-2.67-1.54-2.67-5.39,0-6.93l107.33-61.97c1.99-1.15,4.55-.39,5.58,1.66,9.65,19.17,15.09,40.84,15.09,63.77Z"
                        style="fill: rgb(157, 104, 72)" />
                    <path
                        class="cls-2"
                        d="M268.66,228.02c-23.98,36.4-64.24,61.11-110.42,63.74-2.3.13-4.24-1.7-4.24-4v-123.9c0-3.08,3.33-5,6-3.46l107.31,61.95c1.99,1.15,2.61,3.75,1.34,5.67Z" />
                    <path
                        class="cls-2"
                        d="M146,163.86v123.9c0,2.3-1.94,4.13-4.24,4-46.18-2.64-86.44-27.35-110.42-63.74-1.27-1.92-.65-4.52,1.34-5.67l107.31-61.95c2.67-1.54,6,.39,6,3.46Z" />
                    <path
                        class="cls-2"
                        d="M136,153.46l-107.33,61.97c-1.99,1.15-4.55.39-5.58-1.66-9.65-19.17-15.09-40.84-15.09-63.77s5.44-44.59,15.09-63.77c1.03-2.05,3.59-2.81,5.58-1.66l107.33,61.97c2.67,1.54,2.67,5.39,0,6.93Z" />
                </g>
                <text x="100" y="220" font-size="200" class="svg-text noSelection">3</text>
            </svg>
        </template>
        {{ $t('FieldAgeIcon_ThirdYear_HelpText') }}
    </v-tooltip>
    <v-tooltip bottom v-if="fieldAge === '4'">
        <template v-slot:activator="{props}">
            <svg
                v-bind="props"
                :width="iconWidth"
                :height="iconHeight"
                xmlns:ns0="http://www.w3.org/2000/svg"
                id="Layer_2"
                viewBox="0 0 300 300">
                <g id="Layer_1-2">
                    <circle class="cls-3" cx="150" cy="150" r="150" />
                    <path
                        class="cls-2"
                        d="M146,12.24v123.9c0,3.08-3.33,5-6,3.46l-107.31-61.95c-1.99-1.15-2.61-3.75-1.34-5.67C55.32,35.59,95.58,10.87,141.76,8.24c2.3-.13,4.24,1.7,4.24,4Z" />
                    <path
                        class="cls-1"
                        d="M267.31,77.65l-107.31,61.95c-2.67,1.54-6-.39-6-3.46V12.24c0-2.3,1.94-4.13,4.24-4,46.18,2.64,86.44,27.35,110.42,63.74,1.27,1.92.65,4.52-1.34,5.67Z" />
                    <path
                        class="cls-2"
                        d="M292,150c0,22.93-5.44,44.59-15.09,63.77-1.03,2.05-3.59,2.81-5.58,1.66l-107.33-61.97c-2.67-1.54-2.67-5.39,0-6.93l107.33-61.97c1.99-1.15,4.55-.39,5.58,1.66,9.65,19.17,15.09,40.84,15.09,63.77Z"
                        style="fill: rgb(157, 104, 72)" />
                    <path
                        class="cls-2"
                        d="M268.66,228.02c-23.98,36.4-64.24,61.11-110.42,63.74-2.3.13-4.24-1.7-4.24-4v-123.9c0-3.08,3.33-5,6-3.46l107.31,61.95c1.99,1.15,2.61,3.75,1.34,5.67Z"
                        style="fill: rgb(157, 104, 72)" />
                    <path
                        class="cls-2"
                        d="M146,163.86v123.9c0,2.3-1.94,4.13-4.24,4-46.18-2.64-86.44-27.35-110.42-63.74-1.27-1.92-.65-4.52,1.34-5.67l107.31-61.95c2.67-1.54,6,.39,6,3.46Z" />
                    <path
                        class="cls-2"
                        d="M136,153.46l-107.33,61.97c-1.99,1.15-4.55.39-5.58-1.66-9.65-19.17-15.09-40.84-15.09-63.77s5.44-44.59,15.09-63.77c1.03-2.05,3.59-2.81,5.58-1.66l107.33,61.97c2.67,1.54,2.67,5.39,0,6.93Z" />
                </g>
                <text x="100" y="220" font-size="200" class="svg-text noSelection">4</text>
            </svg>
        </template>
        {{ $t('FieldAgeIcon_FourthYear_HelpText') }}
    </v-tooltip>
    <v-tooltip bottom v-if="fieldAge === '5'">
        <template v-slot:activator="{props}">
            <svg
                v-bind="props"
                :width="iconWidth"
                :height="iconHeight"
                xmlns:ns0="http://www.w3.org/2000/svg"
                id="Layer_2"
                viewBox="0 0 300 300">
                <g id="Layer_1-2">
                    <circle class="cls-3" cx="150" cy="150" r="150" />
                    <path
                        class="cls-2"
                        d="M146,12.24v123.9c0,3.08-3.33,5-6,3.46l-107.31-61.95c-1.99-1.15-2.61-3.75-1.34-5.67C55.32,35.59,95.58,10.87,141.76,8.24c2.3-.13,4.24,1.7,4.24,4Z" />
                    <path
                        class="cls-1"
                        d="M267.31,77.65l-107.31,61.95c-2.67,1.54-6-.39-6-3.46V12.24c0-2.3,1.94-4.13,4.24-4,46.18,2.64,86.44,27.35,110.42,63.74,1.27,1.92.65,4.52-1.34,5.67Z" />
                    <path
                        class="cls-2"
                        d="M292,150c0,22.93-5.44,44.59-15.09,63.77-1.03,2.05-3.59,2.81-5.58,1.66l-107.33-61.97c-2.67-1.54-2.67-5.39,0-6.93l107.33-61.97c1.99-1.15,4.55-.39,5.58,1.66,9.65,19.17,15.09,40.84,15.09,63.77Z"
                        style="fill: rgb(157, 104, 72)" />
                    <path
                        class="cls-2"
                        d="M268.66,228.02c-23.98,36.4-64.24,61.11-110.42,63.74-2.3.13-4.24-1.7-4.24-4v-123.9c0-3.08,3.33-5,6-3.46l107.31,61.95c1.99,1.15,2.61,3.75,1.34,5.67Z"
                        style="fill: rgb(157, 104, 72)" />
                    <path
                        class="cls-2"
                        d="M146,163.86v123.9c0,2.3-1.94,4.13-4.24,4-46.18-2.64-86.44-27.35-110.42-63.74-1.27-1.92-.65-4.52,1.34-5.67l107.31-61.95c2.67-1.54,6,.39,6,3.46Z"
                        style="fill: rgb(157, 104, 72)" />
                    <path
                        class="cls-2"
                        d="M136,153.46l-107.33,61.97c-1.99,1.15-4.55.39-5.58-1.66-9.65-19.17-15.09-40.84-15.09-63.77s5.44-44.59,15.09-63.77c1.03-2.05,3.59-2.81,5.58-1.66l107.33,61.97c2.67,1.54,2.67,5.39,0,6.93Z" />
                </g>
                <text x="100" y="220" font-size="200" class="svg-text noSelection">5</text>
            </svg>
        </template>
        {{ $t('FieldAgeIcon_FifthYear_HelpText') }}
    </v-tooltip>
    <v-tooltip bottom v-if="fieldAge === '6'">
        <template v-slot:activator="{props}">
            <svg
                v-bind="props"
                :width="iconWidth"
                :height="iconHeight"
                xmlns:ns0="http://www.w3.org/2000/svg"
                id="Layer_2"
                viewBox="0 0 300 300">
                <g id="Layer_1-2">
                    <circle class="cls-3" cx="150" cy="150" r="150" />
                    <path
                        class="cls-2"
                        d="M146,12.24v123.9c0,3.08-3.33,5-6,3.46l-107.31-61.95c-1.99-1.15-2.61-3.75-1.34-5.67C55.32,35.59,95.58,10.87,141.76,8.24c2.3-.13,4.24,1.7,4.24,4Z" />
                    <path
                        class="cls-1"
                        d="M267.31,77.65l-107.31,61.95c-2.67,1.54-6-.39-6-3.46V12.24c0-2.3,1.94-4.13,4.24-4,46.18,2.64,86.44,27.35,110.42,63.74,1.27,1.92.65,4.52-1.34,5.67Z" />
                    <path
                        class="cls-2"
                        d="M292,150c0,22.93-5.44,44.59-15.09,63.77-1.03,2.05-3.59,2.81-5.58,1.66l-107.33-61.97c-2.67-1.54-2.67-5.39,0-6.93l107.33-61.97c1.99-1.15,4.55-.39,5.58,1.66,9.65,19.17,15.09,40.84,15.09,63.77Z"
                        style="fill: rgb(157, 104, 72)" />
                    <path
                        class="cls-2"
                        d="M268.66,228.02c-23.98,36.4-64.24,61.11-110.42,63.74-2.3.13-4.24-1.7-4.24-4v-123.9c0-3.08,3.33-5,6-3.46l107.31,61.95c1.99,1.15,2.61,3.75,1.34,5.67Z"
                        style="fill: rgb(157, 104, 72)" />
                    <path
                        class="cls-2"
                        d="M146,163.86v123.9c0,2.3-1.94,4.13-4.24,4-46.18-2.64-86.44-27.35-110.42-63.74-1.27-1.92-.65-4.52,1.34-5.67l107.31-61.95c2.67-1.54,6,.39,6,3.46Z"
                        style="fill: rgb(157, 104, 72)" />
                    <path
                        class="cls-2"
                        d="M136,153.46l-107.33,61.97c-1.99,1.15-4.55.39-5.58-1.66-9.65-19.17-15.09-40.84-15.09-63.77s5.44-44.59,15.09-63.77c1.03-2.05,3.59-2.81,5.58-1.66l107.33,61.97c2.67,1.54,2.67,5.39,0,6.93Z"
                        style="fill: rgb(157, 104, 72)" />
                </g>
                <text x="100" y="220" font-size="200" class="svg-text noSelection">6</text>
            </svg>
        </template>
        {{ $t('FieldAgeIcon_SixthYear_HelpText') }}
    </v-tooltip>
    <v-tooltip bottom v-if="fieldAge === '0' || fieldAge === 'f' || fieldAge === 'F'">
        <template v-slot:activator="{props}">
            <svg
                v-bind="props"
                :width="iconWidth"
                :height="iconHeight"
                xmlns:ns0="http://www.w3.org/2000/svg"
                id="Layer_2"
                viewBox="0 0 300 300">
                <g id="Layer_1-2">
                    <circle class="cls-3" cx="150" cy="150" r="150" />
                    <path
                        class="cls-2"
                        d="M146,12.24v123.9c0,3.08-3.33,5-6,3.46l-107.31-61.95c-1.99-1.15-2.61-3.75-1.34-5.67C55.32,35.59,95.58,10.87,141.76,8.24c2.3-.13,4.24,1.7,4.24,4Z"
                        style="fill: rgb(157, 104, 72)" />
                    <path
                        class="cls-1"
                        d="M267.31,77.65l-107.31,61.95c-2.67,1.54-6-.39-6-3.46V12.24c0-2.3,1.94-4.13,4.24-4,46.18,2.64,86.44,27.35,110.42,63.74,1.27,1.92.65,4.52-1.34,5.67Z" />
                    <path
                        class="cls-2"
                        d="M292,150c0,22.93-5.44,44.59-15.09,63.77-1.03,2.05-3.59,2.81-5.58,1.66l-107.33-61.97c-2.67-1.54-2.67-5.39,0-6.93l107.33-61.97c1.99-1.15,4.55-.39,5.58,1.66,9.65,19.17,15.09,40.84,15.09,63.77Z"
                        style="fill: rgb(157, 104, 72)" />
                    <path
                        class="cls-2"
                        d="M268.66,228.02c-23.98,36.4-64.24,61.11-110.42,63.74-2.3.13-4.24-1.7-4.24-4v-123.9c0-3.08,3.33-5,6-3.46l107.31,61.95c1.99,1.15,2.61,3.75,1.34,5.67Z"
                        style="fill: rgb(157, 104, 72)" />
                    <path
                        class="cls-2"
                        d="M146,163.86v123.9c0,2.3-1.94,4.13-4.24,4-46.18-2.64-86.44-27.35-110.42-63.74-1.27-1.92-.65-4.52,1.34-5.67l107.31-61.95c2.67-1.54,6,.39,6,3.46Z"
                        style="fill: rgb(157, 104, 72)" />
                    <path
                        class="cls-2"
                        d="M136,153.46l-107.33,61.97c-1.99,1.15-4.55.39-5.58-1.66-9.65-19.17-15.09-40.84-15.09-63.77s5.44-44.59,15.09-63.77c1.03-2.05,3.59-2.81,5.58-1.66l107.33,61.97c2.67,1.54,2.67,5.39,0,6.93Z"
                        style="fill: rgb(157, 104, 72)" />
                </g>
                <text x="100" y="220" font-size="200" class="svg-text noSelection">F</text>
            </svg>
        </template>
        {{ $t('FieldAgeIcon_FallowYear_HelpText') }}
    </v-tooltip>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'FarmFieldAgeIcon',
    props: {
        fieldAge: {type: String},
        iconHeight: {
            type: String,
            default: '40',
        },
        iconWidth: {
            type: String,
            default: '40',
        },
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.svg-text {
    fill: $swatchWHT;
}

.cls-1 {
    fill: $swatchB5;
}
.cls-1,
.cls-2,
.cls-3 {
    stroke-width: 0px;
}
.cls-2 {
    fill: $swatchA3;
}
.cls-3 {
    fill: $swatchA2;
}
</style>
