import {WorkTaskDueDateTiming} from '@/enums/work-task-due-date-timing';
import i18n from '@/i18n';
import {SelectListItem} from '@/models/generic/SelectListItem';
import {ref} from 'vue';

export function useWorkTaskDueDateTimings() {
    const workTaskDueDateTimings = ref<SelectListItem[]>([]);
    const workTaskDueDateTimingsWithNull = ref<SelectListItem[]>([]);

    /**
     * Adds the work task due date timings.
     */
    const setWorkTaskDueDateTimings = () => {
        // Reset array data
        workTaskDueDateTimings.value = [];
        workTaskDueDateTimingsWithNull.value = [];

        // Add items
        workTaskDueDateTimings.value.push({value: WorkTaskDueDateTiming.DueOn, title: i18n.global.t('DueOn')});
        workTaskDueDateTimings.value.push({value: WorkTaskDueDateTiming.DueBy, title: i18n.global.t('DueBy')});
        workTaskDueDateTimings.value.push({
            value: WorkTaskDueDateTiming.DueBetween,
            title: i18n.global.t('DueBetween'),
        });
        workTaskDueDateTimings.value.push({
            value: WorkTaskDueDateTiming.DueAfterTask,
            title: i18n.global.t('DueAfterTask'),
        });
        workTaskDueDateTimings.value.push({
            value: WorkTaskDueDateTiming.DueInMonth,
            title: i18n.global.t('DueInMonth'),
        });

        // Add null value
        workTaskDueDateTimingsWithNull.value = [{value: null, title: ''}, ...workTaskDueDateTimings.value];
    };

    return {
        setWorkTaskDueDateTimings,
        workTaskDueDateTimings,
        workTaskDueDateTimingsWithNull,
    };
}
