import {SwatchCode} from '@/enums/swatch-code';
import {GetSwatchesResponse} from '@/models/api/responses/data-source/GetSwatchesResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useSwatches() {
    const apiService = inject('apiService') as ApiService;
    const swatches = ref<GetSwatchesResponse[]>([]);
    const swatchesWithNull = ref<SelectListItem[]>([]);

    /**
     * Get list of swatches.
     */
    const getSwatches = async () => {
        swatches.value = await apiService.get('data-source/swatches');

        // Add null value
        swatchesWithNull.value = swatches.value.map((swatch) => ({
            value: swatch.swatchCode,
            title: swatch.swatchValue,
        }));

        swatchesWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    /**
     * Gets the value of a specific swatch.
     */
    const getSwatchValue = (swatchCode: SwatchCode): string | undefined => {
        return swatches.value.find((swatch) => swatch.swatchCode.toLowerCase() === swatchCode.toLowerCase())
            ?.swatchValue;
    };

    return {
        getSwatches,
        swatches,
        swatchesWithNull,
        getSwatchValue,
    };
}
