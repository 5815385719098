class PrintHelper {
    public static printDialogContent(content: string, title: string) {
        // Get HTML to print from element
        const prtHtml = content;
    
        // Get all stylesheets HTML
        let stylesHtml = '';
        document.querySelectorAll('link[rel="stylesheet"], style').forEach((node) => {
            stylesHtml += node.outerHTML;
        });
    
        // Open the print window
        const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=1,status=0');
    
        if (WinPrint) {
            WinPrint.document.write(`
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <title>${title}</title>
                ${stylesHtml}
            </head>
            <body>
                ${prtHtml}
            </body>
            </html>
        `);
    
            WinPrint.document.close();
            WinPrint.focus();
            setTimeout(() => {
                WinPrint.print();
                WinPrint.close();
            }, 500);
        }
    }
}

export {PrintHelper};
export default PrintHelper;