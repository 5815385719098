import {GetFarmSitesResponse} from '@/models/api/responses/data-source/GetFarmSitesResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useFarmSites() {
    const apiService = inject('apiService') as ApiService;
    const farmSites = ref<GetFarmSitesResponse[]>([]);
    const farmSitesWithNull = ref<SelectListItem[]>([]);

    /**
     * Get list of farm sites.
     */
    const getFarmSites = async () => {
        farmSites.value = await apiService.get('data-source/farm-sites');

        // Add null value
        farmSitesWithNull.value = farmSites.value.map((farmSite) => ({
            value: farmSite.farmSiteId,
            title: farmSite.farmSiteAbbreviationAndName,
        }));

        farmSitesWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getFarmSites,
        farmSites,
        farmSitesWithNull,
    };
}
