import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createBlock(_component_v_select, {
    class: "farm-selector",
    theme: "farm",
    items: _ctx.items,
    "item-value": "farmSiteId",
    "item-title": "farmSiteAbbreviationAndName",
    modelValue: _ctx.selectedSiteId,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedSiteId) = $event))
  }, null, 8, ["items", "modelValue"]))
}