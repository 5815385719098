export class ManageFarmFieldSeasonForm {
    farmFieldSeasonId: number | null = null;
    farmFieldSeasonStartDate: Date | null = null;
    farmFieldSeasonEndDate: Date | null = null;
    taskDueDateEarliest: Date | null = null;
    taskDueDateLatest: Date | null = null;
    cropYear: number | null = null;
    isActive: boolean = true;
    temporaryIsActive: boolean = true;
    farmFieldId: number | null = null;
    seasonId: number | null = null;
    seasonName: string | null = null;
    yieldTotalWeight: number | null = null;
    yieldAverageCcs: number | null = null;

    constructor(data?: Partial<ManageFarmFieldSeasonForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
