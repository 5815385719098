<template>
    <table style="width: 100%; border-spacing: 1px" class="tblForm swatchG9BG">
        <tr>
            <td class="swatchG7BG">{{ $t('NumberShort') }}</td>
            <td class="swatchG7BG">{{ $t('Type') }}</td>
            <td class="swatchG7BG">{{ $t('TaskTemplate') }}</td>
            <td class="swatchG7BG">{{ $t('DueAfter') }}</td>
            <td class="swatchG7BG">{{ $t('Action') }}</td>
        </tr>
        <tr
            v-for="workTaskPlanItem in workTaskPlanService.workTaskPlanItemsSorted.value"
            :key="workTaskPlanItem.workTaskPlanItemId">
            <td style="width: 7%; text-align: center" class="swatchWHTBG">
                {{ workTaskPlanItem.sequenceNumber }}
            </td>
            <td style="width: 18%; text-align: center" class="swatchWHTBG">
                <WorkTaskTypeChip :workTaskTypeCode="workTaskPlanItem.workTaskTypeCode" />
            </td>
            <td style="width: 40%" class="swatchWHTBG swatchA1 bold">
                {{ workTaskPlanItem.workTaskTemplateName }}
            </td>
            <td style="width: 23%; text-align: center" class="swatchWHTBG swatchA1 bold">
                {{ WorkTaskPlanHelper.getDueAfterText(workTaskPlanItem.numberOfDaysDueAfterPreviousTask) }}
            </td>
            <td style="width: 12%; text-align: center" class="swatchWHTBG">
                <WorkTaskTemplateOperationsMenu
                    v-if="workTaskPlanService.getTaskTemplate(workTaskPlanItem.workTaskTemplateId)"
                    :menu-text="$t('Action')"
                    :work-task-template="workTaskPlanService.getTaskTemplate(workTaskPlanItem.workTaskTemplateId)" />
            </td>
        </tr>
    </table>
</template>

<script setup lang="ts">
import WorkTaskPlanService from '@/services/work-task-plan-service';
import WorkTaskPlanHelper from '@/helpers/WorkTaskPlanHelper';
import WorkTaskTemplateOperationsMenu from '@/components/operations-menus/WorkTaskTemplateOperationsMenu.vue';

defineProps<{
    workTaskPlanService: WorkTaskPlanService;
}>();
</script>
