import {ArtifactFolder} from '@/enums/artifact-folder';
import {ArtifactTypeCode} from '@/enums/artifact-type-code';

export class UploadedArtifactDto {
    file!: File;
    artifactFolder!: ArtifactFolder;
    artifactTypeCode!: ArtifactTypeCode;
    observationId: number | null = null;
    observationActionId: number | null = null;
    userId: number | null = null;
    workTaskId: number | null = null;
    workSubTaskId: number | null = null;

    constructor(data?: Partial<UploadedArtifactDto>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
