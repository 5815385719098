import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
export class WorkTaskPlanSearchParametersDto {
    workTaskPlanId: number | null = null;
    includeWorkTaskPlanItems = false;
    workTaskPlanName: string | null = null;
    workTaskTypeCodeList: WorkTaskTypeCode[] | null = null;
    workTaskTemplateIdList: number[] = [];

    constructor(data?: Partial<WorkTaskPlanSearchParametersDto>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
