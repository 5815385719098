import {WorkTaskActionTypeCode} from '@/enums/work-task-action-type-code';
import i18n from '@/i18n';

export interface WorkTaskActionType {
    workTaskActionTypeCode: WorkTaskActionTypeCode;
    getWorkTaskActionTypeByCode(): string;
    getWorkTaskActionTextTypeByCode(): string;
}

export const workTaskActionTypes: WorkTaskActionType[] = [
    {
        workTaskActionTypeCode: WorkTaskActionTypeCode.Cancelled,
        getWorkTaskActionTypeByCode() {
            return i18n.global.t('Cancelled');
        },
        getWorkTaskActionTextTypeByCode() { 
            return i18n.global.t('TaskCancelled'); 
        },
    },
    {
        workTaskActionTypeCode: WorkTaskActionTypeCode.Completed,
        getWorkTaskActionTypeByCode() {
            return i18n.global.t('Completed');
        },
        getWorkTaskActionTextTypeByCode() { 
            return i18n.global.t('TaskCompleted'); 
        },
    },
    {
        workTaskActionTypeCode: WorkTaskActionTypeCode.Created,
        getWorkTaskActionTypeByCode() {
            return i18n.global.t('Created');
        },
        getWorkTaskActionTextTypeByCode() { 
            return i18n.global.t('TaskCreated'); 
        },
    },
    {
        workTaskActionTypeCode: WorkTaskActionTypeCode.Deleted,
        getWorkTaskActionTypeByCode() {
            return i18n.global.t('Deleted');
        },
        getWorkTaskActionTextTypeByCode() { 
            return i18n.global.t('TaskDeleted'); 
        },
    },
    {
        workTaskActionTypeCode: WorkTaskActionTypeCode.Modified,
        getWorkTaskActionTypeByCode() {
            return i18n.global.t('Modified');
        },
        getWorkTaskActionTextTypeByCode() { 
            return i18n.global.t('TaskModified'); 
        },
    },
    {
        workTaskActionTypeCode: WorkTaskActionTypeCode.Paused,
        getWorkTaskActionTypeByCode() {
            return i18n.global.t('Paused');
        },
        getWorkTaskActionTextTypeByCode() { 
            return i18n.global.t('TaskPaused'); 
        },
    },
    {
        workTaskActionTypeCode: WorkTaskActionTypeCode.Rescheduled,
        getWorkTaskActionTypeByCode() {
            return i18n.global.t('Rescheduled');
        },
        getWorkTaskActionTextTypeByCode() { 
            return i18n.global.t('TaskRescheduled'); 
        },
    },
    {
        workTaskActionTypeCode: WorkTaskActionTypeCode.Restarted,
        getWorkTaskActionTypeByCode() {
            return i18n.global.t('Restarted');
        },
        getWorkTaskActionTextTypeByCode() { 
            return i18n.global.t('TaskRestarted'); 
        },
    },
    {
        workTaskActionTypeCode: WorkTaskActionTypeCode.Started,
        getWorkTaskActionTypeByCode() {
            return i18n.global.t('Started');
        },
        getWorkTaskActionTextTypeByCode() { 
            return i18n.global.t('TaskStarted'); 
        },
    },
];

export function getWorkTaskActionTypeByCode(workTaskActionTypeCode: WorkTaskActionTypeCode): WorkTaskActionType | undefined {
    return workTaskActionTypes.find((type) => type.workTaskActionTypeCode === workTaskActionTypeCode);
}
