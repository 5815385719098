<template>
    <div
        class="observation-type-select-box noSelection"
        :class="{selected: isSelected, disabled: !isEnabled}"
        :title="isEnabled ? '' : $t('TaskTypeCannotBeChanged')">
        <v-row class="pa-2"
            ><ObservationTypeChips :observation-type="observationTypeCode" :is-square-background="true" />
            <span class="observation-type-name">{{ name }}</span></v-row
        >
    </div>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue';
import {getObservationTypeByCode} from '@/services/observation-types-service';
import ObservationTypeChips from '@/components/ObservationTypeChips.vue';

export default defineComponent({
    props: {
        observationTypeCode: String,
        isSelected: {
            default: false,
        },
        isEnabled: Boolean,
    },
    components: {
        ObservationTypeChips,
    },
    setup(props) {
        const observationType = computed(() =>
            !props.observationTypeCode || props.observationTypeCode === ''
                ? null
                : getObservationTypeByCode(props.observationTypeCode),
        );
        const name = computed<string | null>(() => {
            return observationType.value?.getObservationTypeName() || null;
        });

        return {
            name,
        };
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.observation-type-select-box {
    width: 250px;
    padding: 6px;
    border: 2px solid $swatchG6;
    border-radius: 8px;
    position: relative;
    cursor: pointer;

    &:hover {
        border-color: $swatchB6;
    }

    &.selected {
        border-color: $swatchB6;
        background-color: rgba($swatchB7, 0.5);
    }

    &.disabled {
        cursor: not-allowed;
    }
}

.observation-type-name {
    font-size: 16pt;
    font-weight: bold;
    top: 8px;
    align-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
