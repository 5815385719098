import {defineStore} from 'pinia';
import {SearchUsersResponse} from '@/models/api/responses/search/SearchUsersResponse';

export const useDeleteUserModalStore = defineStore('deleteUserModal', {
    state: () => ({
        isVisible: false,
        user: null as SearchUsersResponse | null,
        deletedCounter: 0,
    }),
    actions: {
        open(user: SearchUsersResponse) {
            // Close modal first (if it's already open) so that the watch is triggered when it's reopened
            this.close();

            this.user = user;
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
            this.user = null;
        },
    },
});
