<template>
    <v-dialog v-model="viewMixtureModalStore.isVisible" class="viewFarmSiteModal">
        <v-card style="overflow-y: hidden">
            <v-toolbar color="swatchA2">
                <v-toolbar-title>
                    <font-awesome-icon :icon="['fas', 'flask-round-potion']" size="xl" class="mr-2" />
                    {{ $t('ViewProductMixture') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>
            <LoadingSymbol v-if="isLoading || localMixtureId == null || !mixture" />
            <v-form v-else ref="form" class="pa-4" style="overflow-y: scroll">
                <v-row align="center" class="pl-3">
                    <v-col cols="auto" class="swatchA1 slightlyLarger bold"
                        >"{{ mixture.mixtureName }}" <IdIcon>{{ mixture.mixtureId }}</IdIcon>
                    </v-col>
                </v-row>

                <v-row align="center">
                    <v-col cols="2" class="pl-5">
                        {{ $t('Description') }}
                    </v-col>
                    <v-col cols="8" class="swatchA1"><TranslatableTexts :text="mixture.mixtureDescription" /> </v-col>
                    <v-col cols="2" class="d-flex justify-end"
                        ><FieldSizeChip
                            v-if="mixture.appliedAreaTotal !== null"
                            :farmFieldSize="parseFloat(mixture.appliedAreaTotal.toFixed(2))"
                    /></v-col>
                </v-row>
                <v-row align="center">
                    <v-col cols="2" class="pl-5">
                        {{ $t('LastModified') }}
                    </v-col>
                    <v-col cols="auto"
                        ><UserChip
                            v-if="mixture.lastModifiedUserId"
                            class="pb-1"
                            :user="
                                UserHelper.userChipType(
                                    mixture.lastModifiedUserId,
                                    UserHelper.userFullName(
                                        mixture.lastModifiedUserGivenName,
                                        mixture.lastModifiedUserSurname,
                                    ),
                                )
                            " />
                        <span class="pl-5">{{ formatDate(mixture.lastModifiedDate) }} </span></v-col
                    >
                </v-row>

                <div class="pa-2"></div>
                <v-expansion-panels multiple v-model="openedExpansionPanels">
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header">
                            <FontAwesomeIcon :icon="['fad', 'box-taped']" size="xl" class="mr-2" />
                            {{ $t('IncludedProducts') + ' (' + mixture.products.length + ')' }}</v-expansion-panel-title
                        >
                        <v-expansion-panel-text>
                            <v-data-table
                                class="pa-0 ma-0 pt-1 mixture-product-table"
                                :headers="mixtureProductsHeader"
                                :items="mixtureProducts"
                                :items-per-page="-1"
                                hide-default-footer>
                                <template v-slot:[`item.productSubtypeCode`]="{item}">
                                    <v-col align="center">
                                        <ProductSubtypeIcon
                                            :productTypeCode="item.productTypeCode"
                                            :productSubtypeCode="item.productSubtypeCode" />
                                    </v-col>
                                </template>
                                <template v-slot:[`item.productName`]="{item}">
                                    <div class="swatchA1 slightlyLarger bold">
                                        {{ item.productName }}
                                    </div>
                                </template>

                                <template v-slot:[`item.defaultApplicationRate`]="{item}">
                                    <v-col align="center" class="swatchA1">
                                        {{
                                            item.defaultApplicationRate +
                                            ' ' +
                                            getUnitName(item.defaultApplicationRateUnitCode)
                                        }}
                                    </v-col>
                                </template>

                                <template v-slot:[`item.action`]="{item}">
                                    <v-col align="center">
                                        <v-btn
                                            class="v-btn--custom"
                                            rounded="1"
                                            elevation="2"
                                            v-if="item.productId"
                                            @click.prevent.stop="viewProductModalStore.open(item.productId)">
                                            <template v-slot:prepend>
                                                <FontAwesomeIcon :icon="['fal', 'circle-arrow-right']" size="xl" />
                                            </template>
                                            {{ $t('View') }}
                                        </v-btn>
                                    </v-col>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <div class="pa-2"></div>
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header">
                            <FontAwesomeIcon :icon="['fal', 'clipboard-list-check']" size="xl" class="mr-2" />
                            {{
                                $t('HistoricApplicationsHyphen12Months') + ' (' + workTaskMixtures.length + ')'
                            }}</v-expansion-panel-title
                        >
                        <v-expansion-panel-text>
                            <v-data-table
                                class="pa-0 ma-0 historic-application-table"
                                :headers="historicApplicationHeader"
                                :items="workTaskMixtures"
                                :items-per-page="-1"
                                hide-default-footer>
                                <template v-slot:[`item.farmSiteAbbreviation`]="{item}">
                                    <v-col align="center">
                                        <FarmSiteChip
                                            v-if="item.farmSiteAbbreviation && item.farmSiteName"
                                            :farm-site-abbreviation="item.farmSiteAbbreviation"
                                            :farm-site-name="item.farmSiteName" />
                                    </v-col>
                                </template>
                                <template v-slot:[`item.farmFieldCode`]="{item}">
                                    <div class="swatchA1 slightlyLarger bold" v-if="item.farmFieldCode">
                                        {{ item.farmFieldCode }}
                                    </div>
                                </template>
                                <template v-slot:[`item.lastAppliedTime`]="{item}">
                                    <v-col
                                        align="center"
                                        v-if="item.lastAppliedTime"
                                        class="swatchA1 ma-0 pa-0"
                                        style="max-width: 150px">
                                        {{ formatDate(item.lastAppliedTime) }}
                                    </v-col>
                                </template>

                                <template v-slot:[`item.workTaskUsers`]="{item}">
                                    <div v-for="workTaskUser in item.workTaskUsers" :key="workTaskUser.userId">
                                        <v-col
                                            align="center"
                                            v-if="workTaskUser.userId"
                                            class="swatchA1 ma-0 pa-0 pb-1">
                                            <UserChip
                                                :user="
                                                    UserHelper.userChipType(
                                                        workTaskUser.userId,
                                                        UserHelper.userFullName(
                                                            workTaskUser.userGivenName,
                                                            workTaskUser.userSurname,
                                                        ),
                                                    )
                                                " />
                                        </v-col>
                                    </div>
                                </template>

                                <template v-slot:[`item.farmFieldSize`]="{item}">
                                    <v-col align="center" class="swatchA1" v-if="item.farmFieldSize">
                                        {{ parseFloat(item.farmFieldSize.toFixed(2)) + ' ha' }}
                                    </v-col>
                                </template>

                                <template v-slot:[`item.action`]="{item}">
                                    <v-col align="center">
                                        <v-btn
                                            class="v-btn--custom"
                                            rounded="1"
                                            elevation="2"
                                            v-if="item.workTaskId"
                                            @click.prevent.stop="viewWorkTaskModalStore.open(item.workTaskId)">
                                            <template v-slot:prepend>
                                                <FontAwesomeIcon :icon="['fal', 'circle-arrow-right']" size="xl" />
                                            </template>
                                            {{ $t('ViewTask') }}
                                        </v-btn>
                                    </v-col>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <div class="pa-2"></div>
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header">
                            <FontAwesomeIcon :icon="['fal', 'copy']" size="xl" class="mr-2" />
                            {{ $t('DataSheetsAndFiles') + ' (' + artifacts.length + ')' }}</v-expansion-panel-title
                        >
                        <v-expansion-panel-text>
                            <v-data-table
                                class="pa-0 ma-0 pt-1 historic-application-table"
                                :headers="artifactHeader"
                                :items="artifacts"
                                :items-per-page="-1"
                                hide-default-footer>
                                <template v-slot:[`item.artifactFileTypeCode`]="{item}">
                                    <v-col align="center">
                                        <FileTypeButton
                                            :url="item.artifactUrl"
                                            :display-name="undefined"
                                            :file-type-code="item.artifactFileTypeCode" />
                                    </v-col>
                                </template>
                                <template v-slot:[`item.artifactDisplayName`]="{item}">
                                    <div class="swatchA1 slightlyLarger bold pa-0 ma-0" style="max-width: 200px">
                                        {{ item.artifactDisplayName }}
                                    </div>
                                </template>
                                <template v-slot:[`item.artifactDescription`]="{item}">
                                    <div
                                        class="swatchA1 pa-0 ma-0"
                                        v-if="item.artifactDescription"
                                        style="min-width: 250px">
                                        <TranslatableTexts :text="item.artifactDescription" />
                                    </div>
                                </template>

                                <template v-slot:[`item.artifactFileSize`]="{item}">
                                    <v-col
                                        cols="12"
                                        align="center"
                                        class="swatchA1 pa-0 ma-0"
                                        v-if="item.artifactFileSize !== null"
                                        style="min-width: 100px">
                                        {{ parseFloat((item.artifactFileSize / 1024).toFixed(4)) + ' MB' }}
                                    </v-col>
                                </template>

                                <template v-slot:[`item.artifactCreatedDate`]="{item}">
                                    <v-col
                                        cols="12"
                                        align="center"
                                        class="swatchA1 pa-0 ma-0"
                                        v-if="item.artifactCreatedDate"
                                        style="min-width: 180px">
                                        {{ formatDateTime(item.artifactCreatedDate) }}
                                    </v-col>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <div class="pa-2"></div>
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header">
                            <FontAwesomeIcon :icon="['fal', 'arrow-up-right-from-square']" size="xl" class="mr-2" />
                            {{ $t('Links') + ' (' + links.length + ')' }}</v-expansion-panel-title
                        >
                        <v-expansion-panel-text>
                            <v-data-table
                                class="pa-0 ma-0 pt-1 historic-application-table"
                                :headers="linkHeader"
                                :items="links"
                                :items-per-page="-1"
                                hide-default-footer>
                                <template v-slot:[`item.linkId`]="{item}">
                                    <v-col align="center">
                                        <FileTypeButton
                                            :url="item.linkUrl"
                                            :display-name="undefined"
                                            :file-type-code="ArtifactFileTypeCode.Other" />
                                    </v-col>
                                </template>
                                <template v-slot:[`item.linkName`]="{item}">
                                    <div class="swatchA1 slightlyLarger bold pa-0 ma-0" style="max-width: 200px">
                                        {{ item.linkName }}
                                    </div>
                                </template>
                                <template v-slot:[`item.linkUrl`]="{item}">
                                    <div
                                        class="swatchA1 slightlySmaller pa-0 ma-0"
                                        v-if="item.linkUrl"
                                        style="min-width: 250px">
                                        {{ item.linkUrl }}
                                    </div>
                                </template>

                                <template v-slot:[`item.linkCreatedDate`]="{item}">
                                    <v-col
                                        cols="12"
                                        align="center"
                                        class="swatchA1 pa-0 ma-0"
                                        v-if="item.linkCreatedDate"
                                        style="min-width: 180px">
                                        {{ formatDateTime(item.linkCreatedDate) }}
                                    </v-col>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG" v-if="!isLoading && localMixtureId != null">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Back') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import '@/assets/scss/farm/view-farm-site-modal.scss';
import 'leaflet/dist/leaflet.css';
import '@/assets/scss/home-dashboard/home-dashboard-map.scss';

import {useViewMixtureModalStore} from '@/stores/modals/view-mixture-modal-store';
import {watch, inject, ref, onUnmounted} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import {MixtureSearchResultDto} from '@/models/data-transfer-objects/search/mixture-search/MixtureSearchResultDto';
import {SearchMixturesResponse} from '@/models/api/responses/search/SearchMixturesResponse';
import FieldSizeChip from '@/components/other-chips/FieldSizeChip.vue';
import {MixtureProductDto} from '@/models/data-transfer-objects/search/mixture-search/MixtureProductDto';
import i18n from '@/i18n';
import ProductSubtypeIcon from '@/components/ProductSubtypeIcon.vue';
import {format} from 'date-fns';
import {getApplicationRateUnitByCode} from '@/services/application-rate-units-service';
import {ApplicationRateUnitCode} from '@/enums/application-rate-unit-code';
import {useViewProductModalStore} from '@/stores/modals/view-product-modal-store';
import UserChip from '@/components/UserChip.vue';
import UserHelper from '@/helpers/UserHelper';
import {useViewWorkTaskModalStore} from '@/stores/modals/view-work-task-modal-store';
const viewWorkTaskModalStore = useViewWorkTaskModalStore();
import {SearchWorkTaskMixturesResponse} from '@/models/api/responses/search/SearchWorkTaskMixturesResponse';
import {WorkTaskMixtureDto} from '@/models/data-transfer-objects/search/mixture-search/WorkTaskMixtureDto';
import FarmSiteChip from '@/components/other-chips/FarmSiteChip.vue';
import {DetailedArtifactDto} from '@/models/data-transfer-objects/system/DetailedArtifactDto';
import FileTypeButton from '@/components/FileTypeButton.vue';
import {LinkDto} from '@/models/data-transfer-objects/system/LinkDto';
import {ArtifactFileTypeCode} from '@/enums/artifact-file-type-code';

const viewProductModalStore = useViewProductModalStore();
// Form
let isLoading = ref<boolean>(false);

const viewMixtureModalStore = useViewMixtureModalStore();
const isConfirmed = ref<boolean>(false);
const localMixtureId = ref<number | null>(null);
const mixture = ref<MixtureSearchResultDto | null>(null);
const mixtureProducts = ref<MixtureProductDto[]>([]);
const workTaskMixtures = ref<WorkTaskMixtureDto[]>([]);
const artifacts = ref<DetailedArtifactDto[]>([]);
const links = ref<LinkDto[]>([]);

const mixtureProductsHeader = ref([
    {title: i18n.global.t('Type'), key: 'productSubtypeCode', sortable: false},
    {title: i18n.global.t('ProductName'), key: 'productName', sortable: true},
    {title: i18n.global.t('ApplicateRate'), key: 'defaultApplicationRate', sortable: true},
    {title: '', key: 'action', sortable: false},
]);

const historicApplicationHeader = ref([
    {title: i18n.global.t('FarmSite'), key: 'farmSiteAbbreviation', sortable: false},
    {title: i18n.global.t('FieldCode'), key: 'farmFieldCode', sortable: true},
    {title: i18n.global.t('Date'), key: 'lastAppliedTime', sortable: true},
    {title: i18n.global.t('Operator(s)'), key: 'workTaskUsers', sortable: false},
    {title: i18n.global.t('Area'), key: 'farmFieldSize', sortable: true},
    {title: '', key: 'action', sortable: false},
]);

const artifactHeader = ref([
    {title: i18n.global.t('Icon'), key: 'artifactFileTypeCode', sortable: false},
    {title: i18n.global.t('Name'), key: 'artifactDisplayName', sortable: true},
    {title: i18n.global.t('Description'), key: 'artifactDescription', sortable: false},
    {title: i18n.global.t('Size'), key: 'artifactFileSize', sortable: false},
    {title: i18n.global.t('Date'), key: 'artifactCreatedDate', sortable: true},
]);

const linkHeader = ref([
    {title: i18n.global.t('Icon'), key: 'linkId', sortable: false},
    {title: i18n.global.t('Name'), key: 'linkName', sortable: true},
    {title: i18n.global.t('Description'), key: 'linkUrl', sortable: false},
    {title: i18n.global.t('Date'), key: 'linkCreatedDate', sortable: true},
]);

const openedExpansionPanels = ref<number[]>([0, 1, 2, 3]);
// Services
const apiService = inject('apiService') as ApiService;

/**
 * Load data for the modal.
 */
const loadData = async (mixtureId: number) => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // Set data
    localMixtureId.value = mixtureId;

    // Get mixture details
    const searchResults = (await apiService.post('search/mixtures', {
        isQuickSearch: false,
        mixtureIdList: [mixtureId],
    })) as SearchMixturesResponse;

    if (searchResults.mixtures.length === 1) {
        mixture.value = searchResults.mixtures[0];
        mixtureProducts.value = searchResults.mixtures[0].products;
        artifacts.value = searchResults.mixtures[0].artifacts;
        links.value = searchResults.mixtures[0].links;
    }

    //get work task mixtures
    const workMixtureSearchResults = (await apiService.post('search/work-task-mixtures', {
        mixtureId: mixtureId,
    })) as SearchWorkTaskMixturesResponse;

    if (workMixtureSearchResults.workTaskMixtures.length > 0) {
        workTaskMixtures.value = workMixtureSearchResults.workTaskMixtures;
    }

    isLoading.value = false;
};

/**
 * Close the modal.
 */
const close = () => {
    viewMixtureModalStore.close();
};

onUnmounted(() => {
    viewMixtureModalStore.close();
});

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    mixture.value = null;
    isConfirmed.value = false;
    mixtureProducts.value = [];
};

// Watch for changes to viewUserModalStore.userId
watch(
    () => viewMixtureModalStore.isVisible,
    async (val) => {
        if (val && viewMixtureModalStore.mixtureId !== null) {
            await loadData(viewMixtureModalStore.mixtureId);
        }
    },
);

const formatDate = (date: Date | null): string | null => {
    if (!date) return '';
    return format(date, 'd MMMM yyyy');
};

const getUnitName = (unitCode: ApplicationRateUnitCode | null) => {
    if (!unitCode) return '';
    const applicationRateUnit = getApplicationRateUnitByCode(unitCode);
    if (!applicationRateUnit) return '';
    return applicationRateUnit.applicationRateUnitName;
};

const formatDateTime = (date: Date | null): string | null => {
    if (!date) return null;

    return format(date, 'dd/MM/yyyy h:mm a');
};

import {useDeleteUserModalStore} from '@/stores/modals/delete-user-modal-store';
const deleteUserModalStore = useDeleteUserModalStore();
import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();

watch(
    [() => deleteUserModalStore.deletedCounter, () => deleteOrCancelWorkTaskModalStore.savedCounter],
    async () => {
        if (viewMixtureModalStore.isVisible && viewMixtureModalStore.mixtureId !== null) {
            await loadData(viewMixtureModalStore.mixtureId);
        }
    },
    {deep: true},
);
</script>
<style lang="scss">
@import '@/assets/scss/swatches.scss';
.mixture-product-table .v-table__wrapper thead {
    background-color: $swatchG9;
}
</style>
