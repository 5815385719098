import {ManageWorkTaskModalMode} from '@/enums/modal-modes/manage-work-task-modal-mode';
import {defineStore} from 'pinia';

export const useManageWorkTaskModalStore = defineStore('manageWorkTaskModal', {
    state: () => ({
        isVisible: false,
        modalMode: ManageWorkTaskModalMode.Create,
        workTaskId: null as number | null,
        farmFieldId: null as number | null,
        workTaskTemplateId: null as number | null,
        savedCounter: 0,
    }),
    actions: {
        open(
            modalMode: ManageWorkTaskModalMode,
            workTaskId: number | null = null,
            farmFieldId: number | null = null,
            workTaskTemplateId: number | null = null,
        ) {
            // Close modal first (if it's already open) so that the watch is triggered when it's reopened
            this.close();

            this.modalMode = modalMode;
            this.workTaskId = workTaskId;
            this.farmFieldId = farmFieldId;
            this.workTaskTemplateId = workTaskTemplateId;
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
            this.workTaskId = null;
            this.farmFieldId = null;
            this.workTaskTemplateId = null;
        },
    },
});
