<template>
    <div
        class="status-chip-container swatchWHT"
        :style="{height: props.height, width: props.width, fontSize: fontSize}"
        :class="getObservationStatusColour()">
        <span>{{ getObservationStatusText() }}</span>
    </div>
</template>

<script setup lang="ts">
import {ObservationStatusCode} from '@/enums/observaton-status-code';
import i18n from '@/i18n';

// Props
const props = withDefaults(
    defineProps<{
        observationStatusCode: ObservationStatusCode;
        height: string;
        width: string;
        fontSize?: string;
    }>(),
    {
        height: '25px',
        width: '80px',
        fontSize: '14px',
    },
);

// Get observation status colour for chip
const getObservationStatusColour = (): string => {
    switch (props.observationStatusCode) {
        case ObservationStatusCode.Open:
            return 'swatchERRBG';
        case ObservationStatusCode.InProgress:
        case ObservationStatusCode.Acknowledged:
            return 'swatchB7BG';
        case ObservationStatusCode.Resolved:
            return 'swatchGRNBG';
        case ObservationStatusCode.Archived:
            return 'swatchG2BG';
        case ObservationStatusCode.Cancelled:
            return 'swatchG1BG';
        default:
            return 'swatchBLK';
    }
};

// Get observation status text
const getObservationStatusText = (): string => {
    switch (props.observationStatusCode) {
        case ObservationStatusCode.Open:
            return i18n.global.t('Open');
        case ObservationStatusCode.InProgress:
            return i18n.global.t('InProgress');
        case ObservationStatusCode.Acknowledged:
            return i18n.global.t('Acknowledged');
        case ObservationStatusCode.Resolved:
            return i18n.global.t('Resolved');
        case ObservationStatusCode.Archived:
            return i18n.global.t('Archived');
        case ObservationStatusCode.Cancelled:
            return i18n.global.t('Cancelled');
        default:
            return '';
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.status-chip-container {
    border-radius: 5px;
    border: 1px solid $swatchWHT;
    text-align: center;
    font-family: 'Open Sans Condensed', sans-serif;
}
</style>
