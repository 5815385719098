<template>
    <div>
        <highcharts :options="chartOptions" :style="distributionChartContainerStyle" />
    </div>
</template>

<script lang="ts" setup>
import {ref, computed, watch} from 'vue';
import {GetCropDistributionResponse} from '@/models/api/responses/crops/GetCropDistributionResponse';
import i18n from '@/i18n';

// Props
const props = withDefaults(
    defineProps<{
        cropSizeData: GetCropDistributionResponse;
        chartHeight?: number;
        chartWidth?: number;
        tooltipFontSize?: number;
    }>(),
    {
        chartHeight: 100,
        chartWidth: 100,
        tooltipFontSize: 10,
    },
);

// Reactive chart options
const chartOptions = ref({});

// Prepare data for Highcharts
const chartData = computed(() => {
    return props.cropSizeData.seasons.map((season) => ({
        name: season.seasonName,
        y: season.totalSize,
        seasonStartYear: season.seasonStartYear,
        cropYear: season.cropYear,
    }));
});

const tooltipSize = computed(() => (props.tooltipFontSize ?? '10') + 'px');
const tooltipText = computed(() => i18n.global.t('Year'));
const distributionChartContainerStyle = computed(() => ({
    width: (props.chartWidth ?? 100) + 'px',
    height: (props.chartHeight ?? 100) + 'px',
    position: 'relative',
    overflow: 'hidden',
}));

// Watch for changes and update chart options
watch(
    () => chartData.value,
    (newData) => {
        chartOptions.value = {
            chart: {
                type: 'pie',
                backgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                spacing: [0, 0, 0, 0],
                margin: [0, 0, 0, 0],
            },
            title: {
                text: null,
            },
            tooltip: {
                pointFormat: `${tooltipText.value} {point.seasonStartYear} <b>{point.percentage:.1f}%</b>`,
                style: {
                    fontSize: tooltipSize.value,
                },
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false,
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '80%'],
                    size: '110%',
                },
            },
            series: [
                {
                    innerSize: '50%',
                    data: newData,
                },
            ],
            credits: {
                enabled: false,
            },
        };
    },
    {immediate: true},
);
</script>
