<template>
    <v-dialog max-width="1000px" v-model="homeDashboardStore.workTaskFiltersVisible">
        <v-card style="overflow: visible">
            <v-toolbar color="swatchG2">
                <v-tab class="ma-0 swatchA3BG" height="100%" readonly>
                    <FontAwesomeIcon :icon="['fal', 'filter']" size="xl" class="mr-2" />
                    {{ $t('ApplyFilter') }}
                </v-tab>
                <v-spacer></v-spacer>
                <CloseModalButton @click="closeModal()" />
            </v-toolbar>
            <LoadingSymbol v-if="isLoading" />

            <table v-else style="width: 100%" class="tblForm pa-4">
                <tr>
                    <td class="tableCell padding">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('TaskTypes') }}</span>
                            <HelpIcon :help-text="$t('WorkTaskSearch_TaskTypes_HelpText')" />
                        </div>
                    </td>
                    <td>
                        <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                            <v-select
                                v-model="searchParameters.workTaskTypeCodeList"
                                hide-details
                                multiple
                                clearable
                                chips
                                closable-chips
                                :items="workTaskTypes"
                                item-title="workTaskTypeName"
                                item-value="workTaskTypeCode"
                                class="custom-chips"></v-select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="tableCell padding" style="width: 10%; min-width: 10rem">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('CropType') }}</span>
                            <HelpIcon :help-text="$t('FarmFieldSearch_CropType_HelpText')" />
                        </div>
                    </td>
                    <td>
                        <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                            <v-select
                                v-model="searchParameters.cropTypeIdList"
                                hide-details
                                multiple
                                clearable
                                chips
                                closable-chips
                                :items="cropTypes"
                                item-title="cropTypeName"
                                item-value="cropTypeId"
                                class="custom-chips"></v-select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="tableCell padding">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('Crops') }}</span>
                            <HelpIcon :help-text="$t('FarmFieldSearch_Crop_HelpText')" />
                        </div>
                    </td>
                    <td>
                        <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                            <v-select
                                v-model="searchParameters.cropIdList"
                                hide-details
                                multiple
                                clearable
                                chips
                                closable-chips
                                :items="filteredCrops"
                                item-title="cropName"
                                item-value="cropId"
                                class="custom-chips"></v-select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="tableCell padding">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('CropYear') }}</span>
                            <HelpIcon :help-text="$t('FarmFieldSearch_CropYear_HelpText')" />
                        </div>
                    </td>
                    <td>
                        <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                            <v-select
                                v-model="searchParameters.cropYear"
                                hide-details
                                :items="cropYearsWithNull"
                                item-title="title"
                                item-value="value"
                                :max-width="'200'"></v-select>
                        </div>
                    </td>
                </tr>
                <tr v-if="!isFutureMode">
                    <td class="tableCell padding">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('User') }}</span>
                            <HelpIcon :help-text="$t('WorkTaskSearch_User_HelpText')" />
                        </div>
                    </td>
                    <td>
                        <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                            <v-autocomplete
                                v-model="searchParameters.userIdList"
                                :items="users"
                                hide-details
                                multiple
                                clearable
                                small-chips
                                item-title="userFullName"
                                item-value="userId">
                                <template #selection="{item}">
                                    <UserChip
                                        :user="UserHelper.userChipType(item.value, item.title)"
                                        :closable="true"
                                        @close-chip="handleChipClose" />
                                </template>
                            </v-autocomplete>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="tableCell padding">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('SortOrder') }}</span>
                            <HelpIcon :help-text="$t('WorkTaskSearch_SortOrder_HelpText')" />
                        </div>
                    </td>
                    <td>
                        <v-select
                            v-model="searchParameters.sortOrder"
                            hide-details
                            :items="isFutureMode ? sortOrderFuture : sortOrderRecent"
                            item-title="title"
                            item-value="value"
                            max-width="400"></v-select>
                    </td>
                </tr>
            </table>

            <v-card-actions class="swatchG9BG">
                <v-btn @click="closeModal()">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Back') }}
                </v-btn>
                <v-spacer />
                <v-btn @click="resetParameters()">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'filter-circle-xmark']" size="xl" />
                    </template>
                    {{ $t('ResetFilters') }}
                </v-btn>
                <v-btn @click="search(false)">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fac', 'filter-check']" size="xl" />
                    </template>
                    {{ $t('ApplyFilters') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import {computed, inject, onMounted, ref, watch} from 'vue';
import ApiService from '@/services/api-service';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import {useUserStore} from '@/stores/user-store';
import i18n from '@/i18n';
import {useWorkTaskTypes} from '@/composables/data-source/work-task-types';
const {getWorkTaskTypes, workTaskTypes} = useWorkTaskTypes();
import {useCrops} from '@/composables/data-source/crops';
const {getCrops, crops} = useCrops();
import {useCropTypes} from '@/composables/data-source/crop-types';
const {getCropTypes, cropTypes} = useCropTypes();
import {useCropYears} from '@/composables/data-source/crop-years';
const {cropYearsWithNull} = useCropYears();
import {useUsers} from '@/composables/data-source/users';
const {getUsers, users} = useUsers();
import {GetCropTypesRequest} from '@/models/api/requests/data-source/GetCropTypesRequest';
import {SearchWorkTasksResponse} from '@/models/api/responses/search/SearchWorkTasksResponse';
import UserChip from '@/components/UserChip.vue';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';
import UserHelper from '@/helpers/UserHelper';

// Services and stores
const apiService = inject('apiService') as ApiService;
const homeDashboardStore = useHomeDashboardStore();
const userStore = useUserStore();

const isLoading = ref<boolean>(false);
const farmSiteId = computed(() => userStore.user?.farmSiteId ?? null);

// Options for Sort Order
const sortOrderFuture = [
    {value: 'ScheduledDate', title: i18n.global.t('ScheduledDateAscendingBrackets')},
    {value: 'TaskType', title: i18n.global.t('TaskType')},
];

const sortOrderRecent = [
    {value: 'DateCompleted', title: i18n.global.t('DateCompletedDescendingBrackets')},
    {value: 'TaskType', title: i18n.global.t('TaskType')},
    {value: 'User', title: i18n.global.t('User')},
];

const isFutureMode = computed(() => homeDashboardStore.isTaskFutureMode);

const searchParameters = ref<SearchWorkTasksRequest>(new SearchWorkTasksRequest());
const defaultSearchParameters = ref<SearchWorkTasksRequest>({
    ...new SearchWorkTasksRequest(),
});

// Crops based on selected crop types
const filteredCrops = computed(() => {
    const selectedCropTypeIds = searchParameters.value.cropTypeIdList;

    if (!selectedCropTypeIds || selectedCropTypeIds.length === 0) {
        return [];
    }

    return crops.value.filter((crop) => selectedCropTypeIds.includes(crop.cropTypeId));
});

// Deselect user chip if manually closed
const handleChipClose = (userId: number) => {
    if (searchParameters.value.userIdList) {
        const index = searchParameters.value.userIdList.indexOf(userId);
        if (index !== -1) {
            searchParameters.value.userIdList.splice(index, 1);
        }
    }
};

// Close filter modal
const closeModal = () => {
    homeDashboardStore.workTaskFiltersVisible = false;
};

// Search for work tasks
const search = async (isReset: boolean) => {
    isLoading.value = true;

    const response: SearchWorkTasksResponse = await apiService.post('search/work-tasks', searchParameters.value);

    if (isFutureMode.value) {
        homeDashboardStore.futureWorkTasksFiltered = response.workTasks;
    } else {
        homeDashboardStore.recentWorkTasksFiltered = response.workTasks;
    }

    if (!isReset) {
        const filterApplied = isFilterApplied();

        if (isFutureMode.value) {
            homeDashboardStore.workTaskFutureFilterApplied = filterApplied;
        } else {
            homeDashboardStore.workTaskRecentFilterApplied = filterApplied;
        }

        homeDashboardStore.deselectWorkTaskLevel1();

        Object.assign(
            isFutureMode.value
                ? homeDashboardStore.searchParametersFutureTask
                : homeDashboardStore.searchParametersRecentTask,
            searchParameters.value,
        );

        closeModal();
    }

    isLoading.value = false;
};

// Determines if filters have changed from default
const isFilterApplied = () => {
    const sortedObj1 = sortArraysInObject(searchParameters.value);
    const sortedObj2 = sortArraysInObject(defaultSearchParameters.value);

    return JSON.stringify(sortedObj1) !== JSON.stringify(sortedObj2);
};

// Makes a copy to compare arrays regardless of order
function sortArraysInObject(obj: SearchWorkTasksRequest): SearchWorkTasksRequest {
    const copy = new SearchWorkTasksRequest({...obj});

    if (copy.workTaskTypeCodeList)
        copy.workTaskTypeCodeList = [...copy.workTaskTypeCodeList].sort((a, b) => a.localeCompare(b));
    if (copy.cropTypeIdList) copy.cropTypeIdList = [...copy.cropTypeIdList].sort((a, b) => a - b);
    if (copy.cropIdList) copy.cropIdList = [...copy.cropIdList].sort((a, b) => a - b);

    if (copy.userIdList && copy.userIdList.length > 0) {
        copy.userIdList = [...copy.userIdList].sort((a, b) => a - b);
    } else {
        copy.userIdList = [];
    }

    return copy;
}

// Reset search parameters to default
const resetParameters = async () => {
    homeDashboardStore.resetWorkTaskSearchParameters(false);
    searchParameters.value = {...defaultSearchParameters.value};
    search(true);
};

// Watch for changes in the local copy and emit the update event
watch(
    () => searchParameters.value.cropTypeIdList,
    () => {
        const currentCropIds = searchParameters.value.cropIdList || [];
        const filteredCropIds = filteredCrops.value.map((crop) => crop.cropId);

        // Only keep the crop IDs that are in the filtered list and were previously selected
        searchParameters.value.cropIdList = currentCropIds.filter((cropId) => filteredCropIds.includes(cropId));
    },
);

// Get dropdown data
const initializeDropdowns = async () => {
    // Get data
    const searchRequest: GetCropTypesRequest = {
        farmSiteId: farmSiteId.value,
    };

    await getWorkTaskTypes();
    await getCropTypes(searchRequest);
    await getCrops();
    await getUsers();
};

// Initialize default and regular search parameters
const initializeSearchParameters = () => {
    Object.assign(
        searchParameters.value,
        isFutureMode.value
            ? homeDashboardStore.searchParametersFutureTask
            : homeDashboardStore.searchParametersRecentTask,
    );

    // Initialize search parameters only if they haven't been set
    if (!searchParameters.value.workTaskTypeCodeList) {
        searchParameters.value.workTaskTypeCodeList = workTaskTypes.value
            .map((taskType) => taskType.workTaskTypeCode)
            .filter((code) => code !== null);
    }
    if (!searchParameters.value.cropTypeIdList) {
        searchParameters.value.cropTypeIdList = cropTypes.value.map((cropType) => cropType.cropTypeId);
    }
    if (!searchParameters.value.cropIdList) {
        searchParameters.value.cropIdList = crops.value.map((crop) => crop.cropId);
    }

    searchParameters.value.farmSiteId = farmSiteId.value;
    defaultSearchParameters.value.farmSiteId = farmSiteId.value;
    defaultSearchParameters.value.workTaskTypeCodeList = workTaskTypes.value
        .map((taskType) => taskType.workTaskTypeCode)
        .filter((code) => code !== null);
    defaultSearchParameters.value.cropTypeIdList = cropTypes.value.map((cropType) => cropType.cropTypeId);
    defaultSearchParameters.value.cropIdList = crops.value.map((crop) => crop.cropId);
    defaultSearchParameters.value.sortOrder = isFutureMode.value ? 'ScheduledDate' : 'DateCompleted';
};

// Fetch data for dropdowns
onMounted(async () => {
    isLoading.value = true;

    await initializeDropdowns();
    initializeSearchParameters();

    isLoading.value = false;
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.custom-chips :deep(.v-chip) {
    background-color: $swatchB2;
    color: $swatchWHT;
}
</style>
