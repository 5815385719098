<template>
    <div>
        <v-card flat style="overflow: visible">
            <v-toolbar flat color="swatchG2">
                <v-tabs v-model="tab">
                    <v-tab value="SearchParameters">
                        <FontAwesomeIcon :icon="['fal', 'filter']" size="xl" class="mr-2" />
                        {{ $t('Filters') }}
                    </v-tab>
                    <v-tab value="SearchTaskResults" v-show="hasSearchBeenRun">
                        <FontAwesomeIcon :icon="['fal', 'clipboard-list-check']" size="xl" class="mr-2" />
                        {{ taskResultsTabTitle }}
                    </v-tab>
                    <v-tab value="SearchObservationResults" v-show="hasSearchBeenRun">
                        <FontAwesomeIcon :icon="['fal', 'binoculars']" size="xl" class="mr-2" />
                        {{ observationResultsTabTitle }}
                    </v-tab>
                </v-tabs>
            </v-toolbar>

            <LoadingSymbol v-if="isLoading" />

            <v-form v-show="!isLoading" class="pa-4" @keyup.enter="search">
                <v-tabs-window v-model="tab" style="overflow: visible">
                    <v-tabs-window-item value="SearchParameters">
                        <ActivityReportParameters
                            ref="refActivityReportParameters"
                            v-model:searchParameters="searchParameters" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="SearchTaskResults">
                        <WorkTaskSearchResults
                            :results-list="searchTaskResults"
                            @switch-to-search="selectSearchParametersTab"
                            :display-toolbar="false"
                            :open-task-default="false" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="SearchObservationResults">
                        <ObservationSearchResults
                            :results-list="searchObservationResults"
                            @switch-to-search="selectSearchParametersTab"
                            :display-toolbar="false"
                            :open-observation-default="false" />
                    </v-tabs-window-item>
                </v-tabs-window>
            </v-form>
            <v-card-actions class="justify-space-between swatchG9BG">
                <BackButton v-if="!isSearchParametersTabSelected" @click="selectSearchParametersTab" />
                <v-spacer></v-spacer>
                <v-btn v-if="isSearchParametersTabSelected" @click="search">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'check-square']" size="xl" />
                    </template>
                    {{ $t('ApplyFilters') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar v-model="snackbar.show" timeout="4000">
            {{ snackbar.text }}
        </v-snackbar>
    </div>
</template>

<script setup lang="ts">
import {computed, inject, ref, watch} from 'vue';
import ApiService from '@/services/api-service.js';
import i18n from '@/i18n';
import WorkTaskSearchResults from '@/views/search/WorkTaskSearchResults.vue';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {ObservationSearchResultDto} from '@/models/data-transfer-objects/search/observation-search/ObservationSearchResultDto';
import {SearchWorkTasksResponse} from '@/models/api/responses/search/SearchWorkTasksResponse';
import {SearchObservationsResponse} from '@/models/api/responses/search/SearchObservationsResponse';
import {SearchObservationsRequest} from '@/models/api/requests/search/SearchObservationsRequest';
import ObservationSearchResults from '@/views/search/ObservationSearchResults.vue';
import ActivityReportParameters from './ActivityReportParameters.vue';
import {ActivityReportForm} from '@/models/reports/ActivityReportForm';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';
import {format} from 'date-fns';

const isLoading = ref<boolean>(false);
const hasSearchBeenRun = ref<boolean>(false);
const searchParameters = ref(
    new ActivityReportForm({
        isQuickSearch: false,
        isExportCSV: false,
    }),
);
const searchTaskResults = ref<WorkTaskSearchResultDto[]>([]);
const searchObservationResults = ref<ObservationSearchResultDto[]>([]);
const snackbar = ref({
    show: false,
    text: '',
});
const refActivityReportParameters = ref();
let apiService = inject('apiService') as ApiService;
let tab = ref<string>('SearchParameters');

/**
 * Call API to retrieve and display data.
 */
async function search() {
    const validationResult = await refActivityReportParameters.value.validateParameters();

    if (validationResult) {
        isLoading.value = true;

        if (!searchParameters.value.isExportCSV) {
            // Get search task results
            const taskResponse: SearchWorkTasksResponse = await apiService.post(
                'search/work-tasks',
                Object.assign(new SearchWorkTasksRequest(), searchParameters.value),
            );
            searchTaskResults.value = taskResponse.workTasks;

            // Get search observation results
            const observationParams = Object.assign(new SearchObservationsRequest(), searchParameters.value);
            observationParams.farmFieldIdList = searchParameters.value.farmFieldId
                ? [searchParameters.value.farmFieldId]
                : [];

            const observationResponse: SearchObservationsResponse = await apiService.post(
                'search/observations',
                observationParams,
            );
            searchObservationResults.value = observationResponse.observations;

            // Record the search has been run, so that the results tab can be shown
            hasSearchBeenRun.value = true;

            // Switch to results tab
            tab.value = 'SearchTaskResults';
        } else {
            await exportTaskList();
            await exportObservationList();
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ExportCSVsSuccess');
        }

        isLoading.value = false;
    }
}

/**
 * Common export function
 */
const exportList = async (type: 'work-tasks' | 'observations', request: object, fileNamePrefix: string) => {
    try {
        const fileName = i18n.global.t(fileNamePrefix) + '_' + format(new Date(), 'yyyyMMddHHmm') + '.csv';
        await apiService.downloadFilePost(`search/${type}/export`, request, null, fileName);
    } catch (error) {
        // Show fail feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ExportCSVError');
        throw error;
    }
};

/**
 * Export Task List to CSV file
 */
const exportTaskList = async () => {
    const taskRequest = Object.assign(new SearchWorkTasksRequest(), searchParameters.value);
    await exportList('work-tasks', taskRequest, i18n.global.t('Tasks'));
};

/**
 * Export Observation List to CSV file
 */
const exportObservationList = async () => {
    const observationParams = Object.assign(new SearchObservationsRequest(), searchParameters.value);
    observationParams.farmFieldIdList = searchParameters.value.farmFieldId ? [searchParameters.value.farmFieldId] : [];
    await exportList('observations', observationParams, i18n.global.t('Observations'));
};

/**
 * Select the search parameters tab.
 */
async function selectSearchParametersTab() {
    // Switch to search paramters tab
    tab.value = 'SearchParameters';
}

/**
 * Title of the task results tab.
 */
const taskResultsTabTitle = computed(() => {
    return `${i18n.global.t('CompletedTasks')} (${searchTaskResults.value.length.toLocaleString()})`;
});

/**
 * Title of the observation results tab.
 */
const observationResultsTabTitle = computed(() => {
    return `${i18n.global.t('ClosedObservations')} (${searchObservationResults.value
        .filter((e) => e.isClosed)
        .length.toLocaleString()})`;
});

/**
 * Flag to indicate if the Search Parameters tab is selected.
 */
const isSearchParametersTabSelected = computed(() => {
    return tab.value == 'SearchParameters';
});

import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();
import {useDeleteUserModalStore} from '@/stores/modals/delete-user-modal-store';
const deleteUserModalStore = useDeleteUserModalStore();

watch(
    [() => deleteOrCancelWorkTaskModalStore.savedCounter, () => deleteUserModalStore.deletedCounter],
    async () => {
        if (!isSearchParametersTabSelected.value) {
            await search();
        }
    },
    {deep: true},
);
</script>
