export class SearchCropVarietiesRequest {
    isQuickSearch: boolean = false;
    quickSearchText: string | null = null;
    cropId: number | null = null;
    cropVarietyName: string | null = null;
    cropVarietyIdList: number[] | null = null;
    cropTypeIdList: number[] | null = null;
    farmFieldIdList: number[] | null = null;
    farmSiteIdList: number[] | null = null;
    includeFarmBlocks: boolean = false;

    constructor(data?: Partial<SearchCropVarietiesRequest>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
