<template>
    <div class="chart-container" v-if="!isHide">
        <v-row class="pa-1"
            ><div class="swatchWHT pt-2 pl-3">{{ workTaskCodeName }}</div>
            <v-spacer /> <span :class="workTaskCodeColorClasses"></span
        ></v-row>
        <highcharts :options="chartOptions" />
        <v-row v-if="totalArea != null && totalArea != undefined"
            ><v-spacer /><span class="swatchWHT pr-5">
                <span class="larger-text"
                    ><b>{{ Math.round(totalArea * 100) / 100 }}</b></span
                >
                Ha</span
            ></v-row
        >
    </div>
</template>

<script lang="ts">
import {PropType, defineComponent, ref, computed} from 'vue';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import {getWorkTaskTypeByCode} from '@/services/work-task-types-service';

export default defineComponent({
    name: 'TaskTypeMetric',
    props: {
        workTaskTypeCode: String as PropType<WorkTaskTypeCode>,
        totalArea: Number,
        historicData: Array<number>,
        isHide: Boolean,
    },
    setup(props) {
        const workTaskCodeName = computed(() => {
            const workTaskType = props.workTaskTypeCode ? getWorkTaskTypeByCode(props.workTaskTypeCode) : null;
            return workTaskType ? workTaskType.getWorkTaskTypePast() : '';
        });

        const workTaskCodeColorClasses = computed(() => {
            const workTaskType = props.workTaskTypeCode ? getWorkTaskTypeByCode(props.workTaskTypeCode) : null;
            return [workTaskType ? workTaskType.color + 'BG' : 'swatchB2BG', 'status-indicator'];
        });

        // Highcharts options
        const chartOptions = ref({
            chart: {
                type: 'line',
                backgroundColor: 'transparent',
                height: '50px',
            },
            title: {
                text: null,
            },
            xAxis: {
                visible: false,
            },
            yAxis: {
                visible: false,
            },
            series: [
                {
                    name: workTaskCodeName.value,
                    data: props.historicData,
                    color: '#ffffff',
                    lineWidth: 2,
                    marker: {
                        enabled: false,
                    },
                },
            ],
            tooltip: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: false,
            },
        });

        return {
            chartOptions,
            workTaskCodeName,
            workTaskCodeColorClasses,
        };
    },
});
</script>

<style lang="scss" scoped>
@import '../assets/scss/swatches.scss';
.chart-container {
    position: relative;
    width: 170px;
    height: 90px;
    background-color: $swatchG1;
    border: 1px solid $swatchG1;
}

.highcharts-background {
    fill: transparent;
}
.larger-text {
    font-size: 26px;
}
.status-indicator {
    height: 20px;
    width: 20px;
    border: 1px solid $swatchWHT;
    border-radius: 50%;
    margin-top: 10px;
    margin-right: 10px;
}
</style>
