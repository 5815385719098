import {ProductTypeCode} from '@/enums/product-type-code';
import {ProductSubtypeCode} from '@/enums/product-subtype-code';

export class SearchProductRequest {
    isQuickSearch: boolean = false;
    quickSearchText: string | null = null;
    productIdList: number[] | null = null;
    farmFieldIdList: number[] | null = null;
    farmSiteIdList: number[] | null = null;
    mixtureIdList: number[] | null = null;
    productName: string | null = null;
    productBrandName: string | null = null;
    productTypeCodeList: ProductTypeCode[] | null = null;
    productSubtypeCodeList: ProductSubtypeCode[] | null = null;
    dateRangeFrom: string | null = null;
    dateRangeTo: string | null = null;
    includeDetailedArtifacts: boolean = true;
    includeLinks: boolean = true;

    constructor(data?: Partial<SearchProductRequest>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
