export enum WorkTaskCompletionTimeCode {
    Minutes15 = 'Minutes15',
    Minutes30 = 'Minutes30',
    Minutes45 = 'Minutes45',
    Minutes60 = 'Minutes60',
    Minutes90 = 'Minutes90',
    Hours2 = 'Hours2',
    Hours3 = 'Hours3',
    Hours4 = 'Hours4',
    Hours5 = 'Hours5',
    Hours6 = 'Hours6',
    Hours7 = 'Hours7',
    Hours8 = 'Hours8',
    Hours10 = 'Hours10',
    Hours12 = 'Hours12',
    Days1 = 'Days1',
    Days2 = 'Days2',
    Days3 = 'Days3',
    Days4 = 'Days4',
    Days5 = 'Days5',
    Days7 = 'Days7',
}
