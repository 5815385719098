<template>
    <v-menu open-on-click location="bottom end" :close-on-content-click="false">
        <template v-slot:activator="{props}">
            <v-btn v-bind="props" class="task-metric-button">
                <template v-slot:prepend>
                    <FontAwesomeIcon :icon="['fal', 'eye-slash']" size="xl" />
                </template>
                {{ $t('ShowSlashHide') }}
            </v-btn>
        </template>

        <v-list class="rounded-0">
            <v-list-item v-for="(setting, index) in homeDashboardStore.mapSettings" :key="index">
                <v-list-item-title>
                    <v-btn
                        @click="settingClicked(setting)"
                        class="justify-start"
                        block
                        :style="setting.hasOpacity ? 'height: 70px' : ''"
                        :ripple="!setting.hasOpacity">
                        <v-row>
                            <v-col class="d-flex justify-center" style="width: 100px; max-width: 100px">
                                <v-slider
                                    v-if="setting.hasOpacity"
                                    v-model="setting.opacity"
                                    @end="saveOpacitySetting(setting)"
                                    :thumb-size="10"
                                    thumb-label="always"
                                    hide-details
                                    min="0"
                                    max="100">
                                    <template #thumb-label="{modelValue}">
                                        {{ formatSliderLabel(modelValue) }}
                                    </template>
                                </v-slider>
                                <v-checkbox
                                    v-else
                                    v-model="setting.isVisible"
                                    @click.stop="saveVisibilitySetting(setting)"
                                    density="compact"
                                    hide-details
                                    color="swatchGRN"
                                    style="font-size: 16pt" />
                            </v-col>
                            <v-col align-self="center" style="font-size: medium">
                                {{ setting.settingName }}
                            </v-col>
                        </v-row>
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script setup lang="ts">
import {UpdateHomeDashboardMapSettingRequest} from '@/models/api/requests/users/UpdateHomeDashboardMapSettingRequest';
import {HomeDashboardMapSetting} from '@/models/home-dashboard/HomeDashboardMapSetting';
import ApiService from '@/services/api-service';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import {inject} from 'vue';
const homeDashboardStore = useHomeDashboardStore();

// Services
const apiService = inject('apiService') as ApiService;

/**
 * Remove decimals from slider label.
 */
const formatSliderLabel = (value: number) => {
    return Math.round(value);
};

/**
 * Handles click event when one of the setting list items is clicked.
 */
const settingClicked = (setting: HomeDashboardMapSetting) => {
    if (!setting.hasOpacity) {
        setting.isVisible = !setting.isVisible;
        saveVisibilitySetting(setting);
    }
};

/**
 * Call API to save the updated visibility value to the database.
 */
const saveVisibilitySetting = async (setting: HomeDashboardMapSetting) => {
    // Timeout is used here to ensure that the API is only called for the new setting value
    setTimeout(async () => {
        await apiService.post('users/home-dashboard-map-setting', {
            settingCode: setting.settingCode,
            isVisible: setting.isVisible,
        } as UpdateHomeDashboardMapSettingRequest);
    }, 50);
};

/**
 * Call API to save the updated opacity value to the database.
 */
const saveOpacitySetting = async (setting: HomeDashboardMapSetting) => {
    // Timeout is used here to ensure that the API is only called for the new setting value
    setTimeout(async () => {
        await apiService.post('users/home-dashboard-map-setting', {
            settingCode: setting.settingCode,
            isVisible: true,
            opacity: Math.round(setting.opacity ?? 0),
        } as UpdateHomeDashboardMapSettingRequest);
    }, 50);
};
</script>
<style lang="scss" scoped>
.task-metric-button {
    margin-right: 1rem;
}
</style>
