<template>
    <v-icon v-bind="$attrs" :icon="icon" class="circle-icon" :class="getClass">
        <slot></slot>
    </v-icon>
</template>

<script lang="ts">
import {PropType, defineAsyncComponent, defineComponent} from 'vue';

// Dynamic import is required to prevent CommonJS/ECMAScript related errors when building
const VIcon = defineAsyncComponent(() => import('vuetify/components').then((module) => module.VIcon));

export default defineComponent({
    name: 'CircleIcon',
    extends: VIcon,
    props: {
        class: String as PropType<string>,
        icon: String as PropType<string>,
    },
    computed: {
        // Use a computed property to return the value of the class prop
        getClass() {
            return this.class;
        },
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.circle-icon {
    color: $swatchWHT; /* Sets the color of the question mark */
    background-color: $swatchA1;
    border-radius: 50%; /* Makes the background a circle */
    padding: 12px; /* Adds some padding around the icon */
    font-size: 12pt;
}
</style>
