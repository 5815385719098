<template>
    <div class="divGenericInfo">
        <v-card outlined style="width: 90%; max-width: 50rem; margin: auto">
            <v-toolbar color="swatchORG">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'ban']" size="xl" class="mr-2" />
                    {{ $t('AccessDenied') }}
                </v-toolbar-title>
            </v-toolbar>

            <v-form class="pa-4">
                <div class="mainPanelInner">
                    <div class="table" style="width: 100%">
                        <div class="tableCell errorImage">
                            <img src="@/assets/images/fail-thong.png" />
                        </div>
                        <div class="tableCell">
                            <div class="errorTitle">{{ $t('OopsBlowout') }}</div>

                            {{ $t('AccessDeniedMessage', {systemName: configStore.systemName}) }}
                        </div>
                    </div>
                </div>
            </v-form>
        </v-card>
    </div>
</template>

// TS
<script lang="ts">
import {defineComponent} from 'vue';
import {useConfigStore} from '@/stores/config-store';

export default defineComponent({
    setup() {
        return {
            configStore: useConfigStore(),
        };
    },
});
</script>

// CSS
<style scoped>
.mainPanelOuter {
    width: 800px;
}

.mainPanelInner {
    padding: 30px;
}

.errorTitle {
    font-size: 18pt;
    margin-bottom: 20px;
}

.errorImage {
    padding-left: 20px;
    padding-right: 50px;
}
</style>
