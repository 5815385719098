export const swatches = {
    swatchA1: '#003B46',
    swatchA2: '#005D47',
    swatchA3: '#00804E',
    swatchA4: '#7FC81C',
    swatchA5: '#B8E531',
    swatchA6: '#DDF587',
    swatchA7: '#EBFAB6',
    swatchB1: '#00AF93',
    swatchB2: '#50C861',
    swatchB3: '#C5DF45',
    swatchB4: '#E9EA34',
    swatchB5: '#9D6848',
    swatchB6: '#3BA3DB',
    swatchB7: '#9BC9E1',
    swatchC1: '#6BD23C',
    swatchC2: '#3CD28C',
    swatchC3: '#3C9AD2',
    swatchC4: '#5D3CD2',
    swatchC5: '#D23CC9',
    swatchC6: '#D23C6D',
    swatchC7: '#D2BB3C',
    swatchG1: '#333333',
    swatchG2: '#555555',
    swatchG3: '#888888',
    swatchG4: '#AAAAAA',
    swatchG5: '#BFBFBF',
    swatchG6: '#CCCCCC',
    swatchG7: '#DDDDDD',
    swatchG8: '#E5E5E5',
    swatchG9: '#EEEEEE',
    swatchGA: '#F5F5F5',
    swatchGB: '#FDFDFD',
    swatchERR: '#E4CD36',
    swatchGRN: '#60A91D',
    swatchORG: '#FF8B06',
    swatchRED: '#DE3716',
    swatchBG: '#F7F7F3',
    swatchBLK: '#111111',
    swatchPBG: '#FFFFFF',
    swatchWHT: '#FFFFFF',
};
