<template>
    <div class="rounded-text custom-background">
        <CircleIcon class="custom-chip custom-icon swatchG2BG">
            <FontAwesomeIcon :icon="['fa', 'vector-square']" class="rounded-text custom-background" />
        </CircleIcon>
        <span class="pr-2" style="padding-left: 5px" v-if="formattedName != ''">{{ formattedName }}</span>
    </div>
</template>

<script lang="ts">
import {PropType, defineComponent, computed} from 'vue';

export default defineComponent({
    name: 'SeedOriginChip',
    props: {
        farmSiteAbbreviation: String as PropType<string>,
        farmFieldCode: String as PropType<string>,
        farmBlockCode: String as PropType<string>,
    },
    setup(props) {
        // Convert date to desired format
        const formattedName = computed(
            () =>
                (props.farmSiteAbbreviation ? props.farmSiteAbbreviation : '') +
                (props.farmFieldCode ? '.' + props.farmFieldCode : '') +
                (props.farmBlockCode ? '.' + props.farmBlockCode : ''),
        );

        return {
            formattedName,
        };
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';
.custom-chip {
    font-size: 18pt;
    height: 30px;
    width: 30px;
}
.rounded-text {
    display: inline-flex;
    align-items: center;
    border-radius: 20px; /* Adjust the border radius to make it round */
    font-family: 'Open Sans Condensed', sans-serif;
}

.custom-background {
    background-color: rgba($swatchG2, 0.8);
    color: $swatchWHT;
}
.custom-icon {
    background-color: $swatchG2;
}
</style>
