<template>
    <v-tooltip bottom>
        <template v-slot:activator="{props}">
            <div class="rounded-text" :style="backgroundStyle">
                <CircleIcon v-bind="props" :style="iconStyle">
                    <FontAwesomeIcon :icon="['fal', iconCode]" />
                </CircleIcon>
                <span class="pr-2" style="padding-left: 5px">{{ name }}</span>
            </div>
        </template>
        <slot name="helpText"></slot>
        {{ name }}
    </v-tooltip>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue';
import {getObservationTypeByCode, ObservationType} from '@/services/observation-types-service';

export default defineComponent({
    name: 'ObservationTypeChips',
    props: {
        observationType: String,
        iconHeight: {
            type: String,
            default: '30',
        },
        iconWidth: {
            type: String,
            default: '30',
        },
        iconSize: {
            type: String,
            default: '18',
        },
    },
    setup(props) {
        const observationType = computed<ObservationType | undefined>(() => {
            if (!props.observationType) return undefined;
            return getObservationTypeByCode(props.observationType);
        });

        const iconCode = computed<string>(() => {
            return observationType.value?.iconCode || 'question';
        });

        const color = computed<string>(() => {
            return observationType.value?.color || '#000000';
        });

        const iconStyle = computed(() => ({
            height: props.iconHeight + 'px',
            width: props.iconWidth + 'px',
            fontSize: props.iconSize + 'pt',
            backgroundColor: observationType.value?.color ?? '#000000',
        }));

        function hexToRgb(hex: string) {
            let r = 0,
                g = 0,
                b = 0;
            if (hex.length === 7) {
                r = parseInt(hex.slice(1, 3), 16);
                g = parseInt(hex.slice(3, 5), 16);
                b = parseInt(hex.slice(5, 7), 16);
            }
            return `${r}, ${g}, ${b}`;
        }

        const backgroundStyle = computed(() => ({
            backgroundColor: `rgba(${hexToRgb(observationType.value?.color ?? '#000000')}, 0.8)`,
            color: 'white',
        }));

        const name = computed<string | null>(() => observationType.value?.getObservationTypeName() || '');

        return {
            iconCode,
            name,
            color,
            iconStyle,
            backgroundStyle,
        };
    },
});
</script>

<style lang="scss" scoped>
@import '../assets/scss/swatches.scss';
.rounded-text {
    display: inline-flex;
    align-items: center;
    border-radius: 20px; /* Adjust the border radius to make it round */
    font-family: 'Open Sans Condensed', sans-serif;
}
.primary-background {
    background-color: rgba($swatchA2, 0.8);
    color: $swatchWHT;
}
.secondary-background {
    background-color: rgba($swatchA5, 0.8);
    color: $swatchBLK;
}
.primary-icon {
    background-color: $swatchA2 !important;
}
.secondary-icon {
    background-color: $swatchA5 !important;
}
</style>
