export enum ArtifactFileTypeCode {
    Compressed = 'Compressed',
    Csv = 'Csv',
    Excel = 'Excel',
    Image = 'Image',
    Other = 'Other',
    Pdf = 'Pdf',
    Text = 'Text',
    Video = 'Video',
    Word = 'Word',
}
