import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_FontAwesomeLayers = _resolveComponent("FontAwesomeLayers")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock(_component_v_tooltip, { bottom: "" }, {
    activator: _withCtx(({props}) => [
      _createElementVNode("div", _normalizeProps(_guardReactiveProps(props)), [
        _createVNode(_component_FontAwesomeLayers, {
          class: "fa-lg",
          style: _normalizeStyle(_ctx.iconStyle)
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FontAwesomeIcon, {
              icon: ['fas', _ctx.isSquareBackground ? 'square' : 'message-middle'],
              class: _normalizeClass(_ctx.isSquareBackground ? ['swatchWHT'] : ['swatchWHT', 'icon']),
              transform: "grow-1"
            }, null, 8, ["icon", "class"]),
            _createVNode(_component_FontAwesomeIcon, {
              icon: ['fas', _ctx.isSquareBackground ? 'square' : 'message-middle'],
              style: _normalizeStyle({color: _ctx.color}),
              class: _normalizeClass(_ctx.isSquareBackground ? [] : ['icon'])
            }, null, 8, ["icon", "style", "class"]),
            _createVNode(_component_FontAwesomeIcon, {
              icon: ['fal', _ctx.iconCode],
              class: "swatchWHT",
              transform: _ctx.isSquareBackground ? 'shrink-5' : 'shrink-4 up-2'
            }, null, 8, ["icon", "transform"])
          ]),
          _: 1
        }, 8, ["style"])
      ], 16)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "helpText", {}, undefined, true),
      _createTextVNode(" " + _toDisplayString(_ctx.name), 1)
    ]),
    _: 3
  }))
}