import {defineStore} from 'pinia';

export const useDeleteFarmFieldModalStore = defineStore('deleteFarmFieldModal', {
    state: () => ({
        isVisible: false,
        farmFieldId: null as number | null,
        deletedCounter: 0,
    }),
    actions: {
        open(farmFieldId: number) {
            // Close modal first (if it's already open) so that the watch is triggered when it's reopened
            this.close();
            this.farmFieldId = farmFieldId;
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
            this.farmFieldId = null;
        },
    },
});
