<template>
    <div class="divGenericInfo">
        <v-card outlined style="width: 90%; max-width: 50rem; margin: auto">
            <v-toolbar color="primary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'info-circle']" size="xl" class="mr-2" />
                    {{ pageTitle }}
                </v-toolbar-title>
            </v-toolbar>

            <v-form class="pa-4">
                <div class="mainPanelInner">
                    <div class="table" style="width: 100%">
                        <div class="tableCell">
                            <div class="infoTitle">{{ pageTitle }}</div>

                            <div v-if="isAboutMode">
                                <p>
                                    {{ $t('GenericInformation_AboutSystem') }}
                                </p>
                            </div>
                            <div v-else-if="isPrivacyMode">
                                <p>
                                    {{ $t('GenericInformation_PrivacyPolicy') }}
                                </p>
                            </div>
                            <div v-else-if="isCopyrightMode">
                                <p>
                                    {{ $t('GenericInformation_Copyright') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </v-form>
        </v-card>
    </div>
</template>

// TS
<script lang="ts">
import i18n from '@/i18n';
import {computed, defineComponent} from 'vue';
import {useRoute} from 'vue-router';

export default defineComponent({
    setup() {
        const route = useRoute();

        /**
         * Flag to indicate if the form is in About mode.
         */
        const isAboutMode = computed(() => route.query.page == 'about');

        /**
         * Flag to indicate if the form is in Copyright mode.
         */
        const isCopyrightMode = computed(() => route.query.page == 'copyright');

        /**
         * Flag to indicate if the form is in Privacy Policy mode.
         */
        const isPrivacyMode = computed(() => route.query.page == 'privacy');

        /**
         * Title of the page
         */
        const pageTitle = computed(() => {
            if (isAboutMode.value) {
                return i18n.global.t('About');
            } else if (isCopyrightMode.value) {
                return i18n.global.t('Copyright');
            } else if (isPrivacyMode.value) {
                return i18n.global.t('Privacy');
            }
            return null;
        });

        return {
            isAboutMode,
            isCopyrightMode,
            isPrivacyMode,
            pageTitle,
        };
    },
});
</script>

// CSS
<style scoped>
.mainPanelOuter {
    width: 800px;
}

.mainPanelInner {
    padding: 30px;
}

.infoTitle {
    font-size: 18pt;
    margin-bottom: 20px;
}
</style>
