import {WorkTaskDto} from '@/models/data-transfer-objects/search/farm-field-search/WorkTaskDto';
import {CropCode} from '@/enums/crop-code';
import {FarmFieldRowDirection} from '@/enums/farm-field-row-direction';
export class ManageFarmFieldForm {
    farmSiteId: number | null = null;
    farmFieldId: number | null = null;
    farmFieldName: string | null = null;
    farmFieldCode: string | null = null;
    farmFieldDescription: string | null = null;
    farmFieldSize: number | null = null;
    farmFieldNumRows: number | null = null;
    cropId: number | null = null;
    rowWidth: string | null = null;
    farmFieldColorHexCode: string | null = null;
    farmFieldRowDirection: FarmFieldRowDirection | null = null;
    farmFieldSeasonStartDate: Date | null = null;
    farmFieldSeasonEndDate: Date | null = null;
    currentSeasonName: string | null = null;
    plantedDate: Date | null = null;
    nextWorkTask: WorkTaskDto | null = null;
    cropYear: number | null = null;
    cropCode: CropCode | null = null;
    workTaskCount: number | null = null;
    observationCount: number | null = null;

    constructor(data?: Partial<ManageFarmFieldForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
