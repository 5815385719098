import i18n from '@/i18n';
import {
    faBagSeedling,
    faBinoculars,
    faChartLineDown,
    faChartLineUp,
    faDisease,
    faGauge,
    faImage,
    faMemo,
    faMouseField,
    faPooStorm,
    faSprinklerCeiling,
} from '@awesome.me/kit-b64aff0d40/icons/classic/light';
import {icon} from '@fortawesome/fontawesome-svg-core';

export interface ObservationType {
    observationTypeCode: string;
    getObservationTypeName(): string;
    icon: string;
    iconCode: string;
    color: string;
}

export const observationTypes: ObservationType[] = [
    {
        observationTypeCode: 'Damage',
        getObservationTypeName() {
            return i18n.global.t('ObservationType_ObservationTypeName_Damage');
        },
        iconCode: 'poo-storm',
        icon: icon(faPooStorm).html[0],
        color: '#FF8B06',
    },
    {
        observationTypeCode: 'Disease',
        getObservationTypeName() {
            return i18n.global.t('ObservationType_ObservationTypeName_Disease');
        },
        iconCode: 'disease',
        icon: icon(faDisease).html[0],
        color: '#d23cc9',
    },
    {
        observationTypeCode: 'FertilizerRequest',
        getObservationTypeName() {
            return i18n.global.t('ObservationType_ObservationTypeName_FertilizerRequest');
        },
        iconCode: 'bag-seedling',
        icon: icon(faBagSeedling).html[0],
        color: '#003B46',
    },
    {
        observationTypeCode: 'GoodYield',
        getObservationTypeName() {
            return i18n.global.t('ObservationType_ObservationTypeName_GoodYield');
        },
        iconCode: 'chart-line-up',
        icon: icon(faChartLineUp).html[0],
        color: '#6bd23c',
    },
    {
        observationTypeCode: 'Measurement',
        getObservationTypeName() {
            return i18n.global.t('ObservationType_ObservationTypeName_Measurement');
        },
        iconCode: 'gauge',
        icon: icon(faGauge).html[0],
        color: '#3c9ad2',
    },
    {
        observationTypeCode: 'Note',
        getObservationTypeName() {
            return i18n.global.t('ObservationType_ObservationTypeName_Note');
        },
        iconCode: 'memo',
        icon: icon(faMemo).html[0],
        color: '#d2bb3c',
    },
    {
        observationTypeCode: 'Other',
        getObservationTypeName() {
            return i18n.global.t('ObservationType_ObservationTypeName_Other');
        },
        iconCode: 'binoculars',
        icon: icon(faBinoculars).html[0],
        color: '#595959',
    },
    {
        observationTypeCode: 'Pests',
        getObservationTypeName() {
            return i18n.global.t('ObservationType_ObservationTypeName_Pests');
        },
        iconCode: 'mouse-field',
        icon: icon(faMouseField).html[0],
        color: '#5d3cd2',
    },
    {
        observationTypeCode: 'Photo',
        getObservationTypeName() {
            return i18n.global.t('ObservationType_ObservationTypeName_Photo');
        },
        iconCode: 'image',
        icon: icon(faImage).html[0],
        color: '#1D82B9',
    },
    {
        observationTypeCode: 'PoorYield',
        getObservationTypeName() {
            return i18n.global.t('ObservationType_ObservationTypeName_PoorYield');
        },
        iconCode: 'chart-line-down',
        icon: icon(faChartLineDown).html[0],
        color: '#d23cc9',
    },
    {
        observationTypeCode: 'SprayRequest',
        getObservationTypeName() {
            return i18n.global.t('ObservationType_ObservationTypeName_SprayRequest');
        },
        iconCode: 'sprinkler-ceiling',
        icon: icon(faSprinklerCeiling).html[0],
        color: '#003B46',
    },
];

export function getObservationTypeByCode(observationTypeCode: string): ObservationType | undefined {
    return observationTypes.find((type) => type.observationTypeCode === observationTypeCode);
}
