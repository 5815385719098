// src/directives/v-click-away.ts

import {Directive, DirectiveBinding} from 'vue';

type ClickAwayElement = HTMLElement & {
    clickAwayEvent?: (event: MouseEvent) => void;
};

const vClickAway: Directive = {
    mounted(el: ClickAwayElement, binding: DirectiveBinding<(event: MouseEvent) => void>) {
        // Ensure the binding value is a function
        if (typeof binding.value !== 'function') {
            console.warn(`[v-click-away] provided value is not a function.`);
            return;
        }

        // Assign an event handler that calls the provided function if clicked outside
        el.clickAwayEvent = (event: MouseEvent) => {
            if (!(el === event.target || el.contains(event.target as Node))) {
                binding.value(event); // Call the provided function
            }
        };

        document.addEventListener('click', el.clickAwayEvent);
    },

    unmounted(el: ClickAwayElement) {
        if (el.clickAwayEvent) {
            document.removeEventListener('click', el.clickAwayEvent);
        }
    },
};

export default vClickAway;
