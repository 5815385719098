<template>
    <div class="rounded-text custom-background">
        <CircleIcon class="custom-chip custom-icon swatchA4BG">
            <FontAwesomeIcon :icon="['fal', 'seedling']" class="rounded-text custom-background" />
        </CircleIcon>
        <span class="pr-2" style="padding-left: 5px" v-if="seasonStartTime">
            {{ formattedDate }}
        </span>
    </div>
</template>

<script lang="ts">
import {PropType, defineComponent, computed} from 'vue';

export default defineComponent({
    name: 'FirstPlantedChip',
    props: {
        seasonStartTime: Date as PropType<Date>,
    },
    setup(props) {
        type DateTimeFormatOptions = Intl.DateTimeFormatOptions;

        // Define options for formatting
        const options = {year: 'numeric', month: 'long', day: 'numeric'} as DateTimeFormatOptions;

        // Convert date to desired format
        const formattedDate = computed(() =>
            props.seasonStartTime ? props.seasonStartTime.toLocaleDateString('en-AU', options) : '',
        );

        return {
            formattedDate,
        };
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';
.custom-chip {
    font-size: 18pt;
    height: 30px;
    width: 30px;
}
.rounded-text {
    display: inline-flex;
    align-items: center;
    border-radius: 20px; /* Adjust the border radius to make it round */
    font-family: 'Open Sans Condensed', sans-serif;
}

.custom-background {
    background-color: rgba($swatchA4, 0.8);
    color: $swatchWHT;
}
.custom-icon {
    background-color: $swatchA4;
}
</style>
