import {ProductTypeCode} from '@/enums/product-type-code';
import {ProductSubtypeCode} from '@/enums/product-subtype-code';
import {MixtureProductDto} from '../data-transfer-objects/data-source/MixtureProductDto';

export class WorkTaskMixtureForm {
    workTaskMixtureId: number | null = null;
    workTaskTemplateMixtureId: number | null = null;
    mixtureId: number | null = null;
    newItemId: string | null = null;
    mixtureProducts: MixtureProductDto[] = [];
    mixtureProductSubtypes: Record<
        string,
        {productTypeCode: ProductTypeCode; productSubtypeCode: ProductSubtypeCode; count: number}
    > | null = null;

    constructor(data?: Partial<WorkTaskMixtureForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
