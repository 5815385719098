export class ActionWorkSubTaskDto {
    workSubTaskId!: number;
    isCompleted = false;

    constructor(data?: Partial<ActionWorkSubTaskDto>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
