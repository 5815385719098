<template>
    <table style="width: 100%" class="tblForm">
        <!-- Past Season -->
        <tr v-if="!isCurrentSeason">
            <td class="tableCell padding bold swatchA1 slightlyLarger">
                {{ localFarmFieldSeason.seasonName }}
                <IdIcon v-if="localFarmFieldSeason.seasonId">{{ localFarmFieldSeason.seasonId }}</IdIcon>
            </td>
        </tr>
        <tr v-if="isCurrentSeason">
            <td class="tableCell padding" style="width: 20%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('SelectedSeason') }}</span>
                    <HelpIcon :help-text="$t('FarmFieldSeasonManage_SelectedSeason_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localFarmFieldSeason.seasonId"
                        :items="seasonOptions"
                        item-title="seasonName"
                        item-value="seasonId"
                        max-width="300px" />
                </div>
            </td>
            <td colspan="2" rowspan="3" v-if="isNewSeason">
                <div class="swatchA5BG pa-2" style="max-width: 500px">
                    <FontAwesomeIcon :icon="['fas', 'circle-question']" size="xl" />
                    <span class="pl-2">{{ $t('Instructions') }}</span>
                </div>
                <div class="swatchA6BG pa-2" style="max-width: 500px">
                    {{ $t('FarmFieldSeasonManage_NewSeason_Instructions') }}
                </div>
            </td>
        </tr>
        <tr v-if="isCurrentSeason">
            <td class="tableCell padding" style="width: 20%; min-width: 10rem; vertical-align: top">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('DefaultDates') }}</span>
                </div>
            </td>
            <td>
                {{ selectedSeasonDateText }}
            </td>
        </tr>
        <tr v-if="!isNewSeason">
            <td class="tableCell padding" style="width: 20%; min-width: 10rem; vertical-align: top">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap mt-2">
                    <span>{{ $t('SeasonStatus') }}</span>
                    <HelpIcon :help-text="$t('FarmFieldSeasonManage_SeasonStatus_HelpText')" />
                </div>
            </td>

            <td>
                <v-btn-toggle
                    :disabled="!isCurrentSeason && !isFirstPastSeasonAndNoActiveSeason"
                    v-model="localFarmFieldSeason.temporaryIsActive"
                    class="toggle-border"
                    :color="seasonActiveToggleColor"
                    mandatory
                    density="compact"
                    border>
                    <v-btn :value="true" width="150px" @click="handleClick"
                        >{{ $t('Active')
                        }}<template v-slot:append
                            ><FontAwesomeIcon
                                :icon="['fas', 'circle-check']"
                                size="xl"
                                :class="!localFarmFieldSeason.temporaryIsActive ? 'swatchG3' : ''" /></template
                    ></v-btn>
                    <v-btn :value="false" width="150px"
                        >{{ $t('Finished')
                        }}<template v-slot:append
                            ><FontAwesomeIcon
                                :icon="['fas', 'flag-checkered']"
                                size="xl"
                                :class="localFarmFieldSeason.temporaryIsActive ? 'swatchG3' : ''" /></template
                    ></v-btn>
                </v-btn-toggle>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding" style="width: 20%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('SeasonStartDate') }}</span>
                    <HelpIcon :help-text="$t('FarmFieldSeasonManage_SeasonStartDate_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <VueDatePicker
                        v-model="localFarmFieldSeason.farmFieldSeasonStartDate"
                        :disabled="!isCurrentSeason && !isFirstPastSeasonAndNoActiveSeason"
                        :disabled-dates="isStartDateDisabled"
                        format="dd/MM/yyyy"
                        :enable-time-picker="false"
                        text-input
                        style="max-width: 300px"
                        @change="validateStartDate"
                        @blur="validateStartDate"
                        :locale="i18n.global.locale" />
                    <!-- Hidden text field for validation -->
                    <v-text-field
                        v-model="localFarmFieldSeason.farmFieldSeasonStartDate"
                        :rules="startDateRules"
                        v-show="false" />
                </div>
                <div v-if="startDateError" class="error-message swatchRED">{{ startDateError }}</div>
            </td>
            <td class="tableCell padding" style="width: 20%; min-width: 10rem" v-if="!isNewSeason">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('SeasonEndDate') }}</span>
                    <HelpIcon :help-text="$t('FarmFieldSeasonManage_SeasonEndDate_HelpText')" />
                </div>
            </td>
            <td v-if="!isNewSeason">
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <VueDatePicker
                        :disabled="
                            (!isCurrentSeason && !isFirstPastSeasonAndNoActiveSeason) ||
                            localFarmFieldSeason.temporaryIsActive
                        "
                        :disabled-dates="isEndDateDisabled"
                        v-model="localFarmFieldSeason.farmFieldSeasonEndDate"
                        format="dd/MM/yyyy"
                        :enable-time-picker="false"
                        text-input
                        style="max-width: 300px"
                        @change="validateEndDate"
                        @blur="validateEndDate"
                        :locale="i18n.global.locale" />
                    <!-- Hidden text field for validation -->
                    <v-text-field
                        v-show="false"
                        v-model="localFarmFieldSeason.farmFieldSeasonEndDate"
                        :rules="endDateRules"
                        :disabled="localFarmFieldSeason.temporaryIsActive" />
                </div>
                <div v-if="endDateError && !isDisableValidation" class="error-message swatchRED">
                    {{ endDateError }}
                </div>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding" style="width: 20%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('YearOfCrop') }}</span>
                    <HelpIcon :help-text="$t('FarmFieldSeasonManage_YearOfCrop_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localFarmFieldSeason.cropYear"
                        :items="cropYearOptions"
                        item-title="cropYearName"
                        item-value="cropYear"
                        max-width="300px"
                        :rules="cropYearRules" />
                </div>
            </td>
        </tr>
        <tr v-if="!isNewSeason">
            <td class="tableCell padding" style="width: 20%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('YieldColonTotalWeight') }}</span>
                    <HelpIcon :help-text="$t('FarmFieldSeasonManage_YieldColonTotalWeight_HelpText')" />
                </div>
            </td>
            <td style="width: 30%">
                <v-text-field v-model="localFarmFieldSeason.yieldTotalWeight" max-width="300px" :rules="totalWeight">
                    <template v-slot:append-inner> {{ $t('Tonnes') }}</template>
                </v-text-field>
            </td>
            <td class="tableCell padding" style="width: 20%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('YieldColonAverageCCS') }}</span>
                    <HelpIcon :help-text="$t('FarmSiteManage_YieldColonAverageCCS_HelpText')" />
                </div>
            </td>
            <td style="width: 30%">
                <v-text-field v-model="localFarmFieldSeason.yieldAverageCcs" max-width="300px" :rules="averageCCS"
                    ><template v-slot:append-inner> %</template></v-text-field
                >
            </td>
        </tr>
    </table>
</template>

<script setup lang="ts">
import {ref, reactive, defineAsyncComponent, computed, watch} from 'vue';
import i18n from '@/i18n';
import {ManageFarmFieldSeasonForm} from '@/models/farm/ManageFarmFieldSeasonForm';
import {GetSeasonsResponse} from '@/models/api/responses/data-source/GetSeasonsResponse';

// Props
const props = withDefaults(
    defineProps<{
        farmFieldSeason: ManageFarmFieldSeasonForm;
        seasonOptions: GetSeasonsResponse[];
        pastDateRanges: {start: Date; end: Date}[];
        isCurrentSeason?: boolean;
        isFirstPastSeasonAndNoActiveSeason?: boolean;
    }>(),
    {
        isCurrentSeason: false,
        isFirstPastSeasonAndNoActiveSeason: false,
    },
);

const isDisableValidation = computed(() => localFarmFieldSeason.seasonId === null);
const isNewSeason = computed(
    () => props.farmFieldSeason.farmFieldSeasonId !== 0 && !props.farmFieldSeason.farmFieldSeasonId,
);

// Create a local copy of the prop
const localFarmFieldSeason = reactive<ManageFarmFieldSeasonForm>(props.farmFieldSeason);
localFarmFieldSeason.farmFieldSeasonStartDate = props.farmFieldSeason.farmFieldSeasonStartDate
    ? new Date(props.farmFieldSeason.farmFieldSeasonStartDate)
    : null;
localFarmFieldSeason.farmFieldSeasonEndDate = props.farmFieldSeason.farmFieldSeasonEndDate
    ? new Date(props.farmFieldSeason.farmFieldSeasonEndDate)
    : null;

watch(
    () => localFarmFieldSeason.seasonId,
    (val) => {
        if ((val || val === 0) && props.isCurrentSeason && props.farmFieldSeason.farmFieldSeasonId === null) {
            const selectedSeason = props.seasonOptions.find((e) => e.seasonId === val);
            localFarmFieldSeason.farmFieldSeasonStartDate =
                selectedSeason && selectedSeason.seasonStartDate ? new Date(selectedSeason.seasonStartDate) : null;
        }
    },
);

watch(
    () => localFarmFieldSeason.temporaryIsActive,
    (val) => {
        if (!val && !isEndDateDisabled(new Date())) localFarmFieldSeason.farmFieldSeasonEndDate = new Date();
        else {
            localFarmFieldSeason.farmFieldSeasonEndDate = null;
            endDateError.value = null;
        }
        localFarmFieldSeason.temporaryIsActive = val;
    },
);

const handleClick = () => {
    if (localFarmFieldSeason.temporaryIsActive && props.isFirstPastSeasonAndNoActiveSeason) {
        localFarmFieldSeason.isActive = true;
        localFarmFieldSeason.temporaryIsActive = true;
        localFarmFieldSeason.farmFieldSeasonEndDate = null;
    }
};

const VueDatePicker = defineAsyncComponent(() => import('@vuepic/vue-datepicker'));
const seasonActiveToggleColor = computed(() =>
    !props.isCurrentSeason && !isNewSeason.value && !props.isFirstPastSeasonAndNoActiveSeason ? 'primary' : 'swatchB2',
);
const cropYearOptions = ref([
    {cropYear: 1, cropYearName: i18n.global.t('FieldAgeIcon_FirstYear_HelpText')},
    {cropYear: 2, cropYearName: i18n.global.t('FieldAgeIcon_SecondYear_HelpText')},
    {cropYear: 3, cropYearName: i18n.global.t('FieldAgeIcon_ThirdYear_HelpText')},
    {cropYear: 4, cropYearName: i18n.global.t('FieldAgeIcon_FourthYear_HelpText')},
    {cropYear: 5, cropYearName: i18n.global.t('FieldAgeIcon_FifthYear_HelpText')},
    {cropYear: 6, cropYearName: i18n.global.t('FieldAgeIcon_SixthYear_HelpText')},
    {cropYear: 0, cropYearName: i18n.global.t('FieldAgeIcon_FallowYear_HelpText')},
]);

//add new task constaints
const dateConstraints = computed(() => {
    if (props.farmFieldSeason && props.farmFieldSeason.taskDueDateEarliest && props.farmFieldSeason.taskDueDateLatest)
        return [
            ...props.pastDateRanges,
            {
                start: new Date(props.farmFieldSeason.taskDueDateEarliest),
                end: new Date(props.farmFieldSeason.taskDueDateLatest),
            },
        ];
    else return props.pastDateRanges;
});

// Function to check if a date falls within any of the disabled ranges
const isStartDateDisabled = (date: Date) => {
    return dateConstraints.value.some((range) => {
        return date >= range.start && date <= range.end;
    });
};

// Function to check if a date falls within any of the disabled ranges
const isEndDateDisabled = (date: Date) => {
    if (!localFarmFieldSeason.farmFieldSeasonStartDate) return false;
    else {
        let nearestDate: Date | null = null;
        let smallestDifference = Infinity;

        for (const range of Object.values(dateConstraints.value)) {
            const startDate: Date = range.start;
            const difference = Math.abs(startDate.getTime() - localFarmFieldSeason.farmFieldSeasonStartDate.getTime());

            if (difference < smallestDifference) {
                smallestDifference = difference;
                nearestDate = startDate;
            }
        }
        if (!nearestDate) return false;
        if (nearestDate <= localFarmFieldSeason.farmFieldSeasonStartDate) nearestDate = new Date(2100, 1, 1);
        return date <= localFarmFieldSeason.farmFieldSeasonStartDate || date >= nearestDate;
    }
};

const selectedSeasonDateText = computed(() => {
    const selectedSeason = props.seasonOptions.find((e) => e.seasonId === localFarmFieldSeason.seasonId);
    if (selectedSeason) {
        const startText = new Date(selectedSeason.seasonStartDate).toLocaleDateString('en-AU', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        });
        if (selectedSeason.seasonEndDate) {
            return (
                startText +
                ' - ' +
                new Date(selectedSeason.seasonEndDate).toLocaleDateString('en-AU', {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                })
            );
        } else return startText;
    } else return '';
});

const cropYearRules = ref([
    (v: number | null) => isDisableValidation.value || !!v || v === 0 || i18n.global.t('Validation_Required'),
]);
const totalWeight = ref([
    (v: string | null) => !v || /^-?\d+(\.\d+)?$/.test(v) || i18n.global.t('Validation_InvalidFormat'),
    (v: string | null) =>
        !v ||
        (parseFloat(v) >= 0 && parseFloat(v) <= 100_000) ||
        i18n.global.t('ManageFarmFieldSeason_TotalWeightValidation_InvalidNumber'),
]);
const averageCCS = ref([
    (v: string | null) => !v || /^-?\d+(\.\d+)?$/.test(v) || i18n.global.t('Validation_InvalidFormat'),
    (v: string | null) =>
        !v ||
        (parseFloat(v) >= 0 && parseFloat(v) <= 100) ||
        i18n.global.t('ManageFarmFieldSeason_AverageCCSValidation_InvalidNumber'),
]);

// Validation rules for date pickers
const startDateRules = ref([
    (v: Date | string | null) => isDisableValidation.value || !!v || i18n.global.t('Validation_Required'),
]);

const endDateRules = ref([
    (v: Date | string | null) => localFarmFieldSeason.temporaryIsActive || !!v || i18n.global.t('Validation_Required'),
]);

// Validation states
const startDateError = ref<string | null>(null);
const endDateError = ref<string | null>(null);

// Manual validation methods
const validateStartDate = () => {
    if (!localFarmFieldSeason.farmFieldSeasonStartDate) {
        startDateError.value = i18n.global.t('Validation_Required');
    } else {
        startDateError.value = null;
    }
};

const validateEndDate = () => {
    if (!localFarmFieldSeason.temporaryIsActive && !localFarmFieldSeason.farmFieldSeasonEndDate) {
        endDateError.value = i18n.global.t('Validation_Required');
    } else {
        endDateError.value = null;
    }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';
.error-message {
    font-size: 0.875rem;
}

.v-btn[disabled] {
    opacity: 1 !important;
}

.v-btn__overlay {
    background-color: $swatchWHT !important;
}

.v-btn--disabled.v-btn--variant-elevated,
.v-btn--disabled.v-btn--variant-flat {
    color: var(--v-theme-on-surface);
}

.toggle-border {
    border: 1px solid $swatchG5 !important;
}
</style>
