<template>
    <v-toolbar class="swatchA2BG">
        <FontAwesomeLayers style="font-size: 2rem" class="ml-3 swatchWHT">
            <FontAwesomeIcon :icon="['fal', 'square']" transform="grow-2" />
            <FontAwesomeIcon :icon="['fas', 'clipboard']" transform="shrink-5" />
        </FontAwesomeLayers>
        <v-toolbar-title class="swatchWHT task-template-data-table-title"
            >{{ $t('TaskTemplates') + ` (${resultsList.length})` }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
            v-if="userStore.hasRoleLevel(RoleCode.PlansAndTemplates, 2)"
            class="v-btn--custom mr-2"
            rounded="1"
            @click="manageWorkTaskModalStore.open(ManageWorkTaskModalMode.CreateTemplate, null, null)">
            <template v-slot:prepend>
                <FontAwesomeIcon :icon="['fal', 'plus']" size="xl" />
            </template>
            {{ $t('Add') }}
        </v-btn>

        <v-btn id="menu-activator" :class="filterButtonClass" @click="switchBackToSearch">
            <template v-slot:prepend>
                <FontAwesomeIcon :icon="['fal', 'filter']" size="xl" v-if="isFilterApplied" />
                <FontAwesomeIcon :icon="['fac', 'filter-check']" size="xl" v-else />
            </template>
            {{ $t('Filters') }}
        </v-btn>
    </v-toolbar>
    <v-data-table
        :headers="headers"
        :items="resultsList"
        :items-per-page="configStore.defaultListItemNumberPerPage"
        @click:row="viewTaskTemplate"
        class="work-task-template-search-list">
        <template v-slot:[`item.workTaskTemplateName`]="{item}">
            <div class="tableCell">
                <span class="swatchA1 slightlyLarger"> {{ item.workTaskTemplateName }} </span>
            </div>
        </template>
        <template v-slot:[`item.workTaskTypeCode`]="{item}">
            <v-col cols="12" align="center">
                <WorkTaskTypeChip :workTaskTypeCode="item.workTaskTypeCode" />
            </v-col>
        </template>
        <template v-slot:[`item.createdDate`]="{item}">
            <v-row>
                <UserChip
                    v-if="item.createdUserId !== null"
                    :user="
                        UserHelper.userChipType(
                            item.createdUserId,
                            UserHelper.userFullName(item.createdUserGivenName, item.createdUserSurname),
                        )
                    " />
                <span class="pl-3 pt-1 swatchA1"> {{ formatDate(item.createdDate) }}</span>
            </v-row>
        </template>
        <template v-slot:[`item.lastModifiedDate`]="{item}">
            <v-row>
                <UserChip
                    v-if="item.modifiedUserId !== null"
                    :user="
                        UserHelper.userChipType(
                            item.modifiedUserId,
                            UserHelper.userFullName(item.modifiedUserGivenName, item.modifiedUserSurname),
                        )
                    " />
                <span class="pl-3 pt-1 swatchA1"> {{ formatDate(item.modifiedDate) }}</span>
            </v-row>
        </template>
        <template v-slot:[`item.numberOfWorkTasks`]="{item}">
            <v-col cols="12" align="center">
                <WorkTaskTemplateNumberOfTasksChip :number-of-work-tasks="item.numberOfWorkTasks" />
            </v-col>
        </template>

        <template v-slot:[`item.numberOfWorkTaskPlans`]="{item}">
            <v-col cols="12" align="center">
                <WorkTaskTemplateNumberOfTaskPlansChip :number-of-work-task-plans="item.numberOfWorkTaskPlans" />
            </v-col>
        </template>

        <template v-slot:[`item.action`]="{item}">
            <v-col cols="12" align="center">
                <WorkTaskTemplateOperationsMenu :work-task-template="item" :menuText="actionMenuText" />
            </v-col>
        </template>
    </v-data-table>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import {WorkTaskTemplateSearchResultDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchResultDto';
import {format} from 'date-fns';
import i18n from '@/i18n';
import {useViewWorkTaskTemplateModalStore} from '@/stores/modals/view-work-task-template-modal-store';
const viewWorkTaskTemplateModalStore = useViewWorkTaskTemplateModalStore();
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
import {ManageWorkTaskModalMode} from '@/enums/modal-modes/manage-work-task-modal-mode';
import UserHelper from '@/helpers/UserHelper';
import WorkTaskTemplateNumberOfTasksChip from '@/components/other-chips/WorkTaskTemplateNumberOfTasksChip.vue';
import WorkTaskTemplateNumberOfTaskPlansChip from '@/components/other-chips/WorkTaskTemplateNumberOfTaskPlansChip.vue';
import WorkTaskTemplateOperationsMenu from '@/components/operations-menus/WorkTaskTemplateOperationsMenu.vue';
import UserChip from '@/components/UserChip.vue';
import {useUserStore} from '@/stores/user-store';
import {RoleCode} from '@/enums/role-code';
import {useConfigStore} from '@/stores/config-store';
const configStore = useConfigStore();

const userStore = useUserStore();

const props = withDefaults(
    defineProps<{
        resultsList: WorkTaskTemplateSearchResultDto[];
        isFilterApplied: boolean;
    }>(),
    {
        resultsList: () => [] as WorkTaskTemplateSearchResultDto[],
        isFilterApplied: false,
    },
);

const emit = defineEmits<{
    (event: 'switch-to-search'): void;
}>();

const switchBackToSearch = () => {
    emit('switch-to-search');
};

const manageWorkTaskModalStore = useManageWorkTaskModalStore();

const viewTaskTemplate = (event: MouseEvent, row: {item: WorkTaskTemplateSearchResultDto}) => {
    viewWorkTaskTemplateModalStore.open(row.item.workTaskTemplateId);
};

const actionMenuText = computed(() => i18n.global.t('Action'));
const headers = computed(() => [
    {title: i18n.global.t('TemplateName'), key: 'workTaskTemplateName', sortable: true},
    {title: i18n.global.t('TaskType'), key: 'workTaskTypeCode', sortable: true},
    {title: i18n.global.t('Created'), key: 'createdDate', sortable: true},
    {title: i18n.global.t('LastModified'), key: 'lastModifiedDate', sortable: true},
    {title: i18n.global.t('TimesUsed'), key: 'numberOfWorkTasks', sortable: true},
    {title: i18n.global.t('UsedInPlans'), key: 'numberOfWorkTaskPlans', sortable: true},
    {title: i18n.global.t('Action'), key: 'action', sortable: true},
]);

/**
 * Format date for report results.
 */
const formatDate = (date: Date | null): string => (!date ? '' : format(date, 'dd MMMM, yyyy'));

const filterButtonClass = computed(() => (props.isFilterApplied ? ['v-btn--custom'] : ['swatchB6BG', 'swatchWHT']));
</script>
<style lang="scss">
@import '@/assets/scss/swatches.scss';
.task-template-data-table-title {
    font-size: 1rem;
    max-width: 300px;
}

.work-task-template-search-list .v-table__wrapper thead {
    background-color: $swatchG9;
}
</style>
