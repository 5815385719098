<template>
    <LoadingSymbol v-if="isLoading" />
    <table v-else style="width: 100%" class="tblForm">
        <tr>
            <td colspan="4" class="subHeader">{{ $t('Particulars') }}</td>
        </tr>
        <tr>
            <td class="tableCell padding" style="width: 15%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('Crop') }}</span>
                    <HelpIcon :help-text="$t('CropVarietySearch_Crop_HelpText')" />
                </div>
            </td>
            <td style="width: 35%">
                <v-select
                    v-model="localSearchParameters.cropId"
                    hide-details
                    clearable
                    chips
                    :items="crops"
                    item-title="cropName"
                    item-value="cropId"
                    max-width="400px" />
            </td>
            <td class="tableCell padding" style="width: 15%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('Variety') }}</span>
                    <HelpIcon :help-text="$t('CropVarietySearch_Variety_HelpText')" />
                </div>
            </td>
            <td style="width: 35%">
                <v-text-field
                    v-model="localSearchParameters.cropVarietyName"
                    hide-details
                    clearable
                    style="width: 400px">
                </v-text-field>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('CropType') }}</span>
                    <HelpIcon :help-text="$t('CropVarietySearch_CropType_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.cropTypeIdList"
                        hide-details
                        multiple
                        clearable
                        chips
                        :items="cropTypes"
                        item-title="cropTypeName"
                        item-value="cropTypeId"
                        max-width="400px"></v-select>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="4" class="subHeader">{{ $t('AttributesAndAssociations') }}</td>
        </tr>
        <tr>
            <td class="tableCell padding" style="width: 15%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('FarmSite(s)Used') }}</span>
                    <HelpIcon :help-text="$t('Crop_FarmSites_HelpText')" />
                </div>
            </td>
            <td style="width: 35%">
                <v-select
                    v-model="localSearchParameters.farmSiteIdList"
                    hide-details
                    multiple
                    clearable
                    chips
                    :items="farmSites"
                    item-title="farmSiteAbbreviationAndName"
                    item-value="farmSiteId"
                    max-width="400px"></v-select>
            </td>
            <td class="tableCell padding" style="width: 15%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('FieldApplied') }}</span>
                    <HelpIcon :help-text="$t('CropVarietySearch_Field_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-autocomplete
                        v-model="localSearchParameters.farmFieldIdList"
                        hide-details
                        multiple
                        clearable
                        chips
                        :items="farmFields"
                        item-title="farmFieldCodeAndName"
                        item-value="farmFieldId"
                        max-width="400px" />
                </div>
            </td>
        </tr>
    </table>
</template>
<script setup lang="ts">
import {onMounted, ref, watch} from 'vue';
import {SearchCropVarietiesRequest} from '@/models/api/requests/search/SearchCropVarietiesRequest';
import {useFarmSites} from '@/composables/data-source/farm-sites';
const {getFarmSites, farmSites} = useFarmSites();
import {useFarmFields} from '@/composables/data-source/farm-fields';
const {getFarmFields, farmFields} = useFarmFields();
import {useCrops} from '@/composables/data-source/crops';
const {getCrops, crops} = useCrops();
import {useCropTypes} from '@/composables/data-source/crop-types';
const {getCropTypes, cropTypes} = useCropTypes();

const props = defineProps<{searchParameters: SearchCropVarietiesRequest}>();
const emit = defineEmits(['update:searchParameters']);
const isLoading = ref<boolean>(false);

// Create a local copy of the prop
const localSearchParameters = ref({...props.searchParameters});
// Watch for changes in the parent parameters object and update local copy
watch(
    () => props.searchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(localSearchParameters.value)) {
            localSearchParameters.value = {...newVal};
        }
    },
    {deep: true, immediate: true},
);
// Watch for changes in the local copy and emit the update event
watch(
    localSearchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(props.searchParameters)) {
            emit('update:searchParameters', newVal);
        }
    },
    {deep: true},
);

// Fetch data for dropdown
onMounted(async () => {
    isLoading.value = true;
    // Get data
    await getFarmSites();
    // Farm Fields
    await getFarmFields();
    await getCrops();
    await getCropTypes();
    isLoading.value = false;
});
</script>
