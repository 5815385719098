<template>
    <v-card rounded="true" class="list-item-container pa-0" style="padding: 5px" elevation="1">
        <v-row class="task-type-code swatchG6BG">
            <div class="start-circle ml-1">
                <FontAwesomeIcon class="swatchWHT" :icon="['fal', 'clock-rotate-left']" />
            </div>

            <span class="task-type-title">
                {{ taskTitle }}
            </span>
        </v-row>
        <div class="task-instructions pl-2">
            {{ taskText }}
        </div>

        <v-row class="d-flex justify-center">
            <v-col cols="auto" class="d-flex justify-center">
                <v-btn
                    rounded="1"
                    elevation="1"
                    v-if="numberOfHistoricTask && numberOfHistoricTask > 0"
                    @click.stop="viewHistoricTaskList">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'circle-arrow-right']" size="lg" />
                    </template>
                    {{ $t('ViewAll') }}
                </v-btn>
                <v-btn
                    v-else
                    rounded="1"
                    elevation="1"
                    @click.stop="manageWorkTaskModalStore.open(ManageWorkTaskModalMode.Create, null, farmFieldId)">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fas', 'plus']" size="lg" />
                    </template>
                    {{ $t('AddTask') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import i18n from '@/i18n';
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
import {ManageWorkTaskModalMode} from '@/enums/modal-modes/manage-work-task-modal-mode';
import {useUserStore} from '@/stores/user-store';
const userStore = useUserStore();
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
const homeDashboardStore = useHomeDashboardStore();
import router from '@/router';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';

const props = withDefaults(
    defineProps<{
        farmFieldId: number;
        numberOfHistoricTask: number | undefined | null;
    }>(),
    {},
);

const manageWorkTaskModalStore = useManageWorkTaskModalStore();

const taskTitle = computed(() => {
    if (props.numberOfHistoricTask) return i18n.global.t('NoTasks');
    return i18n.global.t('HistoricTasks');
});

const taskText = computed(() => {
    if (!props.numberOfHistoricTask || props.numberOfHistoricTask === 0)
        return i18n.global.t('DummyTaskCard_CardText_NoTasks');
    return i18n.global.t('DummyTaskCard_CardText_NoCurrentTasks', {historicTaskNumber: props.numberOfHistoricTask});
});

const viewHistoricTaskList = () => {
    if (props.farmFieldId) {
        const taskSearchRequest = new SearchWorkTasksRequest();
        taskSearchRequest.farmFieldId = props.farmFieldId;
        taskSearchRequest.isHistoricTasksOnly = true;
        homeDashboardStore.workTaskListFilter = taskSearchRequest;
        userStore.closeAllPopups();
        router.push({name: 'WorkTaskList'});
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.list-item-container {
    width: 200px;
    height: 200px;
    border-radius: 3px;
    border: 1px solid $swatchG7;
    background-color: $swatchG7;
    margin-bottom: 10px;

    .start-circle {
        width: 35px;
        height: 35px;
        border: 1px solid $swatchWHT;
        background-color: $swatchBLK;
        border-radius: 50%;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .v-row {
        margin: 0;
    }

    .task-type-code {
        height: 45px;
        align-content: center;
        font-size: large;
        font-weight: bold;
    }

    .task-type-title {
        width: 150px;
        padding: 0px;
        margin: 0px;
        align-content: center;
        text-align: center;
    }

    .task-instructions {
        height: 90px;
        font-weight: bold;
    }
}
</style>
