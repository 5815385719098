import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19938c96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "divGenericInfo" }
const _hoisted_2 = { class: "mainPanelInner" }
const _hoisted_3 = {
  class: "table",
  style: {"width":"100%"}
}
const _hoisted_4 = { class: "tableCell" }
const _hoisted_5 = { class: "infoTitle" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, {
      outlined: "",
      style: {"width":"90%","max-width":"50rem","margin":"auto"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_toolbar, { color: "primary" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_toolbar_title, null, {
              default: _withCtx(() => [
                _createVNode(_component_FontAwesomeIcon, {
                  icon: ['fal', 'info-circle'],
                  size: "xl",
                  class: "mr-2"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.pageTitle), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_form, { class: "pa-4" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.pageTitle), 1),
                  (_ctx.isAboutMode)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('GenericInformation_AboutSystem')), 1)
                      ]))
                    : (_ctx.isPrivacyMode)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('GenericInformation_PrivacyPolicy')), 1)
                        ]))
                      : (_ctx.isCopyrightMode)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("p", null, _toDisplayString(_ctx.$t('GenericInformation_Copyright')), 1)
                          ]))
                        : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}