import {CascadeChangesOptionCode} from '@/enums/cascade-changes-option-code';
import i18n from '@/i18n';

export interface CascadeChangesOption {
    cascadeChangesOptionCode: CascadeChangesOptionCode;
    getCascadeChangesOptionName(): string;
    isAnOptionWhenTaskMovedToEarlierDueDate: boolean;
    isAnOptionWhenTaskMovedToLaterDueDate: boolean;
}

export const cascadeChangesOptions: CascadeChangesOption[] = [
    {
        cascadeChangesOptionCode: CascadeChangesOptionCode.MaintainCurrentDependencyDays,
        getCascadeChangesOptionName() {
            return i18n.global.t('CascadeChanges_MaintainCurrentDependencyDays');
        },
        isAnOptionWhenTaskMovedToEarlierDueDate: true,
        isAnOptionWhenTaskMovedToLaterDueDate: false,
    },
    {
        cascadeChangesOptionCode: CascadeChangesOptionCode.RecalculateDependencyDays,
        getCascadeChangesOptionName() {
            return i18n.global.t('CascadeChanges_RecalculateDependencyDays');
        },
        isAnOptionWhenTaskMovedToEarlierDueDate: true,
        isAnOptionWhenTaskMovedToLaterDueDate: false,
    },
    {
        cascadeChangesOptionCode: CascadeChangesOptionCode.PushLaterTasksBack,
        getCascadeChangesOptionName() {
            return i18n.global.t('CascadeChanges_PushLaterTasksBack');
        },
        isAnOptionWhenTaskMovedToEarlierDueDate: false,
        isAnOptionWhenTaskMovedToLaterDueDate: true,
    },
    {
        cascadeChangesOptionCode: CascadeChangesOptionCode.LeaveLaterTasksUnchanged,
        getCascadeChangesOptionName() {
            return i18n.global.t('CascadeChanges_LeaveLaterTasksUnchanged');
        },
        isAnOptionWhenTaskMovedToEarlierDueDate: false,
        isAnOptionWhenTaskMovedToLaterDueDate: true,
    },
];

export function getCascadeChangesOptionByCode(
    cascadeChangesOptionCode: CascadeChangesOptionCode,
): CascadeChangesOption | undefined {
    return cascadeChangesOptions.find((aru) => aru.cascadeChangesOptionCode === cascadeChangesOptionCode);
}
