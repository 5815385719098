import {WorkTaskDueDateTiming} from '@/enums/work-task-due-date-timing';
import {MonthAndYear} from '../generic/MonthAndYear';
import {CascadeChangesOptionCode} from '@/enums/cascade-changes-option-code';

export class RescheduleWorkTaskForm {
    workTaskId: number | null = null;
    farmFieldId: number | null = null;
    dueDateTiming: WorkTaskDueDateTiming = WorkTaskDueDateTiming.DueBy;
    dueDateOn: Date | null = null;
    dueDateLatest: Date | null = null;
    dueDateMonth: MonthAndYear | null = null;
    dueDateRange: Date[] = [];
    dueAfterWorkTaskId: number | null = null;
    dueAfterWorkTaskWithinDays: number | null = null;
    rescheduleTaskReason: string | null = null;
    rescheduleTaskCascadeChangesOptionCode: CascadeChangesOptionCode | null = null;

    constructor(data?: Partial<RescheduleWorkTaskForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
