import {defineStore} from 'pinia';

export const useViewObservationModalStore = defineStore('viewObservationModal', {
    state: () => ({
        isVisible: false,
        observationId: null as number | null,
        activityComment: null as string | null,
        openDelete: false,
        savedCounter: 0,
    }),
    actions: {
        open(observationId: number, activityComment: string | null = null, openDelete: boolean = false) {
            this.close();

            this.observationId = observationId;
            this.activityComment = activityComment;
            this.openDelete = openDelete;
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
            this.observationId = null;
            this.activityComment = null;
            this.openDelete = false;
        },
    },
});
