export enum HeaderMenuItem {
    Admin = 'Admin',
    Custom = 'Custom',
    Home = 'Home',
    Lists = 'Lists',
    Login = 'Login',
    Config = 'Config',
    Reports = 'Reports',
    Fields = 'Fields',
    Tasks = 'Tasks',
    Observations = 'Observations',
}
