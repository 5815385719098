<template>
    <span class="swatchA1">{{ description }}</span>

    <table class="mt-2" style="border-spacing: 0 10px">
        <tr v-for="workTask in tasksThatWillBeChanged" :key="workTask.workTaskId">
            <td class="pr-4">
                <WorkTaskTypeChip :workTaskTypeCode="workTask.workTaskTypeCode" size="default" icon-size="lg" />
            </td>
            <td class="pr-6">
                <span v-if="workTask.workTaskName" class="swatchA1 bold"> "{{ workTask.workTaskName }}" </span>
                <span class="swatchA1 ml-1">({{ $t('ID') }}: {{ workTask.workTaskId }})</span>
            </td>
            <td>
                <FontAwesomeIcon class="swatchG3 mr-2" :icon="['fa', 'calendar-day']" size="xl" />
                <span class="swatchA1">{{ formatDate(workTask.dueDateLatest) }}</span>
            </td>
        </tr>
    </table>
</template>

<script setup lang="ts">
import {defineProps} from 'vue';
import {format} from 'date-fns';
import {WorkTaskChangeDetailsDto} from '@/models/data-transfer-objects/work-tasks/WorkTaskChangeDetailsDto';

defineProps<{
    tasksThatWillBeChanged: WorkTaskChangeDetailsDto[];
    description: string;
}>();

const formatDate = (date: Date | null, dateFormat: string = 'd MMMM, yyyy'): string | null => {
    if (!date) return null;
    return format(date, dateFormat);
};
</script>
