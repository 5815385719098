<template>
    <v-form
        ref="form"
        v-model="valid"
        validate-on="submit"
        class="work-task-mixture-item"
        :class="{'new-item': isAddNewItem}">
        <table style="width: 100%; border-spacing: 1px; margin-bottom: -1px" class="tblForm swatchG9BG">
            <tr v-if="index == 0 || workTaskMixtureList.length == 0">
                <td class="swatchG7BG">{{ $t('NumberShort') }}</td>
                <td class="swatchG7BG">{{ $t('Mixture') }}</td>
                <td class="swatchG7BG">{{ $t('Contents') }}</td>
                <td class="swatchG7BG"></td>
            </tr>
            <tr>
                <td style="width: 8%; text-align: center" class="swatchWHTBG">
                    <span v-if="isAddNewItem">NEW</span>
                    <span v-if="!isAddNewItem">{{ index + 1 }}</span>
                </td>
                <td style="width: 50%" class="swatchWHTBG">
                    <div style="display: flex; align-items: center">
                        <v-autocomplete
                            v-model="localWorkTaskMixture.mixtureId"
                            :rules="[requiredRule, uniqueRule]"
                            required
                            :readonly="!isAddNewItem"
                            :placeholder="$t('SelectAMixture')"
                            :items="mixtures"
                            item-title="mixtureName"
                            item-value="mixtureId"
                            max-width="400px"
                            menu-icon=""
                            class="swatchA1 font-weight-bold"
                            :class="{noSelection: !isAddNewItem}">
                            <template v-slot:append-inner>
                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchGRNBG"
                                    color="swatchWHT"
                                    v-if="localWorkTaskMixture.mixtureId != null">
                                    <FontAwesomeIcon :icon="['fas', 'check']" />
                                </CircleIcon>
                            </template>
                        </v-autocomplete>

                        <!-- View Mixture button -->
                        <v-btn
                            v-if="localWorkTaskMixture.mixtureId != null"
                            @click="viewMixtureModalStore.open(localWorkTaskMixture.mixtureId)"
                            class="v-btn--custom ml-4">
                            <template v-slot:prepend>
                                <FontAwesomeIcon :icon="['fas', 'flask-round-potion']" size="xl" />
                            </template>
                            {{ $t('View') }}
                        </v-btn>
                    </div>
                </td>
                <td style="width: 30%" class="swatchWHTBG">
                    <div style="display: flex; align-items: center">
                        <ProductSubtypeIcon
                            v-for="(productSubtype, index) in localWorkTaskMixture.mixtureProductSubtypes"
                            :key="index"
                            :product-type-code="productSubtype.productTypeCode"
                            :product-subtype-code="productSubtype.productSubtypeCode"
                            :product-count="productSubtype.count"
                            icon-height="38"
                            icon-width="38"
                            class="mr-2" />
                    </div>
                </td>
                <td style="width: 12%; text-align: center" class="swatchWHTBG">
                    <v-btn
                        v-if="isAddNewItem"
                        @click="addWorkTaskMixture"
                        class="v-btn--custom"
                        style="align-self: flex-end; max-width: 100%">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'plus']" size="xl" />
                        </template>
                        {{ $t('Add') }}
                    </v-btn>

                    <v-btn
                        v-if="!isAddNewItem"
                        @click="deleteWorkTaskMixture(index)"
                        class="v-btn--custom"
                        style="align-self: flex-end; max-width: 100%">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'trash']" size="xl" />
                        </template>
                        {{ $t('Delete') }}
                    </v-btn>
                </td>
            </tr>
        </table>
    </v-form>
</template>

<script setup lang="ts">
import Validation from '@/helpers/ValidationHelper';
import i18n from '@/i18n';
import {GetMixturesResponse} from '@/models/api/responses/data-source/GetMixturesResponse';
import {WorkTaskMixtureForm} from '@/models/work-tasks/WorkTaskMixtureForm';
import {computed, nextTick, onMounted, ref} from 'vue';
import ProductSubtypeIcon from '@/components/ProductSubtypeIcon.vue';
import ManageWorkTaskHelper from '@/helpers/ManageWorkTaskHelper';
import {useViewMixtureModalStore} from '@/stores/modals/view-mixture-modal-store';
const viewMixtureModalStore = useViewMixtureModalStore();

const props = withDefaults(
    defineProps<{
        workTaskMixture?: WorkTaskMixtureForm | null;
        workTaskMixtureList: WorkTaskMixtureForm[];
        mixtures: GetMixturesResponse[];
        index: number;
    }>(),
    {
        workTaskMixture: null,
    },
);
const emit = defineEmits(['add', 'delete']);

// Form
const form = ref();
const valid = ref<boolean>(true);
const isLoading = ref<boolean>(true);
const localWorkTaskMixture = ref<WorkTaskMixtureForm>(new WorkTaskMixtureForm());
let newItemCount = 0;

// Rules
const requiredRule = Validation.createRule_Required();
const uniqueRule = ref((v: number | null) => {
    if (!v) return true;
    if (!isAddNewItem.value) return true;
    return (
        props.workTaskMixtureList.filter((wtm) => wtm.mixtureId == v).length == 0 ||
        i18n.global.t('Validation_MixtureUnique')
    );
});

/**
 * Adds the mixture.
 */
const addWorkTaskMixture = async () => {
    // Perform final client side validation of form
    await validate();

    // If form is valid
    if (valid.value) {
        // Increment new item count so that new items have a unique ID
        newItemCount += 1;

        // Set new item ID
        localWorkTaskMixture.value.newItemId = 'NewItem' + newItemCount;

        // Get mixture product details
        ManageWorkTaskHelper.getMixtureProductDetails(localWorkTaskMixture.value, props.mixtures);

        // Call emit
        emit('add', localWorkTaskMixture.value);

        // Reset data so that another item can be added if needed
        localWorkTaskMixture.value = new WorkTaskMixtureForm();
    }
};

/**
 * Deletes the mixture.
 */
const deleteWorkTaskMixture = (workTaskMixtureIndex: number) => {
    emit('delete', workTaskMixtureIndex);
};

/**
 * Validate the form.
 */
const validate = async (): Promise<boolean> => {
    if (form.value) {
        const {valid} = await form.value.validate();
        return valid;
    }
    return false;
};

/**
 * Flag to indicate if this is in "Add" mode or if it's displaying an existing work task mixture.
 */
const isAddNewItem = computed(() => props.index == -1);

onMounted(async () => {
    // If this is an existing work task mixture
    if (props.workTaskMixture) {
        // Assign existing value to form object
        localWorkTaskMixture.value = Object.assign(new WorkTaskMixtureForm(), props.workTaskMixture);
    }

    // End loading (nextTick is used to ensure that the watch events on localWorkTaskMixture are not triggered during the load)
    nextTick(() => {
        isLoading.value = false;
    });
});

defineExpose({validate});
</script>
