import {GetProductsResponse} from '@/models/api/responses/data-source/GetProductsResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useProducts() {
    const apiService = inject('apiService') as ApiService;
    const products = ref<GetProductsResponse[]>([]);
    const productsWithNull = ref<SelectListItem[]>([]);

    /**
     * Get list of products.
     */
    const getProducts = async () => {
        products.value = await apiService.get('data-source/products');

        // Add null value
        productsWithNull.value = products.value.map((product) => ({
            value: product.productId,
            title: product.productName,
        }));

        productsWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getProducts,
        products,
        productsWithNull,
    };
}
