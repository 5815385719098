export class SaveWorkTaskTemplateMixtureDto {
    workTaskTemplateMixtureId: number | null = null;
    mixtureId!: number;

    constructor(data?: Partial<SaveWorkTaskTemplateMixtureDto>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
