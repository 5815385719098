import {defineStore} from 'pinia';

export const useViewDevicesModalStore = defineStore('viewDevicesModal', {
    state: () => ({
        isVisible: false,
        userId: null as number | null,
    }),
    actions: {
        open(userId: number) {
            // Close modal first (if it's already open) so that the watch is triggered when it's reopened
            this.close();
            this.userId = userId;
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
            this.userId = null;
        },
    },
});
