<template>
    <v-card flat style="width: 50rem; margin: auto">
        <v-toolbar flat color="swatchA3">
            <v-toolbar-title>
                <FontAwesomeIcon :icon="['fal', 'unlock']" size="xl" class="mr-2" />
                {{ panelTitle }}
            </v-toolbar-title>
        </v-toolbar>

        <LoadingSymbol v-if="isLoading" />

        <div v-if="!isLoading" class="pa-4">
            {{ panelDetails }}
            <v-form v-model="passwordValid">
                <div v-if="passwordVisible" class="table" style="width: 80%">
                    <div class="tableRow">
                        <div class="tableCell padding" style="width: 35%">
                            <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                <span>{{ $t('NewPassword') }}</span>
                                <HelpIcon>
                                    <template v-slot:helpText>
                                        <div>
                                            {{ $t('ChangePassword_NewPassword_HelpText') }}
                                        </div>
                                        <ul>
                                            <li>{{ passwordValidator.getLengthRequirements() }}</li>
                                            <li
                                                v-for="requirement in passwordValidator.getCharacterRequirements()"
                                                :key="requirement">
                                                {{ requirement }}
                                            </li>
                                        </ul>
                                    </template>
                                </HelpIcon>
                            </div>
                        </div>
                        <div class="tableCell padding" style="width: 65%">
                            <v-text-field
                                variant="outlined"
                                type="Password"
                                v-model="newPassword"
                                class="percent20"
                                required
                                :rules="passwordRules">
                            </v-text-field>
                        </div>
                    </div>
                    <div class="tableRow">
                        <div class="tableCell padding">
                            <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                <span>{{ $t('ReEnterNewPassword') }}</span>
                            </div>
                        </div>
                        <div class="tableCell padding">
                            <v-text-field
                                variant="outlined"
                                type="Password"
                                v-model="repeatPassword"
                                class="percent20"
                                required
                                :rules="confirmationRules">
                            </v-text-field>
                        </div>
                    </div>
                </div>
            </v-form>
        </div>
        <v-card-actions class="justify-space-between swatchG9BG">
            <v-spacer />
            <!-- click should take to log in page @click="login"-->
            <v-btn v-if="loginButtonVisible" @click="returnToLogInPage()">
                <template v-slot:prepend>
                    <FontAwesomeIcon :icon="['fal', 'check-square']" size="xl" />
                </template>
                {{ $t('Login') }}
            </v-btn>
            <v-btn v-if="saveButtonVisible" @click="saveUserPassword()" :disabled="!passwordValid">
                <template v-slot:prepend>
                    <FontAwesomeIcon :icon="['fal', 'check-square']" size="xl" />
                </template>
                {{ $t('SavePassword') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts">
import ApiService from '@/services/api-service';
import {useConfigStore} from '@/stores/config-store';
import {defineComponent, inject, PropType, onMounted, ref} from 'vue';
import {ConfirmSignUpVerificationRequest} from '@/models/api/requests/users/ConfirmSignUpVerificationRequest';
import PasswordValidator from '@/helpers/PasswordValidator';
import i18n from '@/i18n';

export default defineComponent({
    props: {
        userID: {
            type: String as PropType<string>,
        },
        signupToken: {
            type: String as PropType<string>,
        },
    },
    setup(props) {
        const apiService = inject('apiService') as ApiService;
        const configStore = useConfigStore();

        // Panel text
        const panelTitle = ref<string>('');
        const panelDetails = ref<string>('');

        // Visibility conditionals
        const isLoading = ref<boolean>(false);
        const passwordVisible = ref<boolean>(false);
        const loginButtonVisible = ref<boolean>(false);
        const saveButtonVisible = ref<boolean>(false);

        // Passwords
        const passwordValidator = ref<PasswordValidator>(new PasswordValidator());
        const regexPassword = new RegExp(configStore.regexPassword);
        const newPassword = ref<string>('');
        const repeatPassword = ref<string>('');
        const passwordValid = ref<boolean>(true);
        const passwordRules = ref<Array<(v: string) => true | string>>([
            (v) => !!v || i18n.global.t('PasswordIsRequired'),
            (v) =>
                (regexPassword.test(v) && passwordValidator.value.isValid(v)) || i18n.global.t('WrongPasswordFormat'),
        ]);
        const confirmationRules = ref<Array<(v: string) => true | string>>([
            (v) => !!v || i18n.global.t('PleaseReEnterYourNewPassword'),
            (v) =>
                (regexPassword.test(v) && passwordValidator.value.isValid(v)) || i18n.global.t('WrongPasswordFormat'),
            (v) => v === newPassword.value || i18n.global.t('PasswordsMustMatch'),
        ]);

        /**
         * Initialize the page.
         */
        async function init() {
            confirmSignupVerification(true, null);
        }

        /**
         * Confirms sign up verification.
         */
        async function confirmSignupVerification(onlyValidateToken: boolean, userPassword: string | null) {
            isLoading.value = true;

            try {
                const request: ConfirmSignUpVerificationRequest = {
                    userRegistrationToken: props.signupToken,
                    userId: Number(props.userID),
                    onlyValidateToken: onlyValidateToken,
                    userPassword: userPassword,
                };

                const response = await apiService.post('/authentication/confirm-signup-verification', request);

                switch (response) {
                    case 1:
                        panelTitle.value = i18n.global.t('ConfirmSignupVerification');
                        if (request.onlyValidateToken) {
                            panelDetails.value = i18n.global.t('ConfirmSignup_EnterPassword_PanelMessage');
                            passwordVisible.value = true;
                            saveButtonVisible.value = true;
                        } else {
                            panelDetails.value = i18n.global.t('ConfirmSignup_VerificationSuccess_PanelMessage');
                            passwordVisible.value = false;
                            loginButtonVisible.value = true;
                            saveButtonVisible.value = false;
                        }
                        break;
                    case 2:
                        panelTitle.value = i18n.global.t('SignUpVerificationLinkHasLapsed');
                        panelDetails.value = i18n.global.t('ConfirmSignup_LapsedLink_PanelMessage');
                        break;
                    case 3:
                        panelTitle.value = i18n.global.t('SignUpVerificationConfirmed');
                        panelDetails.value = i18n.global.t('ConfirmSignup_AlreadyVerified_PanelMessage');
                        loginButtonVisible.value = true;
                        break;
                    default:
                        panelTitle.value = i18n.global.t('InvalidSignUpVerificationLink');
                        panelDetails.value = i18n.global.t('ConfirmSignup_InvalidLink_PanelMessage');
                        break;
                }
            } catch (error) {
                panelTitle.value = i18n.global.t('ConfirmSignupVerification');
                panelDetails.value = i18n.global.t('ErrorGeneric');
            }

            isLoading.value = false;
        }

        /**
         * Return user to log in page.
         */
        async function returnToLogInPage() {
            apiService.logout();
        }

        /**
         * Save user password if valid.
         */
        async function saveUserPassword() {
            if (passwordValid.value) {
                confirmSignupVerification(false, newPassword.value);
            }
        }

        onMounted(() => {
            init();
        });

        return {
            apiService,
            configStore,
            panelTitle,
            panelDetails,
            isLoading,
            passwordVisible,
            loginButtonVisible,
            saveButtonVisible,
            passwordValidator,
            regexPassword,
            newPassword,
            repeatPassword,
            passwordValid,
            passwordRules,
            confirmationRules,
            confirmSignupVerification,
            returnToLogInPage,
            saveUserPassword,
        };
    },
});
</script>
