<template>
    <div class="rounded-text custom-background">
        <CircleIcon class="custom-chip custom-icon swatchG2BG">
            <FontAwesomeIcon
                :icon="['fa', 'line-height']"
                :rotation="270"
                class="rounded-text custom-background"
                size="xs" />
        </CircleIcon>
        <span class="pr-2" style="padding-left: 5px" v-if="rowWidth || rowWidth === 0">{{ rowWidth }}m</span>
    </div>
</template>

<script lang="ts">
import {PropType, defineComponent} from 'vue';

export default defineComponent({
    name: 'RowWidthChip',
    props: {
        rowWidth: Number as PropType<number>,
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.custom-icon {
    background-color: $swatchG2;
}
.custom-chip {
    font-size: 18pt;
    height: 30px;
    width: 30px;
}
.rounded-text {
    display: inline-flex;
    align-items: center;
    border-radius: 20px; /* Adjust the border radius to make it round */
    font-family: 'Open Sans Condensed', sans-serif;
}

.custom-background {
    background-color: rgba($swatchG2, 0.8);
    color: $swatchWHT;
}
</style>
