import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleIcon = _resolveComponent("CircleIcon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock(_component_v_tooltip, { bottom: "" }, {
    activator: _withCtx(({props}) => [
      _createVNode(_component_CircleIcon, _mergeProps(props, {
        class: "help-icon",
        style: _ctx.style,
        icon: "mdi-help"
      }), null, 16, ["style"])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "helpText", {}, undefined, true),
      _createTextVNode(" " + _toDisplayString(_ctx.helpText), 1)
    ]),
    _: 3
  }))
}