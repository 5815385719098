<template>
    <div>
        <v-input v-model="observationTypeCodeInternal" :rules="rules" required hide-details="auto"></v-input>
        <v-row v-for="(observationTypeRow, index) in observationTypesMatrix" :key="index" class="pt-3">
            <span v-for="observationType in observationTypeRow" :key="observationType.observationTypeCode" class="pl-3">
                <ObservationTypeSelectBox
                    :observation-type-code="observationType.observationTypeCode"
                    @click="clickObservationType(observationType.observationTypeCode)"
                    :is-selected="observationTypeCodeInternal === observationType.observationTypeCode"
                    :is-enabled="isEnabled" />
            </span>
        </v-row>
    </div>
</template>

<script setup lang="ts">
import {ref, watch, defineProps, defineEmits, computed} from 'vue';
import ObservationTypeSelectBox from '@/components/ObservationTypeSelectBox.vue';
import {observationTypes, ObservationType} from '@/services/observation-types-service';

const props = defineProps<{
    modelValue: string | null;
    rules: Array<(v: string | null) => boolean | string>;
    isEnabled: boolean;
}>();

const convertTo2DArray = (arr: ObservationType[]) => {
    const result = [];
    for (let i = 0; i < arr.length; i += 3) {
        result.push(arr.slice(i, i + 3));
    }
    return result;
};

const observationTypesMatrix = computed(() => convertTo2DArray(observationTypes));

const emit = defineEmits<{
    (e: 'update:modelValue', value: string | null): void;
}>();

const observationTypeCodeInternal = ref<string | null>(props.modelValue);

watch(
    () => props.modelValue,
    (newValue) => {
        observationTypeCodeInternal.value = newValue;
    },
);

/**
 * Select or deselect the observation type.
 */
const clickObservationType = (observationTypeCode: string) => {
    if (!props.isEnabled) return;

    if (observationTypeCodeInternal.value === observationTypeCode) {
        // If already selected, deselect work task type
        observationTypeCodeInternal.value = null;
    } else {
        // Select work task type
        observationTypeCodeInternal.value = observationTypeCode;
    }

    emit('update:modelValue', observationTypeCodeInternal.value);
};
</script>
