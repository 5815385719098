import {defineStore} from 'pinia';
import {GetFarmSitesResponse} from '@/models/api/responses/data-source/GetFarmSitesResponse';
import ApiService from '@/services/api-service';

export const useFarmStore = defineStore('farmSites', {
    state: () => ({
        farmSites: [] as GetFarmSitesResponse[],
        selectedSiteId: undefined as number | undefined,
    }),

    actions: {
        async fetchFarmSites(apiService: ApiService) {
            try {
                const response = await apiService.get('data-source/farm-sites');
                const data = response as GetFarmSitesResponse[]; // Type cast to expected interface
                this.farmSites = data; // Update state with fetched data
            } catch (error) {
                console.error('Failed to farm sites', error);
            }
        },
        async updateUserPreferredSite(apiService: ApiService, farmSiteId: number, userId: number): Promise<boolean> {
            try {
                if (farmSiteId && userId) {
                    const params = {
                        farmSiteId: farmSiteId,
                        UserId: userId,
                    };
                    return await apiService.post('users/update-farm-site', params);
                } else return false;
            } catch (error) {
                console.error('Failed to farm sites', error);
                return false;
            }
        },
        getCurrentSite(): GetFarmSitesResponse | undefined {
            if (this.farmSites && this.farmSites.length > 0) {
                return this.farmSites.find((e) => e.farmSiteId === this.selectedSiteId);
            }
        },
    },
});
