import {GetCropVarietiesResponse} from '@/models/api/responses/data-source/GetCropVarietiesResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useCropVarieties() {
    const apiService = inject('apiService') as ApiService;
    const cropVarieties = ref<GetCropVarietiesResponse[]>([]);
    const cropVarietiesWithNull = ref<SelectListItem[]>([]);

    /**
     * Get list of crop varieties.
     */
    const getCropVarieties = async () => {
        cropVarieties.value = await apiService.get('data-source/crop-varieties');

        // Add null value
        cropVarietiesWithNull.value = cropVarieties.value.map((cropVariety) => ({
            value: cropVariety.cropVarietyId,
            title: cropVariety.cropVarietyName,
        }));

        cropVarietiesWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getCropVarieties,
        cropVarieties,
        cropVarietiesWithNull,
    };
}
