import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f18b6f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rounded-text custom-background" }
const _hoisted_2 = {
  key: 0,
  class: "pr-2",
  style: {"padding-left":"5px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_CircleIcon = _resolveComponent("CircleIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CircleIcon, { class: "custom-chip custom-icon swatchG2BG" }, {
      default: _withCtx(() => [
        _createVNode(_component_FontAwesomeIcon, {
          icon: ['fa', 'tally-3'],
          class: "rounded-text custom-background",
          style: {"padding-left":"3px"}
        })
      ]),
      _: 1
    }),
    (_ctx.numberOfRows || _ctx.numberOfRows === 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(`${_ctx.numberOfRows} ${_ctx.numberOfRows > 1 ? _ctx.$t('Rows') : _ctx.$t('Row')} ${
                _ctx.directionText ? '(' + _ctx.directionText + ')' : ''
            }`), 1))
      : _createCommentVNode("", true)
  ]))
}