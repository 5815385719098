import {WorkTaskProductForm} from './WorkTaskProductForm';
import {ActionWorkTaskFarmBlockForm} from './ActionWorkTaskFarmBlockForm';
import {WorkTaskActionTypeCode} from '@/enums/work-task-action-type-code';
import {WorkTaskCompletionTimeCode} from '@/enums/work-task-completion-time-code';

export class ActionWorkTaskForm {
    workTaskId: number | null = null;
    workTaskActionTypeCode: WorkTaskActionTypeCode | null = null;
    actionedDate: Date | null = null;
    actionedUserId: number | null = null;
    comments: string | null = null;
    irrigationRateActual: number | null = null;
    estimatedCompletionTimeCode: WorkTaskCompletionTimeCode | null = null;
    isConfirmed = false;
    workTaskProducts: WorkTaskProductForm[] = [];
    workTaskFarmBlocks: ActionWorkTaskFarmBlockForm[] = [];

    constructor(data?: Partial<ActionWorkTaskForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
