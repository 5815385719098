<template>
    <LoadingSymbol v-if="isLoading" />

    <table v-else style="width: 100%" class="tblForm">
        <tr>
            <td colspan="4" class="subHeader">{{ $t('Particulars') }}</td>
        </tr>
        <tr>
            <td class="tableCell padding" style="width: 10%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('FarmSite(s)') }}</span>
                    <HelpIcon :help-text="$t('FarmFieldSearch_FarmSites_HelpText')" />
                </div>
            </td>
            <td style="width: 40%">
                <v-select
                    v-model="localSearchParameters.farmSiteIdList"
                    hide-details
                    multiple
                    clearable
                    chips
                    :items="farmSites"
                    item-title="farmSiteAbbreviationAndName"
                    item-value="farmSiteId"
                    max-width="400px"></v-select>
            </td>
            <td class="tableCell padding" style="width: 10%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('Name') }}</span>
                    <HelpIcon :help-text="$t('FarmFieldSearch_Name_HelpText')" />
                </div>
            </td>
            <td style="width: 40%">
                <v-text-field v-model="localSearchParameters.farmFieldName" hide-details clearable style="width: 400px">
                </v-text-field>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('CropType') }}</span>
                    <HelpIcon :help-text="$t('FarmFieldSearch_CropType_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.cropTypeIdList"
                        hide-details
                        multiple
                        clearable
                        chips
                        :items="cropTypes"
                        item-title="cropTypeName"
                        item-value="cropTypeId"
                        max-width="400px"></v-select>
                </div>
            </td>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('Crop') }}</span>
                    <HelpIcon :help-text="$t('FarmFieldSearch_Crop_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.cropId"
                        hide-details
                        :items="cropsWithNull"
                        item-title="title"
                        item-value="value"
                        max-width="400px"></v-select>
                </div>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('CropVariety') }}</span>
                    <HelpIcon :help-text="$t('FarmFieldSearch_CropVariety_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-text-field
                        v-model="localSearchParameters.cropVarietyName"
                        hide-details
                        clearable
                        max-width="400px">
                    </v-text-field>
                </div>
            </td>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('CropYear') }}</span>
                    <HelpIcon :help-text="$t('FarmFieldSearch_CropYear_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.cropYear"
                        hide-details
                        :items="cropYearsWithNull"
                        item-title="title"
                        item-value="value"
                        max-width="400px"></v-select>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="4" class="subHeader">{{ $t('AttributesAndAssociations') }}</td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('TaskTypes') }}</span>
                    <HelpIcon :help-text="$t('FarmFieldSearch_TaskTypes_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.workTaskTypeCodeList"
                        hide-details
                        multiple
                        clearable
                        chips
                        :items="workTaskTypes"
                        item-title="workTaskTypeName"
                        item-value="workTaskTypeCode"
                        max-width="400px"></v-select>
                </div>
            </td>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('TaskStatus') }}</span>
                    <HelpIcon :help-text="$t('FarmFieldSearch_TaskStatus_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.workTaskStatus"
                        hide-details
                        :items="workTaskStatusOptions"
                        item-title="title"
                        item-value="value"
                        max-width="400px"></v-select>
                </div>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('Product') }}</span>
                    <HelpIcon :help-text="$t('FarmFieldSearch_ProductName_HelpText')" />
                </div>
            </td>
            <td>
                <v-text-field v-model="localSearchParameters.productName" hide-details clearable style="width: 400px">
                </v-text-field>
            </td>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('CurrentSeason') }}</span>
                    <HelpIcon :help-text="$t('FarmFieldSearch_CurrentSeason_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.currentSeasonId"
                        hide-details
                        :items="seasonsWithNull"
                        item-title="title"
                        item-value="value"
                        max-width="400px"></v-select>
                </div>
            </td>
        </tr>
    </table>
</template>

<script setup lang="ts">
import {onMounted, ref, watch} from 'vue';
import {SearchFarmFieldsRequest} from '@/models/api/requests/search/SearchFarmFieldsRequest';
import i18n from '@/i18n';
import {useCropYears} from '@/composables/data-source/crop-years';
const {cropYearsWithNull} = useCropYears();
import {useFarmSites} from '@/composables/data-source/farm-sites';
const {getFarmSites, farmSites} = useFarmSites();
import {useCrops} from '@/composables/data-source/crops';
const {getCrops, cropsWithNull} = useCrops();
import {useCropTypes} from '@/composables/data-source/crop-types';
const {getCropTypes, cropTypes} = useCropTypes();
import {useWorkTaskTypes} from '@/composables/data-source/work-task-types';
const {getWorkTaskTypes, workTaskTypes} = useWorkTaskTypes();
import {useSeasons} from '@/composables/data-source/seasons';
const {getSeasons, seasonsWithNull} = useSeasons();

const props = defineProps<{searchParameters: SearchFarmFieldsRequest}>();
const emit = defineEmits(['update:searchParameters']);
const isLoading = ref<boolean>(false);

// Options for Work Task Status
const workTaskStatusOptions = [
    {value: null, title: ''},
    {value: 'CompletedLast30Days', title: i18n.global.t('CompletedLast30Days')},
    {value: 'CompletedLast7Days', title: i18n.global.t('CompletedLast7Days')},
    {value: 'Overdue', title: i18n.global.t('Overdue')},
    {value: 'DueNext7Days', title: i18n.global.t('DueNext7Days')},
    {value: 'DueNext30Days', title: i18n.global.t('DueNext30Days')},
];

// Create a local copy of the prop
const localSearchParameters = ref({...props.searchParameters});

// Watch for changes in the parent parameters object and update local copy
watch(
    () => props.searchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(localSearchParameters.value)) {
            localSearchParameters.value = {...newVal};
        }
    },
    {deep: true, immediate: true},
);

// Watch for changes in the local copy and emit the update event
watch(
    localSearchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(props.searchParameters)) {
            emit('update:searchParameters', newVal);
        }
    },
    {deep: true},
);

// Fetch data for dropdown
onMounted(async () => {
    isLoading.value = true;

    // Get data
    await getFarmSites();
    await getCrops();
    await getCropTypes();
    await getWorkTaskTypes();
    await getSeasons();

    isLoading.value = false;
});
</script>
