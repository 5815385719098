import {WorkTaskTemplateSearchResultDto} from '../data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchResultDto';

export class WorkTaskPlanItemForm {
    workTaskPlanItemId: number | null = null;
    newItemId: string | null = null;
    workTaskTypeCode: string | null = null;
    workTaskTemplateId: number | null = null;
    workTaskTemplate: WorkTaskTemplateSearchResultDto | null = null;
    numberOfDaysDueAfterPreviousTask: number | null = null;
    duplicatedFromWorkTaskPlanItemId: number | null = null;

    constructor(data?: Partial<WorkTaskPlanItemForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
