import { GetCropTypesRequest } from '@/models/api/requests/data-source/GetCropTypesRequest';
import {GetCropTypesResponse} from '@/models/api/responses/data-source/GetCropTypesResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useCropTypes() {
    const apiService = inject('apiService') as ApiService;
    const cropTypes = ref<GetCropTypesResponse[]>([]);
    const cropTypesWithNull = ref<SelectListItem[]>([]);

    /**
     * Get list of crop types.
     */
    const getCropTypes = async (parameters?: GetCropTypesRequest | null) => {
        cropTypes.value = await apiService.post('data-source/crop-types', parameters);

        // Add null value
        cropTypesWithNull.value = cropTypes.value.map((cropType) => ({
            value: cropType.cropTypeId,
            title: cropType.cropTypeName,
        }));

        cropTypesWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getCropTypes,
        cropTypes,
        cropTypesWithNull,
    };
}
