<template>
    <LoadingSymbol v-if="isLoading || observation == null" />
    <div v-else>
        <NavigatorTabFarmDetailsHeader
            class="swatchWHTBG"
            v-if="observation?.farmFieldId"
            :farm-field-id="observation?.farmFieldId" />

        <v-expansion-panels v-model="openPanels" multiple>
            <v-expansion-panel>
                <v-expansion-panel-title class="swatchG8BG swatchBLK pa-3">
                    <FontAwesomeIcon :icon="['fa', 'question-circle']" size="lg" />
                    <span class="ps-2">{{ $t('Summary') }}</span>
                </v-expansion-panel-title>
                <v-expansion-panel-text class="swatchA1">
                    <div class="pa-3">
                        <div class="bold">{{ observation.observationTitle }}</div>
                        <div style="font-style: italic">
                            <TranslatableTexts :text="observation.observationComments" />
                        </div>

                        <div v-if="observation.artifacts.length > 0" class="pt-2">
                            <div class="pb-2" style="display: flex; flex-wrap: wrap; gap: 10px">
                                <ImageCard
                                    v-for="artifact in observation.artifacts.filter(
                                        (artifact) => artifact.artifactFileTypeCode === ArtifactFileTypeCode.Image,
                                    )"
                                    :key="artifact.artifactId"
                                    :image-url="artifact.artifactUrl"
                                    :display-image-name="false" />
                            </div>

                            <FileTypeButton
                                v-for="(artifact, index) in getNonImageArtifacts"
                                :key="artifact.artifactId"
                                :url="artifact.artifactUrl"
                                :display-name="artifact.artifactDisplayName"
                                :file-type-code="artifact.artifactFileTypeCode"
                                :style="{
                                    width: '100%',
                                    marginBottom: index !== getNonImageArtifacts.length - 1 ? '8px' : '0',
                                }" />
                        </div>
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-title class="swatchG8BG swatchBLK pa-3">
                    <FontAwesomeIcon :icon="['fa', 'list-timeline']" size="lg" />
                    <span class="ps-2">{{ $t('Activity') }}</span>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <div class="pa-3">
                        <v-list class="pa-0" density="compact">
                            <div v-if="userStore.user" style="display: flex">
                                <UserChip
                                    :user="
                                    UserHelper.userChipType(
                                        userStore.user.userId,
                                        UserHelper.userFullName(
                                            userStore.user.firstName!,
                                            userStore.user.lastName!,
                                        ),
                                    )
                                " />

                                <span class="swatchA1" style="margin-left: auto">{{ $t('New').toUpperCase() }}</span>
                            </div>
                            <v-textarea
                                class="pt-2 pb-2 percent20"
                                :label="$t('AddANewComment')"
                                v-model="activityComment"
                                :rules="commentBoxFocused ? commentRules : []"
                                :hide-details="!commentBoxFocused || activityComment != ''"
                                required
                                clearable
                                variant="outlined"
                                rows="1"
                                auto-grow
                                @focus="commentBoxFocused = true">
                            </v-textarea>
                            <div v-if="commentBoxFocused" class="pt-2 pb-2 activity-buttons">
                                <v-btn
                                    ref="cancelButton"
                                    class="activity-button swatchGABG"
                                    elevation="1"
                                    rounded="x-large"
                                    @click="cancelCommentClick">
                                    <template v-slot:prepend>
                                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                                    </template>
                                    {{ $t('Cancel') }}
                                </v-btn>

                                <v-btn
                                    ref="expandButton"
                                    class="activity-button swatchGABG"
                                    elevation="1"
                                    rounded="x-large"
                                    @click="viewObservationModalStore.open(observation.observationId, activityComment)">
                                    <template v-slot:prepend>
                                        <FontAwesomeIcon class="ma-0" :icon="['fal', 'expand']" size="xl" />
                                    </template>
                                    {{ $t('Expand') }}
                                </v-btn>

                                <v-btn
                                    ref="saveButton"
                                    class="activity-button swatchGRNBG swatchWHT"
                                    elevation="1"
                                    rounded="x-large"
                                    :disabled="!activityComment || activityComment == ''"
                                    @click="saveCommentClick()">
                                    <template v-slot:prepend>
                                        <FontAwesomeIcon :icon="['fal', 'floppy-disk']" size="xl" />
                                    </template>
                                    {{ $t('Save') }}
                                </v-btn>
                            </div>
                            <v-list-item
                                v-for="action in observation.observationActions"
                                :key="action.observationActionId"
                                class="pa-0">
                                <div>
                                    <div style="display: flex">
                                        <UserChip
                                            :user="
                                                UserHelper.userChipType(
                                                    action.observationActionUserId,
                                                    UserHelper.userFullName(
                                                        action.observationActionUserGivenName,
                                                        action.observationActionUserSurname,
                                                    ),
                                                )
                                            " />

                                        <span class="swatchA1" style="margin-left: auto">{{
                                            formatDate(action.observationActionDate)
                                        }}</span>
                                    </div>
                                    <div class="pt-3 pb-3 swatchA1" style="font-style: italic">
                                        <TranslatableTexts :text="action.observationActionComments" />
                                    </div>
                                    <div v-if="action.artifacts.length > 0">
                                        <FileTypeButton
                                            v-for="(artifact, index) in action.artifacts"
                                            :key="artifact.artifactId"
                                            :url="artifact.artifactUrl"
                                            :display-name="artifact.artifactDisplayName"
                                            :file-type-code="artifact.artifactFileTypeCode"
                                            :style="{
                                                width: '100%',
                                                marginBottom: index !== action.artifacts.length - 1 ? '5px' : '10px',
                                            }" />
                                    </div>
                                </div>
                            </v-list-item>
                        </v-list>
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-snackbar v-model="snackbar.show" timeout="4000">
            {{ snackbar.text }}
        </v-snackbar>
    </div>
</template>

<script setup lang="ts">
import {ref, inject, watch, onMounted, computed} from 'vue';
import {SearchObservationsRequest} from '@/models/api/requests/search/SearchObservationsRequest';
import {SearchObservationsResponse} from '@/models/api/responses/search/SearchObservationsResponse';
import {ObservationSearchResultDto} from '@/models/data-transfer-objects/search/observation-search/ObservationSearchResultDto';
import NavigatorTabFarmDetailsHeader from '@/views/home-dashboard/NavigatorTabFarmDetailsHeader.vue';
import ApiService from '@/services/api-service';
import {format} from 'date-fns';
import UserChip from '@/components/UserChip.vue';
import {useUserStore} from '@/stores/user-store';
import {SaveObservationActionRequest} from '@/models/api/requests/observation/SaveObservationActionRequest';
import {ObservationActionTypeCode} from '@/enums/observation-action-type-code';
import FileTypeButton from '@/components/FileTypeButton.vue';
import i18n from '@/i18n';
import {ArtifactFileTypeCode} from '@/enums/artifact-file-type-code';
import ImageCard from '@/components/ImageCard.vue';
import FileUploadHelper from '@/helpers/FileUploadHelper';
import {useViewObservationModalStore} from '@/stores/modals/view-observation-modal-store';
import UserHelper from '@/helpers/UserHelper';

// Props
const props = withDefaults(
    defineProps<{
        observationId?: number | null;
    }>(),
    {
        observationId: null,
    },
);

// Local state for observation
const observation = ref<ObservationSearchResultDto | null>(null);

// Opens panels by default
const openPanels = ref<number[]>([0, 1]);

const commentBoxFocused = ref<boolean>(false);
const activityComment = ref<string | null>(null);

// Function to fetch observations from API
const apiService = inject('apiService') as ApiService;
const isLoading = ref(false);
const userStore = useUserStore();
const viewObservationModalStore = useViewObservationModalStore();
const snackbar = ref({
    show: false,
    text: '',
});
const commentRules = ref<Array<(v: string) => true | string>>([(v) => !!v || i18n.global.t('Validation_Required')]);
const fetchObservationDetails = async () => {
    isLoading.value = true;

    // Reset object
    observation.value = null;

    // Build search request using the user ID
    const searchRequest: SearchObservationsRequest = new SearchObservationsRequest({
        isQuickSearch: false,
        observationId: props.observationId,
        includeObservationActions: true,
        includeArtifacts: true,
    });

    // Get and display details
    const results: SearchObservationsResponse = await apiService.post('search/observations', searchRequest);
    if (results.observations.length == 1) {
        observation.value = results.observations[0];

        // Order observation actions by date
        if (observation.value.observationActions.length > 1) {
            observation.value.observationActions = observation.value.observationActions.sort(
                (a, b) => new Date(b.observationActionDate).getTime() - new Date(a.observationActionDate).getTime(),
            );
        }
    }

    isLoading.value = false;
};

// Format date to necessary structure
const formatDate = (date: Date | null): string | null => {
    if (!date) return null;

    return format(date, 'dd MMM, yyyy h:mm a');
};

const getNonImageArtifacts = computed(() => {
    if (!observation.value || !observation.value.artifacts) {
        return [];
    }
    return observation.value.artifacts.filter(
        (artifact) => artifact.artifactFileTypeCode !== ArtifactFileTypeCode.Image,
    );
});

// Handle cancelling new comment box
const cancelCommentClick = () => {
    activityComment.value = null;
    commentBoxFocused.value = false;
};

// Save activity comment
const saveCommentClick = async () => {
    if (userStore.user && observation.value) {
        const request: SaveObservationActionRequest = {
            observationActionTypeCode: ObservationActionTypeCode.Actioned,
            observationActionComments: activityComment.value,
            observationId: observation.value?.observationId,
            observationActionUserId: userStore.user?.userId,
            observationStatusCode: observation.value?.observationStatusCode,
            haveArtifactsBeenUpdated: false,
            artifacts: [],
        };

        try {
            // Call API to save user
            const result = await apiService.post(
                'observations/save-action',
                FileUploadHelper.createFormDataObject(request),
            );

            if (result) {
                fetchObservationDetails();
                cancelCommentClick();

                snackbar.value.show = true;
                snackbar.value.text = i18n.global.t('CreateActivityComment_Success');
            } else {
                snackbar.value.show = true;
                snackbar.value.text = i18n.global.t('CreateActivityComment_Failed');
            }
        } catch (error) {
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
        }
    }
};

// Watch the ID prop to refetch items when it changes
watch(
    () => props.observationId,
    (newVal) => {
        if (newVal !== null) {
            snackbar.value = {
                show: false,
                text: '',
            };
            activityComment.value = null;
            commentBoxFocused.value = false;
            fetchObservationDetails();
        }
    },
);

// Fetch items on component mount
onMounted(() => {
    fetchObservationDetails();
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.activity-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;

    .activity-button {
        padding: 0px 8px 0px 13px;
    }
}

:deep(.v-expansion-panel--active > .v-expansion-panel-title:not(.v-expansion-panel-title--static)) {
    min-height: 48px;
}
:deep(.v-expansion-panel--active:not(:first-child)),
:deep(.v-expansion-panel--active + .v-expansion-panel) {
    margin-top: 0px;
}
:deep(.v-expansion-panel-text__wrapper) {
    padding: 0 !important;
}
</style>
