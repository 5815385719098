import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_CircleIcon = _resolveComponent("CircleIcon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock(_component_v_tooltip, {
    bottom: "",
    disabled: _ctx.isTooltipDisabled
  }, {
    activator: _withCtx(({props}) => [
      _createVNode(_component_CircleIcon, _mergeProps(props, {
        style: {height: _ctx.iconHeight + 'px', width: _ctx.iconWidth + 'px', border: _ctx.border},
        class: _ctx.iconClass
      }), {
        default: _withCtx(() => [
          _createVNode(_component_FontAwesomeIcon, {
            icon: [_ctx.iconFamily, _ctx.iconCode],
            size: _ctx.iconSize,
            transform: _ctx.iconCode === 'harvester' ? 'down-2 grow-3' : ''
          }, null, 8, ["icon", "size", "transform"])
        ]),
        _: 2
      }, 1040, ["style", "class"])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "helpText", {}, undefined, true),
      _createTextVNode(" " + _toDisplayString(_ctx.name), 1)
    ]),
    _: 3
  }, 8, ["disabled"]))
}