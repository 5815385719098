<template>
    <v-btn
        v-if="url && url != ''"
        class="v-btn--custom"
        :class="[displayName ? 'subtask-text' : 'subtask-icon']"
        rounded="x-large"
        @click.prevent.stop="goToFileLink(url)">
        <FontAwesomeIcon :icon="['fas', iconType]" :class="[displayName ? 'pr-2' : '']" size="xl" />
        {{ truncatedDisplayName }}
    </v-btn>
</template>

<script setup lang="ts">
import {ArtifactFileTypeCode} from '@/enums/artifact-file-type-code';
import {computed} from 'vue';

// Props
const props = defineProps<{
    url: string;
    displayName?: string;
    fileTypeCode?: ArtifactFileTypeCode;
}>();

const goToFileLink = (url: string) => window.open(url, '_blank');

const iconType = computed(() => {
    switch (props.fileTypeCode) {
        case ArtifactFileTypeCode.Csv:
            return 'file-csv';
        case ArtifactFileTypeCode.Pdf:
            return 'file-pdf';
        case ArtifactFileTypeCode.Text:
            return 'file-lines';
        case ArtifactFileTypeCode.Image:
            return 'file-image';
        case ArtifactFileTypeCode.Excel:
            return 'file-excel';
        case ArtifactFileTypeCode.Word:
            return 'file-word';
        case ArtifactFileTypeCode.Video:
            return 'file-video';
        case ArtifactFileTypeCode.Compressed:
            return 'file-zipper';
        default:
            return 'arrow-up-right-from-square';
    }
});

const truncatedDisplayName = computed(() => {
    if (!props.displayName) return '';

    const isDefaultIcon = iconType.value === 'arrow-up-right-from-square';
    const maxLength = isDefaultIcon ? 8 : 16;
    const frontChars = isDefaultIcon ? 4 : 12;
    const backChars = 4;

    if (props.displayName.length <= maxLength) {
        return props.displayName;
    }

    const start = props.displayName.slice(0, frontChars);
    const end = props.displayName.slice(-backChars);
    return `${start}...${end}`;
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';
.subtask-text {
    justify-content: start;
    overflow: hidden;
}
.subtask-icon {
    width: 37px;
}
</style>
