import {GetWorkTaskTypesResponse} from '@/models/api/responses/data-source/GetWorkTaskTypesResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useWorkTaskTypes() {
    const apiService = inject('apiService') as ApiService;
    const workTaskTypes = ref<GetWorkTaskTypesResponse[]>([]);
    const workTaskTypesWithNull = ref<SelectListItem[]>([]);

    /**
     * Get list of work task types.
     */
    const getWorkTaskTypes = async () => {
        workTaskTypes.value = await apiService.get('data-source/work-task-types');

        // Add null value
        workTaskTypesWithNull.value = workTaskTypes.value.map((workTaskType) => ({
            value: workTaskType.workTaskTypeCode,
            title: workTaskType.workTaskTypeName,
        }));

        workTaskTypesWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getWorkTaskTypes,
        workTaskTypes,
        workTaskTypesWithNull,
    };
}
