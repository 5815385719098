export class SearchUsersRequest {
    isQuickSearch: boolean = false;
    quickSearchText: string | null = null;
    userId: number | null = null;
    userIdList: number[] | null = null;
    isCustomerUser: boolean | null = null;
    userFullName: string | null = null;
    userGivenName: string | null = null;
    userSurname: string | null = null;
    farmSiteIdList: number[] | null = null;
    mobilePlatformList: string[] | null = null;
    recentActivityList: string[] | null = null;
    recentActivitySinceDate: Date | null = null;
    userRoleCodeList: string[] | null = null;

    constructor(data?: Partial<SearchUsersRequest>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
