export class DeleteOrCancelWorkTaskForm {
    workTaskId: number | null = null;
    isCancel = false;
    isDelete = false;
    explanation: string | null = null;

    constructor(data?: Partial<DeleteOrCancelWorkTaskForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
