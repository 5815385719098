export enum WorkTaskActionTypeCode {
    Cancelled = 'Cancelled',
    Completed = 'Completed',
    Created = 'Created',
    Deleted = 'Deleted',
    Modified = 'Modified',
    Paused = 'Paused',
    Rescheduled = 'Rescheduled',
    Restarted = 'Restarted',
    Started = 'Started',
}
