<template>
    <v-btn @click="handleClick">
        <template v-slot:prepend>
            <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
        </template>
        {{ $t('Back') }}
    </v-btn>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useRouter} from 'vue-router';

export default defineComponent({
    name: 'BackButton',
    setup(props, {attrs}) {
        const router = useRouter();

        function handleClick(event: Event) {
            if (attrs.onClick) {
                // eslint-disable-next-line @typescript-eslint/ban-types
                (attrs.onClick as Function)(event);
            } else {
                router.go(-1);
            }
        }

        return {handleClick};
    },
});
</script>
