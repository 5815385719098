<template>
    <div>
        <v-dialog v-model="isVisible">
            <v-card>
                <v-form ref="form" class="pa-4" style="overflow-y: scroll; height: 100%" @keyup.enter="search">
                    <farm-field-search-parameters v-model:searchParameters="localSearchParametersFarmField" />
                </v-form>
                <v-card-actions class="swatchG9BG">
                    <v-btn @click="isVisible = false">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                        </template>
                        {{ $t('Cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="search" :disabled="isLoading">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'search']" size="xl" />
                        </template>
                        {{ $t('Search') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <LoadingSymbol v-if="isLoading" />

        <farm-field-search-results
            v-else
            @switch-to-search="openSearchModal"
            @view-fields-on-home="viewFieldListOnHome"
            @export-field-list="exportFieldList"
            :is-filter-applied="isFilterApplied"
            :results-list="searchResultsFarmField" />
        <v-snackbar v-model="snackbar.show" timeout="4000">
            {{ snackbar.text }}
        </v-snackbar>
    </div>
</template>

<script setup lang="ts">
import {computed, ref, inject, onMounted, watch} from 'vue';
import ApiService from '@/services/api-service.js';
import {SearchFarmFieldsRequest} from '@/models/api/requests/search/SearchFarmFieldsRequest';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {SearchFarmFieldsResponse} from '@/models/api/responses/search/SearchFarmFieldsResponse';
import FarmFieldSearchParameters from '@/views/search/FarmFieldSearchParameters.vue';
import FarmFieldSearchResults from '@/views/search/FarmFieldSearchResults.vue';
import {useFarmStore} from '@/stores/farm-store';
const farmStore = useFarmStore();
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
const homeDashboardStore = useHomeDashboardStore();
import {NavigatorPanelTab} from '@/enums/home-dashboard/navigator-panel-tab';
import router from '@/router';
import {useRoute} from 'vue-router';
const route = useRoute();
import i18n from '@/i18n';
import {format} from 'date-fns';
import {useDeleteFarmFieldModalStore} from '@/stores/modals/delete-farm-field-modal-store';
const deleteFarmFieldModalStore = useDeleteFarmFieldModalStore();
import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
import {useUserStore} from '@/stores/user-store';
const userStore = useUserStore();
const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();

// Services
const apiService = inject('apiService') as ApiService;

//Local variables
const isVisible = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const searchParametersFarmField = ref(new SearchFarmFieldsRequest());
const localSearchParametersFarmField = ref(new SearchFarmFieldsRequest());
const searchResultsFarmField = ref<FarmFieldSearchResultDto[]>([]);
const snackbar = ref({
    show: false,
    text: '',
});

const isFilterApplied = computed(
    () =>
        !!(
            (searchParametersFarmField.value.isQuickSearch &&
                (searchParametersFarmField.value.quickSearchText === null ||
                    searchParametersFarmField.value.quickSearchText === '')) ||
            (!searchParametersFarmField.value.isQuickSearch &&
                searchParametersFarmField.value.cropId === null &&
                (searchParametersFarmField.value.cropVarietyName === null ||
                    searchParametersFarmField.value.cropVarietyName === '') &&
                (searchParametersFarmField.value.farmFieldName === null ||
                    searchParametersFarmField.value.farmFieldName === '') &&
                (searchParametersFarmField.value.cropTypeIdList === null ||
                    searchParametersFarmField.value.cropTypeIdList.length === 0) &&
                (searchParametersFarmField.value.workTaskTypeCodeList === null ||
                    searchParametersFarmField.value.workTaskTypeCodeList.length === 0) &&
                (searchParametersFarmField.value.workTaskStatus === null ||
                    searchParametersFarmField.value.workTaskStatus === '') &&
                (searchParametersFarmField.value.productName === null ||
                    searchParametersFarmField.value.productName === '') &&
                searchParametersFarmField.value.cropYear === null &&
                searchParametersFarmField.value.farmSiteIdList &&
                searchParametersFarmField.value.farmSiteIdList.length === 1 &&
                searchParametersFarmField.value.farmSiteIdList[0] === farmStore.selectedSiteId)
        ),
);

/**
 * Open search modal
 */
async function openSearchModal() {
    isVisible.value = true;
}

/**
 * Save the modal.
 */
const search = async () => {
    isLoading.value = true;

    searchParametersFarmField.value = Object.assign(
        new SearchFarmFieldsRequest(),
        localSearchParametersFarmField.value,
    );

    //Include farm blocks and open observations
    searchParametersFarmField.value.includeFarmBlocks = true;
    searchParametersFarmField.value.includeOpenObservations = true;
    searchParametersFarmField.value.includeFarmSeasons = true;

    const response: SearchFarmFieldsResponse = await apiService.post(
        'search/farm-fields',
        searchParametersFarmField.value,
    );
    searchResultsFarmField.value = response.farmFields;

    isLoading.value = false;
    isVisible.value = false;
};

onMounted(async () => {
    await loadFarmFieldList(route.params.cropVarietyId);
});

const loadFarmFieldList = async (val: string | string[] | null) => {
    if (homeDashboardStore.parameterFarmFields.length > 0) {
        localSearchParametersFarmField.value.farmFieldIdList = [...homeDashboardStore.parameterFarmFields];
        homeDashboardStore.parameterFarmFields = [];
        await search();
        localSearchParametersFarmField.value = new SearchFarmFieldsRequest();
        if (farmStore.selectedSiteId) localSearchParametersFarmField.value.farmSiteIdList = [farmStore.selectedSiteId];
    } else {
        const isFilterFromHome = !!homeDashboardStore.farmFieldListFilter;
        if (homeDashboardStore.farmFieldListFilter) {
            localSearchParametersFarmField.value = {...homeDashboardStore.farmFieldListFilter};
            homeDashboardStore.farmFieldListFilter = null;
        } else localSearchParametersFarmField.value = new SearchFarmFieldsRequest();

        if (farmStore.selectedSiteId) localSearchParametersFarmField.value.farmSiteIdList = [farmStore.selectedSiteId];

        if (val && Number.isInteger(Number(val))) {
            localSearchParametersFarmField.value.cropVarietyIdList = [Number(val)];
        }

        await search();

        //reset search parameter after searching
        if (isFilterFromHome) {
            localSearchParametersFarmField.value = new SearchFarmFieldsRequest();
            if (farmStore.selectedSiteId)
                localSearchParametersFarmField.value.farmSiteIdList = [farmStore.selectedSiteId];
        } else {
            localSearchParametersFarmField.value = Object.assign(
                new SearchFarmFieldsRequest(),
                searchParametersFarmField.value,
            );
            // Remove `cropVarietyId`
            if (val && Number.isInteger(Number(val))) {
                localSearchParametersFarmField.value.cropVarietyIdList = [];
                router.replace({
                    name: 'FarmFieldList',
                    params: {cropVarietyId: undefined},
                });
            }
        }
    }
};

const viewFieldListOnHome = () => {
    homeDashboardStore.resetFarmFieldSearchParameters();
    homeDashboardStore.navigatorPanelActiveTab = NavigatorPanelTab.FarmFields;
    homeDashboardStore.isMultiSelectFarmFieldsMode =
        searchResultsFarmField.value && searchResultsFarmField.value.length > 1;
    homeDashboardStore.selectedFarmFieldsLevel1.splice(
        0,
        homeDashboardStore.selectedFarmFieldsLevel1.length,
        ...searchResultsFarmField.value,
    );

    userStore.closeAllPopups();

    if (!homeDashboardStore.isHomeDashboardOpen) {
        router.push({name: 'HomeDashboard'});
    }
};

watch(
    () => route.params.cropVarietyId,
    async (val) => {
        if (val && val !== '') await loadFarmFieldList(val);
    },
);

watch(
    [
        () => deleteFarmFieldModalStore.deletedCounter,
        () => deleteOrCancelWorkTaskModalStore.savedCounter,
        () => homeDashboardStore.parameterFarmFields,
    ],
    async () => {
        if (homeDashboardStore.parameterFarmFields && homeDashboardStore.parameterFarmFields.length > 0)
            await loadFarmFieldList(null);
    },
    {deep: true},
);

const exportFieldList = async () => {
    try {
        const fileName = i18n.global.t('FarmField') + '_' + format(new Date(), 'yyyyMMddHHmm') + '.csv';
        await apiService.downloadFilePost('search/farm-fields/export', searchParametersFarmField.value, null, fileName);
    } catch (error) {
        // Show fail feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ExportCSVError');
        isLoading.value = false;
    }
};
</script>
