<template>
    <div>
        <v-dialog v-model="isVisible">
            <v-card>
                <v-form ref="form" class="pa-4" style="overflow-y: scroll; height: 100%" @keyup.enter="search">
                    <work-task-template-search-parameters
                        v-model:searchParameters="localSearchParametersWorkTaskTemplate" />
                </v-form>
                <v-card-actions class="swatchG9BG">
                    <v-btn @click="isVisible = false">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                        </template>
                        {{ $t('Cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="search" :disabled="isLoading">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'search']" size="xl" />
                        </template>
                        {{ $t('Search') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <LoadingSymbol v-if="isLoading" />

        <work-task-template-search-results
            v-else
            @switch-to-search="openSearchModal"
            :is-filter-applied="isFilterApplied"
            :results-list="searchResultsWorkTaskTemplate" />
    </div>
</template>

<script setup lang="ts">
import {computed, ref, inject, onMounted, watch} from 'vue';
import ApiService from '@/services/api-service.js';
import {WorkTaskTemplateSearchParametersDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchParametersDto';
import {WorkTaskTemplateSearchResultDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchResultDto';
import {SearchWorkTaskTemplatesResponse} from '@/models/api/responses/search/SearchWorkTaskTemplatesResponse';
import WorkTaskTemplateSearchParameters from '@/views/search/WorkTaskTemplateSearchParameters.vue';
import WorkTaskTemplateSearchResults from '@/views/search/WorkTaskTemplateSearchResults.vue';
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';

const manageWorkTaskModalStore = useManageWorkTaskModalStore();

// Services
const apiService = inject('apiService') as ApiService;

//Local variables
const isVisible = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const searchParametersWorkTaskTemplate = ref(new WorkTaskTemplateSearchParametersDto());
const localSearchParametersWorkTaskTemplate = ref(new WorkTaskTemplateSearchParametersDto());
const searchResultsWorkTaskTemplate = ref<WorkTaskTemplateSearchResultDto[]>([]);

const isFilterApplied = computed(
    () =>
        !!(
            (searchParametersWorkTaskTemplate.value.WorkTaskTemplateName === null ||
                searchParametersWorkTaskTemplate.value.WorkTaskTemplateName === '') &&
            (searchParametersWorkTaskTemplate.value.workTaskTypeCodeList === null ||
                searchParametersWorkTaskTemplate.value.workTaskTypeCodeList.length === 0) &&
            (searchParametersWorkTaskTemplate.value.workTaskPlanIdList === null ||
                searchParametersWorkTaskTemplate.value.workTaskPlanIdList.length === 0)
        ),
);

/**
 * Open search modal
 */
async function openSearchModal() {
    isVisible.value = true;
}

/**
 * Save the modal.
 */
const search = async () => {
    isLoading.value = true;

    searchParametersWorkTaskTemplate.value = Object.assign(
        new WorkTaskTemplateSearchParametersDto(),
        localSearchParametersWorkTaskTemplate.value,
    );
    searchParametersWorkTaskTemplate.value.includeArtifacts = false;
    searchParametersWorkTaskTemplate.value.includeWorkTaskTemplateCropVarietyPreferences = false;
    searchParametersWorkTaskTemplate.value.includeWorkTaskTemplateMixtures = false;
    searchParametersWorkTaskTemplate.value.includeWorkTaskTemplateProducts = false;
    searchParametersWorkTaskTemplate.value.includeWorkTaskTemplateSubTasks = false;
    const response: SearchWorkTaskTemplatesResponse = await apiService.post('search/work-task-templates', {
        searchParameters: searchParametersWorkTaskTemplate.value,
    });
    searchResultsWorkTaskTemplate.value = response.workTaskTemplates;

    isLoading.value = false;
    isVisible.value = false;
};

onMounted(async () => {
    await search();
    localSearchParametersWorkTaskTemplate.value = Object.assign(
        new WorkTaskTemplateSearchParametersDto(),
        searchParametersWorkTaskTemplate.value,
    );
});

import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();
import {useDeleteUserModalStore} from '@/stores/modals/delete-user-modal-store';
const deleteUserModalStore = useDeleteUserModalStore();
import {useDeleteWorkTaskPlanModalStore} from '@/stores/modals/delete-work-task-plan-modal-store';
const deleteWorkTaskPlanModalStore = useDeleteWorkTaskPlanModalStore();
import {useDeleteWorkTaskTemplateModalStore} from '@/stores/modals/delete-work-task-template-modal-store';
const deleteWorkTaskTemplateModalStore = useDeleteWorkTaskTemplateModalStore();

watch(
    [
        () => deleteOrCancelWorkTaskModalStore.savedCounter,
        () => deleteUserModalStore.deletedCounter,
        () => manageWorkTaskModalStore.savedCounter,
        () => deleteWorkTaskPlanModalStore.deletedCounter,
        () => deleteWorkTaskTemplateModalStore.deletedCounter,
    ],
    async () => {
        await search();
        localSearchParametersWorkTaskTemplate.value = Object.assign(
            new WorkTaskTemplateSearchParametersDto(),
            searchParametersWorkTaskTemplate.value,
        );
    },
    {deep: true},
);
</script>
