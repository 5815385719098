import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    icon: "",
    onClick: _ctx.handleClick,
    class: "closeButton",
    size: "small"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_icon, { size: "x-large" }, {
        default: _withCtx(() => [
          _createTextVNode("mdi-close")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClick"]))
}