<template>
    <div class="ChangePassword">
        <!-- Login Form -->
        <v-card outlined class="transparent-card">
            <v-toolbar flat color="primary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'unlock']" size="xl" class="mr-2" />
                    {{ panelTitle }}
                </v-toolbar-title>
            </v-toolbar>

            <div class="pa-4" v-if="panelMessage && panelMessage !== ''">{{ panelMessage }}</div>
            <div class="pa-4" v-else>
                <v-form v-model="valid">
                    <table style="width: 80%" class="tblForm">
                        <tr>
                            <td class="tableCell padding" style="width: 40%">
                                <div
                                    class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                    <span> {{ $t('CurrentPassword') }}</span>
                                    <HelpIcon :help-text="$t('EnterYourCurrentPassword')" />
                                </div>
                            </td>
                            <td style="width: 60%">
                                <v-text-field
                                    variant="outlined"
                                    v-model="oldPassword"
                                    required
                                    type="password"
                                    :rules="oldPasswordRules" />
                            </td>
                        </tr>

                        <tr>
                            <td class="tableCell padding">
                                <div
                                    class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                    <span> {{ $t('NewPassword') }}</span>
                                    <HelpIcon>
                                        <template v-slot:helpText>
                                            <div>
                                                {{ $t('ChangePassword_NewPassword_HelpText') }}
                                            </div>
                                            <ul>
                                                <li>{{ passwordValidator.getLengthRequirements() }}</li>
                                                <li
                                                    v-for="requirement in passwordValidator.getCharacterRequirements()"
                                                    :key="requirement">
                                                    {{ requirement }}
                                                </li>
                                            </ul>
                                        </template>
                                    </HelpIcon>
                                </div>
                            </td>
                            <td>
                                <v-text-field
                                    variant="outlined"
                                    v-model="newPassword"
                                    required
                                    type="password"
                                    :rules="newPasswordRules" />
                            </td>
                        </tr>

                        <tr>
                            <td class="tableCell padding">
                                <div
                                    class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                    <span> {{ $t('ReEnterNewPassword') }}</span>
                                    <HelpIcon :help-text="$t('RepeatYourPassword')" />
                                </div>
                            </td>
                            <td>
                                <v-text-field
                                    variant="outlined"
                                    v-model="repeatPassword"
                                    required
                                    type="password"
                                    :rules="reapeatPasswordRules" />
                            </td>
                        </tr>
                    </table>
                    <v-alert v-if="errorMessage" type="error" :value="true">
                        {{ errorMessage }}
                    </v-alert>
                </v-form>
            </div>

            <v-card-actions class="justify-space-between swatchG9BG">
                <BackButton />
                <v-btn
                    v-if="!panelMessage || panelMessage === ''"
                    color="primary"
                    @click="changePassword"
                    :disabled="!valid">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'floppy-disk']" size="xl" :value="2134" />
                    </template>
                    {{ $t('SavePassword') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script lang="ts">
import {defineComponent, inject, ref, watch} from 'vue';
import {useConfigStore} from '@/stores/config-store';
import {useUserStore} from '@/stores/user-store';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {byPrefixAndName} from '@awesome.me/kit-b64aff0d40/icons';
import ApiService from '@/services/api-service';
import i18n from '@/i18n';
import router from '@/router';
import axios from 'axios';
import PasswordValidator from '@/helpers/PasswordValidator';

export default defineComponent({
    components: {
        FontAwesomeIcon,
    },
    setup() {
        // Stores
        const configStore = useConfigStore();
        const userStore = useUserStore();

        // Services
        const apiService = inject('apiService') as ApiService;

        // Form fields
        const panelTitle = ref<string>(i18n.global.t('ChangePassword'));
        const panelMessage = ref<string>('');
        const oldPassword = ref<string>('');
        const newPassword = ref<string>('');
        const repeatPassword = ref<string>('');
        const passwordValidator = ref<PasswordValidator>(new PasswordValidator());
        const valid = ref<boolean>(true);
        const errorMessage = ref('');
        const regexPassword = new RegExp(configStore.regexPassword);
        const systemName = configStore.systemName;
        const response = ref<number | null>(null);

        // Rules
        const oldPasswordRules = ref<Array<(v: string) => true | string>>([
            (v) => !!v || i18n.global.t('YourPasswordIsRequired'),
        ]);

        const newPasswordRules = ref<Array<(v: string) => true | string>>([
            (v) => !!v || i18n.global.t('YourNewPasswordIsRequired'),
            (v) =>
                (regexPassword.test(v) && passwordValidator.value.isValid(v)) || i18n.global.t('WrongPasswordFormat'),
        ]);

        const reapeatPasswordRules = ref<Array<(v: string) => true | string>>([
            (v) => !!v || i18n.global.t('PleaseReEnterYourNewPassword'),
            (v) =>
                (regexPassword.test(v) && passwordValidator.value.isValid(v)) || i18n.global.t('WrongPasswordFormat'),
            (v) => v === newPassword.value || i18n.global.t('NewPasswordDoesNotMatch'),
        ]);

        watch(
            [newPassword, repeatPassword],
            ([newPasswordValue, newRepeatPasswordValue], [prevNewPasswordValue, prevRepeatPasswordValue]) => {
                if (newPasswordValue != prevNewPasswordValue || newRepeatPasswordValue != prevRepeatPasswordValue) {
                    errorMessage.value = '';
                }
            },
        );

        /**
         * Call API to attempt to Change user password
         */
        async function changePassword() {
            if (valid.value) {
                try {
                    response.value = await apiService.post('users/change-password', {
                        OldPassword: oldPassword.value,
                        NewPassword: newPassword.value,
                    });
                    switch (response.value) {
                        case 1:
                            panelTitle.value = i18n.global.t('PasswordChangeSuccess');
                            panelMessage.value = i18n.global.t('ChangePassword_PasswordChangeSuccess_PanelMessage', {
                                systemName: systemName,
                            });
                            break;
                        //Reason: Incorrect Password
                        case 2:
                            errorMessage.value = i18n.global.t('ChangePassword_IncorrectPassword_ErrorMessage');
                            break;
                        //Reason: Wrong password format
                        default:
                            errorMessage.value = i18n.global.t('WrongPasswordFormat');
                            break;
                    }
                } catch (error) {
                    if (axios.isAxiosError(error) && error.response) {
                        panelTitle.value = i18n.global.t('PasswordChangeFailed');
                        panelMessage.value = i18n.global.t('ChangePassword_PasswordChangeFailed_PanelMessage');
                    }
                }
            }
        }

        return {
            // FontAwesome
            byPrefixAndName,

            // Stores
            userStore,
            configStore,

            // Services
            apiService,
            router,

            // Form fields
            panelTitle,
            panelMessage,
            oldPassword,
            newPassword,
            repeatPassword,
            passwordValidator,
            valid,
            errorMessage,
            response,

            // Rules
            oldPasswordRules,
            newPasswordRules,
            reapeatPasswordRules,

            // Functions
            changePassword,
        };
    },
});
</script>

<style scoped>
body.ChangePassword {
    background-image: none;
}

.ChangePassword {
    width: 50rem;
    margin: 0px auto;
}
</style>
