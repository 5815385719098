export enum HomeDashboardMapSettingCode {
    AerialImagery = 'AerialImagery',
    BackgroundMap = 'BackgroundMap',
    CropVariety = 'CropVariety',
    CropYear = 'CropYear',
    FieldNames = 'FieldNames',
    Metrics = 'Metrics',
    NextTaskIcon = 'NextTaskIcon',
    Observations = 'Observations',
}
