import {ApplicationRateUnitCode} from '@/enums/application-rate-unit-code';

export class ActionWorkTaskProductDto {
    workTaskProductId: number | null = null;
    productId!: number;
    actualApplicationRate!: number;
    actualApplicationRateUnitCode!: ApplicationRateUnitCode;

    constructor(data?: Partial<ActionWorkTaskProductDto>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
