export enum ArtifactTypeCode {
    CropVarietyAttachment = 'CropVarietyAttachment',
    MixtureAttachment = 'MixtureAttachment',
    ProductAttachment = 'ProductAttachment',
    ObservationActionAttachment = 'ObservationActionAttachment',
    ObservationAttachment = 'ObservationAttachment',
    UserImage = 'UserImage',
    WorkSubTaskAttachment = 'WorkSubTaskAttachment',
    WorkTaskAttachment = 'WorkTaskAttachment',
}
