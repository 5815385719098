import {createI18n} from 'vue-i18n';
import messagesEnAu from './locales/messages.en-AU.json';
import messagesIdId from './locales/messages.id-ID.json';
import entityDataEnAu from './locales/entity-data.en-AU.json';
import entityDataIdId from './locales/entity-data.id-ID.json';

const messages = {
    'en-AU': {...messagesEnAu, ...entityDataEnAu},
    'id-ID': {...messagesIdId, ...entityDataIdId},
};

const i18n = createI18n({
    locale: 'en-AU',
    fallbackLocale: 'en-AU',
    messages,
});

export default i18n;
