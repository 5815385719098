<template>
    <v-tooltip bottom v-if="isUserHasAccess">
        <template v-slot:activator="{props}">
            <div v-bind="props" class="id-icon">
                <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="-90 -90 400 400">
                    <g id="Layer_1-2">
                        <path
                            class="cls-1"
                            d="M208,80c8.8,0,16-7.2,16-16s-7.2-16-16-16h-32V16c0-8.8-7.2-16-16-16s-16,7.2-16,16v32h-64V16c0-8.8-7.2-16-16-16s-16,7.2-16,16v32H16c-8.8,0-16,7.2-16,16s7.2,16,16,16h32v64H16c-8.8,0-16,7.2-16,16s7.2,16,16,16h32v32c0,8.8,7.2,16,16,16s16-7.2,16-16v-32h64v32c0,8.8,7.2,16,16,16s16-7.2,16-16v-32h32c8.8,0,16-7.2,16-16s-7.2-16-16-16h-32v-64h32ZM144,144h-64v-64h64v64Z" />
                    </g>
                </svg>
            </div>
        </template>
        <slot></slot>
    </v-tooltip>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import CircleIcon from '@/components/CircleIcon.vue';
import {useUserStore} from '@/stores/user-store';
import {RoleCode} from '@/enums/role-code';
import {ref} from 'vue';

export default defineComponent({
    name: 'HelpIcon',
    extends: CircleIcon,
    setup() {
        const userStore = useUserStore();
        const isUserHasAccess = ref<boolean>(userStore.hasRoleLevel(RoleCode.System, 3));
        return {
            isUserHasAccess,
        };
    },
});
</script>

<style lang="scss" scoped>
.id-icon {
    @import '@/assets/scss/swatches.scss';

    border-radius: 50%;
    font-size: 8.6pt;
    text-align: center;
    border: 1px solid $swatchG3;
    color: $swatchG3;
    width: 20px;
    height: 20px;
    cursor: default;
    display: inline-block;
    margin-left: 0.5rem;
    margin-top: 0.4rem;
}

.cls-1 {
    @import '@/assets/scss/swatches.scss';
    fill: $swatchG3;
}
</style>
