<template>
    <v-menu open-on-hover>
        <template v-slot:activator="{props}">
            <v-btn v-bind="props" class="operation-button" rounded="x-large">
                {{ menuText }}
                <FontAwesomeIcon :icon="['fa', 'caret-down']" size="xl" class="ml-2" />
            </v-btn>
        </template>

        <v-list>
            <v-list-item>
                <v-list-item-title>
                    <v-btn @click="viewWorkTaskPlanModalStore.open(workTaskPlan.workTaskPlanId)">
                        {{ $t('ViewTaskPlan') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>
                    <v-btn @click="viewWorkTaskPlanModalStore.open(workTaskPlan.workTaskPlanId, true, false)">
                        {{ $t('ExportTaskPlanSummary') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>
                    <v-btn @click="viewWorkTaskPlanModalStore.open(workTaskPlan.workTaskPlanId, true, true)">
                        {{ $t('ExportTaskPlanDetailed') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>
                    <v-btn
                        @click="
                            manageWorkTaskPlanModalStore.open(
                                ManageWorkTaskPlanModalMode.Modify,
                                workTaskPlan.workTaskPlanId,
                            )
                        ">
                        {{ $t('ModifyTaskPlan') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="userStore.hasRoleLevel(RoleCode.PlansAndTemplates, 3) && showDeleteTaskPlan">
                <v-list-item-title>
                    <v-btn @click="deleteWorkTaskPlanModalStore.open(workTaskPlan.workTaskPlanId)">
                        {{ $t('DeleteTaskPlan') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>
                    <v-btn
                        @click="
                            manageWorkTaskPlanModalStore.open(
                                ManageWorkTaskPlanModalMode.Duplicate,
                                workTaskPlan.workTaskPlanId,
                            )
                        ">
                        {{ $t('DuplicateTaskPlan') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>
                    <v-btn @click="applyWorkTaskPlanModalStore.open(workTaskPlan.workTaskPlanId)">
                        {{ $t('ApplyTaskPlanToField') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script setup lang="ts">
import {WorkTaskPlanSearchResultDto} from '@/models/data-transfer-objects/search/work-task-plan-search/WorkTaskPlanSearchResultDto';
import {defineProps} from 'vue';
import {ManageWorkTaskPlanModalMode} from '@/enums/modal-modes/manage-work-task-plan-modal-mode';
import {useManageWorkTaskPlanModalStore} from '@/stores/modals/manage-work-task-plan-modal-store';
const manageWorkTaskPlanModalStore = useManageWorkTaskPlanModalStore();
import {useViewWorkTaskPlanModalStore} from '@/stores/modals/view-work-task-plan-modal-store';
const viewWorkTaskPlanModalStore = useViewWorkTaskPlanModalStore();
import {useApplyWorkTaskPlanModalStore} from '@/stores/modals/apply-work-task-plan-modal-store';
const applyWorkTaskPlanModalStore = useApplyWorkTaskPlanModalStore();
import {useDeleteWorkTaskPlanModalStore} from '@/stores/modals/delete-work-task-plan-modal-store';
const deleteWorkTaskPlanModalStore = useDeleteWorkTaskPlanModalStore();
import i18n from '@/i18n';
import {useUserStore} from '@/stores/user-store';
const userStore = useUserStore();
import {RoleCode} from '@/enums/role-code';

withDefaults(
    defineProps<{
        workTaskPlan: WorkTaskPlanSearchResultDto;
        menuText?: string;
        showDeleteTaskPlan?: boolean;
    }>(),
    {
        menuText: i18n.global.t('Operations'),
        showDeleteTaskPlan: true,
    },
);
</script>
