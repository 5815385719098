<template>
    <div class="task-status-chip swatchWHT" :class="getTaskStatusColor(workTask)">
        <div class="chip-content">
            <div class="icon-section">
                <FontAwesomeIcon :icon="['fa', getTaskStatusIcon(workTask)]" />
            </div>
            <div class="text-section">
                {{ getTaskStatusText(workTask) }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {defineProps} from 'vue';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import i18n from '@/i18n';

defineProps<{
    workTask: WorkTaskSearchResultDto;
}>();

/**
 * Get status color.
 */
const getTaskStatusColor = (workTask: WorkTaskSearchResultDto): string => {
    if (workTask.isCompleted) {
        return 'swatchGRNBG';
    } else if (workTask.isCancelled) {
        return 'swatchG2BG';
    } else if (workTask.isPaused) {
        return 'swatchG3BG';
    } else if (workTask.isStarted) {
        return 'swatchB6BG';
    } else if (workTask.isOverdue) {
        return 'swatchREDBG';
    }

    return 'swatchERRBG';
};

/**
 * Get status text.
 */
const getTaskStatusText = (workTask: WorkTaskSearchResultDto): string => {
    if (workTask.isCompleted) {
        return i18n.global.t('Complete');
    } else if (workTask.isCancelled) {
        return i18n.global.t('Cancelled');
    } else if (workTask.isPaused) {
        return i18n.global.t('Paused');
    } else if (workTask.isStarted) {
        return i18n.global.t('Underway');
    } else if (workTask.isOverdue) {
        return i18n.global.t('Overdue');
    }

    return i18n.global.t('Open');
};

/**
 * Get status icon.
 */
const getTaskStatusIcon = (workTask: WorkTaskSearchResultDto): string => {
    if (workTask.isCompleted) {
        return 'check';
    } else if (workTask.isCancelled) {
        return 'ban';
    } else if (workTask.isPaused) {
        return 'pause';
    } else if (workTask.isStarted) {
        return 'play';
    } else if (workTask.isOverdue) {
        return 'triangle-exclamation';
    }

    return 'calendar';
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.task-status-chip {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 5px;
    height: 26px;
    border-radius: 5px;
    margin-left: auto;
    text-align: center;
    font-family: 'Open Sans Condensed', sans-serif;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden;
}

.chip-content {
    display: flex;
    width: 100%;
    min-width: 70px;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}

.icon-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-section {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
