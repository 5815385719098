import {ApplicationRateUnitCode} from '@/enums/application-rate-unit-code';

export interface ApplicationRateUnit {
    applicationRateUnitCode: ApplicationRateUnitCode;
    applicationRateUnitName: string;
    isDefaultChemicalUnit: boolean;
    isDefaultFertilizerUnit: boolean;
}

export const applicationRateUnits: ApplicationRateUnit[] = [
    {
        applicationRateUnitCode: ApplicationRateUnitCode.KgPerHa,
        applicationRateUnitName: 'Kg per Ha',
        isDefaultChemicalUnit: false,
        isDefaultFertilizerUnit: true,
    },
    {
        applicationRateUnitCode: ApplicationRateUnitCode.LPerHa,
        applicationRateUnitName: 'L per Ha',
        isDefaultChemicalUnit: true,
        isDefaultFertilizerUnit: false,
    },
];

export function getApplicationRateUnitByCode(
    applicationRateUnitCode: ApplicationRateUnitCode,
): ApplicationRateUnit | undefined {
    return applicationRateUnits.find((aru) => aru.applicationRateUnitCode === applicationRateUnitCode);
}
