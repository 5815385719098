import {SeedRateUnitCode} from '@/enums/seed-rate-unit-code';

export class ActionWorkTaskFarmBlockDto {
    workTaskFarmBlockId: number | null = null;
    farmBlockCode!: string;
    cropVarietyId!: number;
    seedOriginBlockId: number | null = null;
    seedRate!: number;
    seedRateUnitCode!: SeedRateUnitCode;
    startRow!: number;
    endRow!: number;

    constructor(data?: Partial<ActionWorkTaskFarmBlockDto>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
