<template>
    <v-menu
        v-if="searchModes.length > 0"
        v-model="isOpen"
        :close-on-content-click="false"
        @keyup.enter="runQuickSearch"
        @update:modelValue="onMenuUpdate">
        <template v-slot:activator="{props}">
            <v-btn class="btnQuickSearch" color="swatchG2" v-bind="props">
                <FontAwesomeIcon :icon="['fal', 'search']" />
            </v-btn>
        </template>
        <div class="swatchG3BG pa-4">
            <v-row>
                <v-col>
                    <v-text-field
                        ref="txtQuickSearchText"
                        v-model="quickSearchParameters.quickSearchText"
                        class="swatchWHTtxt text-field"
                        hide-details
                        clearable
                        single-line
                        :placeholder="$t('EnterSearchTerm_Ellipsis')"
                        style="width: 300px">
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-select
                        v-model="quickSearchParameters.searchMode"
                        hide-details
                        transparent
                        theme="search"
                        :items="searchModes"
                        item-title="title"
                        item-value="value"
                        :label="$t('SearchFor_Colon')"
                        style="width: 200px"></v-select>
                </v-col>
                <v-col>
                    <v-btn
                        icon
                        @click="runQuickSearch"
                        size="small"
                        class="transparent swatchWHT"
                        color="accent"
                        :title="$t('Search')">
                        <FontAwesomeIcon :icon="['fal', 'search']" style="font-size: 18pt" />
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn
                        icon
                        @click="openAdvancedSearchModal"
                        size="small"
                        class="transparent swatchWHT"
                        :title="$t('OpenAdvancedSearch')">
                        <FontAwesomeIcon
                            :icon="['fal', 'arrow-up-right-and-arrow-down-left-from-center']"
                            style="font-size: 18pt" />
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-menu>
</template>

<script setup lang="ts">
import {useHeaderSearchModes} from '@/composables/header-search-modes';
import {useAdvancedSearchModalStore} from '@/stores/modals/advanced-search-modal-store';
import {onMounted, ref} from 'vue';

const {searchModes, defaultSearchMode, setSearchModes} = useHeaderSearchModes();
const advancedSearchModalStore = useAdvancedSearchModalStore();
const isOpen = ref(false);
const quickSearchParameters = ref();

/**
 * Runs the Quick Search.
 */
const runQuickSearch = () => {
    isOpen.value = false;
    advancedSearchModalStore.open(
        quickSearchParameters.value.searchMode,
        true,
        quickSearchParameters.value.quickSearchText,
        true,
    );
};

/**
 * Opens the Advanced Search modal.
 */
const openAdvancedSearchModal = () => {
    isOpen.value = false;
    advancedSearchModalStore.open(quickSearchParameters.value.searchMode, false, null);
};

const txtQuickSearchText = ref<InstanceType<typeof HTMLInputElement> | null>(null);
const onMenuUpdate = (value: boolean) => {
    if (value) {
        // Clear old search parameters
        quickSearchParameters.value.quickSearchText = '';

        // Focus the textbox when the menu is opened
        setTimeout(() => {
            if (txtQuickSearchText.value) {
                txtQuickSearchText.value.focus();
            }
        }, 100);
    }
};

onMounted(async () => {
    // The timeout is used because there is a delay in the i18n locale being updated, which was causing incorrect translations
    // to be used. And i18n.global.locale does not seem to be reactive so we can't watch for it. There may be a better solution
    // that can be implemented later, but for now this fixes the issue.
    setTimeout(() => {
        // Set search modes in the correct language
        setSearchModes();

        // Set default parameters
        quickSearchParameters.value = {
            quickSearchText: '',
            searchMode: defaultSearchMode.value,
        };
    }, 1000);
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.transparent {
    background-color: transparent;
    border: 1px solid $swatchWHT;
}
</style>

<style lang="scss">
@import '@/assets/scss/swatches.scss';

.v-theme--search .v-list-item--active {
    background-color: $swatchB4;
}

.btnQuickSearch {
    display: table;
    position: relative;
    cursor: pointer;
    height: 100% !important;

    svg:first-child {
        display: table-cell;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        font-size: 1.7rem;
    }
}

.text-field {
    .v-field {
        background-color: $swatchWHT;
    }
}

.select {
    .v-field {
        border-color: $swatchWHT;
    }
}
</style>
