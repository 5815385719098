<template>
    <div class="pa-5">
        <LoadingSymbol v-if="isLoading" />

        <v-card v-else>
            <v-row
                ><v-col cols="4"
                    ><span class="pl-4 pr-4">{{ $t('PlanName') }}</span>
                    <HelpIcon :help-text="$t('WorkTaskTemplateFilter_PlanName_HelpText')" /></v-col
                ><v-col cols="8">
                    <v-text-field
                        v-model="localSearchParameters.workTaskPlanName"
                        hide-details
                        clearable
                        style="width: 400px" /></v-col
            ></v-row>

            <v-row
                ><v-col cols="4"
                    ><span class="pl-4 pr-4">{{ $t('TaskTypes') }}</span>
                    <HelpIcon :help-text="$t('WorkTaskTemplateFilter_TaskTypes_HelpText')" /></v-col
                ><v-col cols="8"
                    ><v-select
                        v-model="localSearchParameters.workTaskTypeCodeList"
                        hide-details
                        multiple
                        clearable
                        chips
                        :items="workTaskTypes"
                        item-title="workTaskTypeName"
                        item-value="workTaskTypeCode"
                        max-width="400px" /></v-col
            ></v-row>
            <v-row>
                <v-col cols="4"
                    ><span class="pl-4 pr-4">{{ $t('TaskTemplate') }}</span>
                    <HelpIcon :help-text="$t('WorkTaskTemplateFilter_TaskTemplate_HelpText')"
                /></v-col>
                <v-col cols="8"
                    ><v-autocomplete
                        v-model="localSearchParameters.workTaskTemplateIdList"
                        :items="workTaskTemplates"
                        hide-details
                        multiple
                        clearable
                        chips
                        item-title="workTaskTemplateName"
                        item-value="workTaskTemplateId"
                        max-width="400px"
                /></v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script setup lang="ts">
import {onMounted, ref, watch} from 'vue';
import {useWorkTaskTypes} from '@/composables/data-source/work-task-types';
const {getWorkTaskTypes, workTaskTypes} = useWorkTaskTypes();
import {useWorkTaskTemplates} from '@/composables/data-source/work-task-templates';
const {getWorkTaskTemplates, workTaskTemplates} = useWorkTaskTemplates();
import {WorkTaskPlanSearchParametersDto} from '@/models/data-transfer-objects/search/work-task-plan-search/WorkTaskPlanSearchParametersDto';

const props = defineProps<{
    searchParameters: WorkTaskPlanSearchParametersDto;
}>();

const emit = defineEmits(['update:searchParameters']);
const isLoading = ref<boolean>(false);
const dateRaised = ref<Array<Date> | null>(null);
const dateDue = ref<Array<Date> | null>(null);
const dateConcluded = ref<Array<Date> | null>(null);

// Create a local copy of the prop
const localSearchParameters = ref({...props.searchParameters});

/**
 * Reset the form to its default state.
 */
const resetLocalParameters = () => {
    dateRaised.value = [];
    dateDue.value = [];
    dateConcluded.value = [];
};

// Watch for changes in the parent parameters object and update local copy
watch(
    () => props.searchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(localSearchParameters.value)) {
            localSearchParameters.value = {...newVal};
        }
    },
    {deep: true, immediate: true},
);

// Watch for changes in the local copy and emit the update event
watch(
    localSearchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(props.searchParameters)) {
            emit('update:searchParameters', newVal);
        }
    },
    {deep: true},
);

// Fetch data for dropdown
onMounted(async () => {
    isLoading.value = true;

    // Get data
    await getWorkTaskTypes();
    await getWorkTaskTemplates();

    isLoading.value = false;
});

// Expose reset function
defineExpose({resetLocalParameters});
</script>
