export class WorkSubTaskForm {
    workSubTaskId: number | null = null;
    workTaskTemplateSubTaskId: number | null = null;
    newItemId: string | null = null;
    workSubTaskName: string | null = null;
    workSubTaskInstructions: string | null = null;
    artifacts: File[] = [];
    haveArtifactsBeenUpdated = false;
    duplicatedFromWorkSubTaskId: number | null = null;
    duplicatedFromWorkTaskTemplateSubTaskId: number | null = null;
    createdFromWorkSubTaskId: number | null = null;
    createdFromWorkTaskTemplateSubTaskId: number | null = null;

    constructor(data?: Partial<WorkSubTaskForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
