<template>
    <div class="divHeaderApp">
        <div class="headerRow">
            <div class="tableCell" style="width: 250px">
                <div class="tableCell">
                    <router-link to="/" style="border-bottom: none !important">
                        <img
                            src="@/assets/images/logo.svg"
                            style="height: 40px; width: auto; padding-top: 0.4rem"
                            class="hand" />
                    </router-link>
                </div>
            </div>
            <div
                class="tableCell noprint"
                v-if="userStore.isLoggedIn && farmStore.farmSites && farmStore.farmSites.length > 0">
                <FarmSelector :items="farmStore.farmSites"> </FarmSelector>
            </div>

            <div
                class="tableCell noprint"
                style="text-align: right; height: 100%; display: flex; justify-content: flex-end">
                <div
                    class="dropdown hand disable-hover"
                    @mouseover="showUserProfileDropdown = true"
                    @mouseleave="showUserProfileDropdown = false"
                    v-if="userStore.isLoggedIn">
                    <div class="dropbtn" v-bind:class="profileClickClass">
                        <div class="tableCell" style="padding-left: 3rem; padding-right: 1rem">
                            {{ userStore.user?.firstName }}
                            {{ userStore.user?.lastName }}
                        </div>
                        <div class="tableCell" style="padding-top: 0.35rem; padding-right: 0.5rem">
                            <FontAwesomeIcon :icon="['fal', 'user']" style="font-size: 1.5rem" />
                        </div>
                    </div>
                    <div class="dropdown-content right disable-hover" v-show="showUserProfileDropdown">
                        {{ $t('UserProfile') }}

                        <ul @click="showUserProfileDropdown = false">
                            <li>
                                <a
                                    @click="
                                        manageUserModalStore.open(
                                            ManageUserModalMode.ChangeProfile,
                                            userStore.user?.userId,
                                        )
                                    ">
                                    {{ $t('ChangeProfile') }}
                                </a>
                            </li>
                            <li>
                                <router-link to="change-password">{{ $t('ChangePassword') }}</router-link>
                            </li>
                            <li>
                                <a @click="logout">{{ $t('LogOut') }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="subHeaderRow noprint" style="width: 100%">
            <!--Left aligned buttons-->
            <div style="display: flex; width: 50%">
                <!-- Login -->
                <div v-if="!userStore.isLoggedIn" class="dropdown">
                    <router-link
                        to="/login"
                        class="btnHeader dropbtn"
                        :class="{
                            show: isMenuItemActive(HeaderMenuItem.Login),
                        }">
                        <FontAwesomeIcon :icon="['fal', 'user']" />
                        <div>{{ headerMenuText }}</div>
                    </router-link>
                    <router-link to="/login" class="dropdown-content link"> {{ $t('Login') }} </router-link>
                </div>

                <!-- Home -->
                <div v-if="userStore.isLoggedIn" class="dropdown">
                    <router-link
                        to="/"
                        class="btnHeader dropbtn"
                        :class="{show: isMenuItemActive(HeaderMenuItem.Home)}">
                        <FontAwesomeIcon :icon="['fal', 'home']" />
                        <div>{{ headerMenuText }}</div>
                    </router-link>
                    <router-link
                        v-if="router.currentRoute.value.name != 'HomeDashboard'"
                        to="/"
                        class="dropdown-content link">
                        {{ $t('HomeDashboard') }}
                    </router-link>
                </div>

                <!-- Admin -->
                <div v-if="userStore.isLoggedIn && userStore.hasRoleLevel(RoleCode.System, 1)" class="dropdown">
                    <div class="btnHeader dropbtn" :class="{show: isMenuItemActive(HeaderMenuItem.Lists)}">
                        <FontAwesomeIcon :icon="['fal', 'list']" />
                        <div>{{ headerMenuText }}</div>
                    </div>
                    <div class="dropdown-content paddingRight">
                        {{ $t('Lists') }}

                        <ul>
                            <li>
                                <router-link to="/crop-variety-list"> {{ $t('CropSlashVarietyList') }} </router-link>
                            </li>
                            <li>
                                <router-link to="/product-list"> {{ $t('ProductList') }}</router-link>
                            </li>
                            <li>
                                <router-link to="/mixture-list"> {{ $t('MixtureList') }} </router-link>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Field Management -->
                <div v-if="userStore.isLoggedIn && userStore.hasRoleLevel(RoleCode.Fields, 1)" class="dropdown">
                    <div class="btnHeader dropbtn" :class="{show: isMenuItemActive(HeaderMenuItem.Fields)}">
                        <FontAwesomeIcon :icon="['fal', 'vector-polygon']" />
                        <div>{{ headerMenuText }}</div>
                    </div>
                    <div class="dropdown-content paddingRight">
                        {{ $t('FieldsAndPaddocks') }}
                        <ul>
                            <li>
                                <a @click="advancedSearchModalStore.open(HeaderSearchMode.FarmField, false, null)">
                                    {{ $t('FieldSearch') }}
                                </a>
                            </li>
                            <li>
                                <router-link to="/farm-field-list"> {{ $t('ViewAllFieldsAtSite') }}</router-link>
                            </li>
                            <li v-if="userStore.hasRoleLevel(RoleCode.Fields, 3)">
                                <a @click="manageFarmFieldModalStore.open()">
                                    {{ $t('CreateField') }}
                                </a>
                            </li>
                        </ul>
                        {{ $t('FarmSite') }}
                        <ul>
                            <li v-if="farmStore.selectedSiteId != null && userStore.hasRoleLevel(RoleCode.System, 1)">
                                <a @click="viewFarmSiteModalStore.open(farmStore.selectedSiteId)">
                                    {{ $t('ViewSite') }}
                                </a>
                            </li>
                            <li v-if="farmStore.selectedSiteId != null && userStore.hasRoleLevel(RoleCode.System, 2)">
                                <a @click="manageFarmSiteModalStore.open(farmStore.selectedSiteId)">
                                    {{ $t('ModifySite') }}
                                </a>
                            </li>
                            <li v-if="userStore.hasRoleLevel(RoleCode.System, 2)">
                                <a @click="manageFarmSiteModalStore.open()">
                                    {{ $t('CreateSite') }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Task Management -->
                <div v-if="userStore.isLoggedIn && userStore.hasRoleLevel(RoleCode.Tasks, 1)" class="dropdown">
                    <div class="btnHeader dropbtn" :class="{show: isMenuItemActive(HeaderMenuItem.Tasks)}">
                        <FontAwesomeIcon :icon="['fal', 'clipboard-list-check']" />
                        <div>{{ headerMenuText }}</div>
                    </div>
                    <div class="dropdown-content paddingRight">
                        {{ $t('TaskManagement') }}
                        <ul>
                            <li v-if="userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                                <a @click="manageWorkTaskModalStore.open(ManageWorkTaskModalMode.Create, null, null)">
                                    {{ $t('CreateTask') }}
                                </a>
                            </li>
                            <li v-if="userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                                <a @click="selectWorkTaskTemplateModalStore.open(null)">
                                    {{ $t('CreateTaskFromTemplate') }}
                                </a>
                            </li>
                            <li>
                                <a @click="advancedSearchModalStore.open(HeaderSearchMode.WorkTask, false, null)">
                                    {{ $t('TaskSearch') }}
                                </a>
                            </li>
                            <li>
                                <router-link to="/work-task-list"> {{ $t('TaskList') }}</router-link>
                            </li>
                        </ul>
                        {{ $t('TaskTemplates') }}
                        <ul>
                            <li v-if="userStore.hasRoleLevel(RoleCode.PlansAndTemplates, 1)">
                                <router-link to="/work-task-template-list">
                                    {{ $t('ManageTaskTemplates') }}
                                </router-link>
                            </li>
                            <li v-if="userStore.hasRoleLevel(RoleCode.PlansAndTemplates, 2)">
                                <a
                                    @click="
                                        manageWorkTaskModalStore.open(
                                            ManageWorkTaskModalMode.CreateTemplate,
                                            null,
                                            null,
                                        )
                                    ">
                                    {{ $t('CreateTaskTemplate') }}
                                </a>
                            </li>
                        </ul>
                        {{ $t('TaskPlans') }}
                        <ul>
                            <li
                                v-if="
                                    userStore.hasRoleLevel(RoleCode.Tasks, 2) &&
                                    userStore.hasRoleLevel(RoleCode.PlansAndTemplates, 3)
                                ">
                                <router-link to="/manage-task-plan-list">
                                    {{ $t('ManageTaskPlans') }}
                                </router-link>
                            </li>
                            <li
                                v-if="
                                    userStore.hasRoleLevel(RoleCode.Tasks, 2) &&
                                    userStore.hasRoleLevel(RoleCode.PlansAndTemplates, 3)
                                ">
                                <a @click="manageWorkTaskPlanModalStore.open(ManageWorkTaskPlanModalMode.Create)">
                                    {{ $t('CreateTaskPlan') }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Observations -->
                <div v-if="userStore.isLoggedIn && userStore.hasRoleLevel(RoleCode.Observations, 1)" class="dropdown">
                    <div class="btnHeader dropbtn" :class="{show: isMenuItemActive(HeaderMenuItem.Observations)}">
                        <FontAwesomeIcon :icon="['fal', 'binoculars']" />
                        <div>{{ headerMenuText }}</div>
                    </div>
                    <div class="dropdown-content paddingRight">
                        {{ $t('Observations') }}
                        <ul>
                            <li>
                                <a @click="advancedSearchModalStore.open(HeaderSearchMode.Observation, false, null)">{{
                                    $t('ObservationSearch')
                                }}</a>
                            </li>
                            <li>
                                <router-link to="/observation-list"> {{ $t('OpenObservations') }}</router-link>
                            </li>
                            <li>
                                <a @click="manageObservationModalStore.open()">
                                    {{ $t('CreateObservation') }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Reports -->
                <div v-if="userStore.isLoggedIn && userStore.hasRoleLevel(RoleCode.Tasks, 1)" class="dropdown">
                    <div class="btnHeader dropbtn" :class="{show: isMenuItemActive(HeaderMenuItem.Reports)}">
                        <FontAwesomeIcon :icon="['fal', 'table-cells']" />
                        <div>{{ headerMenuText }}</div>
                    </div>
                    <div class="dropdown-content paddingRight">
                        {{ $t('Reports') }}
                        <ul>
                            <li v-if="userStore.hasRoleLevel(RoleCode.Observations, 1)">
                                <router-link to="/activity-report"> {{ $t('ActivityReport') }} </router-link>
                            </li>
                            <li>
                                <router-link to="/overdue-task-report"> {{ $t('OverdueTaskReport') }} </router-link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div v-if="userStore.isLoggedIn && userStore.hasRoleLevel(RoleCode.System, 1)" class="dropdown">
                    <div class="btnHeader dropbtn" :class="{show: isMenuItemActive(HeaderMenuItem.Admin)}">
                        <FontAwesomeIcon :icon="['fal', 'cog']" />
                        <div>{{ headerMenuText }}</div>
                    </div>
                    <div class="dropdown-content paddingRight">
                        {{ $t('Administration') }}

                        <ul>
                            <li v-if="userStore.hasRoleLevel(RoleCode.User, 2)">
                                <router-link to="/manage-users"> {{ $t('ManageUsers') }} </router-link>
                            </li>
                            <li v-if="userStore.hasRoleLevel(RoleCode.System, 3)">
                                <router-link to="/system-configuration"> {{ $t('SystemConfiguration') }} </router-link>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Custom -->
                <div class="dropdown" v-if="isMenuItemActive(HeaderMenuItem.Custom)">
                    <div class="btnHeader dropbtn show">
                        <FontAwesomeIcon :icon="['fal', customMenuIcon]" />
                        <div>{{ headerMenuText }}</div>
                    </div>
                </div>
            </div>

            <!--Right aligned buttons-->
            <div style="width: 50%">
                <!-- Quick search -->
                <div style="float: right; margin-right: 1rem" v-if="userStore.isLoggedIn">
                    <QuickSearch></QuickSearch>
                </div>

                <!-- Help -->
                <div style="float: right">
                    <div class="btnHeader">
                        <FontAwesomeIcon :icon="['fal', 'circle-question']" @click="openPDF" />
                    </div>
                </div>

                <!-- Language selector -->
                <div style="float: right"><LanguageSelector /></div>

                <!-- Test components -->
                <div v-if="configStore.isDeveloperMode && userStore.isLoggedIn" style="float: right">
                    <router-link class="btnHeader" to="/test-components" title="Test Components">
                        <font-awesome-icon :icon="['fas', 'screwdriver-wrench']" style="font-size: 1.7rem" />
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, inject, ref} from 'vue';
import {useConfigStore} from '@/stores/config-store';
import {useUserStore} from '@/stores/user-store';
import {useFarmStore} from '@/stores/farm-store';
import {useManageUserModalStore} from '@/stores/modals/manage-user-modal-store';
import {ManageUserModalMode} from '@/enums/modal-modes/manage-user-modal-mode';
import {useRoute} from 'vue-router';
import ApiService from '@/services/api-service';
import {HeaderMenuItem} from '@/enums/header-menu-item';
import LanguageSelector from '@/components/LanguageSelector.vue';
import FarmSelector from '@/components/FarmSelector.vue';
import {RoleCode} from '@/enums/role-code';
import QuickSearch from '@/views/search/QuickSearch.vue';
import {useViewFarmSiteModalStore} from '@/stores/modals/view-farm-site-modal-store';
import {useManageFarmSiteModalStore} from '@/stores/modals/manage-farm-site-modal-store';
import {useManageFarmFieldModalStore} from '@/stores/modals/manage-farm-field-modal-store';
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
import {useManageObservationModalStore} from '@/stores/modals/manage-observation-modal-store';
import {useAdvancedSearchModalStore} from '@/stores/modals/advanced-search-modal-store';
import {ManageWorkTaskModalMode} from '@/enums/modal-modes/manage-work-task-modal-mode';
import {useSelectWorkTaskTemplateModalStore} from '@/stores/modals/select-work-task-template-modal-store';
import {HeaderSearchMode} from '@/enums/header-search-mode';
import {useManageWorkTaskPlanModalStore} from '@/stores/modals/manage-work-task-plan-modal-store';
import {ManageWorkTaskPlanModalMode} from '@/enums/modal-modes/manage-work-task-plan-modal-mode';
import router from '@/router';

export default defineComponent({
    components: {
        QuickSearch,
        LanguageSelector,
        FarmSelector,
    },

    setup() {
        const apiService = inject('apiService') as ApiService;
        const route = useRoute();

        let showUserProfileDropdown = ref<boolean>(false);

        /**
         * Determine if the current route's meta should show the specific section.
         */
        const isMenuItemActive = (section: HeaderMenuItem) => {
            return route.meta.headerMenuItemToShow === section;
        };

        /**
         * Log the user out.
         */
        async function logout() {
            apiService.logout();
        }

        /**
         * Text to be displayed in the header menu.
         */
        const headerMenuText = computed(() => {
            if (route.meta.headerMenuText && typeof route.meta.headerMenuText === 'function') {
                return route.meta.headerMenuText();
            }
            // Return an empty string if headerMenuText is not available
            return '';
        });

        /**
         * Icon to be displayed in the Custom header menu.
         */
        const customMenuIcon = computed(() => route.meta.customMenuIcon);

        /**
         * Profile button clicked css class.
         * @returns {string} Return "clicked" class specified in ucHeaders.scss if showUserProfileDropdown is true, else empty string.
         */
        const profileClickClass = computed(() => (showUserProfileDropdown.value ? 'clicked' : ''));

        const pdfPath = ref<string | null>(null);

        const loadPDF = async () => {
            try {
                //TODO:Replace the dummy PDF with the actual document
                const pdfModule = await import('@/assets/documents/Dummy_TyewareHelpPDF.pdf');
                pdfPath.value = pdfModule.default;
            } catch (error) {
                console.error('Error loading PDF:', error);
            }
        };

        const openPDF = () => {
            if (!pdfPath.value) {
                loadPDF().then(() => {
                    if (pdfPath.value) {
                        window.open(pdfPath.value, '_blank');
                    }
                });
            } else {
                window.open(pdfPath.value, '_blank');
            }
        };

        return {
            configStore: useConfigStore(),
            userStore: useUserStore(),
            farmStore: useFarmStore(),
            manageUserModalStore: useManageUserModalStore(),
            ManageUserModalMode,
            openPDF,
            showUserProfileDropdown,
            isMenuItemActive,
            logout,
            HeaderMenuItem,
            RoleCode,
            headerMenuText,
            customMenuIcon,
            profileClickClass,
            viewFarmSiteModalStore: useViewFarmSiteModalStore(),
            manageFarmSiteModalStore: useManageFarmSiteModalStore(),
            manageFarmFieldModalStore: useManageFarmFieldModalStore(),
            manageWorkTaskModalStore: useManageWorkTaskModalStore(),
            manageWorkTaskPlanModalStore: useManageWorkTaskPlanModalStore(),
            manageObservationModalStore: useManageObservationModalStore(),
            advancedSearchModalStore: useAdvancedSearchModalStore(),
            selectWorkTaskTemplateModalStore: useSelectWorkTaskTemplateModalStore(),
            HeaderSearchMode,
            ManageWorkTaskModalMode,
            ManageWorkTaskPlanModalMode,
            router,
        };
    },
});
</script>

<style lang="scss">
@import '@/assets/scss/header.scss';
</style>
