import {ManageWorkTaskPlanModalMode} from '@/enums/modal-modes/manage-work-task-plan-modal-mode';
import {defineStore} from 'pinia';

export const useManageWorkTaskPlanModalStore = defineStore('manageWorkTaskPlanModal', {
    state: () => ({
        isVisible: false,
        modalMode: ManageWorkTaskPlanModalMode.Create,
        workTaskPlanId: null as number | null,
        savedCounter: 0,
    }),
    actions: {
        open(modalMode: ManageWorkTaskPlanModalMode, workTaskPlanId: number | null = null) {
            // Close modal first (if it's already open) so that the watch is triggered when it's reopened
            this.close();

            this.modalMode = modalMode;
            this.workTaskPlanId = workTaskPlanId;
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
            this.workTaskPlanId = null;
        },
    },
});
