<template>
    <v-dialog v-model="viewDevicesModalStore.isVisible" style="width: 50vw !important">
        <v-card>
            <v-toolbar color="secondary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'laptop-mobile']" size="xl" class="mr-2" />
                    {{ $t('CurrentDeviceInstallations') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading" />

            <v-form v-else class="pa-4" style="overflow-y: auto">
                <v-list-item v-for="(device, index) in devices" :key="device.mobileDeviceId" style="padding: 5px">
                    <div class="bold swatchB6 slightlyLarger">{{ device.mobileDeviceExternalId }}</div>
                    <div class="swatchA4">{{ device.mobileDeviceManufacturer }} {{ device.mobileDeviceModel }}</div>

                    <!-- Application Version -->
                    <div class="flexRow marginBottom">
                        <div style="min-width: 10rem">{{ $t('ApplicationVersion') }}</div>
                        <HelpIcon
                            :help-text="$t('ViewDevices_ApplicationVersion_HelpText')"
                            style="margin-right: 2rem" />
                        <span v-if="device.appVersion" class="swatchA2">
                            {{ device.appVersion }}
                        </span>
                        <span v-else>{{ $t('NA') }}</span>
                    </div>
                    <!-- Last Active -->
                    <div class="flexRow marginBottom">
                        <div style="min-width: 10rem">{{ $t('LastActive') }}</div>
                        <HelpIcon :help-text="$t('ManageUser_LastActiveMobile_HelpText')" style="margin-right: 2rem" />
                        <span v-if="device.dateLastPing" class="swatchA2">
                            {{ formatDate(device.dateLastPing) }}
                        </span>
                        <span v-else>{{ $t('NA') }}</span>
                    </div>
                    <!-- First Active -->
                    <div class="flexRow marginBottom">
                        <div style="min-width: 10rem">{{ $t('FirstActive') }}</div>
                        <HelpIcon :help-text="$t('ManageUser_FirstActiveMobile_HelpText')" style="margin-right: 2rem" />
                        <span v-if="device.dateFirstPing" class="swatchA2">
                            {{ formatDate(device.dateFirstPing) }}
                        </span>
                        <span v-else>{{ $t('NA') }}</span>
                    </div>
                    <!-- Device Name -->
                    <div class="flexRow marginBottom">
                        <div style="min-width: 10rem">{{ $t('DeviceName') }}</div>
                        <HelpIcon :help-text="$t('ViewDevices_DeviceName_HelpText')" style="margin-right: 2rem" />
                        <span v-if="device.mobileDeviceName" class="swatchA2">
                            {{ device.mobileDeviceName }}
                        </span>
                        <span v-else>{{ $t('NA') }}</span>
                    </div>
                    <!-- Notifications -->
                    <div class="flexRow marginBottom">
                        <div style="min-width: 10rem">{{ $t('Notifications') }}</div>
                        <HelpIcon :help-text="$t('ViewDevices_Notifications_HelpText')" style="margin-right: 2rem" />
                        <div
                            v-if="device.isPushNotificationEnabled && device.isPushNotificationEnabled != null"
                            class="flexRow">
                            <CircleIcon class="marginRightSmall swatchGRNBG">
                                <FontAwesomeIcon :icon="['fal', 'check']" />
                            </CircleIcon>
                            <span>{{ $t('Enabled') }}</span>
                        </div>
                        <div
                            v-else-if="!device.isPushNotificationEnabled && device.isPushNotificationEnabled != null"
                            class="flexRow">
                            <CircleIcon class="marginRightSmall swatchG4BG">
                                <FontAwesomeIcon :icon="['fal', 'xmark']" />
                            </CircleIcon>
                            <span>{{ $t('Disabled') }}</span>
                        </div>
                        <span v-else>{{ $t('NA') }}</span>
                        <v-spacer></v-spacer>
                        <v-btn class="remote-purge">
                            <template v-slot:prepend>
                                <FontAwesomeIcon :icon="['fa', 'trash-can-xmark']" size="xl" />
                            </template>
                            {{ $t('RemotePurge') }}
                        </v-btn>
                    </div>
                    <v-divider v-if="index < devices.length - 1"></v-divider>
                </v-list-item>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <v-btn>
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fa', 'trash-can-xmark']" size="xl" />
                    </template>
                    {{ $t('PurgeAll') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import {useViewDevicesModalStore} from '@/stores/modals/view-devices-modal-store';
import {watch, inject, ref} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import {MobileDeviceSearchResultDto} from '@/models/data-transfer-objects/search/mobile-device-search/MobileDeviceSearchResultDto';
import {format} from 'date-fns';

// Form
let isLoading = ref<boolean>(false);
const viewDevicesModalStore = useViewDevicesModalStore();
const devices = ref<MobileDeviceSearchResultDto[]>([]);

// Services
const apiService = inject('apiService') as ApiService;

/**
 * Load data for the modal.
 */
const loadData = async (userId: number) => {
    isLoading.value = true;

    // Set form user
    const response = await apiService.post('search/mobile-devices', userId);

    devices.value = response.mobileDevices;

    isLoading.value = false;
};

/**
 * Close the modal.
 */
const close = () => {
    viewDevicesModalStore.close();
};

/**
 * Format date for display.
 */
const formatDate = (date: Date | null): string | null => {
    if (!date) return null;

    return format(date, 'dd/MM/yyyy HH:mm');
};

// Watch for changes to viewDevicesModalStore.userId
watch(
    () => viewDevicesModalStore.isVisible,
    async (isVisible) => {
        if (isVisible && viewDevicesModalStore.userId !== null) {
            await loadData(viewDevicesModalStore.userId);
        }
    },
);
</script>

<style lang="scss">
@import '@/assets/scss/swatches.scss';

.flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.remote-purge {
    border: 1px solid $swatchG5 !important;
    background-color: $swatchGA !important;
    color: $swatchG1 !important;
}
</style>
