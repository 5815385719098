// src/directives/v-ripple.ts

import {Directive, DirectiveBinding} from 'vue';

interface RippleOptions {
    color?: string;
    duration?: number;
    centered?: boolean;
}

type RippleElement = HTMLElement & {
    rippleContainer?: HTMLElement;
};

const vRipple: Directive<RippleElement, RippleOptions> = {
    mounted(el: RippleElement, binding: DirectiveBinding<RippleOptions>) {
        // Set default options
        const options: RippleOptions = {
            color: 'rgba(0, 0, 0, 0.35)',
            duration: 500,
            centered: true, // Default to centered ripple
            ...binding.value,
        };

        el.style.position = 'relative';
        el.style.overflow = 'hidden';

        const rippleContainer = document.createElement('div');
        rippleContainer.style.position = 'absolute';
        rippleContainer.style.top = '0';
        rippleContainer.style.left = '0';
        rippleContainer.style.width = '100%';
        rippleContainer.style.height = '100%';
        rippleContainer.style.overflow = 'hidden';
        rippleContainer.style.pointerEvents = 'none';
        el.appendChild(rippleContainer);

        el.rippleContainer = rippleContainer;

        el.addEventListener('click', (event: MouseEvent) => {
            const rect = el.getBoundingClientRect();
            const ripple = document.createElement('div');

            ripple.style.position = 'absolute';
            ripple.style.borderRadius = '50%';
            ripple.style.backgroundColor = options.color!;
            ripple.style.opacity = '0';

            const size = Math.max(rect.width, rect.height) * 2;
            ripple.style.width = ripple.style.height = `${size}px`;

            let x, y;
            if (options.centered) {
                x = (rect.width - size) / 2;
                y = (rect.height - size) / 2;
            } else {
                x = event.clientX - rect.left - size / 2;
                y = event.clientY - rect.top - size / 2;
            }

            ripple.style.left = `${x}px`;
            ripple.style.top = `${y}px`;

            rippleContainer.appendChild(ripple);

            // Animate the ripple
            ripple.animate(
                [
                    {transform: 'scale(0)', opacity: 1},
                    {transform: 'scale(1)', opacity: 0},
                ],
                {
                    duration: options.duration,
                    easing: 'ease-out',
                },
            ).onfinish = () => ripple.remove();
        });
    },

    unmounted(el: RippleElement) {
        if (el.rippleContainer) {
            el.removeChild(el.rippleContainer);
        }
    },
};

export default vRipple;
