<template>
    <v-btn icon @click="handleClick" class="closeButton" size="small">
        <v-icon size="x-large">mdi-close</v-icon>
    </v-btn>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'CloseModalButton',
    setup(props, {attrs}) {
        function handleClick(event: Event) {
            if (attrs.onClick) {
                // eslint-disable-next-line @typescript-eslint/ban-types
                (attrs.onClick as Function)(event);
            }
        }

        return {handleClick};
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.closeButton {
    border: 1px solid $swatchWHT;
    margin-right: 0.5rem;
}
</style>
