export class SaveWorkTaskPlanItemDto {
    workTaskPlanItemId: number | null = null;
    sequenceNumber!: number;
    workTaskTemplateId!: number;
    numberOfDaysDueAfterPreviousTask: number | null = null;
    duplicatedFromWorkTaskPlanItemId: number | null = null;

    constructor(data?: Partial<SaveWorkTaskPlanItemDto>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
