<template>
    <div id="divManageUsers">
        <v-card flat style="overflow: visible">
            <v-toolbar flat color="swatchG2">
                <v-tabs v-model="tab">
                    <v-tab value="SearchParameters">
                        <FontAwesomeIcon :icon="['fal', 'filter']" size="xl" class="mr-2" />
                        {{ $t('Filters') }}
                    </v-tab>
                    <v-tab value="SearchResults" v-show="hasSearchBeenRun">
                        <FontAwesomeIcon :icon="['fal', 'th']" size="xl" class="mr-2" />
                        {{ resultsTabTitle }}
                    </v-tab>
                </v-tabs>

                <v-spacer></v-spacer>
                <v-btn
                    @click="manageUserModalStore.open(ManageUserModalMode.Create)"
                    variant="outlined"
                    v-if="hasModifyStaffAccess">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'user-plus']" size="xl" />
                    </template>
                    {{ $t('CreateUser') }}
                </v-btn>
            </v-toolbar>

            <LoadingSymbol v-if="isLoading" />

            <v-form v-else class="pa-4" @keyup.enter="search">
                <v-tabs-window v-model="tab" style="overflow: visible">
                    <v-tabs-window-item value="SearchParameters">
                        <user-search-parameters v-model:searchParameters="searchParameters" />
                    </v-tabs-window-item>

                    <v-tabs-window-item value="SearchResults">
                        <user-search-results :results-list="searchResults"></user-search-results>
                    </v-tabs-window-item>
                </v-tabs-window>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <BackButton v-if="isSearchParametersTabSelected" />
                <BackButton v-else @click="selectSearchParametersTab" />
                <v-btn @click="search">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'check-square']" size="xl" />
                    </template>
                    {{ $t('ApplyFilters') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onMounted, ref, watch, defineAsyncComponent} from 'vue';
import ApiService from '@/services/api-service.js';
import {SearchUsersResponse} from '@/models/api/responses/search/SearchUsersResponse';
import {SearchUsersRequest} from '@/models/api/requests/search/SearchUsersRequest';
import UserSearchParameters from '@/views/search/UserSearchParameters.vue';
import UserSearchResults from '@/views/search/UserSearchResults.vue';
import {subDays} from 'date-fns';
import {useDeleteUserModalStore} from '@/stores/modals/delete-user-modal-store';
import {useManageUserModalStore} from '@/stores/modals/manage-user-modal-store';
import {ManageUserModalMode} from '@/enums/modal-modes/manage-user-modal-mode';
import {useUserStore} from '@/stores/user-store';
import {RoleCode} from '@/enums/role-code';
import i18n from '@/i18n';

export default defineComponent({
    components: {
        UserSearchParameters,
        UserSearchResults,
    },
    setup() {
        const userStore = useUserStore();
        const deleteUserModalStore = useDeleteUserModalStore();
        const manageUserModalStore = useManageUserModalStore();
        const isLoading = ref<boolean>(true);
        const valid = ref<boolean>(true);
        const hasSearchBeenRun = ref<boolean>(false);
        const searchParameters = ref(
            new SearchUsersRequest({
                isQuickSearch: false,
                recentActivitySinceDate: subDays(new Date(), 7),
            }),
        );
        const searchResults = ref<SearchUsersResponse[]>([]);
        let apiService = inject('apiService') as ApiService;
        let tab = ref<string>('SearchParameters');
        const hasModifyStaffAccess = userStore.hasRoleLevel(RoleCode.System, 2);

        // Dynamically import VueDatePicker
        const VueDatePicker = defineAsyncComponent(() => import('@vuepic/vue-datepicker'));
        /**
         * Initialize the page.
         */
        async function init() {
            isLoading.value = false;
        }

        /**
         * Call API to retrieve and display data.
         */
        async function search() {
            isLoading.value = true;

            // Get search results
            searchResults.value = await apiService.post('search/users', searchParameters.value);

            // Record the search has been run, so that the results tab can be shown
            hasSearchBeenRun.value = true;

            // Switch to results tab
            tab.value = 'SearchResults';

            isLoading.value = false;
        }

        /**
         * Select the search parameters tab.
         */
        async function selectSearchParametersTab() {
            // Switch to search paramters tab
            tab.value = 'SearchParameters';
        }

        /**
         * Title of the results tab.
         */
        const resultsTabTitle = computed(() => {
            return i18n.global.t('SearchResults_WithNumberOfResults', {
                numberOfResults: searchResults.value.length.toLocaleString(),
            });
        });

        /**
         * Flag to indicate if the Search Parameters tab is selected.
         */
        const isSearchParametersTabSelected = computed(() => {
            return tab.value == 'SearchParameters';
        });

        onMounted(async () => {
            await init();
        });

        watch(
            () => deleteUserModalStore.deletedCounter,
            (newVal, oldVal) => {
                // If a user has been deleted and the search has already been run, run it again to ensure the latest details are displayed
                if (hasSearchBeenRun.value == true && newVal > oldVal) {
                    search();
                }
            },
        );

        watch(
            () => manageUserModalStore.savedCounter,
            (newVal, oldVal) => {
                // If a user has been saved and the search has already been run, run it again to ensure the latest details are displayed
                if (hasSearchBeenRun.value == true && newVal > oldVal) {
                    search();
                }
            },
        );

        return {
            manageUserModalStore,
            ManageUserModalMode,
            hasModifyStaffAccess,
            tab,
            isLoading,
            valid,
            hasSearchBeenRun,
            searchParameters,
            searchResults,
            search,
            selectSearchParametersTab,
            resultsTabTitle,
            isSearchParametersTabSelected,
            VueDatePicker,
        };
    },
});
</script>
