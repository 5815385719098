<template>
    <div class="rounded-text custom-background">
        <CircleIcon class="custom-chip custom-icon">
            <FontAwesomeIcon :icon="['fa', 'calendar-days']" class="rounded-text custom-background" />
        </CircleIcon>
        <span class="pr-2" style="padding-left: 5px" v-if="seasonName && seasonName != ''">{{ seasonName }}</span>
    </div>
</template>

<script lang="ts">
import {PropType, defineComponent} from 'vue';

export default defineComponent({
    name: 'SeasonChip',
    props: {
        seasonName: String as PropType<string>,
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';
.custom-chip {
    font-size: 18pt;
    height: 30px;
    width: 30px;
}
.rounded-text {
    display: inline-flex;
    align-items: center;
    border-radius: 20px; /* Adjust the border radius to make it round */
    font-family: 'Open Sans Condensed', sans-serif;
}

.custom-background {
    background-color: rgba($swatchA1, 0.8);
    color: $swatchWHT;
}
.custom-icon {
    background-color: $swatchA1;
}
</style>
