import {WorkTaskCompletionTimeCode} from '@/enums/work-task-completion-time-code';
import i18n from '@/i18n';

export interface WorkTaskCompletionTime {
    workTaskCompletionTimeCode: WorkTaskCompletionTimeCode | null;
    getWorkTaskCompletionTimeName(): string;
}

export const workTaskCompletionTimes: WorkTaskCompletionTime[] = [
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Minutes15,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberMinutesPlural', {number: 15});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Minutes30,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberMinutesPlural', {number: 30});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Minutes45,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberMinutesPlural', {number: 45});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Minutes60,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberMinutesPlural', {number: 60});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Minutes90,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberMinutesPlural', {number: 90});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Hours2,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberHoursPlural', {number: 2});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Hours3,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberHoursPlural', {number: 3});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Hours4,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberHoursPlural', {number: 4});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Hours5,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberHoursPlural', {number: 5});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Hours6,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberHoursPlural', {number: 6});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Hours7,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberHoursPlural', {number: 7});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Hours8,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberHoursPlural', {number: 8});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Hours10,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberHoursPlural', {number: 10});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Hours12,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberHoursPlural', {number: 12});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Days1,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberDaysSingular');
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Days2,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberDaysPlural', {number: 2});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Days3,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberDaysPlural', {number: 3});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Days4,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberDaysPlural', {number: 4});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Days5,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberDaysPlural', {number: 5});
        },
    },
    {
        workTaskCompletionTimeCode: WorkTaskCompletionTimeCode.Days7,
        getWorkTaskCompletionTimeName() {
            return i18n.global.t('NumberDaysPlural', {number: 7});
        },
    },
];

export const workTaskCompletionTimesWithNull: WorkTaskCompletionTime[] = [
    {
        workTaskCompletionTimeCode: null,
        getWorkTaskCompletionTimeName() {
            return '';
        },
    },
    ...workTaskCompletionTimes,
];

export function getWorkTaskCompletionTimeByCode(
    workTaskCompletionTimeCode: WorkTaskCompletionTimeCode,
): WorkTaskCompletionTime | undefined {
    return workTaskCompletionTimes.find((aru) => aru.workTaskCompletionTimeCode === workTaskCompletionTimeCode);
}
