import {swatches} from '@/theme/swatches';

/**
 * Get the hex color value for a given swatch name.
 *
 * @param swatchName - The name of the swatch (e.g., 'swatchA1').
 * @returns The hex color value as a string.
 */
export function getSwatchColor(swatchName: keyof typeof swatches): string {
    return swatches[swatchName] || '#000000'; // Default to black if swatch not found
}

/**
 * Lighten a hex color by a given percentage.
 *
 * @param hex - The hex color to lighten (e.g., '#003B46').
 * @param percent - The percentage to lighten the color by (0-100).
 * @returns The lightened hex color as a string.
 */
export function lightenColor(hex: string, percent: number): string {
    // Remove the '#' if it's there
    hex = hex.replace(/^#/, '');

    // Convert hex to RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Increase RGB values by the specified percentage
    r = Math.min(255, Math.floor(r + (255 - r) * (percent / 100)));
    g = Math.min(255, Math.floor(g + (255 - g) * (percent / 100)));
    b = Math.min(255, Math.floor(b + (255 - b) * (percent / 100)));

    // Convert RGB back to hex
    const rr = r.toString(16).padStart(2, '0');
    const gg = g.toString(16).padStart(2, '0');
    const bb = b.toString(16).padStart(2, '0');

    return `#${rr}${gg}${bb}`;
}
