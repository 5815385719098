import {HeaderSearchMode} from '@/enums/header-search-mode';
import {defineStore} from 'pinia';

export const useAdvancedSearchModalStore = defineStore('advandedSearchUserModal', {
    state: () => ({
        isVisible: false,
        searchMode: null as HeaderSearchMode | null,
        isQuickSearch: false,
        quickSearchText: null as string | null,
        filterBySite: false,
    }),
    actions: {
        open(searchMode: HeaderSearchMode | null, isQuickSearch: boolean, quickSearchText: string | null, filterBySite: boolean = false) {
            // Close modal first (if it's already open) so that the watch is triggered when it's reopened
            this.close();

            this.searchMode = searchMode;
            this.isQuickSearch = isQuickSearch;
            this.quickSearchText = quickSearchText;
            this.filterBySite = filterBySite;
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
            this.searchMode = HeaderSearchMode.User;
            this.isQuickSearch = false;
            this.quickSearchText = null;
            this.filterBySite = false;
        },
    },
});
