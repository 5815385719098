<template>
    <v-dialog v-model="deleteUserModalStore.isVisible" class="deleteUserModal">
        <v-card>
            <v-toolbar color="swatchA2">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'trash-xmark']" size="xl" class="mr-2" />
                    {{ $t('ConfirmDeleteUser') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading || user == null" />

            <v-form v-else ref="form" class="pa-4" style="overflow-y: scroll">
                <div class="profile-detail">
                    <div class="large theme staffUserIconClass">
                        <FontAwesomeIcon :icon="['fas', 'user-shield']" />
                    </div>
                    <div class="space-between-div paddingLeft">
                        <div>
                            <span class="bold swatchA2 slightlyLarger">
                                {{ user.userGivenName }}, {{ user.userSurname }}
                            </span>
                            <IdIcon>{{ user.userId }}</IdIcon>
                        </div>
                        <div>
                            <span>{{ user.userEmailAddress }}</span>
                        </div>
                    </div>
                </div>

                <!-- Instructions -->
                <v-card>
                    <v-toolbar color="swatchGRN">
                        <v-toolbar-title>
                            <FontAwesomeIcon :icon="['fal', 'question-square']" size="xl" class="mr-2" />
                            {{ $t('Instructions') }}
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="instructionDetails">
                        {{ $t('DeleteUser_Instructions', {systemName: configStore.systemName}) }}
                    </v-card-text>
                </v-card>

                <!-- Confirm Delete -->
                <v-card class="marginTop">
                    <v-toolbar color="swatchRED">
                        <v-toolbar-title>
                            <FontAwesomeIcon :icon="['fal', 'check']" size="xl" class="mr-2" />
                            {{ $t('ConfirmDeleteUser') }}
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="userDeleteConfirmation">
                        {{ $t('DeleteUser_ActionTracked') }}

                        <v-row class="mt-1">
                            <v-col cols="1" align-self="center">
                                <v-checkbox v-model="isConfirmed" class="confirm-checkbox" hide-details></v-checkbox>
                            </v-col>
                            <v-col cols="11" align-self="center">
                                {{ $t('DeleteUser_Confirmation', {systemName: configStore.systemName}) }}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <v-btn @click="confirmDelete" :disabled="!isConfirmed">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'check']" size="xl" />
                    </template>
                    {{ $t('Confirm') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/user/delete-user-modal.scss';
import '@/assets/scss/user/user-manager.scss';

import {useConfigStore} from '@/stores/config-store';
import {useUserStore} from '@/stores/user-store';
import {useDeleteUserModalStore} from '@/stores/modals/delete-user-modal-store';
import {watch, inject, ref} from 'vue';
import {SearchUsersResponse} from '@/models/api/responses/search/SearchUsersResponse';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import i18n from '@/i18n';

// Config settings
const configStore = useConfigStore();

// Form
let isLoading = ref<boolean>(false);
const snackbar = ref({
    show: false,
    text: '',
});
const deleteUserModalStore = useDeleteUserModalStore();
const user = ref<SearchUsersResponse | null>(null);
const isConfirmed = ref(false);

// Services
const apiService = inject('apiService') as ApiService;
const userStore = useUserStore();

/**
 * Load data for the modal.
 */
const loadData = async (userToLoad: SearchUsersResponse) => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // Set form user
    user.value = userToLoad;

    isLoading.value = false;
};

/**
 * Close the modal.
 */
const close = () => {
    deleteUserModalStore.close();
};

/**
 * Save the modal.
 */
const confirmDelete = async () => {
    if (user.value == null) return;

    isLoading.value = true;

    try {
        // Call API to delete user
        await apiService.post('users/delete', user.value.userId);

        // Show success feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('DeleteUser_Success');
        deleteUserModalStore.deletedCounter++;

        // Log the user out if they have deleted themselves
        if (userStore.user?.userId == user.value.userId) {
            apiService.logout();
        }

        // Close modal
        deleteUserModalStore.close();
    } catch (ex: unknown) {
        // Show fail feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ErrorGeneric');
        isLoading.value = false;
    }
};

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    user.value = null;
    isConfirmed.value = false;
};

// Watch for changes to deleteUserModalStore.userId
watch(
    () => deleteUserModalStore.isVisible,
    async (isVisible) => {
        if (isVisible && deleteUserModalStore.user !== null) {
            await loadData(deleteUserModalStore.user);
        }
    },
);
</script>
