import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a4dbb06"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rounded-text custom-background" }
const _hoisted_2 = {
  key: 0,
  class: "pr-2",
  style: {"padding-left":"5px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_CircleIcon = _resolveComponent("CircleIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CircleIcon, { class: "custom-chip custom-icon" }, {
      default: _withCtx(() => [
        _createVNode(_component_FontAwesomeIcon, {
          icon: ['fa', 'calendar-days'],
          class: "rounded-text custom-background"
        })
      ]),
      _: 1
    }),
    (_ctx.seasonName && _ctx.seasonName != '')
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.seasonName), 1))
      : _createCommentVNode("", true)
  ]))
}