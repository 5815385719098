<template>
    <LoadingSymbol v-if="isLoading" />

    <table v-else style="width: 100%" class="tblForm">
        <tr>
            <td colspan="4" class="subHeader">{{ $t('Filters') }}</td>
        </tr>
        <tr>
            <td class="tableCell padding" style="width: 15%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('TemplateName') }}</span>
                    <HelpIcon :help-text="$t('TaskTemplateSearch_TaskTemplateName_HelpText')" />
                </div>
            </td>
            <td style="width: 35%">
                <v-text-field
                    v-model="localSearchParameters.WorkTaskTemplateName"
                    hide-details
                    clearable
                    style="width: 400px">
                </v-text-field>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('TaskTypes') }}</span>
                    <HelpIcon :help-text="$t('TaskTemplateSearch_TaskTypes_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.workTaskTypeCodeList"
                        hide-details
                        multiple
                        clearable
                        chips
                        :items="workTaskTypes"
                        item-title="workTaskTypeName"
                        item-value="workTaskTypeCode"
                        max-width="400px"></v-select>
                </div>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding" style="width: 15%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('TaskPlan') }}</span>
                    <HelpIcon :help-text="$t('TaskTemplateSearch_TaskPlan_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-autocomplete
                        v-model="localSearchParameters.workTaskPlanIdList"
                        hide-details
                        multiple
                        clearable
                        chips
                        :items="workTaskPlans"
                        item-title="workTaskPlanName"
                        item-value="workTaskPlanId"
                        max-width="400px" />
                </div>
            </td>
        </tr>
    </table>
</template>

<script setup lang="ts">
import {onMounted, ref, watch} from 'vue';
import {WorkTaskTemplateSearchParametersDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchParametersDto';
import {useWorkTaskPlans} from '@/composables/data-source/work-task-plans';
const {getWorkTaskPlans, workTaskPlans} = useWorkTaskPlans();
import {useWorkTaskTypes} from '@/composables/data-source/work-task-types';
const {getWorkTaskTypes, workTaskTypes} = useWorkTaskTypes();

const props = defineProps<{searchParameters: WorkTaskTemplateSearchParametersDto}>();
const emit = defineEmits(['update:searchParameters']);
const isLoading = ref<boolean>(false);

// Create a local copy of the prop
const localSearchParameters = ref({...props.searchParameters});

// Watch for changes in the parent parameters object and update local copy
watch(
    () => props.searchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(localSearchParameters.value)) {
            localSearchParameters.value = {...newVal};
        }
    },
    {deep: true, immediate: true},
);

// Watch for changes in the local copy and emit the update event
watch(
    localSearchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(props.searchParameters)) {
            emit('update:searchParameters', newVal);
        }
    },
    {deep: true},
);

// Fetch data for dropdown
onMounted(async () => {
    isLoading.value = true;

    // get dropdown data
    await getWorkTaskPlans();
    await getWorkTaskTypes();

    isLoading.value = false;
});
</script>
