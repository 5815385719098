<template>
    <LoadingSymbol v-if="isLoading" />

    <table v-else style="width: 100%" class="tblForm">
        <tr>
            <td colspan="4" class="subHeader">{{ $t('Particulars') }}</td>
        </tr>
        <tr>
            <td class="tableCell padding" style="width: 10%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('Name') }}</span>
                    <HelpIcon :help-text="$t('UserSearch_Name_HelpText')" />
                </div>
            </td>
            <td style="width: 40%">
                <v-text-field v-model="localSearchParameters.userFullName" hide-details clearable style="width: 400px">
                </v-text-field>
            </td>
            <td style="width: 10%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('Roles') }}</span>
                    <HelpIcon :help-text="$t('UserSearch_Roles_HelpText')" />
                </div>
            </td>
            <td style="width: 40%">
                <v-select
                    v-model="localSearchParameters.userRoleCodeList"
                    hide-details
                    multiple
                    clearable
                    chips
                    :items="roleCodeList"
                    item-title="roleName"
                    item-value="roleCode"
                    style="width: 400px"></v-select>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('FarmSite(s)') }}</span>
                    <HelpIcon :help-text="$t('UserSearch_FarmSites_HelpText')" />
                </div>
            </td>
            <td style="width: 40%">
                <v-select
                    v-model="localSearchParameters.farmSiteIdList"
                    hide-details
                    multiple
                    clearable
                    chips
                    :items="farmSites"
                    item-title="farmSiteAbbreviationAndName"
                    item-value="farmSiteId"
                    max-width="400px"></v-select>
            </td>
        </tr>
        <!-- Hiding the Recent Activity section for now because it's not in the spec -->
        <tr style="display: none">
            <td>
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('RecentActivity') }}</span>
                    <HelpIcon :help-text="$t('UserSearch_RecentActivity_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <div class="tableCell">
                        <v-select
                            v-model="localSearchParameters.recentActivityList"
                            hide-details
                            multiple
                            clearable
                            chips
                            :items="recentActivityList"
                            item-title="title"
                            item-value="value"
                            style="width: 400px"></v-select>
                    </div>
                    <div class="tableCell paddingLeft" v-show="showRecentActivitySinceDate">
                        <component
                            :is="VueDatePicker"
                            v-model="localSearchParameters.recentActivitySinceDate"
                            placeholder="DD/MM/YYYY, HH:MM"
                            format="dd/MM/yyyy HH:mm"
                            text-input
                            :auto-position="false"
                            :locale="i18n.global.locale" />
                    </div>
                </div>
            </td>
        </tr>
    </table>
</template>

<script setup lang="ts">
import {ref, computed, watch, defineAsyncComponent, onMounted, inject} from 'vue';
import i18n from '@/i18n';
import {SearchUsersRequest} from '@/models/api/requests/search/SearchUsersRequest';
import {GetUserRolesResponse} from '@/models/api/responses/data-source/GetUserRolesResponse';
import ApiService from '@/services/api-service';
import {useFarmSites} from '@/composables/data-source/farm-sites';
const {getFarmSites, farmSites} = useFarmSites();

const props = defineProps<{searchParameters: SearchUsersRequest}>();
const emit = defineEmits(['update:searchParameters']);
const isLoading = ref<boolean>(false);

// List of Recent Activity options
const recentActivityList = [
    {value: 'ACTIVENOW', title: i18n.global.t('ActiveNow')},
    {value: 'ACTIVE24', title: i18n.global.t('ActiveInLast24Hours')},
    {value: 'SINCEDATE', title: i18n.global.t('ActiveSinceDate')},
];

// Dynamically import VueDatePicker
const VueDatePicker = defineAsyncComponent(() => import('@vuepic/vue-datepicker'));

// Create a local copy of the prop
const localSearchParameters = ref({...props.searchParameters});

/**
 * Flag to indicate if the Recent Activity Since Date should be shown.
 */
const showRecentActivitySinceDate = computed(() => {
    return localSearchParameters.value.recentActivityList
        ? localSearchParameters.value.recentActivityList.indexOf('SINCEDATE') > -1
        : false;
});

// Watch for changes in the parent parameters object and update local copy
watch(
    () => props.searchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(localSearchParameters.value)) {
            localSearchParameters.value = {...newVal};
        }
    },
    {deep: true, immediate: true},
);

// Watch for changes in the local copy and emit the update event
watch(
    localSearchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(props.searchParameters)) {
            emit('update:searchParameters', newVal);
        }
    },
    {deep: true},
);

// Fetch data for dropdown
const apiService = inject('apiService') as ApiService;
const roleCodeList = ref<GetUserRolesResponse[]>([]);
onMounted(async () => {
    isLoading.value = true;

    // Get data
    roleCodeList.value = await apiService.get('data-source/user-roles');
    await getFarmSites();

    isLoading.value = false;
});
</script>
