import {ArtifactFileTypeCode} from '@/enums/artifact-file-type-code';
import i18n from '@/i18n';
import {createFileExtensionInfo, FileExtensionInfo} from '@/models/other/FileExtensionInfo';

class FileService {
    /**
     * File extensions that are allowed to be uploaded.
     */
    private allowedFileExtensions: Record<string, FileExtensionInfo> = {
        '.7z': createFileExtensionInfo(ArtifactFileTypeCode.Compressed),
        '.avi': createFileExtensionInfo(ArtifactFileTypeCode.Video),
        '.csv': createFileExtensionInfo(ArtifactFileTypeCode.Csv),
        '.doc': createFileExtensionInfo(ArtifactFileTypeCode.Word),
        '.docx': createFileExtensionInfo(ArtifactFileTypeCode.Word),
        '.heic': createFileExtensionInfo(ArtifactFileTypeCode.Image),
        '.jpeg': createFileExtensionInfo(ArtifactFileTypeCode.Image),
        '.jpg': createFileExtensionInfo(ArtifactFileTypeCode.Image),
        '.mov': createFileExtensionInfo(ArtifactFileTypeCode.Video),
        '.mp4': createFileExtensionInfo(ArtifactFileTypeCode.Video),
        '.pdf': createFileExtensionInfo(ArtifactFileTypeCode.Pdf),
        '.png': createFileExtensionInfo(ArtifactFileTypeCode.Image),
        '.rar': createFileExtensionInfo(ArtifactFileTypeCode.Compressed),
        '.rtf': createFileExtensionInfo(ArtifactFileTypeCode.Word),
        '.txt': createFileExtensionInfo(ArtifactFileTypeCode.Text),
        '.webp': createFileExtensionInfo(ArtifactFileTypeCode.Image),
        '.webm': createFileExtensionInfo(ArtifactFileTypeCode.Video),
        '.xls': createFileExtensionInfo(ArtifactFileTypeCode.Excel),
        '.xlsx': createFileExtensionInfo(ArtifactFileTypeCode.Excel),
        '.zip': createFileExtensionInfo(ArtifactFileTypeCode.Compressed),
    };

    /**
     * Check to see if the file's extension is allowed to be uploaded.
     */
    public isFileExtensionAllowed(fileName: string): boolean {
        const extension = this.getFileExtension(fileName);
        return !!this.allowedFileExtensions[extension];
    }

    /**
     * Get info about the file extension.
     */
    public getFileExtensionInfo(fileName: string): FileExtensionInfo | null {
        const extension = this.getFileExtension(fileName);
        return this.allowedFileExtensions[extension] || null;
    }

    /**
     * Validate the file to ensure the extension is allowed and that it's not too large.
     */
    public validateFile(file: File, allowedFileTypes?: ArtifactFileTypeCode[]): {isValid: boolean; message?: string} {
        const fileInfo = this.getFileExtensionInfo(file.name);

        // Check if file extension is allowed to be uploaded
        const allowedExtensions = this.getAllowedExtensions(allowedFileTypes);
        if (!fileInfo || (allowedFileTypes && !allowedFileTypes.includes(fileInfo.fileTypeCode))) {
            return {
                isValid: false,
                message: i18n.global.t('Validation_FileExtensionNotAllowed', {
                    allowedExtensions: allowedExtensions.join(', '),
                }),
            };
        }

        // Check if file is not too large
        const maxSizeInBytes = fileInfo.maxSizeInMegabytes * 1024 * 1024;
        if (file.size > maxSizeInBytes) {
            return {
                isValid: false,
                message: i18n.global.t('Validation_MaxFileSize', {
                    maxSizeInMegabytes: fileInfo.maxSizeInMegabytes,
                }),
            };
        }

        return {
            isValid: true,
        };
    }

    /**
     * Validates that the picture has the correct dimensions.
     */
    public validateImageDimensions = (file: File, height: number, width: number): Promise<{isValid: boolean; message?: string}> => {
        return new Promise((resolve) => {
            const img = new Image();
            const url = URL.createObjectURL(file);
    
            img.onload = () => {
                const isRightSize = img.height == height && img.width == width;
                URL.revokeObjectURL(url); // Clean up the object URL
                resolve({isValid: isRightSize, message: i18n.global.t('Validation_ImageDimension', {
                    width: width,
                    height: height
                })});
            };
    
            img.onerror = () => {
                URL.revokeObjectURL(url);
                resolve({isValid: false, message: i18n.global.t('Validation_FailedToLoadImage')}); // Fail validation if image can't be loaded
            };
    
            img.src = url; // Start loading the image
        });
    };

    /**
     * Get extensions that are allowed to be uploaded. The full list can be limited by passing in specific file types to allow.
     */
    private getAllowedExtensions(allowedFileTypes?: ArtifactFileTypeCode[]): string[] {
        if (allowedFileTypes) {
            return Object.keys(this.allowedFileExtensions).filter((ext) =>
                allowedFileTypes.includes(this.allowedFileExtensions[ext].fileTypeCode),
            );
        } else {
            return Object.keys(this.allowedFileExtensions);
        }
    }

    /**
     * Get the extension of a file name.
     */
    private getFileExtension(fileName: string): string {
        return fileName.slice(fileName.lastIndexOf('.')).toLowerCase();
    }
}

export default new FileService();
