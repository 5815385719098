import {defineStore} from 'pinia';

export const useApplyWorkTaskPlanModalStore = defineStore('applyWorkTaskPlanModal', {
    state: () => ({
        isVisible: false,
        workTaskPlanId: null as number | null,
        savedCounter: 0,
    }),
    actions: {
        open(workTaskPlanId: number | null) {
            // Close modal first (if it's already open) so that the watch is triggered when it's reopened
            this.close();

            this.workTaskPlanId = workTaskPlanId;
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
            this.workTaskPlanId = null;
        },
    },
});
