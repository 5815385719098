import {ObservationActionTypeCode} from '@/enums/observation-action-type-code';
import {ObservationStatusCode} from '@/enums/observaton-status-code';

export class ActionObservationForm {
    observationActionTypeCode: ObservationActionTypeCode | null = null;
    observationActionComments: string | null = null;
    observationStatusCode: ObservationStatusCode | null = null;
    observationId: number | null = null;
    observationActionUserId: number | null = null;
    artifacts: File[] = [];
    haveArtifactsBeenUpdated = false;

    constructor(data?: Partial<ActionObservationForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
