import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "footerRow"
}
const _hoisted_2 = {
  class: "table",
  style: {"margin-left":"auto","margin-right":"auto"}
}
const _hoisted_3 = { class: "tableCell" }
const _hoisted_4 = { style: {"margin-top":"5px"} }
const _hoisted_5 = { class: "utilityName" }
const _hoisted_6 = {
  key: 1,
  class: "swatchG1BG",
  style: {"height":"2px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.isUserLoggedIn)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createVNode(_component_router_link, { to: "info?page=privacy" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Privacy')), 1)
                ]),
                _: 1
              }),
              _createTextVNode(" | "),
              _createVNode(_component_router_link, { to: "info?page=about" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('AboutSystemName', {systemName: _ctx.configStore.systemName})), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_router_link, { to: "info?page=copyright" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Copyright')) + " ©", 1)
                ]),
                _: 1
              }),
              _createTextVNode(" 2024 "),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.configStore.utilityNameFull), 1)
            ])
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_6))
}