<template>
    <v-tooltip bottom>
        <template v-slot:activator="{props}">
            <v-chip
                v-bind="props"
                variant="flat"
                :size="size"
                density="comfortable"
                :color="lighterColor"
                pill
                class="pr-5 noSelection">
                <v-avatar start>
                    <CircleIcon :style="{height: '40px', width: '40px'}" :class="iconClass">
                        <FontAwesomeIcon :icon="['fal', 'list-timeline']" :size="iconSize" />
                    </CircleIcon>
                </v-avatar>

                <div class="chip-text">
                    <span>{{ numberOfWorkTaskPlans?.toLocaleString() }}</span>
                </div>
            </v-chip>
        </template>
        <slot name="helpText"></slot>
        {{ tooltipMessage }}
    </v-tooltip>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue';
import {getSwatchColor, lightenColor} from '@/helpers/SwatchHelper';
import {SwatchCode} from '@/enums/swatch-code';
import i18n from '@/i18n';

export default defineComponent({
    props: {
        numberOfWorkTaskPlans: Number,
        size: {
            type: String,
            default: 'default',
        },
        iconSize: {
            type: String,
            default: null,
        },
    },
    setup(props) {
        const color = computed<SwatchCode>(() => {
            return SwatchCode.SwatchA1;
        });

        const lighterColor = computed<string>(() => {
            const hexColor = getSwatchColor(color.value);
            return lightenColor(hexColor, 15);
        });

        const iconClass = computed<string>(() => {
            return `bg-${color.value}`;
        });

        const tooltipMessage = computed<string>(() => {
            if (props.numberOfWorkTaskPlans == 1) {
                return i18n.global.t('TemplateNumberOfTaskPlansSingular');
            } else {
                return i18n.global.t('TemplateNumberOfTaskPlansPlural', {
                    numberOfWorkTaskPlans: props.numberOfWorkTaskPlans?.toLocaleString(),
                });
            }
        });

        return {
            iconClass,
            lighterColor,
            tooltipMessage,
        };
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.chip-text {
    font-size: 12pt;
    color: $swatchWHT;
}
</style>
