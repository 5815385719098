<template>
    <v-form ref="form" v-model="valid">
        <div v-if="!isLoadingDueDateControl" style="display: flex; align-items: center">
            <v-select
                v-model="localWorkTask.dueDateTiming"
                :rules="[requiredRule]"
                required
                :items="workTaskDueDateTimings"
                item-title="title"
                item-value="value"
                max-width="250px"
                class="swatchA1 font-weight-bold"></v-select>

            <div class="ml-2">
                <!-- DatePicker for: DueOn -->
                <VueDatePicker
                    v-if="localWorkTask.dueDateTiming == WorkTaskDueDateTiming.DueOn"
                    v-model="localWorkTask.dueDateOn"
                    :rules="[requiredRule]"
                    required
                    format="dd/MM/yyyy"
                    :enable-time-picker="false"
                    text-input
                    style="max-width: 170px"
                    class="swatchA1 font-weight-medium"
                    :teleport="true"
                    :locale="i18n.global.locale" />

                <!-- DatePicker for: DueBy -->
                <VueDatePicker
                    v-if="localWorkTask.dueDateTiming == WorkTaskDueDateTiming.DueBy"
                    v-model="localWorkTask.dueDateLatest"
                    :rules="[requiredRule]"
                    required
                    format="dd/MM/yyyy"
                    :enable-time-picker="false"
                    text-input
                    style="max-width: 170px"
                    class="swatchA1 font-weight-medium"
                    :teleport="true"
                    :locale="i18n.global.locale" />

                <!-- DatePicker for: DueBetween -->
                <VueDatePicker
                    v-else-if="localWorkTask.dueDateTiming == WorkTaskDueDateTiming.DueBetween"
                    v-model="localWorkTask.dueDateRange"
                    :rules="[requiredRule]"
                    required
                    format="dd/MM/yyyy"
                    :enable-time-picker="false"
                    text-input
                    range
                    style="max-width: 270px"
                    class="swatchA1 font-weight-medium"
                    :teleport="true"
                    :locale="i18n.global.locale" />

                <!-- DatePicker for: DueInMonth -->
                <VueDatePicker
                    v-else-if="localWorkTask.dueDateTiming == WorkTaskDueDateTiming.DueInMonth"
                    v-model="localWorkTask.dueDateMonth"
                    :rules="[requiredRule]"
                    required
                    format="yyyy MMMM"
                    month-picker
                    :enable-time-picker="false"
                    style="max-width: 270px"
                    class="swatchA1 font-weight-medium"
                    :teleport="true"
                    :locale="i18n.global.locale" />

                <!-- DatePicker Validation -->
                <v-validation
                    v-if="
                        localWorkTask.dueDateTiming == WorkTaskDueDateTiming.DueOn ||
                        localWorkTask.dueDateTiming == WorkTaskDueDateTiming.DueBy ||
                        localWorkTask.dueDateTiming == WorkTaskDueDateTiming.DueBetween ||
                        localWorkTask.dueDateTiming == WorkTaskDueDateTiming.DueInMonth
                    "
                    :rules="[dateRequiredRule, dateNotInPastRule]">
                    <template v-slot="{errorMessages, isValid}">
                        <div v-if="isValid.value != null">
                            <span v-for="(error, index) in errorMessages.value" :key="index" class="error-message">
                                {{ error }}
                            </span>
                        </div>
                    </template>
                </v-validation>

                <div v-if="localWorkTask.dueDateTiming == WorkTaskDueDateTiming.DueAfterTask">
                    <div style="display: flex; align-items: center">
                        <v-select
                            v-model="localWorkTask.dueAfterWorkTaskId"
                            :rules="[requiredRule]"
                            required
                            :placeholder="dueAfterTaskLabel"
                            :items="predecessorWorkTasks"
                            item-value="workTaskId"
                            width="450px"
                            class="swatchA1 font-weight-bold">
                            <template #item="{props, item}">
                                <v-list-item v-bind="props" :title="null">
                                    <WorkTaskSelectItem :work-task="item.raw" />
                                </v-list-item>
                            </template>
                            <template v-slot:selection="{item}">
                                <WorkTaskSelectItem :work-task="item.raw" />
                            </template>
                        </v-select>

                        <span class="ml-2">{{ $t('Within') }}</span>

                        <v-text-field
                            v-model="localWorkTask.dueAfterWorkTaskWithinDays"
                            :rules="[requiredRule, numericRule, wholeNumberRule]"
                            required
                            :suffix="$t('Days')"
                            width="100px"
                            class="ml-4 swatchA1 font-weight-bold">
                        </v-text-field>
                    </div>

                    <!-- Due Date Validation -->
                    <v-validation :rules="[dateNotInPastRule]">
                        <template v-slot="{errorMessages, isValid}">
                            <div v-if="isValid.value != null">
                                <span v-for="(error, index) in errorMessages.value" :key="index" class="error-message">
                                    {{ error }}
                                </span>
                            </div>
                        </template>
                    </v-validation>
                </div>
            </div>
        </div>
    </v-form>
</template>

<script setup lang="ts">
import {computed, defineProps, inject, nextTick, onMounted, ref, watch} from 'vue';
import {ManageWorkTaskForm} from '@/models/work-tasks/ManageWorkTaskForm';
import {useWorkTaskDueDateTimings} from '@/composables/data-source/work-task-due-date-timings';
import {WorkTaskDueDateTiming} from '@/enums/work-task-due-date-timing';
import {addDays, endOfMonth, isAfter, isEqual, startOfToday} from 'date-fns';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import i18n from '@/i18n';
import Validation from '@/helpers/ValidationHelper';
import {GetPredecessorWorkTasksResponse} from '@/models/api/responses/work-tasks/GetPredecessorWorkTasksResponse';
import {ApplyWorkTaskPlanForm} from '@/models/work-tasks/ApplyWorkTaskPlanForm';
import {GetPredecessorWorkTasksRequest} from '@/models/api/requests/work-tasks/GetPredecessorWorkTasksRequest';
import WorkTaskSelectItem from '@/components/WorkTaskSelectItem.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import ApiService from '@/services/api-service';
import {RescheduleWorkTaskForm} from '@/models/work-tasks/RescheduleWorkTaskForm';
const {setWorkTaskDueDateTimings, workTaskDueDateTimings} = useWorkTaskDueDateTimings();

// Props
const props = defineProps<{
    workTask: ManageWorkTaskForm | ApplyWorkTaskPlanForm | RescheduleWorkTaskForm;
    workTaskTypeCode: WorkTaskTypeCode | null | undefined;
    isLoading?: boolean;
}>();

// Services
const apiService = inject('apiService') as ApiService;

// Form data
const form = ref();
const valid = ref<boolean>(true);
const isUpdatingLocalObject = ref(false);
const localWorkTask = ref({...props.workTask});
const predecessorWorkTasks = ref<GetPredecessorWorkTasksResponse[]>([]);
const isLoadingDueDateControl = ref(true);

// Emits
const emit = defineEmits(['update:workTask']);

// Rules
const requiredRule = Validation.createRule_Required();
const numericRule = Validation.createRule_Numeric(i18n.global.t('Validation_MustBeNonNegative'), 0);
const wholeNumberRule = Validation.createRule_WholeNumber(null);
const dateRequiredRule = () => {
    switch (localWorkTask.value.dueDateTiming) {
        case WorkTaskDueDateTiming.DueOn: {
            if (localWorkTask.value.dueDateOn !== null && localWorkTask.value.dueDateOn !== undefined) {
                return true;
            }

            break;
        }
        case WorkTaskDueDateTiming.DueBy: {
            if (localWorkTask.value.dueDateLatest !== null && localWorkTask.value.dueDateLatest !== undefined) {
                return true;
            }

            break;
        }

        case WorkTaskDueDateTiming.DueBetween: {
            if (
                localWorkTask.value.dueDateRange !== null &&
                localWorkTask.value.dueDateRange !== undefined &&
                localWorkTask.value.dueDateRange.length == 2 &&
                localWorkTask.value.dueDateRange[0] !== null &&
                localWorkTask.value.dueDateRange[1] !== null
            ) {
                return true;
            }

            break;
        }

        case WorkTaskDueDateTiming.DueInMonth: {
            if (localWorkTask.value.dueDateMonth !== null && localWorkTask.value.dueDateMonth !== undefined) {
                return true;
            }

            break;
        }
    }

    return i18n.global.t('Validation_Required');
};
const dateNotInPastRule = () => {
    // Irrigate and Harvest tasks can be in the past
    if (props.workTaskTypeCode == WorkTaskTypeCode.Irrigate || props.workTaskTypeCode == WorkTaskTypeCode.Harvest) {
        return true;
    }

    // Get the date to validate
    let dueDateLatest: Date | null = null;
    switch (localWorkTask.value.dueDateTiming) {
        case WorkTaskDueDateTiming.DueOn: {
            dueDateLatest = localWorkTask.value.dueDateOn;

            break;
        }
        case WorkTaskDueDateTiming.DueBy: {
            dueDateLatest = localWorkTask.value.dueDateLatest;

            break;
        }

        case WorkTaskDueDateTiming.DueBetween: {
            if (localWorkTask.value.dueDateRange && localWorkTask.value.dueDateRange.length == 2)
                dueDateLatest = localWorkTask.value.dueDateRange[1];

            break;
        }

        case WorkTaskDueDateTiming.DueInMonth: {
            if (localWorkTask.value.dueDateMonth) {
                // Get start of month
                dueDateLatest = new Date(
                    localWorkTask.value.dueDateMonth.year,
                    localWorkTask.value.dueDateMonth.month,
                    1,
                );

                // Set date to end of month
                dueDateLatest = endOfMonth(dueDateLatest);
            }

            break;
        }

        case WorkTaskDueDateTiming.DueAfterTask: {
            if (localWorkTask.value.dueAfterWorkTaskId && localWorkTask.value.dueAfterWorkTaskWithinDays) {
                // Get the selected predecessor task
                const predecessorWorkTask = predecessorWorkTasks.value.find(
                    (wt) => wt.workTaskId == localWorkTask.value.dueAfterWorkTaskId,
                );

                if (predecessorWorkTask) {
                    // Get the due date of the selected predecessor task
                    const predecessorWorkTaskDueDate =
                        predecessorWorkTask.completedDate ??
                        predecessorWorkTask.cancelledDate ??
                        predecessorWorkTask.dueDate;

                    if (predecessorWorkTaskDueDate) {
                        // Safely parse the date
                        const safeDateString = predecessorWorkTaskDueDate.toString().replace(/(\.\d{3})\d+/, '$1');
                        const parsedDate = new Date(safeDateString);

                        if (isNaN(parsedDate.getTime())) {
                            console.error('Parsed Date is invalid:', parsedDate);
                        } else {
                            // Ensure days to add is a valid number
                            const daysToAdd = Number(localWorkTask.value.dueAfterWorkTaskWithinDays);

                            if (isNaN(daysToAdd)) {
                                console.error('Invalid number of days to add:', daysToAdd);
                            } else {
                                // Calculate the due date for this task
                                dueDateLatest = addDays(parsedDate, daysToAdd);
                            }
                        }
                    }
                }
            }

            break;
        }
    }

    // If not due date, return true because the "required field" validation should be triggered instead
    if (!dueDateLatest) return true;

    // Check if date is on or after today
    const today = startOfToday();
    if (isAfter(dueDateLatest, today) || isEqual(dueDateLatest, today)) {
        return true;
    }

    return i18n.global.t('Validation_MustNotBeInThePast');
};

/**
 * Get all work tasks that could be a predecessor for this task when the due date is dependent upon
 * another tasking being completed.
 */
const getPredecessorWorkTasks = async () => {
    // If no field is selected, return early
    if (!localWorkTask.value.farmFieldId) return;

    // Call API to get predecessor tasks
    const request: GetPredecessorWorkTasksRequest = {
        dependentWorkTaskId: hasWorkTaskId(localWorkTask.value) ? localWorkTask.value.workTaskId : null,
        farmFieldId: localWorkTask.value.farmFieldId,
        workTaskId: null,
    };
    const response = (await apiService.get(
        'work-tasks/predecessor-work-tasks',
        request,
    )) as GetPredecessorWorkTasksResponse[];

    // Add null value
    response.unshift({
        workTaskId: null,
        workTaskTypeName: '',
    });

    predecessorWorkTasks.value = response;
};

/**
 * The label for the "due after task" select field.
 */
const dueAfterTaskLabel = computed(() => {
    if (!localWorkTask.value.farmFieldId) {
        return i18n.global.t('SelectAFieldToPopulateTheTaskList');
    } else {
        return null;
    }
});

/**
 * Flag to indicate if the passed object has a workTaskId property.
 */
const hasWorkTaskId = (
    workTask: ApplyWorkTaskPlanForm | ManageWorkTaskForm | RescheduleWorkTaskForm,
): workTask is ManageWorkTaskForm | RescheduleWorkTaskForm => {
    return 'workTaskId' in workTask;
};

/**
 * Validate the form.
 */
const validate = async (): Promise<boolean> => {
    if (form.value) {
        const {valid} = await form.value.validate();
        return valid;
    }
    return false;
};

// Refresh list of predecessor tasks when farm field changes.
watch(
    () => localWorkTask.value.farmFieldId,
    async () => {
        if (!props.isLoading) {
            // Set selected predecessor task back to null
            localWorkTask.value.dueAfterWorkTaskId = null;

            // Get predecessor tasks for this farm field
            await getPredecessorWorkTasks();
        }
    },
);

// When the parent object is changed, apply these changes to the local object.
watch(
    () => props.workTask,
    async () => {
        if (!isUpdatingLocalObject.value) {
            localWorkTask.value = {...props.workTask};
        }
    },
    {deep: true},
);

// When the local object is changed, apply these changes to the parent object.
watch(
    () => localWorkTask,
    async () => {
        isUpdatingLocalObject.value = true;
        emit('update:workTask', localWorkTask.value);

        // No longer updating local object (nextTick is used to ensure that the "apply changes to local object" watch is not triggered)
        nextTick(() => {
            isUpdatingLocalObject.value = false;
        });
    },
    {deep: true},
);

onMounted(async () => {
    isLoadingDueDateControl.value = true;

    // Load dropdown data
    await setWorkTaskDueDateTimings();
    await getPredecessorWorkTasks();

    // End loading
    isLoadingDueDateControl.value = false;
});

defineExpose({validate});
</script>
