import {GetObservationTypesResponse} from '@/models/api/responses/data-source/GetObservationTypesResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useObservationTypes() {
    const apiService = inject('apiService') as ApiService;
    const observationTypes = ref<GetObservationTypesResponse[]>([]);
    const observationTypesWithNull = ref<SelectListItem[]>([]);
    const acknowledgedObservationTypeCodes = ref(['Note', 'GoodYield', 'Measurement', 'Photo', 'PoorYield']);

    /**
     * Get list of observation types.
     */
    const getObservationTypes = async () => {
        observationTypes.value = await apiService.get('data-source/observation-types');

        // Add null value
        observationTypesWithNull.value = observationTypes.value.map((observationType) => ({
            value: observationType.observationTypeCode,
            title: observationType.observationTypeName,
        }));

        observationTypesWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getObservationTypes,
        observationTypes,
        observationTypesWithNull,
        acknowledgedObservationTypeCodes,
    };
}
