<template>
    <div
        v-if="getDaysUntilTaskIsDue(workTask.dueDate) > 0 && !workTask.isCompleted && !workTask.isCancelled"
        class="task-date-chip">
        {{ getDaysUntilTaskIsDue(workTask.dueDate) }}
        {{ getDaysUntilTaskIsDue(workTask.dueDate) != 1 ? $t('Days') : $t('Day') }}
    </div>
</template>

<script setup lang="ts">
import {defineProps} from 'vue';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';

defineProps<{
    workTask: WorkTaskSearchResultDto;
}>();

// Gets the days until a task is overdue
const getDaysUntilTaskIsDue = (dueDate: Date): number => {
    const today = new Date();
    const nextDate = new Date(dueDate);
    let timeDifference = nextDate.getTime() - today.getTime();
    let dayDifference = Math.round(timeDifference / (1000 * 3600 * 24));
    return dayDifference;
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.task-date-chip {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    height: 24px;
    background-color: $swatchERR;
    color: $swatchWHT;
    text-align: center;
    border-radius: 3px;
    font-family: 'Open Sans Condensed', sans-serif;
}
</style>
