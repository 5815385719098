export enum ArtifactFolder {
    CropVarieties = 'CropVarieties',
    FarmFieldPolygons = 'FarmFieldPolygons',
    Mixtures = 'Mixtures',
    Products = 'Products',
    Observations = 'Observations',
    UserPhotos = 'UserPhotos',
    WorkSubTasks = 'WorkSubTasks',
    WorkTasks = 'WorkTasks',
}
