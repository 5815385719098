import {ArtifactFileTypeCode} from '@/enums/artifact-file-type-code';

export interface FileExtensionInfo {
    fileTypeCode: ArtifactFileTypeCode;
    maxSizeInMegabytes: number;
}

export function createFileExtensionInfo(fileTypeCode: ArtifactFileTypeCode): FileExtensionInfo {
    let maxSizeInMegabytes: number;

    switch (fileTypeCode) {
        case ArtifactFileTypeCode.Compressed:
            maxSizeInMegabytes = 256;
            break;
        case ArtifactFileTypeCode.Csv:
            maxSizeInMegabytes = 16;
            break;
        case ArtifactFileTypeCode.Excel:
            maxSizeInMegabytes = 16;
            break;
        case ArtifactFileTypeCode.Image:
            maxSizeInMegabytes = 16;
            break;
        case ArtifactFileTypeCode.Pdf:
            maxSizeInMegabytes = 16;
            break;
        case ArtifactFileTypeCode.Text:
            maxSizeInMegabytes = 4;
            break;
        case ArtifactFileTypeCode.Video:
            maxSizeInMegabytes = 256;
            break;
        case ArtifactFileTypeCode.Word:
            maxSizeInMegabytes = 16;
            break;
        default:
            maxSizeInMegabytes = 99;
            break;
    }

    return {fileTypeCode, maxSizeInMegabytes: maxSizeInMegabytes};
}
