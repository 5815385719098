import {defineStore} from 'pinia';

export const useViewProductModalStore = defineStore('viewProductModal', {
    state: () => ({
        isVisible: false,
        productId: null as number | null,
    }),
    actions: {
        open(productId: number) {
            // Close modal first (if it's already open) so that the watch is triggered when it's reopened
            this.close();

            if (productId !== null) {
                this.productId = productId;
                this.isVisible = true;
            }
        },
        close() {
            this.isVisible = false;
            this.productId = null;
        },
    },
});
