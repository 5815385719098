import {defineStore} from 'pinia';

export const useViewWorkTaskTemplateModalStore = defineStore('viewWorkTaskTemplateModal', {
    state: () => ({
        isVisible: false,
        workTaskTemplateId: null as number | null,
        isPrinting: false,
    }),
    actions: {
        open(workTaskTemplateId: number, isPrinting: boolean = false) {
            // Close modal first (if it's already open) so that the watch is triggered when it's reopened
            this.close();

            this.workTaskTemplateId = workTaskTemplateId;
            this.isVisible = true;
            this.isPrinting = isPrinting;
        },
        close() {
            this.isVisible = false;
            this.workTaskTemplateId = null;
            this.isPrinting = false;
        },
    },
});
