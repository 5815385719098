<template>
    <LoadingSymbol v-if="isLoading" />

    <table v-else style="width: 100%" class="tblForm">
        <tr>
            <td colspan="4" class="subHeader">{{ $t('Particulars') }}</td>
        </tr>
        <tr>
            <td class="tableCell padding" style="width: 10%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('FarmSite(s)') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_FarmSites_HelpText')" />
                </div>
            </td>
            <td style="width: 40%">
                <v-select
                    v-model="localSearchParameters.farmSiteIdList"
                    hide-details
                    multiple
                    clearable
                    chips
                    :items="farmSites"
                    item-title="farmSiteAbbreviationAndName"
                    item-value="farmSiteId"
                    max-width="400px"></v-select>
            </td>
            <td class="tableCell padding" style="width: 10%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('ObservationName') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_ObservationName_HelpText')" />
                </div>
            </td>
            <td style="width: 40%">
                <v-text-field
                    v-model="localSearchParameters.observationName"
                    hide-details
                    clearable
                    style="width: 400px">
                </v-text-field>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('ObservationType') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_ObservationType_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.observationTypeCodeList"
                        hide-details
                        multiple
                        clearable
                        chips
                        :items="observationTypes"
                        item-title="observationTypeName"
                        item-value="observationTypeCode"
                        max-width="400px"></v-select>
                </div>
            </td>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('ObservationStatus') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_ObservationStatus_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.observationStatusCode"
                        hide-details
                        :items="observationStatusesWithNull"
                        item-title="title"
                        item-value="value"
                        max-width="400px"></v-select>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="4" class="subHeader">{{ $t('AttributesAndAssociations') }}</td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('DateRaised') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_DateRaised_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <VueDatePicker
                        v-model="dateRaised"
                        range
                        format="dd/MM/yyyy"
                        :enable-time-picker="false"
                        text-input
                        :teleport="true"
                        :preset-dates="presetDates"
                        style="max-width: 300px"
                        :locale="i18n.global.locale" />
                </div>
            </td>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('DateLastActivity') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_DateLastActivity_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <VueDatePicker
                        v-model="dateLastActivity"
                        :teleport="true"
                        range
                        format="dd/MM/yyyy"
                        :enable-time-picker="false"
                        text-input
                        :preset-dates="presetDates"
                        style="max-width: 300px"
                        :locale="i18n.global.locale" />
                </div>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('DateConcluded') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_DateConcluded_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <VueDatePicker
                        v-model="dateConcluded"
                        :teleport="true"
                        range
                        format="dd/MM/yyyy"
                        :enable-time-picker="false"
                        text-input
                        :preset-dates="presetDates"
                        style="max-width: 300px"
                        :locale="i18n.global.locale" />
                </div>
            </td>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('User') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_User_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-autocomplete
                        v-model="localSearchParameters.userIdList"
                        :items="users"
                        hide-details
                        multiple
                        clearable
                        chips
                        item-title="userFullName"
                        item-value="userId"
                        max-width="400px"></v-autocomplete>
                </div>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('Field') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_Field_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-autocomplete
                        v-model="localSearchParameters.farmFieldIdList"
                        :items="farmFields"
                        hide-details
                        multiple
                        clearable
                        chips
                        item-title="farmFieldCodeAndName"
                        item-value="farmFieldId"
                        max-width="400px"></v-autocomplete>
                </div>
            </td>
        </tr>
        <tr v-if="displayExportOptions">
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('ExportTo') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_ExportResultsTo_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.isExportCSV"
                        hide-details
                        :items="exportOptions"
                        item-title="title"
                        item-value="value"
                        max-width="400px"></v-select>
                </div>
            </td>
        </tr>
    </table>
</template>

<script setup lang="ts">
import {defineAsyncComponent, onMounted, ref, watch, computed} from 'vue';
import {SearchObservationsRequest} from '@/models/api/requests/search/SearchObservationsRequest';
import {addDays, addMonths, format} from 'date-fns';
import {useFarmSites} from '@/composables/data-source/farm-sites';
const {getFarmSites, farmSites} = useFarmSites();
import {useFarmFields} from '@/composables/data-source/farm-fields';
const {getFarmFields, farmFields} = useFarmFields();
import {useObservationTypes} from '@/composables/data-source/observation-types';
const {getObservationTypes, observationTypes} = useObservationTypes();
import {useObservationStatuses} from '@/composables/data-source/observation-statuses';
import i18n from '@/i18n';
const {getObservationStatuses, observationStatusesWithNull} = useObservationStatuses();
import {useUsers} from '@/composables/data-source/users';
const {getUsers, users} = useUsers();

const VueDatePicker = defineAsyncComponent(() => import('@vuepic/vue-datepicker'));
const props = withDefaults(
    defineProps<{
        searchParameters: SearchObservationsRequest;
        displayExportOptions?: boolean;
    }>(),
    {
        searchParameters: undefined,
        displayExportOptions: false,
    },
);
const emit = defineEmits(['update:searchParameters']);
const isLoading = ref<boolean>(false);
const dateRaised = ref<Array<Date> | null>(null);
const dateLastActivity = ref<Array<Date> | null>(null);
const dateConcluded = ref<Array<Date> | null>(null);

//Export results options
const exportOptions = computed(() => [
    {
        title: i18n.global.t('CSVFile'),
        value: true,
    },
    {
        title: i18n.global.t('Screen'),
        value: false,
    },
]);

// Create a local copy of the prop
const localSearchParameters = ref({...props.searchParameters});

//The present date for the date picker
const today = new Date();
const presetDates = ref([
    {
        label: i18n.global.t('Last7Days'),
        value: [addDays(today, -6), today],
    },
    {
        label: i18n.global.t('Last14Days'),
        value: [addDays(today, -13), today],
    },
    {
        label: i18n.global.t('Last30Days'),
        value: [addDays(today, -29), today],
    },
    {
        label: i18n.global.t('Last3Months'),
        value: [addMonths(today, -3), today],
    },
    {
        label: i18n.global.t('Last6Months'),
        value: [addMonths(today, -6), today],
    },
    {
        label: i18n.global.t('Last9Months'),
        value: [addMonths(today, -9), today],
    },
    {
        label: i18n.global.t('Last12Months'),
        value: [addMonths(today, -12), today],
    },
]);

/**
 * Reset the form to its default state.
 */
const resetLocalParameters = () => {
    dateRaised.value = [];
    dateLastActivity.value = [];
    dateConcluded.value = [];
};

/**
 * Gets the From date (first date) from a date range array.
 */
const getFromDate = (dates: Array<Date> | null) => {
    // If there is a From date
    if (dates != null && dates.length >= 1 && dates[0] != null) {
        // Return From date
        return format(dates[0], 'yyyy-MM-dd');
    }

    return null;
};

/**
 * Gets the To date (second date) from a date range array.
 */
const getToDate = (dates: Array<Date> | null) => {
    // If there is a To date
    if (dates != null && dates.length >= 2 && dates[1] != null) {
        // Return To date
        return format(dates[1], 'yyyy-MM-dd');
    }

    return null;
};

// Watch for changes in the dateRaised object and update local parameters
watch(
    () => dateRaised.value,
    (newVal) => {
        localSearchParameters.value.dateRaisedFrom = getFromDate(newVal);
        localSearchParameters.value.dateRaisedTo = getToDate(newVal);
    },
);

// Watch for changes in the dateLastActivity object and update local parameters
watch(
    () => dateLastActivity.value,
    (newVal) => {
        localSearchParameters.value.dateLastActivityFrom = getFromDate(newVal);
        localSearchParameters.value.dateLastActivityTo = getToDate(newVal);
    },
);

// Watch for changes in the dateConcluded object and update local parameters
watch(
    () => dateConcluded.value,
    (newVal) => {
        localSearchParameters.value.dateConcludedFrom = getFromDate(newVal);
        localSearchParameters.value.dateConcludedTo = getToDate(newVal);
    },
);

// Watch for changes in the parent parameters object and update local copy
watch(
    () => props.searchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(localSearchParameters.value)) {
            if (newVal.dateRaisedFrom === null || newVal.dateRaisedTo === null) dateRaised.value = [];
            else {
                dateRaised.value = [new Date(newVal.dateRaisedFrom), new Date(newVal.dateRaisedTo)];
            }
            if (newVal.dateLastActivityTo === null || newVal.dateLastActivityFrom === null) dateLastActivity.value = [];
            else {
                dateLastActivity.value = [new Date(newVal.dateLastActivityFrom), new Date(newVal.dateLastActivityTo)];
            }
            if (newVal.dateConcludedTo === null || newVal.dateConcludedFrom === null) dateConcluded.value = [];
            else {
                dateConcluded.value = [new Date(newVal.dateConcludedFrom), new Date(newVal.dateConcludedTo)];
            }
            localSearchParameters.value = {...newVal};
        }
    },
    {deep: true, immediate: true},
);

// Watch for changes in the local copy and emit the update event
watch(
    localSearchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(props.searchParameters)) {
            emit('update:searchParameters', newVal);
        }
    },
    {deep: true},
);

// Fetch data for dropdown
onMounted(async () => {
    isLoading.value = true;

    // reset date range object to an empty array when the any of the date range parameters passed are nulls.
    if (props.searchParameters.dateRaisedFrom === null || props.searchParameters.dateRaisedTo === null)
        dateRaised.value = [];
    else {
        //Convert the passed parameter string to a two-date-object array to show value on the date picker
        dateRaised.value = [
            new Date(props.searchParameters.dateRaisedFrom),
            new Date(props.searchParameters.dateRaisedTo),
        ];
    }

    // reset date range object to an empty array when the any of the date range parameters passed are nulls.
    if (props.searchParameters.dateLastActivityTo === null || props.searchParameters.dateLastActivityFrom === null)
        dateLastActivity.value = [];
    else {
        //Convert the passed parameter string to a two-date-object array to show value on the date picker
        dateLastActivity.value = [
            new Date(props.searchParameters.dateLastActivityFrom),
            new Date(props.searchParameters.dateLastActivityTo),
        ];
    }

    // reset date range object to an empty array when the any of the date range parameters passed are nulls.
    if (props.searchParameters.dateConcludedTo === null || props.searchParameters.dateConcludedFrom === null)
        dateConcluded.value = [];
    else {
        //Convert the passed parameter string to a two-date-object array to show value on the date picker
        dateConcluded.value = [
            new Date(props.searchParameters.dateConcludedFrom),
            new Date(props.searchParameters.dateConcludedTo),
        ];
    }

    // Get data
    await getFarmSites();
    await getFarmFields();
    await getObservationTypes();
    await getObservationStatuses();
    await getUsers();

    isLoading.value = false;
});

// Expose reset function
defineExpose({resetLocalParameters});
</script>
