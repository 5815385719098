import {WorkTaskTypeCode} from '@/enums/work-task-type-code';

export class WorkTaskTemplateSearchParametersDto {
    workTaskTemplateId: number | null = null;
    workTaskTemplateIdList: number[] | null = null;
    WorkTaskTemplateName: string | null = null;
    workTaskTypeCodeList: WorkTaskTypeCode[] | null = null;
    workTaskPlanIdList: number[] | null = [];
    includeArtifacts = false;
    includeWorkTaskTemplateSubTasks = false;
    includeWorkTaskTemplateMixtures = false;
    includeWorkTaskTemplateProducts = false;
    includeWorkTaskTemplateCropVarietyPreferences = false;

    constructor(data?: Partial<WorkTaskTemplateSearchParametersDto>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
