<template>
    <table class="tblForm" style="width: 100%">
        <tr v-if="showDefaultTaskName">
            <td>
                {{ $t('DefaultTaskName') }}
            </td>
            <td class="swatchA1 bold">
                {{ workTaskTemplate.workTaskName }}
            </td>
        </tr>
        <tr>
            <td style="width: 12rem">
                {{ $t('Instructions') }}
            </td>
            <td class="swatchA1 bold" v-if="workTaskTemplate.workTaskInstructions">
                <TranslatableTexts :text="workTaskTemplate.workTaskInstructions" />
            </td>
        </tr>
        <tr v-if="workTaskTemplate.workTaskTypeCode == WorkTaskTypeCode.Plant">
            <td>
                {{ $t('CropToPlant') }}
            </td>
            <td class="swatchA1 bold">
                <CropIcon :cropCode="workTaskTemplate.plantCropCode" :is-show-crop-name="true" />
            </td>
        </tr>
        <tr>
            <td>
                {{ $t('Attachments') }}
            </td>
            <td class="swatchA1 bold">
                <div
                    v-for="(artifact, index) in workTaskTemplate.artifacts"
                    :key="artifact.artifactId"
                    :class="{'mt-2': index > 0}">
                    <FileTypeButton
                        :url="artifact.artifactUrl"
                        :display-name="artifact.artifactDisplayName"
                        :file-type-code="artifact.artifactFileTypeCode" />
                </div>
            </td>
        </tr>
        <tr v-if="workTaskTemplate.workTaskTypeCode == WorkTaskTypeCode.Irrigate">
            <td>
                {{ $t('IrrigationRate') }}
            </td>
            <td style="display: flex; align-items: center">
                <span class="swatchA1 bold">
                    {{ workTaskTemplate.irrigationRatePlanned }}
                </span>
                <span class="swatchA1 ml-2">ML per Ha</span>
            </td>
        </tr>
    </table>

    <!-- Cultivate: Sub-Tasks -->
    <div v-if="workTaskTemplate.workTaskTypeCode == WorkTaskTypeCode.Cultivate" class="mt-2">
        <div class="subHeader">
            {{ $t('CultivateSubTasks') }} ({{ workTaskTemplate.workTaskTemplateSubTasks.length }})
        </div>
        <ViewWorkSubTaskItem
            v-for="(workSubTask, index) in workTaskTemplate.workTaskTemplateSubTasks"
            :key="workSubTask.workTaskTemplateSubTaskId"
            :work-sub-task="workSubTask"
            :index="index" />
    </div>

    <!-- Fertilize/Spray: Products -->
    <div
        v-if="
            workTaskTemplate.workTaskTypeCode == WorkTaskTypeCode.Fertilize ||
            workTaskTemplate.workTaskTypeCode == WorkTaskTypeCode.Spray
        "
        class="mt-2">
        <div class="subHeader">{{ $t('Products') }} ({{ workTaskTemplate.workTaskTemplateProducts.length }})</div>
        <ViewWorkTaskProductItem
            v-for="(workTaskProduct, index) in workTaskTemplate.workTaskTemplateProducts"
            :key="workTaskProduct.workTaskTemplateProductId"
            :work-task-product="workTaskProduct"
            :work-task-product-list="workTaskTemplate.workTaskTemplateProducts"
            :index="index"
            :work-task-type-code="workTaskTemplate.workTaskTypeCode" />
    </div>

    <!-- Plant: Varieties, Fertilizer Products, Spray Products -->
    <div v-if="workTaskTemplate.workTaskTypeCode == WorkTaskTypeCode.Plant" class="mt-2">
        <!-- Variety Preferences -->
        <v-expansion-panels class="marginTop" v-model="expansionPanelsVarieties">
            <v-expansion-panel>
                <v-expansion-panel-title class="expansion-header">
                    {{ $t('VarietyPreferences') }} ({{
                        workTaskTemplate.workTaskTemplateCropVarietyPreferences.length
                    }})
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <ViewWorkTaskCropVarietyPreferenceItem
                        v-for="(
                            workTaskCropVarietyPreference, index
                        ) in workTaskTemplate.workTaskTemplateCropVarietyPreferences"
                        :key="workTaskCropVarietyPreference.workTaskTemplateCropVarietyPreferenceId"
                        :work-task-crop-variety-preference="workTaskCropVarietyPreference"
                        :index="index" />
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <!-- Fertilizer Products -->
        <div class="subHeader mt-2">
            {{ $t('FertiliserProductsToApplyAtPlanting') }} ({{ fertilizerWorkTaskProducts.length }})
        </div>
        <ViewWorkTaskProductItem
            v-for="(workTaskProduct, index) in fertilizerWorkTaskProducts"
            :key="workTaskProduct.workTaskTemplateProductId"
            :work-task-product="workTaskProduct"
            :work-task-product-list="fertilizerWorkTaskProducts"
            :index="index"
            :work-task-type-code="workTaskTemplate.workTaskTypeCode" />

        <!-- Spray Products -->
        <div class="subHeader mt-2">
            {{ $t('SprayProductsToApplyAtPlanting') }} ({{ chemicalWorkTaskProducts.length }})
        </div>
        <ViewWorkTaskProductItem
            v-for="(workTaskProduct, index) in chemicalWorkTaskProducts"
            :key="workTaskProduct.workTaskTemplateProductId"
            :work-task-product="workTaskProduct"
            :work-task-product-list="chemicalWorkTaskProducts"
            :index="index"
            :work-task-type-code="workTaskTemplate.workTaskTypeCode" />
    </div>
</template>

<script setup lang="ts">
import FileTypeButton from '@/components/FileTypeButton.vue';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import ViewWorkSubTaskItem from '@/views/work-tasks/ViewWorkSubTaskItem.vue';
import ViewWorkTaskProductItem from '@/views/work-tasks/ViewWorkTaskProductItem.vue';
import ViewWorkTaskCropVarietyPreferenceItem from '@/views/work-tasks/ViewWorkTaskCropVarietyPreferenceItem.vue';
import {WorkTaskTemplateSearchResultDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchResultDto';
import {computed, ref} from 'vue';
import {ProductTypeCode} from '@/enums/product-type-code';

const props = withDefaults(
    defineProps<{
        workTaskTemplate: WorkTaskTemplateSearchResultDto;
        showDefaultTaskName?: boolean;
    }>(),
    {
        showDefaultTaskName: true,
    },
);

const expansionPanelsVarieties = ref([0]);

/**
 * Fertilizer products.
 */
const fertilizerWorkTaskProducts = computed(() => {
    if (!props.workTaskTemplate) return [];

    return props.workTaskTemplate.workTaskTemplateProducts.filter(
        (wtp) =>
            (wtp.productTypeCode == ProductTypeCode.Fertilizer || wtp.productTypeCode == ProductTypeCode.Other) &&
            wtp.isFertilizer,
    );
});

/**
 * Chemical products.
 */
const chemicalWorkTaskProducts = computed(() => {
    if (!props.workTaskTemplate) return [];

    return props.workTaskTemplate.workTaskTemplateProducts.filter(
        (wtp) =>
            (wtp.productTypeCode == ProductTypeCode.Chemical || wtp.productTypeCode == ProductTypeCode.Other) &&
            wtp.isSpray,
    );
});
</script>
