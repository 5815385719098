/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {LoginResponse} from '@/models/api/responses/authentication/LoginResponse';
import axios, {AxiosInstance} from 'axios';
import router from '@/router';
import {useUserStore} from '@/stores/user-store';
import {Pinia} from 'pinia';

/**
 * Service class used to manage requests to the API.
 */
class ApiService {
    private userStore: ReturnType<typeof useUserStore>;
    baseUrl: string;
    axiosInstance: AxiosInstance;
    localStorageKeys = {
        isRefreshing: 'AGRIAPP_IS_REFRESHING',
    };

    constructor(pinia: Pinia) {
        this.userStore = useUserStore(pinia);

        this.baseUrl = this.removeTrailingForwardSlash(process.env.VUE_APP_API_BASE_URL || '');

        this.axiosInstance = axios.create({
            timeout: 600000,
        });

        this.axiosInstance.interceptors.request.use((config) => {
            config.headers.authorization = `bearer ${this.userStore.user?.accessToken}`;
            return config;
        });

        const interceptErrorResponse = this.getInterceptErrorResponseFn();
        this.axiosInstance.interceptors.response.use(this.interceptSuccessResponseStub, interceptErrorResponse);
    }

    async logout(): Promise<void> {
        if (this.userStore.user) {
            await this.post('/authentication/logout', {
                userId: this.userStore.user?.userId,
                refreshToken: this.userStore.user?.refreshToken,
            });

            this.userStore.closeAllPopups();
            this.userStore.logout();
        }

        this.clearIsRefreshing();
        router.push({name: 'UserLogin'});
    }

    isRefreshing(): boolean {
        return window.localStorage.getItem(this.localStorageKeys.isRefreshing) === 'true';
    }

    setIsRefreshing(isRefreshing: boolean): void {
        window.localStorage.setItem(this.localStorageKeys.isRefreshing, String(isRefreshing));
    }

    clearIsRefreshing(): void {
        window.localStorage.removeItem(this.localStorageKeys.isRefreshing);
    }

    validateResponse(response: any): boolean {
        if (response) {
            if (response.status === 200) {
                return true;
            } else if (response.status === 401) {
                this.logout();
            }
        }
        return false;
    }

    interceptSuccessResponseStub(response: any): any {
        return response;
    }

    getInterceptErrorResponseFn() {
        const self = this;
        return async (error: any) => {
            //Redirect user to the Locked page if they are locked out
            if (error.response.status == 423) {
                router.push({name: 'LockedOut'});
                return Promise.resolve();
            }

            const unauthorized = 401;
            if (error.response.status !== unauthorized) {
                return Promise.reject(error);
            }

            // Check if page is log out only
            const requiresUserToBeLoggedOut = router.currentRoute.value.meta.requiresUserToBeLoggedOut;

            if (requiresUserToBeLoggedOut) {
                return Promise.resolve();
            }

            const config = error.config;
            const refreshTokenEndpoint = '/authentication/refresh-token';

            if (!self.isRefreshing()) {
                if (!config.url.endsWith(refreshTokenEndpoint)) {
                    self.setIsRefreshing(true);
                    return new Promise((resolve, reject) => {
                        const requestConfig = {
                            url: `${self.baseUrl}${refreshTokenEndpoint}`,
                            method: 'post',
                            withCredentials: true,
                            data: {
                                token: self.userStore.user?.refreshToken,
                            },
                        };

                        self.axiosInstance(requestConfig)
                            .then(async (res) => {
                                const success = 200;
                                if (res.status === success) {
                                    if (self.userStore.user) {
                                        self.userStore.user.accessToken = res.data.accessToken;
                                        self.userStore.user.refreshToken = res.data.refreshToken;
                                    }

                                    resolve(self.axiosInstance(config));
                                } else {
                                    reject(self.logout());
                                }
                            })
                            .catch(() => {
                                self.logout();
                            })
                            .finally(() => {
                                self.setIsRefreshing(false);
                            });
                    });
                } else {
                    self.logout();
                }
            } else {
                if (!config.url.endsWith(refreshTokenEndpoint)) {
                    return new Promise((resolve) => {
                        const timeoutMilliseconds = 100;
                        const intervalId = setInterval(() => {
                            if (!self.isRefreshing()) {
                                clearInterval(intervalId);
                                resolve(self.axiosInstance(config));
                            }
                        }, timeoutMilliseconds);
                    });
                } else {
                    return Promise.resolve();
                }
            }
        };
    }

    removeTrailingForwardSlash(s: string): string {
        return s.replace(/\/+$/, '');
    }

    combineUrl(a: string, b: string): string {
        return `${a.replace(/\/+$/, '')}${b.startsWith('/') ? '' : '/'}${b}`;
    }

    async get(url: string, params?: any, headers?: any): Promise<any> {
        let response;
        try {
            const fullUrl = this.combineUrl(this.baseUrl, url);
            response = await this.axiosInstance.get(fullUrl, {
                params,
                headers,
                withCredentials: true,
            });
        } catch (err: any) {
            console.error(err);
            throw err;
        }

        return this.validateResponse(response) ? response.data : null;
    }

    async post(url: string, body: any, customOptions?: any): Promise<any> {
        let response;
        try {
            const fullUrl = this.combineUrl(this.baseUrl, url);

            // Check if body is FormData to set appropriate Content-Type
            const isFormData = body instanceof FormData;
            const defaultHeaders = isFormData ? {} : {'Content-Type': 'application/json'};
            const defaultOptions = {
                headers: defaultHeaders,
                withCredentials: true,
            };

            // Merge customOptions into defaultOptions
            const options = {
                ...defaultOptions,
                ...customOptions,
                headers: {
                    ...defaultHeaders,
                    ...(customOptions?.headers || {}),
                },
            };

            // If body is FormData, delete Content-Type header to allow browser to set it
            if (isFormData && options.headers['Content-Type']) {
                delete options.headers['Content-Type'];
            }

            // Call API
            response = await this.axiosInstance.post(fullUrl, body, options);
        } catch (err: any) {
            console.error(err);
            throw err;
        }

        return this.validateResponse(response) ? response.data : null;
    }

    //Create a new page and downlaod the file
    downLoadBlob(response: axios.AxiosResponse, downloadFileName?: string) {
        const blob = new Blob([response.data], {type: 'text/csv;charset=utf-8;'});

        const fileName = downloadFileName ?? 'export.csv';

        const urlObject = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlObject;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(urlObject);
    }

    //GET request downloading files
    async downloadFile(url: string, params?: any, headers?: any, downloadFileName?: string): Promise<void> {
        try {
            const fullUrl = this.combineUrl(this.baseUrl, url);

            const response = await this.axiosInstance.get(fullUrl, {
                params,
                headers: {
                    ...headers,
                    Accept: 'text/csv',
                },
                responseType: 'blob',
                withCredentials: true,
            });

            this.downLoadBlob(response, downloadFileName);
        } catch (err: any) {
            console.error('Error downloading file:', err);
            throw err;
        }
    }

    // POST request downloading files
    async downloadFilePost(url: string, data?: any, headers?: any, downloadFileName?: string): Promise<void> {
        try {
            const fullUrl = this.combineUrl(this.baseUrl, url);

            const response = await this.axiosInstance.post(fullUrl, data, {
                headers: {
                    ...headers,
                    Accept: 'text/csv',
                },
                responseType: 'blob',
                withCredentials: true,
            });

            this.downLoadBlob(response, downloadFileName);
        } catch (err: any) {
            console.error('Error downloading file:', err);
            throw err;
        }
    }

    async login(username: string, password: string, rememberMe: boolean): Promise<boolean> {
        this.userStore.logout();
        this.setIsRefreshing(false);

        const response: LoginResponse = await this.post('/authentication/login', {
            username,
            password,
            rememberMe,
        });

        this.userStore.user = response;

        return this.userStore.user != null;
    }
}

export default ApiService;
