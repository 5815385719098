import {defineStore} from 'pinia';

export const useViewWorkTaskModalStore = defineStore('viewWorkTaskModal', {
    state: () => ({
        isVisible: false,
        workTaskId: null as number | null,
        isPrinting: false,
    }),
    actions: {
        open(workTaskId: number, isPrinting: boolean = false) {
            // Close modal first (if it's already open) so that the watch is triggered when it's reopened
            this.close();

            this.workTaskId = workTaskId;
            this.isVisible = true;
            this.isPrinting = isPrinting;
        },
        close() {
            this.isVisible = false;
            this.workTaskId = null;
            this.isPrinting = false;
        },
    },
});
