import {Point} from '@/models/generic/Point';

export class ManageObservationForm {
    observationId: number | null = null;
    observationTypeCode: string | null = null;
    location: Point | null = null;
    farmFieldId: number | null = null;
    summary: string | null = null;
    behalfUserId: number | null = null;
    dateCreated: Date | null = null;
    details: string | null = null;
    artifacts: File[] = [];
    haveArtifactsBeenUpdated = false;
    constructor(data?: Partial<ManageObservationForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
