<template>
    <div
        class="work-task-type-select-box noSelection"
        :class="{selected: isSelected, disabled: !isEnabled}"
        :title="isEnabled ? '' : $t('TaskTypeCannotBeChanged')">
        <WorkTaskTypeIcon :work-task-type-code="workTaskTypeCode" :is-tooltip-disabled="true" />
        <span class="work-task-type-name pl-6">{{ name }}</span>
    </div>
</template>

<script lang="ts">
import {PropType, defineComponent, computed} from 'vue';
import {getWorkTaskTypeByCode, WorkTaskType} from '@/services/work-task-types-service';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import WorkTaskTypeIcon from '@/components/WorkTaskTypeIcon.vue';

export default defineComponent({
    props: {
        workTaskTypeCode: String as PropType<WorkTaskTypeCode>,
        isSelected: {
            default: false,
        },
        isEnabled: Boolean,
    },
    components: {
        WorkTaskTypeIcon,
    },
    setup(props) {
        const workTaskType = computed<WorkTaskType | undefined>(() => {
            return getWorkTaskTypeByCode(props.workTaskTypeCode as WorkTaskTypeCode);
        });
        const name = computed<string | null>(() => {
            return workTaskType.value?.getWorkTaskTypeName() || null;
        });

        return {
            name,
        };
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.work-task-type-select-box {
    width: 220px;
    padding: 6px;
    border: 2px solid $swatchG6;
    border-radius: 8px;
    position: relative;
    cursor: pointer;

    &:hover {
        border-color: $swatchB6;
    }

    &.selected {
        border-color: $swatchB6;
        background-color: rgba($swatchB7, 0.5);
    }

    &.disabled {
        cursor: not-allowed;
    }
}

.work-task-type-name {
    font-size: 16pt;
    font-weight: bold;
    position: absolute;
    top: 8px;
}
</style>
