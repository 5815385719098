import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_FontAwesomeLayers = _resolveComponent("FontAwesomeLayers")!
  const _component_CircleIcon = _resolveComponent("CircleIcon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock(_component_v_tooltip, { bottom: "" }, {
    activator: _withCtx(({props}) => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.chipClass)
      }, [
        _createVNode(_component_CircleIcon, _mergeProps(props, {
          style: _ctx.iconStyle,
          class: _ctx.primaryIcon
        }), {
          default: _withCtx(() => [
            (_ctx.productSubtypeCode !== 'Fertilizer')
              ? (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
                  key: 0,
                  icon: [_ctx.iconFamily, _ctx.iconCode],
                  class: "rounded-text"
                }, null, 8, ["icon"]))
              : (_openBlock(), _createBlock(_component_FontAwesomeLayers, {
                  key: 1,
                  class: "rounded-text fa-lg"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FontAwesomeIcon, {
                      icon: ['fas', 'box'],
                      transform: "left-3"
                    }),
                    _createVNode(_component_FontAwesomeIcon, {
                      icon: ['fas', 'seedling'],
                      class: "swatchA4",
                      transform: "shrink-9 left-4 down-2"
                    })
                  ]),
                  _: 1
                }))
          ]),
          _: 2
        }, 1040, ["style", "class"]),
        (_ctx.displayText && _ctx.displayText != '')
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["pr-2", _ctx.showProductCount ? 'pl-2 pr-4' : '']),
              style: {"padding-left":"5px"}
            }, _toDisplayString(_ctx.displayText), 3))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "helpText", {}, undefined, true),
      _createTextVNode(" " + _toDisplayString(_ctx.name), 1)
    ]),
    _: 3
  }))
}