import i18n from '@/i18n';
/**
 * Represents an object that can validate password strength.
 */
class PasswordValidator {
    /**
     * Minimum acceptable password length, in characters.
     */
    minLength = 10;

    /**
     * Maximum acceptable password length, in characters.
     */
    maxLength = 64;

    /**
     * Minimum number of lower case characters that a password may have.
     */
    minLowerCase = 1;

    /**
     * Minimum number of upper case characters that a password may have.
     */
    minUpperCase = 1;

    /**
     * Minimum number of numeric characters that a password may have.
     */
    minNumbers = 1;

    /**
     * Minimum number of special characters that a password may have.
     */
    minSpecialCharacters = 1;

    /**
     * Get password length requirements.
     * @returns {string[]} List of password requirements.
     */
    getLengthRequirements() {
        return i18n.global.t('PasswordValidator_PasswordLengthRequirement_HelpText', {
            passwordMinLength: this.minLength,
            passwordMaxLength: this.maxLength,
        });
    }

    /**
     * Get password character requirements.
     * @returns {string[]} List of password requirements.
     */
    getCharacterRequirements() {
        const requirements = [];

        if (this.minLowerCase > 0) {
            if (this.minLowerCase === 1)
                requirements.push(
                    i18n.global.t('PasswordValidator_PasswordLowercaseRequirementSingle_HelpText', {
                        minLowerCase: this.minLowerCase,
                    }),
                );
            else
                requirements.push(
                    i18n.global.t('PasswordValidator_PasswordLowercaseRequirementPlural_HelpText', {
                        minLowerCase: this.minLowerCase,
                    }),
                );
        }

        if (this.minUpperCase > 0) {
            if (this.minUpperCase === 1)
                requirements.push(
                    i18n.global.t('PasswordValidator_PasswordUppercaseRequirementSingle_HelpText', {
                        minUpperCase: this.minUpperCase,
                    }),
                );
            else
                requirements.push(
                    i18n.global.t('PasswordValidator_PasswordUppercaseRequirementPlural_HelpText', {
                        minUpperCase: this.minUpperCase,
                    }),
                );
        }

        if (this.minNumbers > 0) {
            if (this.minNumbers === 1)
                requirements.push(
                    i18n.global.t('PasswordValidator_PasswordNumberRequirementSingle_HelpText', {
                        minNumbers: this.minNumbers,
                    }),
                );
            else
                requirements.push(
                    i18n.global.t('PasswordValidator_PasswordNumberRequirementPlural_HelpText', {
                        minNumbers: this.minNumbers,
                    }),
                );
        }

        if (this.minSpecialCharacters > 0) {
            if (this.minSpecialCharacters === 1)
                requirements.push(
                    i18n.global.t('PasswordValidator_PasswordSpecialCharacterRequirementSingle_HelpText', {
                        minSpecialCharacters: this.minSpecialCharacters,
                    }),
                );
            else
                requirements.push(
                    i18n.global.t('PasswordValidator_PasswordSpecialCharacterRequirementPlural_HelpText', {
                        minSpecialCharacters: this.minSpecialCharacters,
                    }),
                );
        }

        return requirements;
    }

    /**
     * Get a message describing the password requirements.
     * @returns {string} Message describing the password requirements.
     */
    getRequirementsMessage() {
        const parts = this.getCharacterRequirements();

        let message = `must ${this.getLengthRequirements()}`;
        if (parts.length > 0) {
            message += ` and ${parts.slice(0, -1).join(', ')}` + `, and ${parts[parts.length - 1]}.`;
        } else {
            message += '.';
        }

        return message;
    }

    /**
     * Check whether the given password is valid.
     * @param {string} password Password to check.
     * @returns {boolean} true if the password passes the validation requirements; otherwise false.
     */
    isValid(password: string) {
        if (password == null || password.length < this.minLength || password.length > this.maxLength) return false;

        let numLowerCase = 0;
        let numUpperCase = 0;
        let numNumbers = 0;
        let numSpecialCharacters = 0;

        for (let i = 0; i < password.length; i++) {
            const c = password.charCodeAt(i);
            if (c >= 'a'.charCodeAt(0) && c <= 'z'.charCodeAt(0)) numLowerCase++;
            else if (c >= 'A'.charCodeAt(0) && c <= 'Z'.charCodeAt(0)) numUpperCase++;
            else if (c >= '0'.charCodeAt(0) && c <= '9'.charCodeAt(0)) numNumbers++;
            else if (c <= 127) numSpecialCharacters++;
        }

        return (
            numLowerCase >= this.minLowerCase &&
            numUpperCase >= this.minUpperCase &&
            numNumbers >= this.minNumbers &&
            numSpecialCharacters >= this.minSpecialCharacters
        );
    }
}

export default PasswordValidator;
