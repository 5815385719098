import {UploadedArtifactDto} from '@/models/data-transfer-objects/system/UploadedArtifactDto';

export class SaveWorkTaskTemplateSubTaskDto {
    workTaskTemplateSubTaskId: number | null = null;
    sequenceNumber!: number;
    workSubTaskName!: string;
    workSubTaskInstructions: string | null = null;
    artifacts: UploadedArtifactDto[] = [];
    haveArtifactsBeenUpdated = false;
    duplicatedFromWorkTaskTemplateSubTaskId: number | null = null;
    createdFromWorkSubTaskId: number | null = null;

    constructor(data?: Partial<SaveWorkTaskTemplateSubTaskDto>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
