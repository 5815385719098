import {GetCropsResponse} from '@/models/api/responses/data-source/GetCropsResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useCrops() {
    const apiService = inject('apiService') as ApiService;
    const crops = ref<GetCropsResponse[]>([]);
    const cropsWithNull = ref<SelectListItem[]>([]);

    /**
     * Get list of crops.
     */
    const getCrops = async () => {
        crops.value = await apiService.get('data-source/crops');

        // Add null value
        cropsWithNull.value = crops.value.map((crop) => ({
            value: crop.cropId,
            title: crop.cropName,
        }));

        cropsWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getCrops,
        crops,
        cropsWithNull,
    };
}
