import {GetFarmFieldsRequest} from '@/models/api/requests/data-source/GetFarmFieldsRequest';
import {GetFarmFieldsResponse} from '@/models/api/responses/data-source/GetFarmFieldsResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useFarmFields() {
    const apiService = inject('apiService') as ApiService;
    const farmFields = ref<GetFarmFieldsResponse[]>([]);
    const farmFieldsWithNull = ref<SelectListItem[]>([]);

    /**
     * Get list of farm fields.
     */
    const getFarmFields = async (farmSiteIdList?: number[] | null | undefined) => {
        const getFarmFieldsRequest: GetFarmFieldsRequest = {
            farmSiteIds: farmSiteIdList,
        };
        farmFields.value = await apiService.post('data-source/farm-fields', getFarmFieldsRequest);

        // Add null value
        farmFieldsWithNull.value = farmFields.value.map((farmField) => ({
            value: farmField.farmFieldId,
            title: farmField.farmFieldCodeAndName,
        }));

        farmFieldsWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getFarmFields,
        farmFields,
        farmFieldsWithNull,
    };
}
