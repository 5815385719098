export class SearchMixtureRequest {
    isQuickSearch: boolean = false;
    quickSearchText: string | null = null;
    mixtureIdList: number[] | null = null;
    mixtureName: string | null = null;
    productName: string | null = null;
    productIdList: number[] | null = null;
    farmFieldIdList: number[] | null = null;
    farmSiteIdList: number[] | null = null;
    productTypeCodeList: string[] | null = null;
    productSubtypeCodeList: string[] | null = null;
    dateRangeFrom: string | null = null;
    dateRangeTo: string | null = null;

    constructor(data?: Partial<SearchMixtureRequest>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
