<template>
    <div v-if="isConfigSettingsLoading && isFarmSiteLoading" style="color: black; padding-top: 8rem">
        <v-card outlined style="width: 20rem; margin: auto">
            <v-toolbar color="primary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'loader']" size="xl" class="mr-2" />
                    {{ $t('Loading_Ellipsis') }}
                </v-toolbar-title>
            </v-toolbar>

            <div class="pa-6" style="text-align: center">
                <LoadingSymbol />
            </div>
        </v-card>
    </div>
    <div v-else>
        <div class="app-container">
            <div class="divMasterHeaderRow">
                <div class="divMasterHeaderCell"><PageHeader /></div>
            </div>

            <div class="main-content">
                <div class="divBodyCell" :class="{divBodyCellPadding: isDefaultPaddingUsed}">
                    <div>
                        <router-view v-slot="{Component}">
                            <transition>
                                <component :is="Component" />
                            </transition>
                        </router-view>

                        <!-- Modals -->
                        <AdvancedSearchModal />
                        <DeleteUserModal />
                        <DeleteFarmSiteModal />
                        <ManageUserModal />
                        <ManageFarmSiteModal />
                        <ResetUserPasswordModal />
                        <ViewFarmSiteModal />
                        <ManageFarmFieldModal />
                        <DeleteFarmFieldModal />
                        <ViewFarmFieldModal />
                        <ViewWorkTaskModal />
                        <ManageFarmFieldSeasonModal />
                        <ManageWorkTaskModal />
                        <ManageWorkTaskPlanModal />
                        <ManageObservationModal />
                        <DeleteOrCancelWorkTaskModal />
                        <ActionWorkTaskModal />
                        <RescheduleWorkTaskModal />
                        <ViewObservationModal />
                        <ViewProductModal />
                        <SelectWorkTaskTemplateModal />
                        <ViewMixtureModal />
                        <ViewWorkTaskTemplateModal />
                        <DeleteWorkTaskTemplateModal />
                        <ViewWorkTaskPlanModal />
                        <ApplyWorkTaskPlanModal />
                        <ViewCropVarietyModal />
                        <DeleteWorkTaskPlanModal />
                        <ClearTasksModal />
                        <ViewDevicesModal />
                    </div>

                    <div id="divInfo" runat="server" class="infoBox"></div>
                </div>
            </div>

            <div class="divMasterFooterRow noprint">
                <div class="divMasterFooterCell"><PageFooter /></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, inject} from 'vue';
import {useConfigStore} from '@/stores/config-store';
import {useFarmStore} from '@/stores/farm-store';
import {useUserStore} from '@/stores/user-store';
import {useLocaleCodeStore} from '@/stores/locale-code-store';
import PageFooter from '@/views/shared/PageFooter.vue';
import PageHeader from '@/views/shared/PageHeader.vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import AdvancedSearchModal from '@/views/search/AdvancedSearchModal.vue';
import DeleteUserModal from '@/views/users/DeleteUserModal.vue';
import DeleteFarmSiteModal from '@/views/farms/DeleteFarmSiteModal.vue';
import ViewFarmSiteModal from '@/views/farms/ViewFarmSiteModal.vue';
import ManageUserModal from '@/views/users/ManageUserModal.vue';
import ManageFarmSiteModal from '@/views/farms/ManageFarmSiteModal.vue';
import ManageFarmFieldModal from '@/views/farms/ManageFarmFieldModal.vue';
import ManageFarmFieldSeasonModal from '@/views/farms/ManageFarmFieldSeasonModal.vue';
import DeleteFarmFieldModal from '@/views/farms/DeleteFarmFieldModal.vue';
import ViewFarmFieldModal from '@/views/farms/ViewFarmFieldModal.vue';
import ViewObservationModal from '@/views/observations/ViewObservationModal.vue';
import ViewCropVarietyModal from '@/views/crops/ViewCropVarietyModal.vue';
import ViewProductModal from '@/views/products/ViewProductModal.vue';
import ViewMixtureModal from '@/views/products/ViewMixtureModal.vue';
import ResetUserPasswordModal from '@/views/users/ResetUserPasswordModal.vue';
import ViewWorkTaskModal from '@/views/work-tasks/ViewWorkTaskModal.vue';
import ManageWorkTaskModal from '@/views/work-tasks/ManageWorkTaskModal.vue';
import ManageWorkTaskPlanModal from '@/views/work-tasks/ManageWorkTaskPlanModal.vue';
import ManageObservationModal from '@/views/observations/ManageObservationModal.vue';
import DeleteOrCancelWorkTaskModal from '@/views/work-tasks/DeleteOrCancelWorkTaskModal.vue';
import ActionWorkTaskModal from '@/views/work-tasks/ActionWorkTaskModal.vue';
import RescheduleWorkTaskModal from '@/views/work-tasks/RescheduleWorkTaskModal.vue';
import SelectWorkTaskTemplateModal from './views/work-tasks/SelectWorkTaskTemplateModal.vue';
import ViewWorkTaskTemplateModal from '@/views/work-tasks/ViewWorkTaskTemplateModal.vue';
import DeleteWorkTaskTemplateModal from './views/work-tasks/DeleteWorkTaskTemplateModal.vue';
import ViewWorkTaskPlanModal from '@/views/work-tasks/ViewWorkTaskPlanModal.vue';
import ApplyWorkTaskPlanModal from '@/views/work-tasks/ApplyWorkTaskPlanModal.vue';
import DeleteWorkTaskPlanModal from '@/views/work-tasks/DeleteWorkTaskPlanModal.vue';
import ClearTasksModal from './views/work-tasks/ClearTasksModal.vue';
import ViewDevicesModal from './views/users/ViewDevicesModal.vue';
import i18n from '@/i18n';
import ApiService from '@/services/api-service';
import {useRoute} from 'vue-router';
import {LocaleCultureCode} from './models/system/LocaleCultureCode';

export default defineComponent({
    name: 'App',
    components: {
        PageFooter,
        PageHeader,
        FontAwesomeIcon,
        AdvancedSearchModal,
        DeleteUserModal,
        ManageUserModal,
        ManageFarmSiteModal,
        ResetUserPasswordModal,
        DeleteFarmSiteModal,
        ViewFarmSiteModal,
        ManageFarmFieldModal,
        DeleteFarmFieldModal,
        ViewFarmFieldModal,
        ViewWorkTaskModal,
        ManageFarmFieldSeasonModal,
        ManageWorkTaskModal,
        ManageWorkTaskPlanModal,
        ManageObservationModal,
        DeleteOrCancelWorkTaskModal,
        ActionWorkTaskModal,
        RescheduleWorkTaskModal,
        ViewObservationModal,
        ViewProductModal,
        SelectWorkTaskTemplateModal,
        ViewMixtureModal,
        ViewWorkTaskTemplateModal,
        DeleteWorkTaskTemplateModal,
        ViewWorkTaskPlanModal,
        ApplyWorkTaskPlanModal,
        ViewCropVarietyModal,
        DeleteWorkTaskPlanModal,
        ClearTasksModal,
        ViewDevicesModal,
    },
    created() {
        this.init();
    },
    setup() {
        const configStore = useConfigStore();
        const userStore = useUserStore();
        const localeCodestore = useLocaleCodeStore();
        const farmStore = useFarmStore();

        const isLocaleCodeLoading = computed(() => !(localeCodestore.locales && localeCodestore.locales.length > 0));
        const route = useRoute();

        /**
         * Initialization.
         */
        async function init() {
            const apiService = inject('apiService') as ApiService;

            //Call API to get config settings if they have not been loaded yet
            if (isConfigSettingsLoading.value) {
                await configStore.fetchConfigSettings(apiService);
            }

            if (userStore.isLoggedIn) await farmStore.fetchFarmSites(apiService);

            const localStoredLocale = localStorage.getItem('languagePreference') as LocaleCultureCode | null;
            if (userStore.user && userStore.user.localeCode) {
                //Call API to get local codes if they have not been loaded yet
                if (isLocaleCodeLoading.value) {
                    await localeCodestore.fetchLocaleCodeSettings(apiService);
                }

                const userLocale = localeCodestore.locales.filter((e) => e.localeCode === userStore.user?.localeCode);
                if (userLocale.length > 0) {
                    i18n.global.locale = userLocale[0].localeCultureCode as LocaleCultureCode;
                } else i18n.global.locale = localStoredLocale ? localStoredLocale : 'en-AU';
            } else i18n.global.locale = localStoredLocale ? localStoredLocale : 'en-AU';
            localStorage.setItem('languagePreference', i18n.global.locale);
        }

        /**
         * Flag to indicte whether the config settings have been loaded. This is determined
         * by checking for the "systemName" constant and returning true if it has a value.
         */
        const isConfigSettingsLoading = computed(() => !(configStore.systemName && configStore.systemName.length > 0));
        const isFarmSiteLoading = computed(() => !(farmStore.farmSites && farmStore.farmSites.length > 0));

        /**
         * Flag to indicte whether default padding should be added to the page.
         */
        const isDefaultPaddingUsed = computed(() => route.meta.isDefaultPaddingUsed ?? true);

        return {
            init,
            isConfigSettingsLoading,
            isFarmSiteLoading,
            isDefaultPaddingUsed,
        };
    },
});
</script>

<style scoped>
.app-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Take up the full viewport height */
    width: 100%;
    position: relative;
    display: table;
}

.main-content {
    flex-grow: 1; /* This content grows to take up available space */
    display: table-row;
}

.divMasterHeaderRow {
    display: table-row;
}

.divMasterHeaderCell {
    display: table-cell;
}

.divBodyCell {
    vertical-align: top;
    display: table-cell;
    height: 100%;
}

.divBodyCellPadding {
    padding: 10px;
}

.divMasterFooterRow {
    display: table-row;
}

.divMasterFooterCell {
    vertical-align: bottom;
    display: table-cell;
}
</style>
