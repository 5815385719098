import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_CircleIcon = _resolveComponent("CircleIcon")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_chip = _resolveComponent("v-chip")!

  return (_openBlock(), _createBlock(_component_v_chip, {
    variant: "flat",
    pill: "",
    size: _ctx.size,
    color: _ctx.lighterColor,
    class: "pr-5 work-task-type-chip"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_avatar, { start: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_CircleIcon, {
            style: {height: '40px', width: '40px'},
            class: _normalizeClass(_ctx.iconClass)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FontAwesomeIcon, {
                icon: [_ctx.iconFamily, _ctx.iconCode],
                size: _ctx.iconSize,
                transform: _ctx.iconCode === 'harvester' ? 'down-1' : ''
              }, null, 8, ["icon", "size", "transform"])
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      }),
      _createElementVNode("div", {
        class: _normalizeClass(["chip-text", {'large-text': _ctx.size == 'xlarge'}])
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.name), 1)
      ], 2)
    ]),
    _: 1
  }, 8, ["size", "color"]))
}