import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ca84c8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "pr-2",
  style: {"padding-left":"5px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_CircleIcon = _resolveComponent("CircleIcon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock(_component_v_tooltip, { bottom: "" }, {
    activator: _withCtx(({props}) => [
      _createElementVNode("div", {
        class: "rounded-text",
        style: _normalizeStyle(_ctx.backgroundStyle)
      }, [
        _createVNode(_component_CircleIcon, _mergeProps(props, { style: _ctx.iconStyle }), {
          default: _withCtx(() => [
            _createVNode(_component_FontAwesomeIcon, {
              icon: ['fal', _ctx.iconCode]
            }, null, 8, ["icon"])
          ]),
          _: 2
        }, 1040, ["style"]),
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.name), 1)
      ], 4)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "helpText", {}, undefined, true),
      _createTextVNode(" " + _toDisplayString(_ctx.name), 1)
    ]),
    _: 3
  }))
}