import {GetWorkTaskTemplatesResponse} from '@/models/api/responses/data-source/GetWorkTaskTemplatesResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useWorkTaskTemplates() {
    const apiService = inject('apiService') as ApiService;
    const workTaskTemplates = ref<GetWorkTaskTemplatesResponse[]>([]);
    const workTaskTemplatesWithNull = ref<SelectListItem[]>([]);

    /**
     * Get list of work task templates.
     */
    const getWorkTaskTemplates = async () => {
        workTaskTemplates.value = await apiService.get('data-source/work-task-templates');

        // Add null value
        workTaskTemplatesWithNull.value = workTaskTemplates.value.map((workTaskTemplate) => ({
            value: workTaskTemplate.workTaskTemplateId,
            title: workTaskTemplate.workTaskTemplateName,
        }));

        workTaskTemplatesWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getWorkTaskTemplates,
        workTaskTemplates,
        workTaskTemplatesWithNull,
    };
}
