import {GetUsersRequest} from '@/models/api/requests/data-source/GetUsersRequest';
import {GetUsersResponse} from '@/models/api/responses/data-source/GetUsersResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useUsers() {
    const apiService = inject('apiService') as ApiService;
    const users = ref<GetUsersResponse[]>([]);
    const usersWithNull = ref<SelectListItem[]>([]);

    /**
     * Get list of users.
     */
    const getUsers = async (parameters?: GetUsersRequest | null) => {
        users.value = await apiService.post('data-source/users', parameters);

        // Add null value
        usersWithNull.value = users.value.map((user) => ({
            value: user.userId,
            title: user.userFullName,
        }));

        usersWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getUsers,
        users,
        usersWithNull,
    };
}
