import {defineStore} from 'pinia';
import {GetLocaleCodeSettingsResponse} from '@/models/api/responses/system/GetLocaleCodeSettingsResponse';
import ApiService from '@/services/api-service';

export const useLocaleCodeStore = defineStore('localeCode', {
    state: () => ({
        locales: [] as GetLocaleCodeSettingsResponse[],
    }),

    actions: {
        async fetchLocaleCodeSettings(apiService: ApiService) {
            try {
                const response = await apiService.get('config/locale-code-settings');
                const data = response as GetLocaleCodeSettingsResponse[]; // Type cast to expected interface
                this.locales = data; // Update state with fetched data
            } catch (error) {
                console.error('Failed to fetch locale code', error);
            }
        },
    },
});
