import {GetMixturesResponse} from '@/models/api/responses/data-source/GetMixturesResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useMixtures() {
    const apiService = inject('apiService') as ApiService;
    const mixtures = ref<GetMixturesResponse[]>([]);
    const mixturesWithNull = ref<SelectListItem[]>([]);

    /**
     * Get list of mixtures.
     */
    const getMixtures = async () => {
        mixtures.value = await apiService.get('data-source/mixtures');

        // Add null value
        mixturesWithNull.value = mixtures.value.map((mixture) => ({
            value: mixture.mixtureId,
            title: mixture.mixtureName,
        }));

        mixturesWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getMixtures,
        mixtures,
        mixturesWithNull,
    };
}
