import {GetProductSubtypesResponse} from '@/models/api/responses/data-source/GetProductSubtypesResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useProductSubtypes() {
    const apiService = inject('apiService') as ApiService;
    const productSubtypes = ref<GetProductSubtypesResponse[]>([]);
    const productSubtypesWithNull = ref<SelectListItem[]>([]);

    /**
     * Get list of product types.
     */
    const getProductSubtypes = async () => {
        productSubtypes.value = await apiService.get('data-source/product-subtypes');

        // Add null value
        productSubtypesWithNull.value = productSubtypes.value.map((productSubtype) => ({
            value: productSubtype.productSubtypeCode,
            title: productSubtype.productSubtypeName,
        }));

        productSubtypesWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getProductSubtypes,
        productSubtypes,
        productSubtypesWithNull,
    };
}
