<template>
    <v-tooltip bottom>
        <template v-slot:activator="{props}">
            <div v-bind="props">
                <FontAwesomeLayers class="fa-lg" :style="iconStyle">
                    <FontAwesomeIcon
                        :icon="['fas', isSquareBackground ? 'square' : 'message-middle']"
                        :class="isSquareBackground ? ['swatchWHT'] : ['swatchWHT', 'icon']"
                        transform="grow-1" />
                    <FontAwesomeIcon
                        :icon="['fas', isSquareBackground ? 'square' : 'message-middle']"
                        :style="{color: color}"
                        :class="isSquareBackground ? [] : ['icon']" />
                    <FontAwesomeIcon
                        :icon="['fal', iconCode]"
                        class="swatchWHT"
                        :transform="isSquareBackground ? 'shrink-5' : 'shrink-4 up-2'" />
                </FontAwesomeLayers>
            </div>
        </template>
        <slot name="helpText"></slot>
        {{ name }}
    </v-tooltip>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue';
import {getObservationTypeByCode, ObservationType} from '@/services/observation-types-service';

export default defineComponent({
    name: 'ObservationTypeChips',
    props: {
        observationType: String,
        isSquareBackground: {
            type: Boolean,
            default: false,
        },
        iconHeight: {
            type: String,
            default: '60',
        },
        iconWidth: {
            type: String,
            default: '60',
        },
        iconSize: {
            type: String,
            default: '38',
        },
    },
    setup(props) {
        const observationType = computed<ObservationType | undefined>(() => {
            if (!props.observationType) return undefined;
            return getObservationTypeByCode(props.observationType);
        });

        const iconCode = computed<string>(() => {
            return observationType.value?.iconCode || 'question';
        });

        const color = computed<string>(() => {
            return observationType.value?.color || '#000000';
        });

        const iconStyle = computed(() => ({
            height: props.iconHeight + 'px',
            width: props.iconWidth + 'px',
            fontSize: props.iconSize + 'pt',
        }));

        const name = computed<string | null>(() => observationType.value?.getObservationTypeName() || '');

        return {
            iconCode,
            name,
            color,
            iconStyle,
        };
    },
});
</script>
<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.icon {
    transform: scale(1, 1.2);
}
</style>
