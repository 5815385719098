<template>
    <LoadingSymbol v-if="isLoading || workTask == null" />
    <div v-else>
        <!--Task Overview-->
        <div class="pa-3 swatchWHTBG">
            <div class="flex-row" style="align-items: center">
                <WorkTaskTypeIcon
                    :workTaskTypeCode="getTaskCode(workTask.workTaskTypeCode)"
                    iconHeight="35"
                    iconWidth="35" />

                <span class="bold" style="font-size: larger">{{ workTask.workTaskTypeName }}</span>
                <IdIcon v-if="workTask.workTaskId">{{ workTask.workTaskId }}</IdIcon>

                <div class="task-status-chip">
                    <WorkTaskStatus :work-task="workTask" />
                </div>
            </div>

            <!--Task has not started-->
            <div v-if="!workTask.isStarted && !workTask.isCancelled" class="swatchA1">
                <div
                    v-if="workTask.dueDateTiming == WorkTaskDueDateTiming.DueAfterTask && predecessorWorkTask"
                    class="flex-row">
                    <div class="bold" style="min-width: 37%">{{ $t('Timing') }}</div>
                    <div>
                        <span v-if="workTask.dueAfterWorkTaskWithinDays == 1">
                            {{ $t('WithinDaysAfterTaskSingular') }}
                        </span>
                        <span v-else>
                            {{
                                $t('WithinDaysAfterTaskPlural', {
                                    numDays: workTask.dueAfterWorkTaskWithinDays,
                                })
                            }}
                        </span>
                        {{ predecessorWorkTask.workTaskTypeName }}
                    </div>
                </div>
                <div class="flex-row">
                    <div class="bold" style="min-width: 37%">{{ $t('Due') }}</div>
                    <div>{{ formatDate(workTask.dueDate) }}</div>
                    <FontAwesomeIcon
                        v-if="workTask.isOverdue"
                        class="swatchRED"
                        :icon="['fa', 'calendar-circle-exclamation']"
                        size="xl" />
                    <FontAwesomeIcon v-else class="swatchG3" :icon="['fa', 'calendar-day']" size="xl" />
                </div>
                <div v-if="workTask.workTaskTemplateId" class="flex-row">
                    <div class="bold" style="min-width: 37%">{{ $t('Template') }}</div>
                    {{ workTask.workTaskTemplateName }}
                    <v-btn
                        class="v-btn--custom pa-0"
                        rounded="circle"
                        min-width="26px"
                        max-height="26px"
                        @click="viewWorkTaskTemplateModalStore.open(workTask.workTaskTemplateId)">
                        <FontAwesomeIcon :icon="['fal', 'arrow-circle-right']" />
                    </v-btn>
                </div>
            </div>
            <div v-else>
                <div v-if="workTask.isStarted" class="flex-row swatchA1">
                    <div class="bold" style="min-width: 37%">{{ $t('Started') }}</div>
                    <div>{{ formatDate(workTask.startedDate) }}</div>
                    <FontAwesomeIcon class="swatchB6" :icon="['fa', 'calendar-star']" size="xl" />
                </div>
                <!--Task is paused or in progress-->
                <div v-if="!workTask.isCompleted && !workTask.isCancelled" class="flex-row swatchA1">
                    <div class="bold" style="min-width: 37%">{{ $t('LastAction') }}</div>
                    <div>{{ formatDate(workTask.modifiedDate) }}</div>
                    <FontAwesomeIcon class="swatchB6" :icon="['fa', 'calendar-lines-pen']" size="xl" />
                </div>
                <!--Task is completed-->
                <div v-else-if="workTask.isCompleted" class="flex-row swatchA1">
                    <div class="bold" style="min-width: 37%">{{ $t('Complete') }}</div>
                    <div>{{ formatDate(workTask.completedDate) }}</div>
                    <FontAwesomeIcon class="swatchGRN" :icon="['fa', 'calendar-check']" size="xl" />
                </div>
                <!--Task is cancelled-->
                <div v-else-if="workTask.isCancelled" class="flex-row swatchA1">
                    <div class="bold" style="min-width: 37%">{{ $t('Cancelled') }}</div>
                    <div>{{ formatDate(workTask.cancelledDate) }}</div>
                    <FontAwesomeIcon class="swatchG1" :icon="['fa', 'calendar-xmark']" size="xl" />
                </div>
                <div class="flex-row" v-if="workTask.workTaskUsers.length > 0">
                    <div class="swatchA1 bold" style="min-width: 37%">
                        {{ workTask.workTaskUsers.length === 1 ? $t('User') : $t('Users') }}
                    </div>
                    <v-list density="compact" class="user-chip-list pa-0">
                        <v-list-item
                            v-for="workTaskUser in workTask.workTaskUsers"
                            :key="workTaskUser.userId"
                            class="pa-0">
                            <UserChip
                                :user="
                                    UserHelper.userChipType(
                                        workTaskUser.userId,
                                        UserHelper.userFullName(workTaskUser.userGivenName, workTaskUser.userSurname),
                                    )
                                " />
                        </v-list-item>
                    </v-list>
                </div>
            </div>
        </div>

        <v-expansion-panels v-model="openPanels" multiple>
            <!--Instructions-->
            <v-expansion-panel>
                <v-expansion-panel-title class="swatchG8BG swatchBLK pa-3">
                    <FontAwesomeIcon :icon="['fa', 'circle-question']" size="lg" />
                    <span class="ps-2">{{ $t('Instructions') }}</span>
                </v-expansion-panel-title>
                <v-expansion-panel-text class="swatchA1">
                    <div class="pa-3">
                        <i><TranslatableTexts :text="workTask.workTaskInstructions" /></i>

                        <div v-if="workTask.artifacts.length > 0" class="pt-2">
                            <FileTypeButton
                                v-for="(artifact, index) in workTask.artifacts"
                                :key="artifact.artifactId"
                                :url="artifact.artifactUrl"
                                :display-name="artifact.artifactDisplayName"
                                :file-type-code="artifact.artifactFileTypeCode"
                                :style="{
                                    width: '100%',
                                    marginBottom: index !== workTask.artifacts.length - 1 ? '8px' : '0',
                                }" />
                        </div>
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>

            <!--Sub-Tasks-->
            <v-expansion-panel v-if="workTask.workTaskTypeCode === WorkTaskTypeCode.Cultivate">
                <v-expansion-panel-title class="swatchG8BG swatchBLK pa-3">
                    <FontAwesomeIcon :icon="['fal', 'clipboard-list']" size="lg" />
                    <span class="ps-2">{{ $t('SubHyphenTasks') }}</span>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <v-list v-if="workTask.workSubTasks.length > 0" class="pa-0" density="compact">
                        <template v-for="(subTask, index) in workTask.workSubTasks" :key="subTask.workSubTaskId">
                            <v-list-item class="pa-1">
                                <div class="subtask-container">
                                    <div class="swatchA1BG subtask-sequence">
                                        <span class="swatchWHT bold">{{ subTask.sequenceNumber }}</span>
                                    </div>

                                    <span class="subtask-name">{{ subTask.workSubTaskName }}</span>

                                    <div class="subtask-container-end">
                                        <v-list v-if="subTask.isCompleted" class="subtask-users pa-0" density="compact">
                                            <v-list-item
                                                v-for="workSubTaskUser in subTask.workSubTaskUsers"
                                                class="pa-0"
                                                :key="workSubTaskUser.userId">
                                                <UserChip
                                                    :user="
                                                        UserHelper.userChipType(
                                                            workSubTaskUser.userId,
                                                            UserHelper.userFullName(
                                                                workSubTaskUser.userGivenName,
                                                                workSubTaskUser.userSurname,
                                                            ),
                                                        )
                                                    "
                                                    icon-size="26"
                                                    :icon-only="true" />
                                            </v-list-item>
                                        </v-list>

                                        <FontAwesomeIcon
                                            v-if="subTask.isCompleted"
                                            class="swatchGRN"
                                            :icon="['fa', 'check-square']"
                                            size="2xl" />

                                        <v-btn
                                            class="v-btn--custom subtask-icon"
                                            rounded="x-large"
                                            @click="viewWorkTaskModalStore.open(workTask.workTaskId)">
                                            <FontAwesomeIcon :icon="['fa', 'square-info']" size="xl" />
                                        </v-btn>

                                        <FileTypeButton
                                            v-if="subTask.artifacts.length > 0"
                                            :url="subTask.artifacts[0].artifactUrl"
                                            :file-type-code="subTask.artifacts[0].artifactFileTypeCode" />
                                    </div>
                                </div>
                            </v-list-item>

                            <v-divider v-if="index < workTask.workSubTasks.length - 1"></v-divider>
                        </template>
                    </v-list>
                    <div v-else class="pa-3">{{ $t('NoDataToDisplay') }}</div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script setup lang="ts">
import {ref, inject, watch, onMounted} from 'vue';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';
import {SearchWorkTasksResponse} from '@/models/api/responses/search/SearchWorkTasksResponse';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import ApiService from '@/services/api-service';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import {format} from 'date-fns';
import UserChip from '@/components/UserChip.vue';
import FileTypeButton from '@/components/FileTypeButton.vue';
import WorkTaskStatus from '@/components/work-tasks/WorkTaskStatus.vue';
import {useViewWorkTaskModalStore} from '@/stores/modals/view-work-task-modal-store';
import UserHelper from '@/helpers/UserHelper';
import {useViewWorkTaskTemplateModalStore} from '@/stores/modals/view-work-task-template-modal-store';
import {GetPredecessorWorkTasksResponse} from '@/models/api/responses/work-tasks/GetPredecessorWorkTasksResponse';
import {GetPredecessorWorkTasksRequest} from '@/models/api/requests/work-tasks/GetPredecessorWorkTasksRequest';
import {WorkTaskDueDateTiming} from '@/enums/work-task-due-date-timing';

// Props
const props = withDefaults(
    defineProps<{
        workTaskId?: number | null;
    }>(),
    {
        workTaskId: null,
    },
);

// Stores
const viewWorkTaskModalStore = useViewWorkTaskModalStore();
const viewWorkTaskTemplateModalStore = useViewWorkTaskTemplateModalStore();

// Local state for work task
const workTask = ref<WorkTaskSearchResultDto | null>(null);
const predecessorWorkTask = ref<GetPredecessorWorkTasksResponse | null>(null);

// Opens panels by default
const openPanels = ref<number[]>([0, 1]);

// Function to fetch work task from API
const apiService = inject('apiService') as ApiService;
const isLoading = ref(false);
const fetchWorkTaskDetails = async () => {
    isLoading.value = true;

    // Reset object
    workTask.value = null;

    // Build search request using the user ID
    const searchRequest: SearchWorkTasksRequest = new SearchWorkTasksRequest({
        isQuickSearch: false,
        workTaskId: props.workTaskId,
        includeArtifacts: true,
        includeWorkSubTasks: true,
        includeWorkTaskUsers: true,
        includeWorkTaskMixtures: true,
        includeWorkTaskProducts: true,
    });

    // Get and display details
    const results: SearchWorkTasksResponse = await apiService.post('search/work-tasks', searchRequest);
    if (results.workTasks.length == 1) {
        workTask.value = results.workTasks[0];

        // If this task is due after another task
        if (workTask.value.dueDateTiming == WorkTaskDueDateTiming.DueAfterTask) {
            // Get the details of the other task
            await getPredecessorWorkTask();
        }
    }

    isLoading.value = false;
};

/**
 * Get all work tasks that could be a predecessor for this task when the due date is dependent upon
 * another tasking being completed.
 */
const getPredecessorWorkTask = async () => {
    // If no work task, return early
    if (!workTask.value) return;

    // Call API to get predecessor tasks
    const request: GetPredecessorWorkTasksRequest = {
        dependentWorkTaskId: null,
        farmFieldId: null,
        workTaskId: workTask.value.dueAfterWorkTaskId,
    };
    const response = (await apiService.get(
        'work-tasks/predecessor-work-tasks',
        request,
    )) as GetPredecessorWorkTasksResponse[];

    if (response.length == 1) {
        predecessorWorkTask.value = response[0];
    }
};

// Watch the ID prop to refetch items when it changes
watch(
    () => props.workTaskId,
    (newVal) => {
        if (newVal !== null) {
            fetchWorkTaskDetails();
        }
    },
);

// Get task code from string
const getTaskCode = (nextTask: string | undefined) => {
    return nextTask as WorkTaskTypeCode;
};

const formatDate = (date: Date | null): string | null => {
    if (!date) return null;
    return format(date, 'd MMM yyyy');
};

// Fetch items on component mount
onMounted(() => {
    fetchWorkTaskDetails();
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.flex-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 15px;

    :deep(.id-icon) {
        margin: 0;
    }

    .task-status-chip {
        margin-left: auto;
    }

    .user-chip-list {
        background-color: inherit;
    }
}

.flex-row:last-child {
    margin-bottom: 0;
}

.subtask-container {
    display: inline-flex;
    width: 100%;
    align-items: center;
    gap: 5px;

    .subtask-sequence {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        text-align: center;
    }

    .subtask-name {
        max-width: 33%;
        max-height: 48px;
        overflow: hidden;
    }

    .subtask-container-end {
        margin-left: auto;
        gap: 5px;
        display: inline-flex;
        align-items: center;

        .subtask-users {
            display: flex;
            flex-direction: row;
            gap: 2px;
            flex-wrap: wrap;
        }

        .subtask-icon {
            padding: 0;
            min-width: min-content;
            width: 37px;
        }
    }
}

:deep(.v-expansion-panel--active > .v-expansion-panel-title:not(.v-expansion-panel-title--static)) {
    min-height: 48px;
}

:deep(.v-expansion-panel--active:not(:first-child)),
:deep(.v-expansion-panel--active + .v-expansion-panel) {
    margin-top: 0px;
}

:deep(.v-expansion-panel-text__wrapper) {
    padding: 0 !important;
}
</style>
