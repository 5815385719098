<template>
    <!--Cultivate-->
    <div v-if="workTask.workTaskTypeCode === WorkTaskTypeCode.Cultivate">
        <div v-if="workTask.workTaskTemplateId">
            <b>"{{ workTask.workTaskTemplateName }}"</b>
        </div>
        <b v-else-if="workTask.workSubTasks.length === 0">
            <TranslatableTexts :text="truncateText(workTask.workTaskInstructions)" />
        </b>
        <span v-else-if="workTask.workSubTasks.length > 0">
            <b>"{{ truncateText(workTask.workSubTasks[0].workSubTaskName) }}"</b>
            <div v-if="workTask.workSubTasks.length > 1" style="font-style: italic">
                <b>{{ workTask.workSubTasks.length - 1 }}</b>
                {{ workTask.workSubTasks.length === 2 ? $t('OtherSubHyphenTask') : $t('OtherSubHyphenTasks') }}
            </div>
        </span>
    </div>
    <!--Fertilize/Spray-->
    <div
        v-else-if="
            workTask.workTaskTypeCode === WorkTaskTypeCode.Fertilize ||
            workTask.workTaskTypeCode === WorkTaskTypeCode.Spray
        ">
        <b
            v-if="
                workTask.workTaskMixtures.length === 1 &&
                isSameProductMixture(workTask.workTaskProducts, workTask.workTaskMixtures[0].mixtureId)
            ">
            "{{ workTask.workTaskMixtures[0].mixtureName }}"
        </b>
        <span v-else-if="workTask.workTaskProducts.length > 0">
            {{ getProductText(workTask.workTaskProducts[0]) }}
            <span v-if="workTask.workTaskProducts.length > 1">
                <span
                    v-if="
                        getProductText(workTask.workTaskProducts[0]).length +
                            getProductText(workTask.workTaskProducts[1]).length <=
                        50
                    ">
                    ; {{ getProductText(workTask.workTaskProducts[1]) }}
                    <div v-if="workTask.workTaskProducts.length > 2" style="font-style: italic">
                        <b>{{ workTask.workTaskProducts.length - 2 }}</b>
                        {{ workTask.workTaskProducts.length === 3 ? $t('OtherProduct') : $t('OtherProducts') }}
                    </div>
                </span>
                <span v-else>
                    <div v-if="workTask.workTaskProducts.length > 1" style="font-style: italic">
                        <b>{{ workTask.workTaskProducts.length - 1 }}</b>
                        {{ workTask.workTaskProducts.length === 2 ? $t('OtherProduct') : $t('OtherProducts') }}
                    </div>
                </span>
            </span>
        </span>
    </div>
    <!--Irrigate-->
    <div v-else-if="workTask.workTaskTypeCode === WorkTaskTypeCode.Irrigate">
        <b v-if="workTask.workTaskName">"{{ workTask.workTaskName }}" </b>
        <span>
            <b>{{ getIrrigationValue() }}</b> ML / Ha
        </span>
    </div>
    <!--Plant-->
    <div v-else-if="workTask.workTaskTypeCode === WorkTaskTypeCode.Plant">
        <b v-if="workTask.workTaskTemplateId">"{{ workTask.workTaskTemplateName }}" </b>
        <b v-else-if="workTask.workTaskName">"{{ workTask.workTaskName }}" </b>
        <span v-if="workTask.plantCropCode">({{ cropName(workTask.plantCropCode) }})</span>
    </div>
    <!--Harvest-->
    <div v-else-if="workTask.workTaskTypeCode === WorkTaskTypeCode.Harvest">
        <b v-if="workTask.workTaskTemplateId">"{{ workTask.workTaskTemplateName }}" </b>
        <b v-else-if="workTask.workTaskName">"{{ workTask.workTaskName }}" </b>
        <span v-if="workTask.primaryCropCode"
            >({{ cropName(workTask.primaryCropCode) }}
            <span v-if="workTask.cropYear">{{ `- ${$t('Year')} ${workTask.cropYear}` }}</span
            >)
        </span>
        <span v-else-if="workTask.plantCropCode"> ({{ cropName(workTask.plantCropCode) }}) </span>
    </div>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import {getCropByCode} from '@/services/crops-service';
import {CropCode} from '@/enums/crop-code';
import {WorkTaskProductDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskProductDto';

// Props
const props = defineProps<{
    workTask: WorkTaskSearchResultDto;
}>();

const workTask = computed(() => {
    return props.workTask;
});

// Truncate a text if over 50 characters
const truncateText = (text: string | null) => {
    if (text) {
        return text.length <= 50 ? text : text.substring(0, 47) + '...';
    }

    return '';
};

const cropName = (crop: string) => {
    return getCropByCode(crop as CropCode)?.getCropName();
};

// Check if all products contain a particular mixture
const isSameProductMixture = (products: WorkTaskProductDto[], mixtureId: number) => {
    if (products.length === 0) {
        return false;
    }
    return products.every((obj) => obj.mixtureId === mixtureId);
};

// Get product text
const getProductText = (product: WorkTaskProductDto) => {
    const {productName, actualApplicationRate, plannedApplicationRate} = product;
    const applicationRate = actualApplicationRate ?? plannedApplicationRate ?? 0;

    return `${productName}: ${parseFloat(applicationRate.toFixed(2))}L / Ha`;
};

// Get irrigation value
const getIrrigationValue = () => {
    const {irrigationRateActual, irrigationRatePlanned} = workTask.value;
    const irrigationRate = irrigationRateActual ?? irrigationRatePlanned ?? 0;

    return `${parseFloat(irrigationRate.toFixed(2))}`;
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

div {
    color: $swatchA1;
}
</style>
