export enum SwatchCode {
    // Swatch Group A
    SwatchA1 = 'swatchA1',
    SwatchA2 = 'swatchA2',
    SwatchA3 = 'swatchA3',
    SwatchA4 = 'swatchA4',
    SwatchA5 = 'swatchA5',
    SwatchA6 = 'swatchA6',
    SwatchA7 = 'swatchA7',

    // Swatch Group B
    SwatchB1 = 'swatchB1',
    SwatchB2 = 'swatchB2',
    SwatchB3 = 'swatchB3',
    SwatchB4 = 'swatchB4',
    SwatchB5 = 'swatchB5',
    SwatchB6 = 'swatchB6',
    SwatchB7 = 'swatchB7',

    // Swatch Group C
    SwatchC1 = 'swatchC1',
    SwatchC2 = 'swatchC2',
    SwatchC3 = 'swatchC3',
    SwatchC4 = 'swatchC4',
    SwatchC5 = 'swatchC5',
    SwatchC6 = 'swatchC6',
    SwatchC7 = 'swatchC7',

    // Swatch Group GRAY
    SwatchG1 = 'swatchG1',
    SwatchG2 = 'swatchG2',
    SwatchG3 = 'swatchG3',
    SwatchG4 = 'swatchG4',
    SwatchG5 = 'swatchG5',
    SwatchG6 = 'swatchG6',
    SwatchG7 = 'swatchG7',
    SwatchG8 = 'swatchG8',
    SwatchG9 = 'swatchG9',
    SwatchGA = 'swatchGA',
    SwatchGB = 'swatchGB',

    // Swatch Group ALERT
    SwatchERR = 'swatchERR',
    SwatchGRN = 'swatchGRN',
    SwatchORG = 'swatchORG',
    SwatchRED = 'swatchRED',

    // Swatch Group BASIC
    SwatchBG = 'swatchBG',
    SwatchBLK = 'swatchBLK',
    SwatchPBG = 'swatchPBG',
    SwatchWHT = 'swatchWHT',
}
