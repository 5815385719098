<template>
    <div class="ma-0 pa-0" v-if="text && text !== ''">
        <v-row no-gutters align="center" style="white-space: pre-wrap">
            <p v-if="buttonHitTimes % 2 === 1">{{ translatedText || text }}</p>
            <p v-else>{{ text }}</p>

            <span class="pl-1"
                ><v-btn
                    icon
                    small
                    @click.prevent.stop="selectLanguage"
                    :style="{width: width + 'px', height: height + 'px', fontSize: fontSize + 'px'}">
                    <v-icon
                        :class="buttonHitTimes % 2 === 0 ? ['icon-border'] : ['icon-border', 'swatchA1BG', 'swatchWHT']"
                        >mdi-translate</v-icon
                    >
                </v-btn></span
            >
        </v-row>
    </div>
</template>

<script setup lang="ts">
import {ref, inject, computed} from 'vue';
import ApiService from '@/services/api-service.js';
import {useLocaleCodeStore} from '@/stores/locale-code-store';
import i18n from '@/i18n';
const localeCodestore = useLocaleCodeStore();

const apiService = inject('apiService') as ApiService;

const props = withDefaults(
    defineProps<{
        text?: string | null;
        width?: number | null;
        height?: number | null;
        fontSize?: number | null;
    }>(),
    {
        width: 30,
        height: 30,
        fontSize: 16,
    },
);

const translatedText = ref<string>('');
const targetLanguage = ref<string>('');
const buttonHitTimes = ref<number>(0);

const selectedLanguage = computed(() => {
    //if the current locale is null or the local list is empty, set to default
    if (!i18n.global.locale || !(localeCodestore.locales && localeCodestore.locales.length > 0)) return 'en';
    else {
        const currentLocale = localeCodestore.locales.filter((e) => e.localeCultureCode === i18n.global.locale);
        if (currentLocale.length > 0) return currentLocale[0].localeDisplayAbbreviation.toLowerCase();
        else return 'en';
    }
});

const selectLanguage = async () => {
    buttonHitTimes.value += 1;
    targetLanguage.value = selectedLanguage.value;
    if ((!translatedText.value || translatedText.value === '') && buttonHitTimes.value % 2 === 1) await translateText();
};

const translateText = async () => {
    try {
        const response = await apiService.post('translation/translate', {
            text: props.text,
            targetLanguage: targetLanguage.value,
        });

        if (response && response.translatedText) translatedText.value = response.translatedText;
    } catch (error) {
        console.error('Translation error:', error);
    }
};
</script>

<style scoped>
.icon-border {
    border: 1px solid;
}
</style>
