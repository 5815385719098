import {defineStore} from 'pinia';

export const useDeleteFarmSiteModalStore = defineStore('deleteFarmSiteModal', {
    state: () => ({
        isVisible: false,
        farmSiteId: null as number | null,
        deletedCounter: 0,
    }),
    actions: {
        open(farmSiteId: number) {
            // Close modal first (if it's already open) so that the watch is triggered when it's reopened
            this.close();
            this.farmSiteId = farmSiteId;
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
            this.farmSiteId = null;
        },
    },
});
