import {WorkTaskPlanItemForm} from './WorkTaskPlanItemForm';

export class ManageWorkTaskPlanForm {
    workTaskPlanId: number | null = null;
    workTaskPlanName: string | null = null;
    workTaskPlanDescription: string | null = null;
    workTaskPlanItems: WorkTaskPlanItemForm[] = [];
    duplicatedFromWorkTaskPlanId: number | null = null;

    constructor(data?: Partial<ManageWorkTaskPlanForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
