export enum CropCode {
    Batatas = 'Batatas',
    Cosmos = 'Cosmos',
    Cowpeas = 'Cowpeas',
    Lablab = 'Lablab',
    Mungbeans = 'Mungbeans',
    OtherLegume = 'OtherLegume',
    SoyBeans = 'SoyBeans',
    SugarCane = 'SugarCane',
    Sunflowers = 'Sunflowers',
    Sunnhemp = 'Sunnhemp',
    Turnera = 'Turnera',
    Zinnia = 'Zinnia',
}
