import {Point} from '@/models/generic/Point';
import {UserNameDto} from '@/models/data-transfer-objects/users/UserNameDto';

export class ManageFarmSiteForm {
    farmSiteId: number | null = null;
    farmSiteName: string | null = null;
    farmSiteAbbreviation: string | null = null;
    farmSiteDescription: string | null = null;
    farmSiteCentroid: Point | null = null;
    defaultPrimaryCropId: number | null = null;
    defaultRowWidth: string | null = null;
    defaultFarmSiteNamingConventionId: number | null = null;
    associatedFarmFieldNumber: number | null = null;
    userNameList: UserNameDto[] = [];

    constructor(data?: Partial<ManageFarmSiteForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
