<template>
    <v-menu open-on-hover>
        <template v-slot:activator="{props}">
            <v-btn v-bind="props" class="operation-button" rounded="x-large">
                {{ menuText }}
                <FontAwesomeIcon :icon="['fa', 'caret-down']" size="xl" class="ml-2" />
            </v-btn>
        </template>

        <v-list>
            <v-list-item>
                <v-list-item-title>
                    <v-btn @click="viewWorkTaskTemplateModalStore.open(workTaskTemplate.workTaskTemplateId, false)">
                        {{ $t('ViewTaskTemplate') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>
                    <v-btn
                        @click="
                            manageWorkTaskModalStore.open(
                                ManageWorkTaskModalMode.ModifyTemplate,
                                null,
                                null,
                                workTaskTemplate.workTaskTemplateId,
                            )
                        ">
                        {{ $t('ModifyTaskTemplate') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="userStore.hasRoleLevel(RoleCode.PlansAndTemplates, 3) && showDeleteTaskTemplate">
                <v-list-item-title>
                    <v-btn @click="deleteWorkTaskTemplateModalStore.open(workTaskTemplate.workTaskTemplateId)">
                        {{ $t('DeleteTaskTemplate') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>
                    <v-btn
                        @click="
                            manageWorkTaskModalStore.open(
                                ManageWorkTaskModalMode.DuplicateTemplate,
                                null,
                                null,
                                workTaskTemplate.workTaskTemplateId,
                            )
                        ">
                        {{ $t('DuplicateTaskTemplate') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>
                    <v-btn
                        @click="
                            manageWorkTaskModalStore.open(
                                ManageWorkTaskModalMode.Create,
                                null,
                                null,
                                workTaskTemplate.workTaskTemplateId,
                            )
                        ">
                        {{ $t('CreateTaskWithThisTemplate') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>
                    <v-btn @click="viewWorkTaskTemplateModalStore.open(workTaskTemplate.workTaskTemplateId, true)">
                        {{ $t('ExportSlashPrintTaskTemplate') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script setup lang="ts">
import {WorkTaskTemplateSearchResultDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchResultDto';
import {defineProps} from 'vue';
import {ManageWorkTaskModalMode} from '@/enums/modal-modes/manage-work-task-modal-mode';
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
const manageWorkTaskModalStore = useManageWorkTaskModalStore();
import {useViewWorkTaskTemplateModalStore} from '@/stores/modals/view-work-task-template-modal-store';
const viewWorkTaskTemplateModalStore = useViewWorkTaskTemplateModalStore();
import {useDeleteWorkTaskTemplateModalStore} from '@/stores/modals/delete-work-task-template-modal-store';
const deleteWorkTaskTemplateModalStore = useDeleteWorkTaskTemplateModalStore();
import i18n from '@/i18n';
import {useUserStore} from '@/stores/user-store';
const userStore = useUserStore();
import {RoleCode} from '@/enums/role-code';

withDefaults(
    defineProps<{
        workTaskTemplate: WorkTaskTemplateSearchResultDto;
        menuText?: string;
        showDeleteTaskTemplate?: boolean;
    }>(),
    {
        menuText: i18n.global.t('Operations'),
        showDeleteTaskTemplate: true,
    },
);
</script>
