import {WorkTaskDueDateTiming} from '@/enums/work-task-due-date-timing';
import {MonthAndYear} from '../generic/MonthAndYear';

export class ApplyWorkTaskPlanForm {
    farmFieldId: number | null = null;
    workTaskPlanId: number | null = null;
    dueDateTiming: WorkTaskDueDateTiming = WorkTaskDueDateTiming.DueBy;
    dueDateOn: Date | null = null;
    dueDateLatest: Date | null = null;
    dueDateMonth: MonthAndYear | null = null;
    dueDateRange: Date[] = [];
    dueAfterWorkTaskId: number | null = null;
    dueAfterWorkTaskWithinDays: number | null = null;
    applyFromSequenceNumber: number | null = null;

    constructor(data?: Partial<ApplyWorkTaskPlanForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
