import {SwatchCode} from '@/enums/swatch-code';
import {ProductTypeCode} from '@/enums/product-type-code';
import {ProductSubtypeCode} from '@/enums/product-subtype-code';
import i18n from '@/i18n';

export interface ProductSubtype {
    ProductTypeCode: ProductTypeCode;
    getProductTypeName(): string;
    ProductSubtypeCode: ProductSubtypeCode;
    getProductSubtypeName(): string;
    iconFamily: string;
    iconCode: string;
    color: string;
}

export const ProductSubtypes: ProductSubtype[] = [
    {
        ProductTypeCode: ProductTypeCode.Chemical,
        getProductTypeName() {
            return i18n.global.t('Chemical');
        },
        ProductSubtypeCode: ProductSubtypeCode.Fungicide,
        getProductSubtypeName() {
            return i18n.global.t('Fungicide');
        },
        iconFamily: 'fas',
        iconCode: 'mushroom',
        color: SwatchCode.SwatchB1,
    },
    {
        ProductTypeCode: ProductTypeCode.Chemical,
        getProductTypeName() {
            return i18n.global.t('Chemical');
        },
        ProductSubtypeCode: ProductSubtypeCode.Herbicide,
        getProductSubtypeName() {
            return i18n.global.t('Herbicide');
        },
        iconFamily: 'fas',
        iconCode: 'plant-wilt',
        color: SwatchCode.SwatchB1,
    },
    {
        ProductTypeCode: ProductTypeCode.Chemical,
        getProductTypeName() {
            return i18n.global.t('Chemical');
        },
        ProductSubtypeCode: ProductSubtypeCode.Insecticide,
        getProductSubtypeName() {
            return i18n.global.t('Insecticide');
        },
        iconFamily: 'fas',
        iconCode: 'bug',
        color: SwatchCode.SwatchB1,
    },
    {
        ProductTypeCode: ProductTypeCode.Chemical,
        getProductTypeName() {
            return i18n.global.t('Chemical');
        },
        ProductSubtypeCode: ProductSubtypeCode.OtherChemical,
        getProductSubtypeName() {
            return i18n.global.t('OtherChemical');
        },
        iconFamily: 'fas',
        iconCode: 'flask-vial',
        color: SwatchCode.SwatchB1,
    },
    {
        ProductTypeCode: ProductTypeCode.Fertilizer,
        getProductTypeName() {
            return i18n.global.t('Fertilizer');
        },
        ProductSubtypeCode: ProductSubtypeCode.Fertilizer,
        getProductSubtypeName() {
            return i18n.global.t('Fertilizer');
        },
        iconFamily: 'fas',
        iconCode: 'box',
        color: SwatchCode.SwatchA4,
    },
    {
        ProductTypeCode: ProductTypeCode.Fertilizer,
        getProductTypeName() {
            return i18n.global.t('Fertilizer');
        },
        ProductSubtypeCode: ProductSubtypeCode.OtherFertilizer,
        getProductSubtypeName() {
            return i18n.global.t('OtherFertilizer');
        },
        iconFamily: 'fas',
        iconCode: 'poop',
        color: SwatchCode.SwatchA4,
    },
    {
        ProductTypeCode: ProductTypeCode.Fertilizer,
        getProductTypeName() {
            return i18n.global.t('Fertilizer');
        },
        ProductSubtypeCode: ProductSubtypeCode.SoilConditioner,
        getProductSubtypeName() {
            return i18n.global.t('SoilConditioner');
        },
        iconFamily: 'fas',
        iconCode: 'jug-detergent',
        color: SwatchCode.SwatchA4,
    },
    {
        ProductTypeCode: ProductTypeCode.Other,
        getProductTypeName() {
            return i18n.global.t('Other');
        },
        ProductSubtypeCode: ProductSubtypeCode.Other,
        getProductSubtypeName() {
            return i18n.global.t('Other');
        },
        iconFamily: 'fas',
        iconCode: 'shower',
        color: SwatchCode.SwatchG3,
    },
    {
        ProductTypeCode: ProductTypeCode.Other,
        getProductTypeName() {
            return i18n.global.t('Other');
        },
        ProductSubtypeCode: ProductSubtypeCode.Adjuvant,
        getProductSubtypeName() {
            return i18n.global.t('Adjuvant');
        },
        iconFamily: 'fas',
        iconCode: 'vial',
        color: SwatchCode.SwatchG3,
    },
];

export function getProductSubtypeByCode(ProductSubtypeCode: ProductSubtypeCode): ProductSubtype | undefined {
    return ProductSubtypes.find((type) => type.ProductSubtypeCode === ProductSubtypeCode);
}
