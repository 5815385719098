import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a4ecf828"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "observation-type-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ObservationTypeChips = _resolveComponent("ObservationTypeChips")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["observation-type-select-box noSelection", {selected: _ctx.isSelected, disabled: !_ctx.isEnabled}]),
    title: _ctx.isEnabled ? '' : _ctx.$t('TaskTypeCannotBeChanged')
  }, [
    _createVNode(_component_v_row, { class: "pa-2" }, {
      default: _withCtx(() => [
        _createVNode(_component_ObservationTypeChips, {
          "observation-type": _ctx.observationTypeCode,
          "is-square-background": true
        }, null, 8, ["observation-type"]),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.name), 1)
      ]),
      _: 1
    })
  ], 10, _hoisted_1))
}