<template>
    <div>
        <div class="swatchG9BG">
            <div class="observation-tabs-row">
                <v-tabs
                    height="42"
                    v-model="homeDashboardStore.isObservationOpenMode"
                    bg-color="swatchG9"
                    selected-class="active-tab">
                    <v-tab class="observation-tab" :value="false">
                        <div>
                            <FontAwesomeIcon :icon="['fal', 'location-check']" size="xl" />
                            {{ $t('Closed') }}
                        </div>
                    </v-tab>
                    <v-tab class="observation-tab" :value="true">
                        <div>
                            <FontAwesomeIcon :icon="['fal', 'location-dot']" size="xl" />
                            {{ $t('Open') }}
                        </div>
                    </v-tab>
                </v-tabs>
            </div>
        </div>
        <LoadingSymbol v-if="isLoading" />
        <div v-else-if="filteredObservations.length > 0">
            <v-list class="list-container">
                <v-list-item
                    v-for="observation in filteredObservations"
                    :key="observation.observationId"
                    :id="'navigatorTabObservation_' + observation.observationId"
                    class="list-item-container"
                    :class="{
                        selected: homeDashboardStore.isObservationSelected(observation),
                        'is-moused-over': homeDashboardStore.isObservationMousedOver(observation),
                    }"
                    @mouseenter="homeDashboardStore.onObservationMouseOver(observation.observationId)"
                    @mouseleave="homeDashboardStore.onObservationMouseOut"
                    @click="() => selectItem(observation)"
                    @dblclick="() => handleRowDoubleClick(observation.observationId)"
                    style="padding: 5px"
                    rounded>
                    <div class="flex-row pl-0">
                        <div class="observation-icon-container">
                            <ObservationTypeChips
                                icon-height="37"
                                icon-width="30"
                                icon-size="21"
                                :observationType="observation.observationTypeCode" />
                        </div>
                        <div class="observation-name-container">
                            <span v-if="observation.farmFieldName && displayFieldName(observation)"
                                >"{{ observation.farmFieldName }}":
                            </span>
                            <span :style="{color: getObservationTypeColour(observation.observationTypeCode)}">
                                {{ observation.observationTypeName }}
                            </span>
                        </div>
                        <div class="observation-status-container">
                            <ObservationStatusChip
                                :observation-status-code="observation.observationStatusCode"
                                width="80px"
                                height="23px" />
                        </div>
                    </div>
                    <!--Observation Recorded-->
                    <div class="flex-row">
                        <div class="icon-container">
                            <FontAwesomeIcon class="swatchG3" :icon="['fa', 'calendar-day']" size="xl" />
                        </div>
                        <div class="date-container">{{ formatDate(observation.recordedDate) }}</div>
                        <div class="user-chip">
                            <UserChip
                                v-if="observation.recordedUserId"
                                :user="
                                    UserHelper.userChipType(
                                        observation.recordedUserId,
                                        UserHelper.userFullName(
                                            observation.recordedUserGivenName,
                                            observation.recordedUserSurname,
                                        ),
                                    )
                                " />
                        </div>
                    </div>
                    <!--Observation In Progress-->
                    <div v-if="observation.observationStatusCode === ObservationStatusCode.InProgress" class="flex-row">
                        <div class="icon-container">
                            <FontAwesomeIcon class="swatchB6" :icon="['fa', 'calendar-lines-pen']" size="xl" />
                        </div>
                        <div class="date-container">{{ formatDate(observation.lastModifiedDate) }}</div>
                        <div class="user-chip">
                            <UserChip
                                v-if="observation.lastModifiedUserId"
                                :user="
                                    UserHelper.userChipType(
                                        observation.lastModifiedUserId,
                                        UserHelper.userFullName(
                                            observation.lastModifiedUserGivenName,
                                            observation.lastModifiedUserSurname,
                                        ),
                                    )
                                " />
                        </div>
                    </div>
                    <!--Observation Acknowledged-->
                    <div
                        v-if="observation.observationStatusCode === ObservationStatusCode.Acknowledged"
                        class="flex-row">
                        <div class="icon-container">
                            <FontAwesomeIcon class="swatchB6" :icon="['fa', 'calendar-lines-pen']" size="xl" />
                        </div>
                        <div class="date-container">{{ formatDate(observation.acknowledgedDate) }}</div>
                        <div class="user-chip">
                            <UserChip
                                v-if="observation.acknowledgedUserId"
                                :user="
                                    UserHelper.userChipType(
                                        observation.acknowledgedUserId,
                                        UserHelper.userFullName(
                                            observation.acknowledgedUserGivenName,
                                            observation.acknowledgedUserSurname,
                                        ),
                                    )
                                " />
                        </div>
                    </div>
                    <!--Observation Resolved-->
                    <div v-if="observation.observationStatusCode === ObservationStatusCode.Resolved" class="flex-row">
                        <div class="icon-container">
                            <FontAwesomeIcon class="swatchGRN" :icon="['fa', 'calendar-check']" size="xl" />
                        </div>
                        <div class="date-container">{{ formatDate(observation.resolvedDate) }}</div>
                        <div class="user-chip">
                            <UserChip
                                v-if="observation.resolvedUserId"
                                :user="
                                    UserHelper.userChipType(
                                        observation.resolvedUserId,
                                        UserHelper.userFullName(
                                            observation.resolvedUserGivenName,
                                            observation.resolvedUserSurname,
                                        ),
                                    )
                                " />
                        </div>
                    </div>
                    <!--Observation Archived-->
                    <div v-if="observation.observationStatusCode === ObservationStatusCode.Archived" class="flex-row">
                        <div class="icon-container">
                            <FontAwesomeIcon class="swatchG3" :icon="['fa', 'calendar']" size="xl" />
                        </div>
                        <div class="date-container">
                            {{ formatDate(observation.archivedDate) }}
                        </div>
                        <div class="user-chip">
                            <UserChip
                                v-if="observation.archivedUserId"
                                :user="
                                    UserHelper.userChipType(
                                        observation.archivedUserId,
                                        UserHelper.userFullName(
                                            observation.archivedUserGivenName,
                                            observation.archivedUserSurname,
                                        ),
                                    )
                                " />
                        </div>
                    </div>
                    <!--Observation Cancelled-->
                    <div v-if="observation.observationStatusCode === ObservationStatusCode.Cancelled" class="flex-row">
                        <div class="icon-container">
                            <FontAwesomeIcon class="swatchG3" :icon="['fa', 'calendar']" size="xl" />
                        </div>
                        <div class="date-container">
                            {{ formatDate(observation.cancelledDate) }}
                        </div>
                        <div class="user-chip">
                            <UserChip
                                v-if="observation.cancelledUserId"
                                :user="
                                    UserHelper.userChipType(
                                        observation.cancelledUserId,
                                        UserHelper.userFullName(
                                            observation.cancelledUserGivenName,
                                            observation.cancelledUserSurname,
                                        ),
                                    )
                                " />
                        </div>
                    </div>
                    <div class="flex-row">
                        <div class="comments-container">
                            <TranslatableTexts :text="observation.observationComments" />
                        </div>
                    </div>
                </v-list-item>
            </v-list>
        </div>
        <div v-else class="pa-1">
            <div class="no-observations-warning pa-4">
                <div style="font-size: large; font-weight: bold">{{ $t('NoObservations') }}</div>
                {{ noObservationDataText }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {ref, computed, watch, nextTick} from 'vue';
import {ObservationSearchResultDto} from '@/models/data-transfer-objects/search/observation-search/ObservationSearchResultDto';
import UserChip from '@/components/UserChip.vue';
import {format} from 'date-fns';
import {ObservationStatusCode} from '@/enums/observaton-status-code';
import i18n from '@/i18n';
import ObservationStatusChip from '@/components/ObservationStatusChip.vue';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import ObservationTypeChips from '@/components/ObservationTypeChips.vue';
import {getObservationTypeByCode} from '@/services/observation-types-service';
import {useViewObservationModalStore} from '@/stores/modals/view-observation-modal-store';
import {RoleCode} from '@/enums/role-code';
import {useUserStore} from '@/stores/user-store';
import UserHelper from '@/helpers/UserHelper';

// Stores
const homeDashboardStore = useHomeDashboardStore();
const viewObservationModalStore = useViewObservationModalStore();
const userStore = useUserStore();

const hasObservationAccess = userStore.hasRoleLevel(RoleCode.Observations, 1);

// Loading
const isLoading = ref(false);

// Observations after being filtered
const filteredObservations = computed(() => {
    if (homeDashboardStore.isObservationOpenMode) {
        if (homeDashboardStore.observationOpenFilterApplied) {
            return homeDashboardStore.openObservationsFiltered.filter((o) => !o.isClosed);
        } else {
            return homeDashboardStore.observations.filter((o) => !o.isClosed);
        }
    } else {
        if (homeDashboardStore.observationClosedFilterApplied) {
            return homeDashboardStore.closedObservationsFiltered.filter((o) => o.isClosed);
        } else {
            return homeDashboardStore.observations.filter((o) => o.isClosed);
        }
    }
});

// Get no observation data text
const noObservationDataText = computed(() => {
    if (hasObservationAccess) {
        if (homeDashboardStore.isObservationOpenMode) {
            return i18n.global.t('NavigatorObservationList_NoOpenDataText');
        } else {
            return i18n.global.t('NavigatorObservationList_NoClosedDataText');
        }
    } else {
        return i18n.global.t('YouDoNotHaveObservationAccess');
    }
});

// Determines if field name should be truncated
const displayFieldName = (observation: ObservationSearchResultDto): boolean => {
    const titleLength = (observation.farmFieldName + observation.observationTypeName).length;

    return titleLength < 25;
};

// Format date to necessary structure
const formatDate = (date: Date | null): string | null => {
    if (!date) return null;

    return format(date, 'dd MMM, yyyy');
};

// Get observation type colour
const getObservationTypeColour = (observationTypeCode: string) => {
    return getObservationTypeByCode(observationTypeCode)?.color;
};

// Scroll to the specified observation. This is used to:
//  1. Bring the observation fully into view if it's only partially visible when selecting it on the navigator panel
//  2. Bring the observation fully into view when selecting it on the map
const scrollToObservation = (observation: ObservationSearchResultDto) => {
    // Ensure the correct tab is selected
    if (observation.isClosed) {
        homeDashboardStore.isObservationOpenMode = false;
    } else if (!observation.isClosed) {
        homeDashboardStore.isObservationOpenMode = true;
    }

    // nextTick is used here to handle situations where the tab needs to be changed
    nextTick(() => {
        const element = document.getElementById('navigatorTabObservation_' + observation.observationId);
        if (element) {
            // Get the top position
            const header = document.querySelector('.divMasterHeaderRow');
            const panel = document.querySelector('.navigator-panel-tabs');
            const operations = document.querySelector('.navigator-panel-operations');
            const headerHeight = header ? header.clientHeight : 0;
            const panelHeight = panel ? panel.clientHeight : 0;
            const operationsHeight = operations ? operations.clientHeight : 0;
            const topPosition = headerHeight + panelHeight + operationsHeight;

            // Check if the top/bottom of the farm field item is in view
            const rect = element.getBoundingClientRect();
            const isTopInView = rect.top >= topPosition;
            const isBottomInView = rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);

            // Scroll the farm field item into view if necessary
            if (!isTopInView) {
                element.scrollIntoView({behavior: 'smooth', block: 'start'});
            } else if (!isBottomInView) {
                element.scrollIntoView({behavior: 'smooth', block: 'end'});
            }
        }
    });
};

// Timeout for row clicks
const clickTimeout = ref<number | null>(null);

// Select item
const selectItem = async (item: ObservationSearchResultDto) => {
    // Clear the timeout if it exists to avoid triggering the single-click event
    if (clickTimeout.value) {
        clearTimeout(clickTimeout.value);
        clickTimeout.value = null;
    }

    // Set a timeout to wait for a possible double-click
    clickTimeout.value = window.setTimeout(() => {
        homeDashboardStore.onObservationClicked(item);
        clickTimeout.value = null;
    }, 200);
};

// Handle double click row
const handleRowDoubleClick = (observationId: number) => {
    // Clear the timeout to prevent the single-click event from firing
    if (clickTimeout.value) {
        clearTimeout(clickTimeout.value);
        clickTimeout.value = null;
    }

    viewObservationModalStore.open(observationId);
};

// Scroll the selected observation into view
watch(
    () => homeDashboardStore.selectedObservationLevel1,
    () => {
        if (homeDashboardStore.selectedObservationLevel1) {
            scrollToObservation(homeDashboardStore.selectedObservationLevel1);
        }
    },
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.list-container {
    padding: 5px;
    background-color: $swatchBG;
}

.observation-tabs-row {
    width: 383px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .observation-tab {
        color: $swatchBLK;
        width: 120px;
        padding-bottom: 5px;

        &.active-tab {
            border-bottom: 5px solid $swatchB6;
            padding-bottom: 0px;
        }
        &:hover {
            background-color: rgba($swatchB7, 0.5);
        }
    }
}

.list-item-container {
    width: 100%;
    border-radius: 5px;
    border: 1px solid $swatchG7;
    background-color: $swatchWHT;
    margin-bottom: 10px;
    &.selected,
    &:hover,
    &.is-moused-over {
        background-color: rgba($swatchB7, 0.5);
        border: 1px solid $swatchB6;
    }

    .flex-row {
        display: flex;
        gap: 5px;
        padding-left: 37px;

        .observation-icon-container {
            width: 30px;
            height: 37px;
        }

        .observation-name-container {
            width: 220px;
            height: 30px;
            font-weight: bold;
        }

        .observation-status-container {
            margin-left: auto;
        }

        .icon-container {
            width: 25px;
            height: 25px;
        }

        .date-container {
            width: 110px;
            height: 30px;
        }

        .user-chip {
            width: 175px;
            height: 30px;
        }

        .comments-container {
            width: 320px;
            min-height: 30px;
            max-height: 48px;
            font-weight: bold;
        }
    }
}

.no-observations-warning {
    background-color: $swatchG6;
    border-radius: 5px;
    text-align: center;
}
</style>
