<template>
    <v-input v-model="workTaskTypeCodeInternal" :rules="rules" required hide-details="auto">
        <div>
            <div style="display: flex">
                <WorkTaskTypeSelectBox
                    :work-task-type-code="WorkTaskTypeCode.Cultivate"
                    @click="clickWorkTaskType(WorkTaskTypeCode.Cultivate)"
                    :is-selected="workTaskTypeCodeInternal == WorkTaskTypeCode.Cultivate"
                    :is-enabled="isEnabled" />

                <WorkTaskTypeSelectBox
                    :work-task-type-code="WorkTaskTypeCode.Fertilize"
                    @click="clickWorkTaskType(WorkTaskTypeCode.Fertilize)"
                    :is-selected="workTaskTypeCodeInternal == WorkTaskTypeCode.Fertilize"
                    :is-enabled="isEnabled"
                    class="ml-4" />

                <WorkTaskTypeSelectBox
                    :work-task-type-code="WorkTaskTypeCode.Spray"
                    @click="clickWorkTaskType(WorkTaskTypeCode.Spray)"
                    :is-selected="workTaskTypeCodeInternal == WorkTaskTypeCode.Spray"
                    :is-enabled="isEnabled"
                    class="ml-4" />
            </div>
            <div style="display: flex" class="pt-4">
                <WorkTaskTypeSelectBox
                    :work-task-type-code="WorkTaskTypeCode.Irrigate"
                    @click="clickWorkTaskType(WorkTaskTypeCode.Irrigate)"
                    :is-selected="workTaskTypeCodeInternal == WorkTaskTypeCode.Irrigate"
                    :is-enabled="isEnabled" />

                <WorkTaskTypeSelectBox
                    :work-task-type-code="WorkTaskTypeCode.Plant"
                    @click="clickWorkTaskType(WorkTaskTypeCode.Plant)"
                    :is-selected="workTaskTypeCodeInternal == WorkTaskTypeCode.Plant"
                    :is-enabled="isEnabled"
                    class="ml-4" />

                <WorkTaskTypeSelectBox
                    :work-task-type-code="WorkTaskTypeCode.Harvest"
                    @click="clickWorkTaskType(WorkTaskTypeCode.Harvest)"
                    :is-selected="workTaskTypeCodeInternal == WorkTaskTypeCode.Harvest"
                    :is-enabled="isEnabled"
                    class="ml-4" />
            </div>
        </div>
    </v-input>
</template>

<script setup lang="ts">
import {ref, watch, defineProps, defineEmits} from 'vue';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import WorkTaskTypeSelectBox from '@/components/WorkTaskTypeSelectBox.vue';

const props = defineProps<{
    modelValue: WorkTaskTypeCode | null;
    rules: Array<(v: string | null) => boolean | string>;
    isEnabled: boolean;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: WorkTaskTypeCode | null): void;
}>();

const workTaskTypeCodeInternal = ref<WorkTaskTypeCode | null>(props.modelValue);

watch(
    () => props.modelValue,
    (newValue) => {
        workTaskTypeCodeInternal.value = newValue;
    },
);

/**
 * Select or deselect the work task type.
 */
const clickWorkTaskType = (workTaskTypeCode: WorkTaskTypeCode) => {
    if (!props.isEnabled) return;

    if (workTaskTypeCodeInternal.value == workTaskTypeCode) {
        // If already selected, deselect work task type
        workTaskTypeCodeInternal.value = null;
    } else {
        // Select work task type
        workTaskTypeCodeInternal.value = workTaskTypeCode;
    }

    emit('update:modelValue', workTaskTypeCodeInternal.value);
};
</script>
