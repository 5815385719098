import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d81de85"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chip-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_CircleIcon = _resolveComponent("CircleIcon")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock(_component_v_tooltip, { bottom: "" }, {
    activator: _withCtx(({props}) => [
      _createVNode(_component_v_chip, _mergeProps(props, {
        variant: "flat",
        size: _ctx.size,
        density: "comfortable",
        color: _ctx.lighterColor,
        pill: "",
        class: "pr-5 noSelection"
      }), {
        default: _withCtx(() => [
          _createVNode(_component_v_avatar, { start: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_CircleIcon, {
                style: {height: '40px', width: '40px'},
                class: _normalizeClass(_ctx.iconClass)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FontAwesomeIcon, {
                    icon: ['fal', 'list-timeline'],
                    size: _ctx.iconSize
                  }, null, 8, ["size"])
                ]),
                _: 1
              }, 8, ["class"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", null, _toDisplayString(_ctx.numberOfWorkTaskPlans?.toLocaleString()), 1)
          ])
        ]),
        _: 2
      }, 1040, ["size", "color"])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "helpText", {}, undefined, true),
      _createTextVNode(" " + _toDisplayString(_ctx.tooltipMessage), 1)
    ]),
    _: 3
  }))
}