<template>
    <v-toolbar class="swatchA2BG">
        <font-awesome-icon :icon="['fad', 'box-taped']" size="xl" class="swatchWHT ml-3" />
        <v-toolbar-title class="swatchWHT product-data-table-title"
            >{{ $t('SprayAndFertilizerProducts') + ` (${resultsList.length})` }}
        </v-toolbar-title>
        <v-tabs v-model="tab" bg-color="swatchA2" selected-class="swatchWHTBG swatchBLK" class="crop-variety-tab">
            <v-tab value="chemical">
                <FontAwesomeIcon :icon="['fas', 'flask-vial']" size="xl" class="mr-2" />
                {{ $t('Chemicals') + ` (${chemicalList.length})` }}
            </v-tab>
            <v-tab value="fertilizer">
                <FontAwesomeLayers class="rounded-text fa-lg mr-2">
                    <FontAwesomeIcon :icon="['fas', 'box']" transform="left-3" size="xl" />
                    <FontAwesomeIcon
                        :icon="['fas', 'seedling']"
                        class="swatchA2"
                        size="xl"
                        transform="shrink-9 left-4 down-2" />
                </FontAwesomeLayers>
                {{ $t('Fertilisers') + ` (${fertilizerList.length})` }}
            </v-tab>
            <v-tab value="other">
                <FontAwesomeIcon :icon="['fas', 'vial']" size="xl" class="mr-2" />
                {{ $t('Other') + ` (${otherList.length})` }}
            </v-tab>
        </v-tabs>

        <v-spacer></v-spacer>

        <v-btn :class="filterButtonClass" rounded="1" @click="switchBackToSearch">
            <template v-slot:prepend>
                <FontAwesomeIcon :icon="['fal', 'filter']" size="xl" v-if="isFilterApplied" />
                <FontAwesomeIcon :icon="['fac', 'filter-check']" size="xl" v-else />
            </template>
            {{ $t('Filters') }}
        </v-btn>
    </v-toolbar>
    <v-data-table
        :headers="headers"
        :items="filteredList"
        :items-per-page="configStore.defaultListItemNumberPerPage"
        @click:row="viewProduct"
        class="product-search-list">
        <template v-slot:[`item.productName`]="{item}">
            <div class="tableCell">
                <span class="swatchA1 slightlyLarger"> {{ item.productName }} </span>
            </div>
        </template>
        <template v-slot:[`item.productSubtypeCode`]="{item}">
            <v-col cols="12" align="center">
                <ProductSubtypeIcon
                    :productSubtypeCode="item.productSubtypeCode"
                    :product-type-code="item.productTypeCode" />
            </v-col>
        </template>
        <template v-slot:[`item.farmFieldApplied`]="{item}">
            <v-col cols="12" align="center" class="swatchA1">
                {{ parseFloat(item.farmFieldApplied ? item.farmFieldApplied.toFixed(2) : '0') + ' Ha' }}
            </v-col>
        </template>
        <template v-slot:[`item.productLastUseDate`]="{item}">
            <div v-if="item.productLastUseDate" class="swatchA1">
                {{ formatDate(item.productLastUseDate) }}
            </div>
            <div v-else style="font-style: italic">{{ $t('Never') }}</div>
        </template>
        <template v-slot:[`item.mixtureCount`]="{item}">
            {{ item.mixtureCount }}
            <span class="pl-2" v-if="item.mixtureCount > 0"
                ><v-btn
                    class="v-btn--custom"
                    @click.prevent.stop="advancedSearchModalStore.close()"
                    :to="`/mixture-list/${item.productId}`"
                    ><FontAwesomeIcon :icon="['fa', 'flask-round-potion']" class="pr-2" size="xl" />{{
                        $t('View')
                    }}</v-btn
                ></span
            >
        </template>

        <template v-slot:[`item.detailedArtifacts`]="{item}">
            <div style="max-width: 220px">
                <span v-for="(artifact, index) in item.detailedArtifacts" :key="artifact.artifactId">
                    <span class="pr-1" v-if="index <= 0 + (item.links.length > 0 ? 0 : 1)">
                        <FileTypeButton
                            :url="artifact.artifactUrl"
                            :display-name="artifact.artifactDisplayName"
                            :file-type-code="artifact.artifactFileTypeCode" />
                    </span>
                </span>

                <span v-for="(link, index) in item.links" :key="link.linkId">
                    <span v-if="index <= 0 + (item.detailedArtifacts.length > 0 ? 0 : 1)" class="pr-1">
                        <FileTypeButton
                            :url="link.linkUrl"
                            :display-name="link.linkName"
                            :file-type-code="ArtifactFileTypeCode.Other" />
                    </span>
                </span>

                <span class="pr-1"></span>
                <span v-if="item.detailedArtifacts && item.detailedArtifacts.length + item.links.length >= 3">
                    <v-menu>
                        <template v-slot:activator="{props}">
                            <v-btn class="v-btn--custom" v-bind="props"
                                ><font-awesome-icon :icon="['fas', 'ellipsis']"
                            /></v-btn>
                        </template>

                        <v-list>
                            <v-list-item
                                v-for="artifact in item.detailedArtifacts.slice(1 + (item.links.length > 0 ? 0 : 1))"
                                :key="artifact.artifactId">
                                <v-list-item-title>
                                    <FileTypeButton
                                        :url="artifact.artifactUrl"
                                        :display-name="artifact.artifactDisplayName"
                                        :file-type-code="artifact.artifactFileTypeCode" />
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                                v-for="link in item.links.slice(1 + (item.detailedArtifacts.length > 0 ? 0 : 1))"
                                :key="link.linkId">
                                <v-list-item-title>
                                    <FileTypeButton
                                        :url="link.linkUrl"
                                        :display-name="link.linkName"
                                        :file-type-code="ArtifactFileTypeCode.Other" />
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </span>
            </div>
        </template>

        <template v-slot:[`item.action`]="{item}">
            <div style="text-align: center">
                <v-menu>
                    <template v-slot:activator="{props}">
                        <v-btn class="v-btn--custom" v-bind="props"> {{ $t('Actions') }} </v-btn>
                    </template>

                    <v-list>
                        <v-list-item>
                            <v-list-item-title>
                                <v-btn @click="viewProductModalStore.open(item.productId)">{{
                                    $t('ViewProduct')
                                }}</v-btn>
                            </v-list-item-title>
                            <v-list-item-title>
                                <v-btn
                                    @click="advancedSearchModalStore.close()"
                                    :to="`/mixture-list/${item.productId}`">
                                    {{ $t('ViewMixturesWithThisProduct') }}
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </template>
    </v-data-table>
</template>

<script setup lang="ts">
import {ref, computed} from 'vue';
import {ProductDto} from '@/models/data-transfer-objects/search/product-search/ProductSearchResultDto';
import {format} from 'date-fns';
import i18n from '@/i18n';
import ProductSubtypeIcon from '@/components/ProductSubtypeIcon.vue';
import {ProductTypeCode} from '@/enums/product-type-code';
import FileTypeButton from '@/components/FileTypeButton.vue';
import {ArtifactFileTypeCode} from '@/enums/artifact-file-type-code';
import {useViewProductModalStore} from '@/stores/modals/view-product-modal-store';
const viewProductModalStore = useViewProductModalStore();
import {useAdvancedSearchModalStore} from '@/stores/modals/advanced-search-modal-store';
const advancedSearchModalStore = useAdvancedSearchModalStore();
import {useConfigStore} from '@/stores/config-store';
const configStore = useConfigStore();
const props = withDefaults(
    defineProps<{
        resultsList: ProductDto[];
        isFilterApplied: boolean;
    }>(),
    {
        resultsList: () => [] as ProductDto[],
        isFilterApplied: false,
    },
);

const chemicalList = computed(() => props.resultsList.filter((e) => e.productTypeCode === ProductTypeCode.Chemical));
const fertilizerList = computed(() =>
    props.resultsList.filter((e) => e.productTypeCode === ProductTypeCode.Fertilizer),
);
const otherList = computed(() => props.resultsList.filter((e) => e.productTypeCode === ProductTypeCode.Other));

const filteredList = computed(() => {
    switch (tab.value) {
        case 'fertilizer':
            return fertilizerList.value;
        case 'other':
            return otherList.value;
        default:
            return chemicalList.value;
    }
});

const emit = defineEmits<{
    (event: 'switch-to-search'): void;
}>();

const switchBackToSearch = () => {
    emit('switch-to-search');
};
const tab = ref<string>('chemical');

const viewProduct = (event: MouseEvent, row: {item: ProductDto}) => {
    viewProductModalStore.open(row.item.productId);
};

const headers = ref([
    {title: i18n.global.t('Name'), key: 'productName', sortable: true},
    {title: i18n.global.t('ProductType'), key: 'productSubtypeCode', sortable: true},
    {title: i18n.global.t('BrandName'), key: 'productBrandName', sortable: true},
    {title: i18n.global.t('LastUsed'), key: 'productLastUseDate', sortable: true},
    {title: i18n.global.t('12MonthApplication'), key: 'farmFieldApplied', sortable: true},
    {title: i18n.global.t('MixturesUsed'), key: 'mixtureCount', sortable: true},
    {title: i18n.global.t('DateSheetsAndLinks'), key: 'detailedArtifacts', sortable: false},
    {title: i18n.global.t('Action'), key: 'action', sortable: true},
]);

/**
 * Format date for report results.
 */
const formatDate = (date: Date): string => {
    if (!date) return '';

    return format(date, 'dd MMM, yyyy');
};

const filterButtonClass = computed(() => (props.isFilterApplied ? ['v-btn--custom'] : ['swatchB6BG', 'swatchWHT']));
</script>
<style lang="scss">
@import '@/assets/scss/swatches.scss';
.product-data-table-title {
    font-size: 1rem;
    max-width: 300px;
}

.product-search-list .v-table__wrapper thead {
    background-color: $swatchG9;
}
</style>
