import {SeedRateUnitCode} from '@/enums/seed-rate-unit-code';
import i18n from '@/i18n';

export interface SeedRateUnit {
    seedRateUnitCode: SeedRateUnitCode;
    getSeedRateUnitName(): string;
    isDefault: boolean;
}

export const seedRateUnits: SeedRateUnit[] = [
    {
        seedRateUnitCode: SeedRateUnitCode.BilletsPerHa,
        getSeedRateUnitName() {
            return i18n.global.t('BilletsPerHa');
        },
        isDefault: false,
    },
    {
        seedRateUnitCode: SeedRateUnitCode.KgPerHa,
        getSeedRateUnitName() {
            return i18n.global.t('KgPerHa');
        },
        isDefault: true,
    },
    {
        seedRateUnitCode: SeedRateUnitCode.PlantletsPerHa,
        getSeedRateUnitName() {
            return i18n.global.t('PlantletsPerHa');
        },
        isDefault: false,
    },
    {
        seedRateUnitCode: SeedRateUnitCode.SeedsPerHa,
        getSeedRateUnitName() {
            return i18n.global.t('SeedsPerHa');
        },
        isDefault: false,
    },
    {
        seedRateUnitCode: SeedRateUnitCode.TonnesPerHa,
        getSeedRateUnitName() {
            return i18n.global.t('TonnesPerHa');
        },
        isDefault: false,
    },
];

export function getSeedRateUnitByCode(seedRateUnitCode: SeedRateUnitCode): SeedRateUnit | undefined {
    return seedRateUnits.find((aru) => aru.seedRateUnitCode === seedRateUnitCode);
}
