<template>
    <div class="rounded-text custom-background">
        <CircleIcon class="custom-chip custom-icon swatchG2BG">
            <FontAwesomeIcon
                :icon="['fa', 'tally-3']"
                class="rounded-text custom-background"
                style="padding-left: 3px" />
        </CircleIcon>
        <span class="pr-2" style="padding-left: 5px" v-if="numberOfRows || numberOfRows === 0">{{
            `${numberOfRows} ${numberOfRows > 1 ? $t('Rows') : $t('Row')} ${
                directionText ? '(' + directionText + ')' : ''
            }`
        }}</span>
    </div>
</template>

<script lang="ts">
import {PropType, defineComponent, computed} from 'vue';
import {FarmFieldRowDirection} from '@/enums/farm-field-row-direction';

export default defineComponent({
    name: 'RowChip',
    props: {
        numberOfRows: Number as PropType<number>,
        rowDirection: {
            type: String as PropType<FarmFieldRowDirection>,
            required: false,
        },
    },
    setup(props) {
        const directionText = computed(() => {
            switch (props.rowDirection) {
                case FarmFieldRowDirection.EastToWest:
                    return 'EW';
                case FarmFieldRowDirection.NorthToSouth:
                    return 'NS';
                case FarmFieldRowDirection.SouthToNorth:
                    return 'SW';
                case FarmFieldRowDirection.WestToEast:
                    return 'WE';
                default:
                    return '';
            }
        });
        return {
            directionText,
        };
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.custom-icon {
    background-color: $swatchG2;
}
.custom-chip {
    font-size: 18pt;
    height: 30px;
    width: 30px;
}
.rounded-text {
    display: inline-flex;
    align-items: center;
    border-radius: 20px; /* Adjust the border radius to make it round */
    font-family: 'Open Sans Condensed', sans-serif;
}

.custom-background {
    background-color: rgba($swatchG2, 0.8);
    color: $swatchWHT;
}
</style>
