import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = { class: "pl-2 language-abbr" }
const _hoisted_3 = { class: "language-abbr" }
const _hoisted_4 = { class: "pl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_menu, {
      "open-on-hover": "",
      "offset-y": "",
      "open-delay": 0
    }, {
      activator: _withCtx(({props}) => [
        _createVNode(_component_v_btn, _mergeProps(props, {
          class: "swatchG2BG swatchWHT",
          style: {"height":"40px"}
        }), {
          default: _withCtx(() => [
            _createVNode(_component_FontAwesomeIcon, {
              icon: ['fal', 'language'],
              size: "xl"
            }),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.selectedLanguage), 1)
          ]),
          _: 2
        }, 1040)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_list, { class: "swatchG2BG swatchWHT" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (locale) => {
              return (_openBlock(), _createBlock(_component_v_list_item, {
                key: locale.localeCode,
                onClick: ($event: any) => (_ctx.changeLanguage(locale))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, { class: "language-list" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_3, _toDisplayString(locale.localeDisplayAbbreviation), 1),
                      _createElementVNode("span", _hoisted_4, _toDisplayString(locale.localeDisplayName), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}