<template>
    <v-form class="work-sub-task-item" :class="{'first-item': index == 0}">
        <div style="width: 3.7rem" class="swatchA1 pl-2">
            {{ index + 1 }}
        </div>
        <div style="width: 100%">
            <div style="display: flex; align-items: center">
                <div class="swatchA1 bold" style="width: 30rem">{{ localWorkSubTask.workSubTaskName }}</div>
            </div>
            <div class="swatchA1 mt-6">
                <TranslatableTexts :text="localWorkSubTask.workSubTaskInstructions" />
            </div>
        </div>
        <div
            style="
                text-align: right;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 35rem;
            ">
            <div style="align-self: flex-end">
                <div class="completed-checkbox noSelection" @click="toggleCompletedFlag">
                    <v-checkbox v-model="localWorkSubTask.isCompleted" color="swatchB2" hide-details />

                    {{ $t('Completed_Question') }}
                </div>
            </div>
        </div>
    </v-form>
</template>

<script setup lang="ts">
import {WorkTaskActionTypeCode} from '@/enums/work-task-action-type-code';
import {WorkSubTaskDto} from '@/models/data-transfer-objects/search/work-task-search/WorkSubTaskDto';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {nextTick, onMounted, ref, watch} from 'vue';

const props = defineProps<{
    workSubTask: WorkSubTaskDto;
    workTaskActionTypeCode: WorkTaskActionTypeCode;
    workTask: WorkTaskSearchResultDto;
    index: number;
}>();
const emit = defineEmits(['update']);

// Form
const isLoading = ref(true);
const localWorkSubTask = ref(new WorkSubTaskDto());
let originalCompletedValue = false;

/**
 * Toggle the completed flag after the completed checkbox has been checked.
 */
const toggleCompletedFlag = () => {
    localWorkSubTask.value.isCompleted = !localWorkSubTask.value.isCompleted;
};

/**
 * If the action mode is set to Complete, automatically mark the sub-task as complete.
 * Otherwise, set it back to its original state.
 */
const setCompletedFlagBasedOnActionMode = () => {
    if (props.workTaskActionTypeCode == WorkTaskActionTypeCode.Completed) {
        localWorkSubTask.value.isCompleted = true;
    } else {
        localWorkSubTask.value.isCompleted = originalCompletedValue;
    }
};

// Update parent when the data is changed.
watch(
    () => localWorkSubTask,
    () => {
        if (!isLoading.value) {
            emit('update', localWorkSubTask.value);
        }
    },
    {deep: true},
);

/**
 * Update completed flag when the action mode is changed.
 */
watch(
    () => props.workTaskActionTypeCode,
    () => {
        if (!isLoading.value) {
            setCompletedFlagBasedOnActionMode();
        }
    },
);

onMounted(async () => {
    localWorkSubTask.value = Object.assign(new WorkSubTaskDto(), props.workSubTask);
    originalCompletedValue = props.workSubTask.isCompleted;

    // End loading (nextTick is used to ensure that the watch events on localWorkSubTask are not triggered during the load)
    nextTick(() => {
        isLoading.value = false;

        // If the task has not been completed or cancelled
        if (!props.workTask.isCompleted && !props.workTask.isCancelled) {
            // Set default completed flag
            setCompletedFlagBasedOnActionMode();
        }
    });
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.work-sub-task-item {
    border-left: 1px solid $swatchG6;
    border-right: 1px solid $swatchG6;
    border-bottom: 1px solid $swatchG6;
    display: flex;
    padding: 10px;

    &.first-item {
        border-top: 1px solid $swatchG6;
        margin-top: 0.5rem;
    }
}

.completed-checkbox {
    border: 1px solid $swatchG6;
    display: flex;
    align-items: center;
    padding-right: 1rem;
    cursor: pointer;
}
</style>
