import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "pa-4"
}
const _hoisted_2 = {
  key: 0,
  class: "table",
  style: {"width":"80%"}
}
const _hoisted_3 = { class: "tableRow" }
const _hoisted_4 = {
  class: "tableCell padding",
  style: {"width":"35%"}
}
const _hoisted_5 = { class: "flex-layout align-items-center justify-content-space-between flex-wrap-nowrap" }
const _hoisted_6 = {
  class: "tableCell padding",
  style: {"width":"65%"}
}
const _hoisted_7 = { class: "tableRow" }
const _hoisted_8 = { class: "tableCell padding" }
const _hoisted_9 = { class: "flex-layout align-items-center justify-content-space-between flex-wrap-nowrap" }
const _hoisted_10 = { class: "tableCell padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_LoadingSymbol = _resolveComponent("LoadingSymbol")!
  const _component_HelpIcon = _resolveComponent("HelpIcon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    flat: "",
    style: {"width":"50rem","margin":"auto"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_toolbar, {
        flat: "",
        color: "swatchA3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar_title, null, {
            default: _withCtx(() => [
              _createVNode(_component_FontAwesomeIcon, {
                icon: ['fal', 'unlock'],
                size: "xl",
                class: "mr-2"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.panelTitle), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSymbol, { key: 0 }))
        : _createCommentVNode("", true),
      (!_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.panelDetails) + " ", 1),
            _createVNode(_component_v_form, {
              modelValue: _ctx.passwordValid,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.passwordValid) = $event))
            }, {
              default: _withCtx(() => [
                (_ctx.passwordVisible)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('NewPassword')), 1),
                            _createVNode(_component_HelpIcon, null, {
                              helpText: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(_ctx.$t('ChangePassword_NewPassword_HelpText')), 1),
                                _createElementVNode("ul", null, [
                                  _createElementVNode("li", null, _toDisplayString(_ctx.passwordValidator.getLengthRequirements()), 1),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.passwordValidator.getCharacterRequirements(), (requirement) => {
                                    return (_openBlock(), _createElementBlock("li", { key: requirement }, _toDisplayString(requirement), 1))
                                  }), 128))
                                ])
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_v_text_field, {
                            variant: "outlined",
                            type: "Password",
                            modelValue: _ctx.newPassword,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newPassword) = $event)),
                            class: "percent20",
                            required: "",
                            rules: _ctx.passwordRules
                          }, null, 8, ["modelValue", "rules"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('ReEnterNewPassword')), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_v_text_field, {
                            variant: "outlined",
                            type: "Password",
                            modelValue: _ctx.repeatPassword,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.repeatPassword) = $event)),
                            class: "percent20",
                            required: "",
                            rules: _ctx.confirmationRules
                          }, null, 8, ["modelValue", "rules"])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_card_actions, { class: "justify-space-between swatchG9BG" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_spacer),
          (_ctx.loginButtonVisible)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.returnToLogInPage()))
              }, {
                prepend: _withCtx(() => [
                  _createVNode(_component_FontAwesomeIcon, {
                    icon: ['fal', 'check-square'],
                    size: "xl"
                  })
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('Login')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.saveButtonVisible)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 1,
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.saveUserPassword())),
                disabled: !_ctx.passwordValid
              }, {
                prepend: _withCtx(() => [
                  _createVNode(_component_FontAwesomeIcon, {
                    icon: ['fal', 'check-square'],
                    size: "xl"
                  })
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('SavePassword')), 1)
                ]),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}