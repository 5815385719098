import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f1540176"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loginForm" }
const _hoisted_2 = {
  key: 1,
  style: {"text-align":"center"}
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, {
      outlined: "",
      class: "transparent-card"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_toolbar, { color: "primary" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_toolbar_title, null, {
              default: _withCtx(() => [
                _createVNode(_component_FontAwesomeIcon, {
                  icon: ['fal', 'user'],
                  size: "xl",
                  class: "mr-2"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('Login')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_form, {
          class: "pa-4 transparent-card",
          modelValue: _ctx.valid,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.valid) = $event)),
          onKeyup: _withKeys(_ctx.login, ["enter"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              label: _ctx.usernameLabel,
              variant: "outlined",
              modelValue: _ctx.username,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
              required: "",
              "hide-details": false,
              rules: _ctx.usernameRules
            }, {
              "prepend-inner": _withCtx(() => [
                _createVNode(_component_FontAwesomeIcon, {
                  icon: ['fal', 'user'],
                  size: "xl",
                  class: "mr-1",
                  style: {"width":"24px"}
                })
              ]),
              _: 1
            }, 8, ["label", "modelValue", "rules"]),
            _createVNode(_component_v_text_field, {
              label: _ctx.$t('Password'),
              variant: "outlined",
              type: "Password",
              modelValue: _ctx.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
              required: "",
              "hide-details": false,
              rules: _ctx.passwordRules
            }, {
              "prepend-inner": _withCtx(() => [
                _createVNode(_component_FontAwesomeIcon, {
                  icon: ['fal', 'key'],
                  size: "xl",
                  class: "mr-1",
                  style: {"width":"24px"}
                })
              ]),
              _: 1
            }, 8, ["label", "modelValue", "rules"]),
            _createVNode(_component_v_row, {
              justify: "space-between",
              align: "center",
              class: "mb-1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_checkbox, {
                      label: _ctx.$t('RememberMe_Question'),
                      class: "remember-me",
                      modelValue: _ctx.rememberMe,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.rememberMe) = $event))
                    }, null, 8, ["label", "modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, { cols: "auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, { to: "/forgotten-password" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('ForgotPassword_Question')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.errorMessage)
              ? (_openBlock(), _createBlock(_component_v_alert, {
                  key: 0,
                  type: "error",
                  value: true
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.isLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_v_progress_circular, {
                    size: 20,
                    class: "mr-2"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('Loading_Ellipsis')), 1)
                ]))
              : (_openBlock(), _createBlock(_component_v_btn, {
                  key: 2,
                  block: "",
                  color: "primary",
                  onClick: _ctx.login,
                  disabled: !_ctx.valid
                }, {
                  prepend: _withCtx(() => [
                    _createVNode(_component_FontAwesomeIcon, {
                      icon: ['fal', 'circle-arrow-right'],
                      size: "xl"
                    })
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('LogIn_WithSpace')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"]))
          ]),
          _: 1
        }, 8, ["modelValue", "onKeyup"])
      ]),
      _: 1
    }),
    (_ctx.loginPageMessage != null && _ctx.loginPageMessage != '')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "loginMessage",
          innerHTML: _ctx.loginPageMessage,
          style: {"width":"100%"}
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}