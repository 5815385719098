export enum ProductSubtypeCode {
    Adjuvant = 'Adjuvant',
    Fertilizer = 'Fertilizer',
    Fungicide = 'Fungicide',
    Herbicide = 'Herbicide',
    Other = 'Other',
    Insecticide = 'Insecticide',
    OtherChemical = 'OtherChemical',
    OtherFertilizer = 'OtherFertilizer',
    SoilConditioner = 'SoilConditioner',
}
