import {defineStore} from 'pinia';

export const useViewWorkTaskPlanModalStore = defineStore('viewWorkTaskPlanModal', {
    state: () => ({
        isVisible: false,
        workTaskPlanId: null as number | null,
        isPrinting: false,
        expandPlanDetailsByDefault: false,
    }),
    actions: {
        open(workTaskPlanId: number, isPrinting: boolean = false, expandPlanDetailsByDefault: boolean = false) {
            // Close modal first (if it's already open) so that the watch is triggered when it's reopened
            this.close();

            this.workTaskPlanId = workTaskPlanId;
            this.isVisible = true;
            this.isPrinting = isPrinting;
            this.expandPlanDetailsByDefault = expandPlanDetailsByDefault;
        },
        close() {
            this.isVisible = false;
            this.workTaskPlanId = null;
            this.isPrinting = false;
        },
    },
});
