import {createRouter, createWebHistory} from 'vue-router';
import UserLogin from '@/views/users/UserLogin.vue';
import ForgottenPassword from '@/views/users/ForgottenPassword.vue';
import ConfirmPasswordReset from '@/views/users/ConfirmPasswordReset.vue';
import ManageUsers from '@/views/users/ManageUsers.vue';
import CropVarietyList from '@/views/lists/CropVarietyList.vue';
import MixtureList from '@/views/lists/MixtureList.vue';
import ProductList from '@/views/lists/ProductList.vue';
import WorkTaskList from '@/views/work-tasks/WorkTaskList.vue';
import WorkTaskPlanList from '@/views/work-tasks/WorkTaskPlanList.vue';
import WorkTaskTemplateList from '@/views/work-tasks/WorkTaskTemplateList.vue';
import ObservationList from '@/views/observations/ObservationList.vue';
import FarmFieldList from '@/views/farms/FarmFieldList.vue';
import GenericInformation from '@/views/other/GenericInformation.vue';
import HomeDashboard from '@/views/home-dashboard/HomeDashboard.vue';
import SystemConfiguration from '@/views/other/SystemConfiguration.vue';
import AccessDenied from '@/views/other/AccessDenied.vue';
import LockedOut from '@/views/other/LockedOut.vue';
import ConfirmSignup from '@/views/users/ConfirmSignup.vue';
import OverdueTaskReport from '@/views/reports/OverdueTaskReport.vue';
import ActivityReport from '@/views/reports/ActivityReport.vue';
import {HeaderMenuItem} from '@/enums/header-menu-item';
import {RoleCode} from '@/enums/role-code';
import {useUserStore} from '@/stores/user-store';
import {inject} from 'vue';
import ApiService from '@/services/api-service';
import {InsertLogRequest} from '@/models/api/requests/system/InsertLogRequest';
import TestComponents from '@/views/other/TestComponents.vue';
import ChangePassword from '@/views/users/ChangePassword.vue';
import i18n from '@/i18n';

const routes = [
    {
        path: '/',
        name: 'HomeDashboard',
        component: HomeDashboard,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Home,
            headerMenuText: () => i18n.global.t('HomeDashboard'),
            isDefaultPaddingUsed: false,
            requiresUserToBeLoggedIn: true,
        },
    },
    {
        path: '/system-configuration',
        name: 'SystemConfiguration',
        component: SystemConfiguration,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Admin,
            headerMenuText: () => i18n.global.t('SystemConfiguration'),
            isDefaultPaddingUsed: true,
            requiresUserToBeLoggedIn: true,
        },
    },
    {
        path: '/login',
        name: 'UserLogin',
        component: UserLogin,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Login,
            headerMenuText: () => i18n.global.t('Login'),
            isDefaultPaddingUsed: true,
            requiresUserToBeLoggedOut: true,
        },
    },
    {
        path: '/forgotten-password',
        name: 'ForgottenPassword',
        component: ForgottenPassword,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Custom,
            headerMenuText: () => i18n.global.t('ForgotPassword'),
            isDefaultPaddingUsed: true,
            customMenuIcon: 'unlock',
            requiresUserToBeLoggedOut: true,
        },
    },
    {
        path: '/test-components',
        name: 'TestComponents',
        component: TestComponents,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Config,
            headerMenuText: () => 'Test Components',
            isDefaultPaddingUsed: true,
        },
    },
    {
        path: '/reset-password/:userID/:resetToken',
        name: 'ConfirmPasswordReset',
        component: ConfirmPasswordReset,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Custom,
            headerMenuText: () => i18n.global.t('ChangePassword'),
            isDefaultPaddingUsed: true,
            customMenuIcon: 'unlock',
            requiresUserToBeLoggedOut: true,
        },
    },
    {
        path: '/change-password',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Custom,
            headerMenuText: () => i18n.global.t('ChangePassword'),
            isDefaultPaddingUsed: true,
            customMenuIcon: 'unlock',
        },
    },
    {
        path: '/info',
        name: 'GenericInformation',
        component: GenericInformation,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Custom,
            headerMenuText: () => i18n.global.t('Info'),
            isDefaultPaddingUsed: true,
            customMenuIcon: 'info-circle',
        },
    },
    {
        path: '/access-denied',
        name: 'AccessDenied',
        component: AccessDenied,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Custom,
            headerMenuText: () => i18n.global.t('AccessDenied'),
            isDefaultPaddingUsed: true,
            customMenuIcon: 'ban',
        },
    },
    {
        path: '/locked',
        name: 'LockedOut',
        component: LockedOut,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Custom,
            headerMenuText: () => i18n.global.t('LockedOut'),
            isDefaultPaddingUsed: true,
            customMenuIcon: 'ban',
        },
    },
    {
        path: '/manage-users',
        name: 'ManageUsers',
        component: ManageUsers,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Admin,
            headerMenuText: () => i18n.global.t('ManageUsers'),
            isDefaultPaddingUsed: true,
            requiresUserToBeLoggedIn: true,
        },
    },
    {
        path: '/crop-variety-list',
        name: 'CropVarietyList',
        component: CropVarietyList,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Lists,
            headerMenuText: () => i18n.global.t('CropVarietyList'),
            isDefaultPaddingUsed: true,
            requiresUserToBeLoggedIn: true,
        },
    },
    {
        path: '/mixture-list/:productId?',
        name: 'MixtureList',
        component: MixtureList,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Lists,
            headerMenuText: () => i18n.global.t('MixtureList'),
            isDefaultPaddingUsed: true,
            requiresUserToBeLoggedIn: true,
        },
    },
    {
        path: '/product-list',
        name: 'ProductList',
        component: ProductList,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Lists,
            headerMenuText: () => i18n.global.t('ProductList'),
            isDefaultPaddingUsed: true,
            requiresUserToBeLoggedIn: true,
        },
    },
    {
        path: '/observation-list',
        name: 'ObservationList',
        component: ObservationList,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Observations,
            headerMenuText: () => i18n.global.t('OpenObservations'),
            isDefaultPaddingUsed: true,
            requiresUserToBeLoggedIn: true,
        },
    },
    // Farm Field List
    {
        path: '/farm-field-list/:cropVarietyId?',
        name: 'FarmFieldList',
        component: FarmFieldList,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Fields,
            headerMenuText: () => i18n.global.t('AllFieldsAtSite'),
            isDefaultPaddingUsed: true,
            requiresUserToBeLoggedIn: true,
        },
    },
    {
        path: '/cs/:userID/:signupToken',
        name: 'ConfirmSignup',
        component: ConfirmSignup,
        props: true,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Custom,
            headerMenuText: () => i18n.global.t('ConfirmSignup'),
            isDefaultPaddingUsed: true,
            customMenuIcon: 'user',
            requiresUserToBeLoggedOut: true,
        },
    },
    {
        path: '/overdue-task-report',
        name: 'OverdueTaskReport',
        component: OverdueTaskReport,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Reports,
            headerMenuText: () => i18n.global.t('OverdueTaskReport'),
            isDefaultPaddingUsed: true,
            requiresUserToBeLoggedIn: true,
        },
    },
    {
        path: '/activity-report',
        name: 'ActivityReport',
        component: ActivityReport,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Reports,
            headerMenuText: () => i18n.global.t('ActivityReport'),
            isDefaultPaddingUsed: true,
            requiresUserToBeLoggedIn: true,
        },
    },
    {
        path: '/work-task-list/:mixtureId?',
        name: 'WorkTaskList',
        component: WorkTaskList,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Tasks,
            headerMenuText: () => i18n.global.t('TaskList'),
            isDefaultPaddingUsed: true,
            requiresUserToBeLoggedIn: true,
        },
    },
    {
        path: '/manage-task-plan-list',
        name: 'ManageTaskPlanList',
        component: WorkTaskPlanList,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Tasks,
            headerMenuText: () => i18n.global.t('ManageTaskPlans'),
            isDefaultPaddingUsed: true,
            requiresUserToBeLoggedIn: true,
        },
    },
    {
        path: '/work-task-template-list',
        name: 'WorkTaskTemplateList',
        component: WorkTaskTemplateList,
        meta: {
            headerMenuItemToShow: HeaderMenuItem.Tasks,
            headerMenuText: () => i18n.global.t('ManageTaskTemplates'),
            isDefaultPaddingUsed: true,
            requiresUserToBeLoggedIn: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const userStore = useUserStore();
    const apiService = inject('apiService') as ApiService;

    // Log the page visit.
    apiService.post('logging/logs', {
        logDescription: `Navigating to ${to.path} from ${from.path}`,
        logFileName: to.path,
    } as InsertLogRequest);

    // Ping the API server to check if the user is locked out.
    apiService.get('security/ping');

    //Get data from route metadata
    const requiresUserToBeLoggedIn = to.meta.requiresUserToBeLoggedIn as boolean | undefined;
    const requiresUserToBeLoggedOut = to.meta.requiresUserToBeLoggedOut as boolean | undefined;
    const requiredRoleCode = to.meta.requiredRoleCode as RoleCode | undefined;
    const requiredRoleLevel = to.meta.requiredRoleLevel as number | undefined;

    if (requiresUserToBeLoggedIn && !userStore.isLoggedIn) {
        // If the user is not logged in, redirect to login page
        next({name: 'UserLogin'});
    } else if (requiresUserToBeLoggedOut && userStore.isLoggedIn) {
        userStore.setShowLogoutWarning(true);
        // If the user is logged in, redirect to home dashboard
        next({name: 'HomeDashboard'});
    } else if (requiredRoleCode && requiredRoleLevel && !userStore.hasRoleLevel(requiredRoleCode, requiredRoleLevel)) {
        // If the user does not have the required role level, redirect to access denied page
        next({name: 'AccessDenied'});
    } else {
        next();
    }
});

export default router;
