<template>
    <v-tooltip bottom>
        <template v-slot:activator="{props}">
            <CircleIcon v-bind="props" class="help-icon" :style="style" icon="mdi-help" />
        </template>
        <slot name="helpText"></slot>
        {{ helpText }}
    </v-tooltip>
</template>

<script lang="ts">
import {PropType, defineComponent} from 'vue';
import CircleIcon from '@/components/CircleIcon.vue';

export default defineComponent({
    name: 'HelpIcon',
    extends: CircleIcon,
    props: {
        helpText: {
            type: String as PropType<string>,
        },
        style: {
            type: [String, Object],
        },
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.help-icon {
    background-color: $swatchB1;
    cursor: help;
}
</style>
