import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tblList pa-4" }
const _hoisted_2 = { class: "tblListRow" }
const _hoisted_3 = {
  class: "tblListCell head",
  style: {"width":"20%"}
}
const _hoisted_4 = {
  class: "tblListCell head",
  style: {"width":"35%"}
}
const _hoisted_5 = {
  class: "tblListCell head",
  style: {"width":"35%"}
}
const _hoisted_6 = {
  class: "tblListCell head",
  style: {"width":"10%"}
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "tblListCell" }
const _hoisted_9 = { class: "tblListCell" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { class: "tblListCell" }
const _hoisted_14 = {
  class: "tblListCell",
  style: {"text-align":"center"}
}
const _hoisted_15 = {
  style: {"width":"100%"},
  class: "tblForm pa-4"
}
const _hoisted_16 = {
  class: "tableCell padding",
  style: {"width":"25%"}
}
const _hoisted_17 = { class: "flex-layout align-items-center justify-content-space-between flex-wrap-nowrap" }
const _hoisted_18 = { style: {"width":"75%"} }
const _hoisted_19 = {
  class: "tableCell padding",
  style: {"width":"25%"}
}
const _hoisted_20 = { class: "flex-layout align-items-center justify-content-space-between flex-wrap-nowrap" }
const _hoisted_21 = { style: {"width":"75%"} }
const _hoisted_22 = {
  class: "tableCell padding",
  style: {"width":"25%"}
}
const _hoisted_23 = { class: "flex-layout align-items-center justify-content-space-between flex-wrap-nowrap" }
const _hoisted_24 = { style: {"width":"75%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_TranslatableTexts = _resolveComponent("TranslatableTexts")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_CloseModalButton = _resolveComponent("CloseModalButton")!
  const _component_LoadingSymbol = _resolveComponent("LoadingSymbol")!
  const _component_HelpIcon = _resolveComponent("HelpIcon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, {
      outlined: "",
      style: {"overflow":"visible"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_toolbar, {
          flat: "",
          color: "swatchG2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_toolbar_title, null, {
              default: _withCtx(() => [
                _createVNode(_component_FontAwesomeIcon, {
                  icon: _ctx.byPrefixAndName.fal['cog'],
                  size: "xl",
                  class: "mr-2"
                }, null, 8, ["icon"]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('ManageSystemConfiguration')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('Config')), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('ConfigValue')), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('ConfigDescription')), 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('Action')), 1)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.configSettingList, (configSetting, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "tblListRow hover separator",
              key: index,
              onClick: ($event: any) => (_ctx.openPopup(configSetting, true))
            }, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(configSetting.configSettingName), 1),
              _createElementVNode("div", _hoisted_9, [
                (configSetting.configSettingDataType == 'String')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(configSetting.configSettingCustomValue != null
                                    ? configSetting.configSettingCustomValue.configSettingValueString
                                    : configSetting.configSettingDefaultValueString), 1))
                  : (configSetting.configSettingDataType == 'Number')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(configSetting.configSettingCustomValue != null
                                    ? configSetting.configSettingCustomValue.configSettingValueNumeric
                                    : configSetting.configSettingDefaultValueNumeric), 1))
                    : (configSetting.configSettingDataType == 'Boolean')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(configSetting.configSettingCustomValue != null
                                    ? configSetting.configSettingCustomValue.configSettingValueBoolean
                                    : configSetting.configSettingDefaultValueBoolean), 1))
                      : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_TranslatableTexts, {
                  text: configSetting.configSettingDescription
                }, null, 8, ["text"])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", null, [
                  _createVNode(_component_v_menu, { "open-on-hover": "" }, {
                    activator: _withCtx(({props}) => [
                      _createVNode(_component_v_btn, _mergeProps({ color: "primary" }, props), {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('Operations')), 1)
                        ]),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    onClick: ($event: any) => (_ctx.openPopup(configSetting, true))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('ViewThisSystemConfig')), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1024),
                              (_ctx.hasModifyConfigAccess)
                                ? (_openBlock(), _createBlock(_component_v_list_item_title, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_btn, {
                                        onClick: ($event: any) => (_ctx.openPopup(configSetting, false))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('ModifyThisSystemConfig')), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"])
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ])
              ])
            ], 8, _hoisted_7))
          }), 128))
        ]),
        _createVNode(_component_v_card_actions, { class: "swatchG9BG" }, {
          default: _withCtx(() => [
            _createVNode(_component_BackButton)
          ]),
          _: 1
        }),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.isPopupOpen,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isPopupOpen) = $event)),
          "max-width": "900"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_toolbar, {
                  flat: "",
                  color: "swatchG2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_toolbar_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_FontAwesomeIcon, {
                          icon: _ctx.byPrefixAndName.fal['cog'],
                          size: "xl",
                          class: "mr-2"
                        }, null, 8, ["icon"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.popupTitle), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_CloseModalButton, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isPopupOpen = false))
                    })
                  ]),
                  _: 1
                }),
                (_ctx.panelLoading)
                  ? (_openBlock(), _createBlock(_component_LoadingSymbol, { key: 0 }))
                  : _createCommentVNode("", true),
                _createElementVNode("table", _hoisted_15, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('Config')), 1),
                        _createVNode(_component_HelpIcon, {
                          "help-text": _ctx.$t('SystemConfig_Config_HelpText')
                        }, null, 8, ["help-text"])
                      ])
                    ]),
                    _createElementVNode("td", _hoisted_18, _toDisplayString(_ctx.selectedConfig?.configSettingName), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('ConfigValue')), 1),
                        _createVNode(_component_HelpIcon, {
                          "help-text": _ctx.$t('SystemConfig_ConfigValue_HelpText')
                        }, null, 8, ["help-text"])
                      ])
                    ]),
                    _createElementVNode("td", _hoisted_21, [
                      (_ctx.selectedConfig?.configSettingDataType == 'String')
                        ? (_openBlock(), _createBlock(_component_v_text_field, {
                            key: 0,
                            modelValue: _ctx.valueString,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valueString) = $event)),
                            rows: "1",
                            "auto-grow": "",
                            rules: [(v) => !!v || _ctx.$t('AValueIsRequired')],
                            disabled: _ctx.isViewMode
                          }, null, 8, ["modelValue", "rules", "disabled"]))
                        : (_ctx.selectedConfig?.configSettingDataType == 'Number')
                          ? (_openBlock(), _createBlock(_component_v_text_field, {
                              key: 1,
                              modelValue: _ctx.valueNumber,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valueNumber) = $event)),
                              type: "number",
                              rules: [(v) => !!v || _ctx.$t('AValueIsRequired')],
                              oninput: "if(this.value < 0) this.value = 0;",
                              disabled: _ctx.isViewMode
                            }, null, 8, ["modelValue", "rules", "disabled"]))
                          : (_ctx.selectedConfig?.configSettingDataType == 'Boolean')
                            ? (_openBlock(), _createBlock(_component_v_select, {
                                key: 2,
                                items: [
                                        {text: _ctx.$t('True'), value: true},
                                        {text: _ctx.$t('False'), value: false},
                                    ],
                                modelValue: _ctx.valueBoolean,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.valueBoolean) = $event)),
                                "hide-details": "",
                                "item-title": "text",
                                "item-value": "value",
                                style: {"width":"150px"},
                                rules: [(v) => (v !== null && v !== undefined) || _ctx.$t('ASelectionIsRequired')],
                                disabled: _ctx.isViewMode
                              }, null, 8, ["items", "modelValue", "rules", "disabled"]))
                            : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('ConfigDescription')), 1),
                        _createVNode(_component_HelpIcon, {
                          "help-text": _ctx.$t('SystemConfig_ConfigDescription_HelpText')
                        }, null, 8, ["help-text"])
                      ])
                    ]),
                    _createElementVNode("td", _hoisted_24, [
                      _createVNode(_component_TranslatableTexts, {
                        text: _ctx.selectedConfig?.configSettingDescription
                      }, null, 8, ["text"])
                    ])
                  ])
                ]),
                _createVNode(_component_v_card_actions, { class: "justify-space-between swatchG9BG" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isPopupOpen = false))
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode(_component_FontAwesomeIcon, {
                          icon: _ctx.byPrefixAndName.fal['arrow-rotate-left'],
                          size: "xl"
                        }, null, 8, ["icon"])
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('Cancel')), 1)
                      ]),
                      _: 1
                    }),
                    (!_ctx.isViewMode)
                      ? (_openBlock(), _createBlock(_component_v_btn, {
                          key: 0,
                          disabled: 
                                (_ctx.selectedConfig?.configSettingDataType == 'String' && _ctx.valueString == '') ||
                                (_ctx.selectedConfig?.configSettingDataType == 'Number' && _ctx.valueNumber?.toString() == '') ||
                                (_ctx.selectedConfig?.configSettingDataType == 'Boolean' && _ctx.valueBoolean == null)
                            ,
                          onClick: _cache[5] || (_cache[5] = ($event: any) => {
                                _ctx.saveChanges();
                                _ctx.isPopupOpen = false;
                            })
                        }, {
                          prepend: _withCtx(() => [
                            _createVNode(_component_FontAwesomeIcon, {
                              icon: _ctx.byPrefixAndName.fal['save'],
                              size: "xl"
                            }, null, 8, ["icon"])
                          ]),
                          default: _withCtx(() => [
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('SaveChanges')), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar.show,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.snackbar.show) = $event)),
      timeout: "4000"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.snackbar.text), 1)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}