<template>
    <!--Verification Email Manager-->
    <div class="divVerificationEmailManager">
        <!-- Resend Verification Button -->
        <v-btn v-show="isVisible" @click="resendSignupVerificationEmail" class="v-btn--custom">
            <template v-slot:prepend>
                <FontAwesomeIcon :icon="['fal', 'envelope']" size="xl" />
            </template>
            {{ $t('ResendVerificationEmail') }}
        </v-btn>

        <!-- Sent successfully message -->
        <div
            v-show="!isVisible && isResendVerificationSuccess === true"
            class="signupVerificationButtonSent inline pa-2">
            <!--Resend Verification Sent-->
            <span style="font-weight: bold">{{ $t('SignupVerificationManager_ResendEmailSent') }}</span>
            <FontAwesomeIcon :icon="['fal', 'check']" size="xl" class="ml-2" />
        </div>

        <!-- Failed to sent message -->
        <div
            v-show="!isVisible && isResendVerificationSuccess === false"
            class="signupVerificationButtonError inline pa-2">
            <!--Resend Verification Sent-->
            <span>
                <span style="font-weight: bold">{{ $t('Error_Colon').toUpperCase() }} </span>
                {{ $t('SignupVerificationManager_ResendEmailNotSent') }}
            </span>
            <FontAwesomeIcon :icon="['fal', 'xmark']" size="xl" class="ml-2" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import {inject, ref} from 'vue';
import ApiService from '@/services/api-service.js';

const props = defineProps({
    userId: {
        type: Number,
        required: true,
    },
});

const apiService = inject('apiService') as ApiService;
const isVisible = ref(true);
const isResendVerificationSuccess = ref<boolean | null>(null);
const userId = ref(props.userId);

const resendSignupVerificationEmail = async () => {
    isVisible.value = false;

    try {
        await apiService.post('users/resend-signup-verification-email', userId.value);
        isResendVerificationSuccess.value = true;
    } catch (error) {
        console.error(error);
        isResendVerificationSuccess.value = false;
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/user/signup-verification-manager.scss';
</style>
