import {SeedRateUnitCode} from '@/enums/seed-rate-unit-code';

export class WorkTaskCropVarietyPreferenceForm {
    workTaskCropVarietyPreferenceId: number | null = null;
    workTaskTemplateCropVarietyPreferenceId: number | null = null;
    cropVarietyId: number | null = null;
    seedOriginBlockId: number | null = null;
    seedRate: number | null = null;
    seedRateUnitCode: SeedRateUnitCode | null = null;
    newItemId: string | null = null;

    constructor(data?: Partial<WorkTaskCropVarietyPreferenceForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
