import {ArtifactDto} from '@/models/data-transfer-objects/system/ArtifactDto';
import {WorkSubTaskUserDto} from './WorkSubTaskUserDto';

export class WorkSubTaskDto {
    workSubTaskId!: number;
    sequenceNumber!: number;
    workSubTaskName!: string;
    workSubTaskInstructions: string | null = null;
    isCompleted!: boolean;
    completedDate: Date | null = null;
    workSubTaskUsers: WorkSubTaskUserDto[] = [];
    artifacts: ArtifactDto[] = [];

    constructor(data?: Partial<WorkSubTaskDto>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
