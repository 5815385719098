<template>
    <v-dialog v-model="manageFarmSiteModalStore.isVisible" class="pnlUserManager">
        <v-card>
            <v-toolbar color="swatchA2">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'farm']" size="xl" class="mr-2" />
                    {{ modalTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading" />

            <v-form v-else ref="form" class="pa-4 divUserManager" v-model="valid" style="overflow-y: scroll">
                <table style="width: 100%" class="tblForm pl-6">
                    <tr>
                        <td class="tableCell padding" style="width: 20%; min-width: 10rem">
                            <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                <span>{{ $t('SiteName') }}</span>
                                <HelpIcon :help-text="$t('FarmSiteManage_SiteName_HelpText')" />
                            </div>
                        </td>
                        <td style="width: 30%">
                            <v-text-field v-model="farmSite.farmSiteName" max-width="300px" :rules="siteNameRules">
                                <template v-slot:append-inner>
                                    <FontAwesomeIcon
                                        :icon="['fas', 'spinner']"
                                        pulse
                                        size="xl"
                                        class="swatchA2"
                                        v-if="farmSite.farmSiteName && isCheckingSiteName" />

                                    <CircleIcon
                                        class="marginLeft marginRightSmall swatchREDBG"
                                        color="swatchWHT"
                                        :title="$t('SiteNameIsNotAvailable')"
                                        v-if="farmSite.farmSiteName && !isCheckingSiteName && !isSiteNameAvailable">
                                        <FontAwesomeIcon :icon="['fas', 'xmark']" />
                                    </CircleIcon>

                                    <CircleIcon
                                        class="marginLeft marginRightSmall swatchGRNBG"
                                        color="swatchWHT"
                                        :title="$t('SiteNameIsAvailable')"
                                        v-if="farmSite.farmSiteName && !isCheckingSiteName && isSiteNameAvailable">
                                        <FontAwesomeIcon :icon="['fas', 'check']" />
                                    </CircleIcon>
                                </template>
                            </v-text-field>
                        </td>
                        <td class="tableCell padding" style="width: 20%; min-width: 10rem">
                            <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                <span>{{ $t('Abbreviation') }}</span>
                                <HelpIcon :help-text="$t('FarmSiteManage_Abbreviation_HelpText')" />
                            </div>
                        </td>
                        <td style="width: 30%">
                            <v-text-field v-model="farmSite.farmSiteAbbreviation" :rules="abbreviationRules">
                                <template v-slot:append-inner>
                                    <FontAwesomeIcon
                                        :icon="['fas', 'spinner']"
                                        pulse
                                        size="xl"
                                        class="swatchA2"
                                        v-if="farmSite.farmSiteAbbreviation && isCheckingSiteAbbreviation" />

                                    <CircleIcon
                                        class="marginLeft marginRightSmall swatchREDBG"
                                        color="swatchWHT"
                                        :title="$t('SiteAbbreviationIsNotAvailable')"
                                        v-if="
                                            farmSite.farmSiteAbbreviation &&
                                            !isCheckingSiteAbbreviation &&
                                            !isSiteAbbreviationAvailable
                                        ">
                                        <FontAwesomeIcon :icon="['fas', 'xmark']" />
                                    </CircleIcon>

                                    <CircleIcon
                                        class="marginLeft marginRightSmall swatchGRNBG"
                                        color="swatchWHT"
                                        :title="$t('SiteAbbreviationIsAvailable')"
                                        v-if="
                                            farmSite.farmSiteAbbreviation &&
                                            !isCheckingSiteAbbreviation &&
                                            isSiteAbbreviationAvailable
                                        ">
                                        <FontAwesomeIcon :icon="['fas', 'check']" />
                                    </CircleIcon>
                                </template>
                            </v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td class="tableCell padding" style="width: 20%; min-width: 10rem; vertical-align: top">
                            <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                <span>{{ $t('Description') }}</span>
                                <HelpIcon :help-text="$t('FarmSiteManage_Description_HelpText')" />
                            </div>
                        </td>
                        <td colspan="3">
                            <v-textarea v-model="farmSite.farmSiteDescription" variant="outlined" rows="3" auto-grow />
                        </td>
                    </tr>
                    <tr>
                        <td class="tableCell padding">
                            <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                <span>{{ $t('CentralPoint') }}</span>
                                <HelpIcon :help-text="$t('FarmSiteManage_CentralPoint_HelpText')" />
                            </div>
                        </td>
                        <td>
                            <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                                <LocationPicker
                                    :latLng="siteCentroid"
                                    :input-required="true"
                                    @update:latLng="siteCentroid = $event" />
                            </div>
                        </td>
                    </tr>
                </table>

                <v-expansion-panels v-model="defaultPanel">
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header">{{
                            $t('DefaultAttributes')
                        }}</v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <table style="width: 100%" class="tblForm">
                                <tr>
                                    <td class="tableCell padding" style="width: 20.5%; min-width: 10rem">
                                        <div
                                            class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                            <span>{{ $t('PrimaryCrop') }}</span>
                                            <HelpIcon :help-text="$t('FarmSiteManage_PrimaryCrop_HelpText')" />
                                        </div>
                                    </td>
                                    <td style="width: 30.5%">
                                        <v-select
                                            v-model="farmSite.defaultPrimaryCropId"
                                            :items="crops"
                                            item-title="cropName"
                                            item-value="cropId"
                                            max-width="300px"
                                            :rules="primaryCropRules" />
                                    </td>
                                    <td class="tableCell padding" style="width: 20.5%; min-width: 10rem">
                                        <div
                                            class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                            <span>{{ $t('RowWidth') }}</span>
                                            <HelpIcon :help-text="$t('FarmSiteManage_RowWidth_HelpText')" />
                                        </div>
                                    </td>
                                    <td style="width: 40%">
                                        <v-text-field v-model="farmSite.defaultRowWidth" :rules="rowWidthRules" />
                                    </td>
                                </tr>
                                <tr>
                                    <td class="tableCell padding" style="width: 20%; min-width: 10rem">
                                        <div
                                            class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                            <span>{{ $t('NamingConvention') }}</span>
                                            <HelpIcon :help-text="$t('FarmSiteManage_NamingConvention_HelpText')" />
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                            class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                                            <v-select
                                                v-model="farmSite.defaultFarmSiteNamingConventionId"
                                                :rules="namingConventionRules"
                                                :items="farmSiteNamingConventions"
                                                item-title="farmSiteNamingConventionName"
                                                item-value="farmSiteNamingConventionId"
                                                max-width="300px" />
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <v-btn @click="save" :disabled="isLoading">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'save']" size="xl" />
                    </template>
                    {{ $t('Save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/user/user-manager.scss';

import {useManageFarmSiteModalStore} from '@/stores/modals/manage-farm-site-modal-store';
import {CheckFarmSiteNameAvailableRequest} from '@/models/api/requests/farm/CheckFarmSiteNameAvailableRequest';
import {CheckFarmSiteAbbreviationAvailableRequest} from '@/models/api/requests/farm/CheckFarmSiteAbbreviationAvailableRequest';
import {ManageFarmSiteForm} from '@/models/farm/ManageFarmSiteForm';
import {computed, watch, inject, ref, onUnmounted} from 'vue';
import {SearchFarmSitesResponse} from '@/models/api/responses/search/SearchFarmSitesResponse';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import {useCrops} from '@/composables/data-source/crops';
const {getCrops, crops} = useCrops();
import {useFarmSiteNamingConventions} from '@/composables/data-source/farm-site-naming-conventions';
const {getFarmSiteNamingConventions, farmSiteNamingConventions} = useFarmSiteNamingConventions();
import {SaveFarmSiteRequest} from '@/models/api/requests/farm/SaveFarmSiteRequest';
import {useFarmStore} from '@/stores/farm-store';
const farmStore = useFarmStore();
import {useFarmSites} from '@/composables/data-source/farm-sites';
const {getFarmSites} = useFarmSites();
import {debounce} from 'lodash';
import LocationPicker from '@/components/LocationPicker.vue';

import i18n from '@/i18n';

const isCheckingSiteName = ref(false);
const isSiteNameAvailable = ref(true);
const isCheckingSiteAbbreviation = ref(false);
const isSiteAbbreviationAvailable = ref(true);
const siteCentroid = ref<number[]>([]);

// Form
const form = ref();
const valid = ref<boolean>(true);
let isLoading = ref<boolean>(false);
const snackbar = ref({
    show: false,
    text: '',
});

// // Rules
const siteNameRules = ref([
    (v: string | null) => !!v || i18n.global.t('Validation_Required'),
    async (v: string | null) => {
        if (!v) return true;
        if (isCheckingSiteName.value == true) return false;
        return await checkFarmSiteNameAvailable(v);
    },
]);

const abbreviationRules = ref([
    (v: string | null) => !!v || i18n.global.t('Validation_Required'),
    (v: string | null) => (v && !v.includes(' ') && v.length <= 8) || i18n.global.t('Validation_InvalidFormat'),
    async (v: string | null) => {
        if (!v) return true;
        if (isCheckingSiteAbbreviation.value == true) return false;
        return await checkFarmSiteAbbreviationAvailable(v);
    },
]);
const namingConventionRules = ref([(v: number | null) => !!v || i18n.global.t('Validation_Required')]);

const primaryCropRules = ref([(v: number | null) => !!v || v === 0 || i18n.global.t('Validation_Required')]);
const rowWidthRules = ref([
    (v: string | null) => !!v || i18n.global.t('Validation_Required'),
    (v: string | null) => (v && /^-?\d+(\.\d+)?$/.test(v)) || i18n.global.t('Validation_InvalidFormat'),
    (v: string | null) =>
        (v && parseFloat(v) >= 0.3 && parseFloat(v) <= 6) ||
        i18n.global.t('ManageFarmSite_RowWidthValidation_InvalidRowWidthRange'),
]);

// Services
const apiService = inject('apiService') as ApiService;

// Farm Site modal
const manageFarmSiteModalStore = useManageFarmSiteModalStore();
const farmSite = ref<ManageFarmSiteForm>(new ManageFarmSiteForm());
const localFarmSiteId = ref<number | null>(null);
const defaultPanel = ref<number | null>(0);

/**
 * Load data for the modal.
 */
const loadData = async (farmSiteId: number | null) => {
    isLoading.value = true;

    // Reset form
    defaultPanel.value = 0;
    farmSite.value = new ManageFarmSiteForm();
    await getCrops();
    await getFarmSiteNamingConventions();
    localFarmSiteId.value = farmSiteId;

    // If a farmSiteId ID was specified
    if (farmSiteId !== null) {
        // Get farm site details
        const searchResults = (await apiService.post('search/farm-sites-by-id', {
            farmSiteId: farmSiteId,
        })) as SearchFarmSitesResponse;
        if (searchResults.farmSites.length === 1) {
            farmSite.value = Object.assign(new ManageFarmSiteForm(), searchResults.farmSites[0]);
            siteCentroid.value = farmSite.value.farmSiteCentroid
                ? farmSite.value.farmSiteCentroid.coordinates.reverse()
                : [];
        }
    }

    isLoading.value = false;
};

/**
 * Checks if the specified farm site name is available. Debounce is used to prevent the API from being called too often.
 */
const debouncedCheckFarmSiteNameAvailable = debounce(
    async (inputFarmSiteName: string, resolve: (value: string | boolean) => void) => {
        const request: CheckFarmSiteNameAvailableRequest = {
            farmSiteName: inputFarmSiteName,
            farmSiteId: localFarmSiteId.value,
        };

        const isAvailable = await apiService.post('farmSites/check-farm-site-name-available', request);
        isCheckingSiteName.value = false;
        isSiteNameAvailable.value = isAvailable;

        resolve(isAvailable || i18n.global.t('Validation_FarmSiteNameAlreadyTaken'));
    },
    500,
);

/**
 * Checks if the specified farm site name is available.
 */
const checkFarmSiteNameAvailable = async (inputFarmSiteName: string): Promise<string | boolean> => {
    isCheckingSiteName.value = true;

    return new Promise((resolve) => {
        debouncedCheckFarmSiteNameAvailable(inputFarmSiteName, resolve);
    });
};

/**
 * Checks if the specified farm site abbreviation is available. Debounce is used to prevent the API from being called too often.
 */
const debouncedCheckFarmSiteAbbreviationAvailable = debounce(
    async (inputFarmSiteAbbreviation: string, resolve: (value: string | boolean) => void) => {
        const request: CheckFarmSiteAbbreviationAvailableRequest = {
            farmSiteAbbreviation: inputFarmSiteAbbreviation,
            farmSiteId: localFarmSiteId.value,
        };

        const isAvailable = await apiService.post('farmSites/check-farm-site-abbreviation-available', request);
        isCheckingSiteAbbreviation.value = false;
        isSiteAbbreviationAvailable.value = isAvailable;

        resolve(isAvailable || i18n.global.t('Validation_FarmSiteAbbreviationAlreadyTaken'));
    },
    500,
);

/**
 * Checks if the specified farm site abbreviation is available.
 */
const checkFarmSiteAbbreviationAvailable = async (inputFarmSiteAbbreviation: string): Promise<string | boolean> => {
    isCheckingSiteAbbreviation.value = true;

    return new Promise((resolve) => {
        debouncedCheckFarmSiteAbbreviationAvailable(inputFarmSiteAbbreviation, resolve);
    });
};

/**
 * Close the modal.
 */
const close = () => {
    siteCentroid.value = [];
    manageFarmSiteModalStore.close();
};

onUnmounted(() => {
    localFarmSiteId.value = null;
    siteCentroid.value = [];
    close();
});
/**
 * Save the modal.
 */
const save = async () => {
    // Perform final client side validation of form
    await form.value.validate();

    // If form is valid
    if (valid.value) {
        let snackbarMessage = '';
        isLoading.value = true;

        try {
            // Build API request
            const saveFarmSiteRequest: SaveFarmSiteRequest = {
                farmSiteId: farmSite.value.farmSiteId,
                farmSiteName: farmSite.value.farmSiteName,
                farmSiteAbbreviation: farmSite.value.farmSiteAbbreviation,
                farmSiteDescription: farmSite.value.farmSiteDescription,
                farmSiteCentroid: siteCentroid.value,
                defaultPrimaryCropId: farmSite.value.defaultPrimaryCropId,
                defaultRowWidth: farmSite.value.defaultRowWidth ? parseFloat(farmSite.value.defaultRowWidth) : null,
                defaultFarmSiteNamingConventionId: farmSite.value.defaultFarmSiteNamingConventionId,
            };

            // Call API to save user
            await apiService.post('farmSites/farm-site', saveFarmSiteRequest);

            // Set snackbar message
            if (!localFarmSiteId.value) {
                snackbarMessage = i18n.global.t('CreateFarmSite_Success');
            } else {
                snackbarMessage = i18n.global.t('ModifyFarmSite_Success');
            }

            // Show success feedback to user
            snackbar.value.show = true;
            snackbar.value.text = snackbarMessage;
            manageFarmSiteModalStore.savedCounter++;

            // update farm store
            await farmStore.fetchFarmSites(apiService);
            await getFarmSites();

            // Close modal
            manageFarmSiteModalStore.close();
        } catch (ex: unknown) {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }
    }
};

/**
 * The title of the modal.
 */
const modalTitle = computed(() => {
    let title = localFarmSiteId.value !== null ? i18n.global.t('ModifyFarmSite') : i18n.global.t('CreateFarmSite');

    return title;
});

// Watch for changes to manageFarmSiteModalStore.farmSiteId
watch(
    () => manageFarmSiteModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadData(manageFarmSiteModalStore.farmSiteId);
        }
    },
);
</script>
