import {defineStore} from 'pinia';

export const useManageObservationModalStore = defineStore('manageObservationModal', {
    state: () => ({
        isVisible: false,
        observationId: null as number | null,
        savedCounter: 0,
    }),
    actions: {
        open(observationId: number | null = null) {
            // Close modal first (if it's already open) so that the watch is triggered when it's reopened
            this.close();

            this.observationId = observationId;
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
            this.observationId = null;
        },
    },
});
