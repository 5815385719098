<template>
    <v-dialog v-model="resetUserPasswordModalStore.isVisible" style="width: 40vw !important">
        <v-card>
            <v-toolbar color="secondary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'exclamation']" size="xl" class="mr-2" />
                    {{ $t('ConfirmPasswordReset') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading || user == null" />

            <v-form v-else class="pa-4" style="overflow-y: auto">
                {{ $t('YouHaveRequestedToResetThePassword_Colon') }}
                <br />
                <br />
                <div>
                    <span class="font-weight-bold">{{ $t('UserId_Colon') }}</span>
                    {{ user.userId }}
                </div>
                <div>
                    <span class="font-weight-bold">{{ $t('EmailAddress_Colon') }}</span>
                    {{ user.userEmailAddress }}
                </div>
                <br />
                {{ $t('AreYouSureYouWantToContinue_Question') }}
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <v-btn @click="confirmResetUserPassword">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'check']" size="xl" />
                    </template>
                    {{ $t('Confirm') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import {useResetUserPasswordModalStore} from '@/stores/modals/reset-user-password-modal-store';
import {watch, inject, ref} from 'vue';
import {SearchUsersResponse} from '@/models/api/responses/search/SearchUsersResponse';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import i18n from '@/i18n';

// Form
let isLoading = ref<boolean>(false);
const snackbar = ref({
    show: false,
    text: '',
});
const resetUserPasswordModalStore = useResetUserPasswordModalStore();
const user = ref<SearchUsersResponse | null>(null);

// Services
const apiService = inject('apiService') as ApiService;

/**
 * Load data for the modal.
 */
const loadData = async (userToLoad: SearchUsersResponse) => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // Set form user
    user.value = userToLoad;

    isLoading.value = false;
};

/**
 * Close the modal.
 */
const close = () => {
    resetUserPasswordModalStore.close();
};

/**
 * Save the modal.
 */
const confirmResetUserPassword = async () => {
    if (user.value == null) return;

    isLoading.value = true;

    try {
        // Call API to reset the user's password
        await apiService.post('users/reset-user-password-by-id', user.value.userId);

        // Show success feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ResetUserPassword_Success');

        // Close modal
        resetUserPasswordModalStore.close();
    } catch (ex: unknown) {
        // Show fail feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ErrorGeneric');
        isLoading.value = false;
    }
};

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    user.value = null;
};

// Watch for changes to resetUserPasswordModalStore.userId
watch(
    () => resetUserPasswordModalStore.isVisible,
    async (isVisible) => {
        if (isVisible && resetUserPasswordModalStore.user !== null) {
            await loadData(resetUserPasswordModalStore.user);
        }
    },
);
</script>
