import {ManageUserModalMode} from '@/enums/modal-modes/manage-user-modal-mode';
import {defineStore} from 'pinia';

export const useManageUserModalStore = defineStore('manageUserModal', {
    state: () => ({
        isVisible: false,
        modalMode: ManageUserModalMode.View,
        userId: null as number | null,
        savedCounter: 0,
    }),
    actions: {
        open(modalMode: ManageUserModalMode, userId: number | null = null) {
            // Close modal first (if it's already open) so that the watch is triggered when it's reopened
            this.close();

            this.modalMode = modalMode;
            this.userId = userId;
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
            this.userId = null;
        },
    },
});
