
export class ActivityReportForm {
    isQuickSearch: boolean = false;
    dateConcludedFrom: string | null = null;
    dateConcludedTo: string | null = null;
    farmSiteIdList: number[] | null = null;
    farmFieldId: number | null = null;
    userIdList: number[] | null = null;
    isExportCSV: boolean = false;

    constructor(data?: Partial<ActivityReportForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
