import {UploadedArtifactDto} from '@/models/data-transfer-objects/system/UploadedArtifactDto';

export class UploadFileRequest {
    uploadedArtifact: UploadedArtifactDto | null = null;

    constructor(data?: Partial<UploadFileRequest>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
