<template>
    <div>
        <v-dialog v-model="isVisible" style="width: 50vw">
            <v-card>
                <v-card-title class="swatchB1BG"
                    ><div class="swatchWHT">{{ $t('Filters') }}</div></v-card-title
                >
                <v-form ref="form" class="pa-4" style="height: 100%" @keyup.enter="search">
                    <work-task-plan-search-parameters v-model:searchParameters="localSearchParametersWorkTask" />
                </v-form>
                <v-card-actions class="swatchG9BG">
                    <v-btn @click="isVisible = false">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                        </template>
                        {{ $t('Cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="search" :disabled="isLoading">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'search']" size="xl" />
                        </template>
                        {{ $t('Search') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <LoadingSymbol v-if="isLoading" />

        <work-task-plan-search-results
            v-else
            @switch-to-search="openSearchModal"
            :is-filter-applied="isFilterApplied"
            :results-list="searchResultsWorkTask" />
        <v-snackbar v-model="snackbar.show" timeout="4000">
            {{ snackbar.text }}
        </v-snackbar>
    </div>
</template>

<script setup lang="ts">
import {computed, ref, inject, onMounted, watch} from 'vue';
import ApiService from '@/services/api-service.js';
import {WorkTaskPlanSearchParametersDto} from '@/models/data-transfer-objects/search/work-task-plan-search/WorkTaskPlanSearchParametersDto';
import {WorkTaskPlanSearchResultDto} from '@/models/data-transfer-objects/search/work-task-plan-search/WorkTaskPlanSearchResultDto';
import {SearchWorkTaskPlansResponse} from '@/models/api/responses/search/SearchWorkTaskPlansResponse';
import WorkTaskPlanSearchParameters from '@/views/work-tasks/WorkTaskPlanSearchParameters.vue';
import WorkTaskPlanSearchResults from '@/views/work-tasks/WorkTaskPlanSearchResults.vue';
import {SearchWorkTaskPlansRequest} from '@/models/api/requests/search/SearchWorkTaskPlansRequest';
import {useManageWorkTaskPlanModalStore} from '@/stores/modals/manage-work-task-plan-modal-store';
const manageWorkTaskPlanModalStore = useManageWorkTaskPlanModalStore();
import {useApplyWorkTaskPlanModalStore} from '@/stores/modals/apply-work-task-plan-modal-store';
const applyWorkTaskPlanModalStore = useApplyWorkTaskPlanModalStore();
import {useDeleteWorkTaskPlanModalStore} from '@/stores/modals/delete-work-task-plan-modal-store';
const deleteWorkTaskPlanModalStore = useDeleteWorkTaskPlanModalStore();
// Services
const apiService = inject('apiService') as ApiService;

//Local variables
const isVisible = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const searchParametersWorkTaskPlan = ref(new WorkTaskPlanSearchParametersDto());
const localSearchParametersWorkTask = ref(new WorkTaskPlanSearchParametersDto());
const searchResultsWorkTask = ref<WorkTaskPlanSearchResultDto[]>([]);
const snackbar = ref({
    show: false,
    text: '',
});

const isFilterApplied = computed(
    () =>
        !!(
            (searchParametersWorkTaskPlan.value.workTaskPlanName === null ||
                searchParametersWorkTaskPlan.value.workTaskPlanName === '') &&
            (searchParametersWorkTaskPlan.value.workTaskTypeCodeList === null ||
                searchParametersWorkTaskPlan.value.workTaskTypeCodeList.length === 0) &&
            (searchParametersWorkTaskPlan.value.workTaskTemplateIdList === null ||
                searchParametersWorkTaskPlan.value.workTaskTemplateIdList.length === 0)
        ),
);

/**
 * Open search modal
 */
async function openSearchModal() {
    isVisible.value = true;
}

/**
 * Save the modal.
 */
const search = async () => {
    isLoading.value = true;

    searchParametersWorkTaskPlan.value = Object.assign(
        new WorkTaskPlanSearchParametersDto(),
        localSearchParametersWorkTask.value,
    );
    searchParametersWorkTaskPlan.value.includeWorkTaskPlanItems = true;

    const workTaskPlanSearchRequest: SearchWorkTaskPlansRequest = {
        searchParameters: searchParametersWorkTaskPlan.value,
    };
    const response: SearchWorkTaskPlansResponse = await apiService.post(
        'search/work-task-plans',
        workTaskPlanSearchRequest,
    );
    searchResultsWorkTask.value = response.workTaskPlans;

    isLoading.value = false;
    isVisible.value = false;
};

const loadTaskList = async () => {
    localSearchParametersWorkTask.value = new WorkTaskPlanSearchParametersDto();
    await search();
    localSearchParametersWorkTask.value = Object.assign(
        new WorkTaskPlanSearchParametersDto(),
        searchParametersWorkTaskPlan.value,
    );
};

onMounted(async () => {
    await loadTaskList();
});

import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();
import {useDeleteUserModalStore} from '@/stores/modals/delete-user-modal-store';
const deleteUserModalStore = useDeleteUserModalStore();
import {useDeleteWorkTaskTemplateModalStore} from '@/stores/modals/delete-work-task-template-modal-store';
const deleteWorkTaskTemplateModalStore = useDeleteWorkTaskTemplateModalStore();

watch(
    [
        () => manageWorkTaskPlanModalStore.savedCounter,
        () => applyWorkTaskPlanModalStore.savedCounter,
        () => deleteWorkTaskPlanModalStore.deletedCounter,
        () => deleteOrCancelWorkTaskModalStore.savedCounter,
        () => deleteUserModalStore.deletedCounter,
        () => deleteWorkTaskTemplateModalStore.deletedCounter,
    ],
    async () => {
        await loadTaskList();
    },
    {deep: true},
);
</script>
