<template>
    <v-menu open-on-hover>
        <template v-slot:activator="{props}">
            <v-btn v-bind="props" class="operation-button" :elevation="elevation" rounded="x-large">
                {{ menuText }}
                <FontAwesomeIcon :icon="['fa', 'caret-down']" size="xl" class="ml-2" />
            </v-btn>
        </template>

        <v-list>
            <v-list-item>
                <v-list-item-title>
                    <v-btn @click="viewObservationModalStore.open(observation.observationId)">
                        {{ $t('ViewSlashActionObservation') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="userStore.hasRoleLevel(RoleCode.Observations, 2)">
                <v-list-item-title>
                    <v-btn @click="manageObservationModalStore.open(observation.observationId)">
                        {{ $t('ModifyObservation') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="observation.farmFieldId">
                <v-list-item-title>
                    <v-btn @click="viewOnMap(observation.farmFieldId, observation.farmSiteId)">
                        {{ $t('SelectField') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script setup lang="ts">
import {defineProps} from 'vue';
import i18n from '@/i18n';
import {ObservationSearchResultDto} from '@/models/data-transfer-objects/search/observation-search/ObservationSearchResultDto';
import {useFarmStore} from '@/stores/farm-store';
import {useUserStore} from '@/stores/user-store';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import {useAdvancedSearchModalStore} from '@/stores/modals/advanced-search-modal-store';
import {useManageObservationModalStore} from '@/stores/modals/manage-observation-modal-store';
import {useViewObservationModalStore} from '@/stores/modals/view-observation-modal-store';
import {RoleCode} from '@/enums/role-code';
import router from '@/router';

withDefaults(
    defineProps<{
        observation: ObservationSearchResultDto;
        menuText?: string;
        elevation?: number;
    }>(),
    {
        menuText: i18n.global.t('Operations'),
        elevation: 0,
    },
);

// Stores
const farmStore = useFarmStore();
const userStore = useUserStore();
const homeDashboardStore = useHomeDashboardStore();
const advancedSearchModalStore = useAdvancedSearchModalStore();
const manageObservationModalStore = useManageObservationModalStore();
const viewObservationModalStore = useViewObservationModalStore();

// view field on map
async function viewOnMap(farmFieldId: number, farmSiteId: number | null) {
    if (farmSiteId !== null && farmFieldId !== null) {
        if (farmStore.selectedSiteId !== farmSiteId) {
            farmStore.selectedSiteId = farmSiteId;
            if (userStore.isLoggedIn && userStore.user) userStore.user.farmSiteId = farmSiteId;
            setTimeout(() => {
                homeDashboardStore.farmFieldIdToFocusOn = farmFieldId;
            }, 1000);
        } else homeDashboardStore.farmFieldIdToFocusOn = farmFieldId;

        advancedSearchModalStore.close();
        if (!homeDashboardStore.isHomeDashboardOpen) {
            router.push({name: 'HomeDashboard'});
        }
    }
}
</script>
