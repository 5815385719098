import { UserFarmSitePositionCode } from "@/enums/user-farm-site-position-code";
import i18n from '@/i18n';

export interface UserFarmSitePosition {
    userFarmSitePositionCode: UserFarmSitePositionCode | null;
    getUserFarmPositionName(): string;
}

export const userFarmSitePositions: UserFarmSitePosition[] = [
    {
        userFarmSitePositionCode: UserFarmSitePositionCode.FarmSiteWorker,
        getUserFarmPositionName() {
            return i18n.global.t('FarmSiteWorker');
        },
    },
    {
        userFarmSitePositionCode: UserFarmSitePositionCode.FarmSiteManager,
        getUserFarmPositionName() {
            return i18n.global.t('FarmSiteManager');
        },
    },
    {
        userFarmSitePositionCode: UserFarmSitePositionCode.PlantationManager,
        getUserFarmPositionName() {
            return i18n.global.t('PlantationManager');
        },
    },
];

export function getUserFarmPositionName(userFarmSitePositionCode: UserFarmSitePositionCode): UserFarmSitePosition | undefined {
    return userFarmSitePositions.find((fsp) => fsp.userFarmSitePositionCode === userFarmSitePositionCode);
}
