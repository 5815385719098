import {GetWorkTaskPlansResponse} from '@/models/api/responses/data-source/GetWorkTaskPlansResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useWorkTaskPlans() {
    const apiService = inject('apiService') as ApiService;
    const workTaskPlans = ref<GetWorkTaskPlansResponse[]>([]);
    const workTaskPlansWithNull = ref<SelectListItem[]>([]);

    /**
     * Get list of work task plans.
     */
    const getWorkTaskPlans = async () => {
        workTaskPlans.value = await apiService.get('data-source/work-task-plans');

        // Add null value
        workTaskPlansWithNull.value = workTaskPlans.value.map((workTaskPlan) => ({
            value: workTaskPlan.workTaskPlanId,
            title: workTaskPlan.workTaskPlanName,
        }));

        workTaskPlansWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getWorkTaskPlans,
        workTaskPlans,
        workTaskPlansWithNull,
    };
}
