import i18n from '@/i18n';

class WorkTaskPlanHelper {
    /**
     * Get text to display in the Due After section for a specific plan item.
     */
    public static getDueAfterText(numberOfDaysDueAfterPreviousTask: number | null) {
        if (numberOfDaysDueAfterPreviousTask == null) {
            return '';
        } else if (numberOfDaysDueAfterPreviousTask == 0) {
            return i18n.global.t('ImmediatelyAfterPrevious');
        } else if (numberOfDaysDueAfterPreviousTask == 1) {
            return i18n.global.t('DaysAfterPreviousTaskSingular');
        } else {
            return i18n.global.t('DaysAfterPreviousTaskPlural', {
                numberOfDaysDueAfterPreviousTask: numberOfDaysDueAfterPreviousTask,
            });
        }
    }
}

export {WorkTaskPlanHelper};
export default WorkTaskPlanHelper;
