import i18n from '@/i18n';
import {SelectListItem} from '@/models/generic/SelectListItem';
import {ref} from 'vue';

export function useCropYears() {
    const cropYears = ref<SelectListItem[]>([]);
    const cropYearsWithNull = ref<SelectListItem[]>([]);

    /**
     * Adds the crop years.
     */
    const setCropYears = async () => {
        // Add years
        let yearNum = 1;
        while (yearNum <= 7) {
            cropYears.value.push({
                value: yearNum,
                title: `${i18n.global.t('Year')} ${yearNum.toString()}`,
                getTitle: function () {
                    return `${i18n.global.t('Year')} ${yearNum.toString()}`;
                },
            });

            yearNum++;
        }

        // Add null value
        cropYearsWithNull.value = [{value: null, title: ''}, ...cropYears.value];
    };

    // Fetch the search modes initially
    setCropYears();

    return {
        cropYears,
        cropYearsWithNull,
    };
}
