<template>
    <v-form
        ref="form"
        v-model="valid"
        validate-on="submit"
        class="work-task-plan-item"
        :class="{'new-item': isAddNewItem}">
        <table style="width: 100%; border-spacing: 1px; margin-bottom: -1px" class="tblForm swatchG9BG">
            <tr v-if="index == 0 || workTaskPlanItemList.length == 0">
                <td class="swatchG7BG">{{ $t('NumberShort') }}</td>
                <td class="swatchG7BG">{{ $t('Type') }}</td>
                <td class="swatchG7BG">{{ $t('TaskTemplate') }}</td>
                <td class="swatchG7BG">
                    {{ $t('DueAfter') }}
                    <HelpIcon
                        style="position: absolute; right: 6px"
                        :help-text="$t('ManageWorkTaskPlan_DueAfter_HelpText')" />
                </td>
                <td class="swatchG7BG">{{ $t('Action') }}</td>
            </tr>
            <tr>
                <td style="width: 7%; text-align: center" class="swatchWHTBG">
                    <span v-if="isAddNewItem">NEW</span>
                    <span v-if="!isAddNewItem">{{ index + 1 }}</span>
                </td>
                <td style="width: 22%" class="swatchWHTBG">
                    <div style="display: flex; align-items: center">
                        <v-select
                            v-model="localWorkTaskPlanItem.workTaskTypeCode"
                            :items="workTaskTypes"
                            item-title="title"
                            item-value="value"
                            max-width="180px"
                            class="swatchA1 font-weight-bold" />

                        <div class="ml-4">
                            <WorkTaskTypeIcon
                                v-if="localWorkTaskPlanItem.workTaskTypeCode"
                                :workTaskTypeCode="localWorkTaskPlanItem.workTaskTypeCode" />
                        </div>
                    </div>
                </td>
                <td style="width: 43%" class="swatchWHTBG">
                    <div style="display: flex; align-items: center">
                        <v-autocomplete
                            v-model="localWorkTaskPlanItem.workTaskTemplate"
                            :rules="[requiredRule]"
                            required
                            :readonly="!isAddNewItem"
                            :placeholder="$t('Select')"
                            :items="filteredWorkTaskTemplateList"
                            item-title="workTaskTemplateName"
                            item-value="workTaskTemplateId"
                            return-object
                            max-width="390px"
                            menu-icon=""
                            class="swatchA1 font-weight-bold"
                            :class="{noSelection: !isAddNewItem}">
                            <template v-slot:append-inner>
                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchG2BG"
                                    color="swatchWHT"
                                    v-if="!isAddNewItem">
                                    <FontAwesomeIcon :icon="['fas', 'lock']" />
                                </CircleIcon>
                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchGRNBG"
                                    color="swatchWHT"
                                    v-else-if="localWorkTaskPlanItem.workTaskTemplate != null">
                                    <FontAwesomeIcon :icon="['fas', 'check']" />
                                </CircleIcon>
                            </template>
                        </v-autocomplete>

                        <v-btn
                            v-if="localWorkTaskPlanItem.workTaskTemplate != null"
                            class="v-btn--custom ml-4"
                            @click="
                                viewWorkTaskTemplateModalStore.open(
                                    localWorkTaskPlanItem.workTaskTemplate.workTaskTemplateId,
                                    false,
                                )
                            ">
                            <template v-slot:prepend>
                                <FontAwesomeIcon :icon="['fal', 'arrow-circle-right']" size="xl" />
                            </template>
                            {{ $t('View') }}
                        </v-btn>
                    </div>
                </td>
                <td style="width: 12%" class="swatchWHTBG">
                    <v-text-field
                        v-if="index == 0 || workTaskPlanItemList.length == 0"
                        :label="$t('NA')"
                        disabled
                        width="125px"
                        class="swatchA1 font-weight-bold">
                        <template #append-inner>
                            <span>{{ $t('Days') }}</span>
                        </template>
                    </v-text-field>

                    <v-text-field
                        v-else
                        v-model="localWorkTaskPlanItem.numberOfDaysDueAfterPreviousTask"
                        :rules="[requiredRule, numericRule, wholeNumberRule]"
                        required
                        :suffix="$t('Days')"
                        width="125px"
                        class="swatchA1 font-weight-bold" />
                </td>
                <td style="width: 16%; text-align: center" class="swatchWHTBG">
                    <!-- Add button. Shown when the component is in "Add" mode. -->
                    <v-btn
                        v-if="isAddNewItem"
                        @click="addWorkTaskPlanItem"
                        class="v-btn--custom"
                        style="align-self: flex-end; max-width: 100%">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'plus']" size="xl" />
                        </template>
                        {{ $t('Add') }}
                    </v-btn>

                    <!-- Up/down buttons -->
                    <v-btn
                        v-if="!isAddNewItem"
                        @click="moveItemBack(index)"
                        icon="mdi-arrow-up"
                        class="v-btn--custom"
                        density="comfortable"
                        :disabled="index == 0"
                        :ripple="false"
                        v-ripple="{duration: 250}" />

                    <v-btn
                        v-if="!isAddNewItem"
                        @click="moveItemAhead(index)"
                        icon="mdi-arrow-down"
                        class="v-btn--custom ml-2"
                        density="comfortable"
                        :disabled="index == workTaskPlanItemList.length - 1"
                        :ripple="false"
                        v-ripple="{duration: 250}" />

                    <!-- Delete button. Shown when displaying an existing work task product. It will be replaced with 
                        the Reset button in action mode if this is a product that has been saved against the task. -->
                    <v-btn
                        v-if="!isAddNewItem"
                        @click="deleteWorkTaskPlanItem"
                        icon="mdi-trash-can-outline"
                        class="v-btn--custom ml-2"
                        density="comfortable" />
                </td>
            </tr>
        </table>
    </v-form>
</template>

<script setup lang="ts">
import Validation from '@/helpers/ValidationHelper';
import {WorkTaskTemplateSearchResultDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchResultDto';
import {SelectListItem} from '@/models/generic/SelectListItem';
import {WorkTaskPlanItemForm} from '@/models/work-tasks/WorkTaskPlanItemForm';
import {computed, nextTick, onMounted, ref, watch} from 'vue';
import {useViewWorkTaskTemplateModalStore} from '@/stores/modals/view-work-task-template-modal-store';
const viewWorkTaskTemplateModalStore = useViewWorkTaskTemplateModalStore();
import {useConfigStore} from '@/stores/config-store';
const configStore = useConfigStore();

const props = withDefaults(
    defineProps<{
        workTaskPlanItem?: WorkTaskPlanItemForm | null;
        workTaskPlanItemList: WorkTaskPlanItemForm[];
        workTaskTypes: SelectListItem[];
        workTaskTemplates: WorkTaskTemplateSearchResultDto[];
        index: number;
    }>(),
    {
        workTaskPlanItem: null,
    },
);
const emit = defineEmits(['add', 'update', 'delete', 'moveItemAhead', 'moveItemBack']);

// Form
const form = ref();
const valid = ref<boolean>(true);
const isLoading = ref<boolean>(true);
const localWorkTaskPlanItem = ref<WorkTaskPlanItemForm>(new WorkTaskPlanItemForm());
let newItemCount = 0;

// Rules
const requiredRule = Validation.createRule_Required();
const numericRule = Validation.createRule_Numeric(null, 0);
const wholeNumberRule = Validation.createRule_WholeNumber();

/**
 * Adds the plan item.
 */
const addWorkTaskPlanItem = async () => {
    // Perform final client side validation of form
    await validate();

    // If form is valid
    if (valid.value) {
        // Increment new item count so that new items have a unique ID
        newItemCount += 1;

        // Set new item ID
        localWorkTaskPlanItem.value.newItemId = 'NewItem' + newItemCount;

        // Call emit
        emit('add', localWorkTaskPlanItem.value);

        // Reset data so that another item can be added if needed
        localWorkTaskPlanItem.value = new WorkTaskPlanItemForm();
    }
};

/**
 * Deletes the plan item.
 */
const deleteWorkTaskPlanItem = () => {
    emit('delete', props.index);
};

/**
 * Moves the plan item up.
 */
const moveItemAhead = (workTaskPlanItemIndex: number) => {
    emit('moveItemAhead', workTaskPlanItemIndex);
};

/**
 * Moves the plan item down.
 */
const moveItemBack = (workTaskPlanItemIndex: number) => {
    emit('moveItemBack', workTaskPlanItemIndex);
};

/**
 * Validate the form.
 */
const validate = async (): Promise<boolean> => {
    if (form.value) {
        const {valid} = await form.value.validate();
        return valid;
    }
    return false;
};

/**
 * Flag to indicate if this is in "Add" mode or if it's displaying an existing plan item.
 */
const isAddNewItem = computed(() => props.index == -1);

/**
 * List of work task templates filtered by the selected task type.
 */
const filteredWorkTaskTemplateList = computed(() => {
    const workTaskTypeCode = localWorkTaskPlanItem.value.workTaskTypeCode;

    return props.workTaskTemplates.filter(
        (wtt) => workTaskTypeCode == null || wtt.workTaskTypeCode == workTaskTypeCode,
    );
});

// Update parent when the data is changed.
watch(
    [() => localWorkTaskPlanItem],
    () => {
        if (!isLoading.value) {
            emit('update', localWorkTaskPlanItem.value);
        }
    },
    {deep: true},
);

/**
 * When the task type code changes, set the selected template back to null to ensure a template with a different
 * type is not selected.
 */
watch(
    () => localWorkTaskPlanItem.value.workTaskTypeCode,
    async () => {
        // If the task type of the selected template is different, set the selected template to null
        if (
            localWorkTaskPlanItem.value.workTaskTemplate?.workTaskTypeCode !=
            localWorkTaskPlanItem.value.workTaskTypeCode
        ) {
            localWorkTaskPlanItem.value.workTaskTemplate = null;
        }
    },
);

/**
 * When the task template changes, set the selected task type to the task type of the selected template.
 */
watch(
    () => localWorkTaskPlanItem.value.workTaskTemplate,
    async () => {
        // If the task type of the selected template is different, set the selected template to null
        if (
            localWorkTaskPlanItem.value.workTaskTemplate != null &&
            localWorkTaskPlanItem.value.workTaskTemplate.workTaskTypeCode !=
                localWorkTaskPlanItem.value.workTaskTypeCode
        ) {
            localWorkTaskPlanItem.value.workTaskTypeCode =
                localWorkTaskPlanItem.value.workTaskTemplate.workTaskTypeCode;
        }
    },
);

onMounted(async () => {
    if (props.workTaskPlanItem) {
        localWorkTaskPlanItem.value = Object.assign(new WorkTaskPlanItemForm(), props.workTaskPlanItem);
    }

    // Set default value if in Add mode
    if (isAddNewItem.value) {
        localWorkTaskPlanItem.value.numberOfDaysDueAfterPreviousTask = configStore.taskPlanItemDefaultDueAfterValue;
    }

    // End loading (nextTick is used to ensure that the watch events on localWorkTaskPlanItem are not triggered during the load)
    nextTick(() => {
        isLoading.value = false;
    });
});

defineExpose({validate});
</script>
