import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-102c2f49"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "work-task-type-name pl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkTaskTypeIcon = _resolveComponent("WorkTaskTypeIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["work-task-type-select-box noSelection", {selected: _ctx.isSelected, disabled: !_ctx.isEnabled}]),
    title: _ctx.isEnabled ? '' : _ctx.$t('TaskTypeCannotBeChanged')
  }, [
    _createVNode(_component_WorkTaskTypeIcon, {
      "work-task-type-code": _ctx.workTaskTypeCode,
      "is-tooltip-disabled": true
    }, null, 8, ["work-task-type-code"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.name), 1)
  ], 10, _hoisted_1))
}