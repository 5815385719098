<template>
    <v-form :class="{'first-item': index == 0}">
        <table style="width: 100%; border-spacing: 1px; margin-bottom: -1px" class="tblForm swatchG9BG">
            <tr v-if="index == 0">
                <td class="swatchG7BG">{{ $t('NumberShort') }}</td>
                <td class="swatchG7BG">{{ $t('Product') }}</td>
                <td class="swatchG7BG">{{ $t('Type') }}</td>
                <td class="swatchG7BG">{{ $t('ApplicationRate') }}</td>
            </tr>
            <tr v-if="showHeader">
                <td colspan="4" class="swatchG4BG swatchG4txt">
                    <div style="display: flex; align-items: center">
                        <CircleIcon
                            class="swatchWHTBorder mr-4"
                            :class="workTaskTypeCode == WorkTaskTypeCode.Fertilize ? 'swatchB2BG' : 'swatchB1BG'"
                            color="swatchWHT">
                            <FontAwesomeIcon
                                :icon="['fas', workTaskProduct.mixtureId ? 'flask-round-potion' : 'bag-seedling']" />
                        </CircleIcon>

                        <div v-if="workTaskProduct.mixtureId">
                            {{ $t('Mixture') }}: "{{ workTaskProduct.mixtureName }}"
                        </div>
                        <div v-else>
                            {{ $t('OtherProductsInThisTask') }}
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td style="width: 7%; text-align: center" class="swatchWHTBG">
                    {{ index + 1 }}
                </td>
                <td style="width: 47%" class="swatchWHTBG">
                    <div style="display: flex; align-items: center">
                        <span class="swatchA1 bold">{{ workTaskProduct.productName }}</span>

                        <!-- View Product button -->
                        <v-btn
                            @click="viewProductModalStore.open(workTaskProduct.productId)"
                            class="v-btn--custom"
                            style="margin-left: auto">
                            <template v-slot:prepend>
                                <FontAwesomeIcon :icon="['fal', 'arrow-circle-right']" size="xl" />
                            </template>
                            {{ $t('View') }}
                        </v-btn>
                    </div>
                </td>
                <td style="width: 17%; text-align: center" class="swatchWHTBG">
                    <ProductSubtypeIcon
                        :product-type-code="workTaskProduct.productTypeCode"
                        :product-subtype-code="workTaskProduct.productSubtypeCode"
                        icon-height="38"
                        icon-width="38" />
                </td>
                <td style="width: 29%" class="swatchWHTBG">
                    <div style="display: flex; align-items: center">
                        <div style="width: 6rem">
                            <span v-if="isActualApplicationRateDifferentToPlanned" class="swatchA1 strikethrough">
                                {{ workTaskProduct.plannedApplicationRate ?? 0 }}
                            </span>
                        </div>
                        <div
                            style="width: 6rem"
                            class="swatchA1 bold"
                            :class="{swatchRED: isActualApplicationRateDifferentToPlanned}">
                            {{ applicationRate }}
                        </div>
                        <div>
                            {{ applicationRateUnitName }}
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </v-form>
</template>

<script setup lang="ts">
import ProductSubtypeIcon from '@/components/ProductSubtypeIcon.vue';
import {applicationRateUnits} from '@/services/application-rate-units-service';
import {WorkTaskProductDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskProductDto';
import {computed} from 'vue';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import {WorkTaskTemplateProductDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateProductDto';
import {ApplicationRateUnitCode} from '@/enums/application-rate-unit-code';
import {useViewProductModalStore} from '@/stores/modals/view-product-modal-store';
const viewProductModalStore = useViewProductModalStore();

const props = defineProps<{
    workTaskProduct: WorkTaskProductDto | WorkTaskTemplateProductDto;
    index: number;
    workTaskProductList: WorkTaskProductDto[] | WorkTaskTemplateProductDto[];
    workTaskTypeCode: WorkTaskTypeCode;
}>();

/**
 * Flag to indicate if the passed object is in Task or Template mode. This is used to prevent type errors.
 */
const isTemplateMode = (
    workTaskProduct: WorkTaskProductDto | WorkTaskTemplateProductDto,
): workTaskProduct is WorkTaskTemplateProductDto => {
    return !('actualApplicationRate' in workTaskProduct);
};

/**
 * The application rate.
 */
const applicationRate = computed(() => {
    if (isTemplateMode(props.workTaskProduct)) {
        return props.workTaskProduct.plannedApplicationRate;
    } else {
        return props.workTaskProduct.actualApplicationRate ?? props.workTaskProduct.plannedApplicationRate;
    }
});

/**
 * The name of the application rate unit.
 */
const applicationRateUnitName = computed(() => {
    // Determine unit code
    let applicationRateUnitCode: ApplicationRateUnitCode | null = null;
    if (isTemplateMode(props.workTaskProduct)) {
        applicationRateUnitCode = props.workTaskProduct.plannedApplicationRateUnitCode;
    } else {
        applicationRateUnitCode =
            props.workTaskProduct.actualApplicationRateUnitCode ?? props.workTaskProduct.plannedApplicationRateUnitCode;
    }

    // Determine name
    return applicationRateUnits.find((aru) => aru.applicationRateUnitCode == applicationRateUnitCode)
        ?.applicationRateUnitName;
});

/**
 * Flag to indicate if the actual application rate is different to what was planend.
 */
const isActualApplicationRateDifferentToPlanned = computed(() => {
    return (
        !isTemplateMode(props.workTaskProduct) &&
        props.workTaskProduct.actualApplicationRate != null &&
        props.workTaskProduct.actualApplicationRate != props.workTaskProduct.plannedApplicationRate
    );
});

/**
 * Flag to indicate if the header should be shown if this is the start of a new product grouping.
 */
const showHeader = computed(() => {
    if (props.workTaskTypeCode == WorkTaskTypeCode.Fertilize || props.workTaskTypeCode == WorkTaskTypeCode.Spray) {
        if (props.index == 0) {
            // Show header if this is the first product
            return true;
        } else if (props.workTaskProduct.mixtureId != props.workTaskProductList[props.index - 1].mixtureId) {
            // Show header if this product's mixture ID is different to the product before it
            return true;
        }
    }

    return false;
});
</script>
