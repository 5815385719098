<template>
    <v-card
        class="clickable-card"
        style="height: 90px; max-width: 300px; align-content: center"
        :style="displayImageName ? '' : 'width: 90px'"
        elevation="1"
        @click="goToFileLink"
        v-if="imageUrl && imageUrl !== ''">
        <div class="pl-1 pr-1" style="display: flex">
            <img :src="imageUrl" :alt="imageTitle" class="image-with-border" />
            <span v-if="displayImageName" class="image-name pa-2">
                {{ props.imageTitle }}
            </span>
        </div>
    </v-card>
</template>

<script setup lang="ts">
import {defineProps} from 'vue';

const props = defineProps<{
    imageUrl: string;
    imageTitle?: string;
    displayImageName?: boolean;
}>();

// Function to handle the click event
const goToFileLink = () => window.open(props.imageUrl, '_blank');
</script>

<style lang="scss" scoped>
@import '../assets/scss/swatches.scss';
.clickable-card {
    cursor: pointer;
    transition: box-shadow 0.3s ease;
}

.clickable-card:hover {
    box-shadow: 0px 4px 12px $swatchG9;
}

.image-name {
    font-size: 16px;
    font-weight: normal;
    font-family: 'Open Sans Condensed', sans-serif;
    align-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.image-with-border {
    height: 80px;
    max-width: 120px;
    border: 1px solid $swatchBLK;
}
</style>
