import {SearchWorkTaskPlansRequest} from '@/models/api/requests/search/SearchWorkTaskPlansRequest';
import {SearchWorkTaskTemplatesRequest} from '@/models/api/requests/search/SearchWorkTaskTemplatesRequest';
import {SearchWorkTaskPlansResponse} from '@/models/api/responses/search/SearchWorkTaskPlansResponse';
import {SearchWorkTaskTemplatesResponse} from '@/models/api/responses/search/SearchWorkTaskTemplatesResponse';
import {WorkTaskPlanItemDto} from '@/models/data-transfer-objects/search/work-task-plan-search/WorkTaskPlanItemDto';
import {WorkTaskPlanSearchParametersDto} from '@/models/data-transfer-objects/search/work-task-plan-search/WorkTaskPlanSearchParametersDto';
import {WorkTaskPlanSearchResultDto} from '@/models/data-transfer-objects/search/work-task-plan-search/WorkTaskPlanSearchResultDto';
import {WorkTaskTemplateSearchParametersDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchParametersDto';
import {WorkTaskTemplateSearchResultDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchResultDto';
import ApiService from '@/services/api-service';
import {computed, ComputedRef, ref} from 'vue';

class WorkTaskPlanService {
    private apiService: ApiService;
    public workTaskPlan = ref<WorkTaskPlanSearchResultDto | null>(null);
    public workTaskTemplates = ref<WorkTaskTemplateSearchResultDto[]>([]);

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    /**
     * Loads the list of work task templates that are associated with this plan.
     */
    public async loadWorkTaskPlan(workTaskPlanId: number) {
        // Build search request using the work task plan ID
        const searchRequest: SearchWorkTaskPlansRequest = {
            searchParameters: new WorkTaskPlanSearchParametersDto({
                workTaskPlanId: workTaskPlanId,
                includeWorkTaskPlanItems: true,
            }),
        };

        // Get work task plan details
        const searchResults = (await this.apiService.post(
            'search/work-task-plans',
            searchRequest,
        )) as SearchWorkTaskPlansResponse;

        if (searchResults.workTaskPlans.length == 1) {
            // Store search result
            this.workTaskPlan.value = searchResults.workTaskPlans[0];

            // Load templates
            await this.loadWorkTaskTemplates(workTaskPlanId);
        }
    }

    /**
     * Loads the list of work task templates that are associated with this plan.
     */
    public async loadWorkTaskTemplates(workTaskPlanId: number) {
        // Build search request
        const workTaskTemplatesSearchRequest: SearchWorkTaskTemplatesRequest = {
            searchParameters: new WorkTaskTemplateSearchParametersDto({
                workTaskPlanIdList: [workTaskPlanId],
                includeArtifacts: true,
                includeWorkTaskTemplateSubTasks: true,
                includeWorkTaskTemplateMixtures: true,
                includeWorkTaskTemplateProducts: true,
                includeWorkTaskTemplateCropVarietyPreferences: true,
            }),
        };

        // Get work task template details
        const workTaskTemplatesSearchResults = (await this.apiService.post(
            'search/work-task-templates',
            workTaskTemplatesSearchRequest,
        )) as SearchWorkTaskTemplatesResponse;

        this.workTaskTemplates.value = workTaskTemplatesSearchResults.workTaskTemplates;
    }

    /**
     * Get task template by ID.
     */
    public getTaskTemplate(taskTemplateId: number) {
        return this.workTaskTemplates.value.find((wtt) => wtt.workTaskTemplateId == taskTemplateId)!;
    }

    /**
     * List of work task plan items sorted by their sequence number.
     */
    public workTaskPlanItemsSorted: ComputedRef<WorkTaskPlanItemDto[]> = computed(() => {
        if (!this.workTaskPlan.value) return [];

        const sortedArray = [...this.workTaskPlan.value.workTaskPlanItems];

        // Populate workTaskTemplateNameWithSequenceNumber for each item
        sortedArray.forEach((item) => {
            item.workTaskTemplateNameWithSequenceNumber = `${item.sequenceNumber}. ${item.workTaskTemplateName}`;
        });

        return sortedArray.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
    });
}

export {WorkTaskPlanService};
export default WorkTaskPlanService;
