import {WorkTaskDueDateTiming} from '@/enums/work-task-due-date-timing';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import {MonthAndYear} from '../generic/MonthAndYear';
import {WorkSubTaskForm} from './WorkSubTaskForm';
import {WorkTaskMixtureForm} from './WorkTaskMixtureForm';
import {WorkTaskProductForm} from './WorkTaskProductForm';
import {WorkTaskCropVarietyPreferenceForm} from './WorkTaskCropVarietyPreferenceForm';

export class ManageWorkTaskForm {
    workTaskId: number | null = null;
    workTaskTemplateId: number | null = null;
    workTaskTypeCode: WorkTaskTypeCode | null = null;
    farmFieldId: number | null = null;
    workTaskTemplateName: string | null = null;
    workTaskName: string | null = null;
    dueDateTiming: WorkTaskDueDateTiming = WorkTaskDueDateTiming.DueBy;
    dueDateOn: Date | null = null;
    dueDateLatest: Date | null = null;
    dueDateMonth: MonthAndYear | null = null;
    dueDateRange: Date[] = [];
    dueAfterWorkTaskId: number | null = null;
    dueAfterWorkTaskWithinDays: number | null = null;
    workTaskInstructions: string | null = null;
    irrigationRatePlanned: number | null = 0;
    plantCropId: number | null = null;
    artifacts: File[] = [];
    haveArtifactsBeenUpdated = false;
    workSubTasks: WorkSubTaskForm[] = [];
    workTaskMixtures: WorkTaskMixtureForm[] = [];
    workTaskProducts: WorkTaskProductForm[] = [];
    workTaskCropVarietyPreferences: WorkTaskCropVarietyPreferenceForm[] = [];
    duplicatedFromWorkTaskId: number | null = null;
    duplicatedFromWorkTaskTemplateId: number | null = null;
    createdFromWorkTaskId: number | null = null;
    createdFromWorkTaskTemplateId: number | null = null;
    isModifyTemplateConfirmed = false;

    constructor(data?: Partial<ManageWorkTaskForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
