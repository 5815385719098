import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "divManageUsers" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_LoadingSymbol = _resolveComponent("LoadingSymbol")!
  const _component_user_search_parameters = _resolveComponent("user-search-parameters")!
  const _component_v_tabs_window_item = _resolveComponent("v-tabs-window-item")!
  const _component_user_search_results = _resolveComponent("user-search-results")!
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, {
      flat: "",
      style: {"overflow":"visible"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_toolbar, {
          flat: "",
          color: "swatchG2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_tabs, {
              modelValue: _ctx.tab,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_tab, { value: "SearchParameters" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FontAwesomeIcon, {
                      icon: ['fal', 'filter'],
                      size: "xl",
                      class: "mr-2"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('Filters')), 1)
                  ]),
                  _: 1
                }),
                _withDirectives(_createVNode(_component_v_tab, { value: "SearchResults" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FontAwesomeIcon, {
                      icon: ['fal', 'th'],
                      size: "xl",
                      class: "mr-2"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.resultsTabTitle), 1)
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, _ctx.hasSearchBeenRun]
                ])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_v_spacer),
            (_ctx.hasModifyStaffAccess)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.manageUserModalStore.open(_ctx.ManageUserModalMode.Create))),
                  variant: "outlined"
                }, {
                  prepend: _withCtx(() => [
                    _createVNode(_component_FontAwesomeIcon, {
                      icon: ['fal', 'user-plus'],
                      size: "xl"
                    })
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('CreateUser')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_LoadingSymbol, { key: 0 }))
          : (_openBlock(), _createBlock(_component_v_form, {
              key: 1,
              class: "pa-4",
              onKeyup: _withKeys(_ctx.search, ["enter"])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_tabs_window, {
                  modelValue: _ctx.tab,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tab) = $event)),
                  style: {"overflow":"visible"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_tabs_window_item, { value: "SearchParameters" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_user_search_parameters, {
                          searchParameters: _ctx.searchParameters,
                          "onUpdate:searchParameters": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchParameters) = $event))
                        }, null, 8, ["searchParameters"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_tabs_window_item, { value: "SearchResults" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_user_search_results, { "results-list": _ctx.searchResults }, null, 8, ["results-list"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["onKeyup"])),
        _createVNode(_component_v_card_actions, { class: "justify-space-between swatchG9BG" }, {
          default: _withCtx(() => [
            (_ctx.isSearchParametersTabSelected)
              ? (_openBlock(), _createBlock(_component_BackButton, { key: 0 }))
              : (_openBlock(), _createBlock(_component_BackButton, {
                  key: 1,
                  onClick: _ctx.selectSearchParametersTab
                }, null, 8, ["onClick"])),
            _createVNode(_component_v_btn, { onClick: _ctx.search }, {
              prepend: _withCtx(() => [
                _createVNode(_component_FontAwesomeIcon, {
                  icon: ['fal', 'check-square'],
                  size: "xl"
                })
              ]),
              default: _withCtx(() => [
                _createTextVNode(" " + _toDisplayString(_ctx.$t('ApplyFilters')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}