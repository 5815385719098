import {GetObservationStatusesResponse} from '@/models/api/responses/data-source/GetObservationStatusesResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref, computed} from 'vue';
import i18n from '@/i18n';
import {ObservationStatusOptionItem} from '@/models/observations/ObservationStatusOptionItem';

export function useObservationStatuses() {
    const apiService = inject('apiService') as ApiService;
    const observationStatuses = ref<GetObservationStatusesResponse[]>([]);
    const observationStatusesWithNull = ref<SelectListItem[]>([]);
    const localObservationStatuses = computed((): ObservationStatusOptionItem[] => [
        {
            observationStatusCode: 'Open',
            observationStatusName: i18n.global.t('Open'),
            observationStatusActionNameWithChange: '',
            observationStatusActionNameNoChange: '',
        } as ObservationStatusOptionItem,
        {
            observationStatusCode: 'Acknowledged',
            observationStatusName: i18n.global.t('Acknowledged'),
            observationStatusActionNameWithChange: i18n.global.t('AcknowledgeThisObservation'),
            observationStatusActionNameNoChange: i18n.global.t('AcknowledgedParenthesisNoChange'),
        } as ObservationStatusOptionItem,
        {
            observationStatusCode: 'InProgress',
            observationStatusName: i18n.global.t('InProgress'),
            observationStatusActionNameWithChange: i18n.global.t('ChangeToInProgress'),
            observationStatusActionNameNoChange: i18n.global.t('InProgressParenthesisNoChange'),
        } as ObservationStatusOptionItem,

        {
            observationStatusCode: 'Cancelled',
            observationStatusName: i18n.global.t('Cancelled'),
            observationStatusActionNameWithChange: i18n.global.t('CancelThisObservation'),
            observationStatusActionNameNoChange: i18n.global.t('CancelledParenthesisNoChange'),
        } as ObservationStatusOptionItem,

        {
            observationStatusCode: 'Resolved',
            observationStatusName: i18n.global.t('Resolved'),
            observationStatusActionNameWithChange: i18n.global.t('ResolveThisObservation'),
            observationStatusActionNameNoChange: i18n.global.t('ResolvedParenthesisNoChange'),
        } as ObservationStatusOptionItem,
        {
            observationStatusCode: 'Archived',
            observationStatusName: i18n.global.t('Archived'),
            observationStatusActionNameWithChange: i18n.global.t('ArchiveThisObservation'),
            observationStatusActionNameNoChange: i18n.global.t('ArchivedParenthesisNoChange'),
        } as ObservationStatusOptionItem,
    ]);

    /**
     * Get list of observation statuses.
     */
    const getObservationStatuses = async () => {
        observationStatuses.value = await apiService.get('data-source/observation-statuses');

        // Add null value
        observationStatusesWithNull.value = observationStatuses.value.map((observationStatus) => ({
            value: observationStatus.observationStatusCode,
            title: observationStatus.observationStatusName,
        }));

        observationStatusesWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getObservationStatuses,
        observationStatuses,
        observationStatusesWithNull,
        localObservationStatuses,
    };
}
