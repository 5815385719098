<template>
    <v-card
        rounded="true"
        class="list-item-container ma-0 pa-0"
        style="padding: 5px"
        elevation="1"
        @click="
            userStore.hasRoleLevel(RoleCode.Observations, 1) &&
                viewObservationModalStore.open(observation.observationId)
        ">
        <v-row class="task-type-code swatchG7BG" style="position: relative">
            <span class="pt-2">
                <ObservationTypeChips
                    :observationType="observation.observationTypeCode"
                    iconHeight="30"
                    iconWidth="38"
                    iconSize="23"
            /></span>

            <span class="swatchBLK pt-2 pl-2">
                {{ observation.observationTypeName }}
            </span>

            <span class="status-chip-container">
                <ObservationStatusChip
                    :observation-status-code="observation.observationStatusCode"
                    width="85px"
                    height="25px"
                    fontSize="15px" />
            </span>
        </v-row>

        <v-row class="pl-2 pt-1" justify="space-between">
            <div>
                <FontAwesomeIcon class="swatchG3" :icon="['fas', 'calendar-day']" size="xl" />
                <span class="pl-2 swatchA1">{{ recordedDate }}</span>
            </div>

            <v-menu class="mr-2 d-flex justify-end" @click.stop>
                <template v-slot:activator="{props}">
                    <div v-bind="props" class="task-menu-button mr-2">
                        <div class="menu-hover">
                            <FontAwesomeIcon :icon="['fas', 'caret-down']" size="xl" style="padding-left: 6px" />
                        </div>
                    </div>
                </template>

                <v-list class="swatchG2BG" density="compact">
                    <v-list-item>
                        <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Observations, 1)">
                            <v-btn
                                class="swatchWHT swatchG2BG"
                                @click="viewObservationModalStore.open(observation.observationId)">
                                {{ $t('ViewObservation') }}
                            </v-btn>
                        </v-list-item-title>
                        <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Observations, 2)">
                            <v-btn
                                class="swatchWHT swatchG2BG"
                                @click="manageObservationModalStore.open(observation.observationId)">
                                {{ $t('UpdateObservation') }}
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-row>
        <v-row class="pb-1 pl-9" style="min-height: 30px" v-if="observation.recordedUserId">
            <UserChip :user="recordedUser" />
        </v-row>
        <v-row class="pl-2 pt-1 pb-1" v-if="isDisplayingSecondDate">
            <FontAwesomeIcon :class="secondDateIconColor" :icon="['fas', secondDateIcon]" size="xl" />

            <div class="pl-2 swatchA1">
                {{ secondDateText }}
            </div>
        </v-row>

        <v-row class="pb-1 pl-9" style="min-height: 30px" v-if="isDisplayingSecondDate">
            <UserChip :user="secondActionUser" />
        </v-row>

        <v-row class="task-instructions pl-2 pr-2 swatchA1">
            <TranslatableTexts :text="observation.observationComments" />
        </v-row>
    </v-card>
</template>

<script setup lang="ts">
import {ObservationSearchResultDto} from '@/models/data-transfer-objects/search/observation-search/ObservationSearchResultDto';
import {format} from 'date-fns';
import UserChip from '@/components/UserChip.vue';
import ObservationTypeChips from '@/components/ObservationTypeChips.vue';
import ObservationStatusChip from '@/components/ObservationStatusChip.vue';
import {computed} from 'vue';
import UserHelper from '@/helpers/UserHelper';
import {useManageObservationModalStore} from '@/stores/modals/manage-observation-modal-store';
import {useViewObservationModalStore} from '@/stores/modals/view-observation-modal-store';
import {RoleCode} from '@/enums/role-code';
import {useUserStore} from '@/stores/user-store';

// Props
const props = withDefaults(
    defineProps<{
        observation: ObservationSearchResultDto;
    }>(),
    {},
);

const userStore = useUserStore();
const manageObservationModalStore = useManageObservationModalStore();
const viewObservationModalStore = useViewObservationModalStore();

const isDisplayingSecondDate = computed(() => {
    if (props.observation.archivedDate && props.observation.resolvedDate) return true;
    if (props.observation.lastModifiedDate && props.observation.lastModifiedDate != props.observation.recordedDate)
        return true;
    return false;
});

const formatDate = (date: Date | null): string | null => {
    if (!date) return null;
    return format(date, 'd MMM, yyyy');
};

const recordedUser = computed(() =>
    UserHelper.userChipType(
        props.observation.recordedUserId,
        UserHelper.userFullName(props.observation.recordedUserGivenName, props.observation.recordedUserSurname),
    ),
);

const recordedDate = computed(() => formatDate(props.observation.recordedDate));

const secondDateIcon = computed(() => {
    if (props.observation.archivedDate) return 'box-archive';
    if (props.observation.resolvedDate) return 'calendar-check';
    return 'calendar-lines-pen';
});

const secondDateIconColor = computed(() => {
    if (props.observation.archivedDate) return ['swatchG4'];
    if (props.observation.resolvedDate) return ['swatchGRN'];
    return ['swatchB6'];
});

const secondDateText = computed(() => {
    if (props.observation.archivedDate) return formatDate(props.observation.archivedDate);
    if (props.observation.resolvedDate) return formatDate(props.observation.resolvedDate);
    return formatDate(props.observation.lastModifiedDate);
});

const secondActionUser = computed(() => {
    if (props.observation.archivedUserId)
        return UserHelper.userChipType(
            props.observation.archivedUserId,
            UserHelper.userFullName(props.observation.archivedUserGivenName, props.observation.archivedUserSurname),
        );
    if (props.observation.resolvedUserId)
        return UserHelper.userChipType(
            props.observation.resolvedUserId,
            UserHelper.userFullName(props.observation.resolvedUserGivenName, props.observation.resolvedUserSurname),
        );
    if (props.observation.acknowledgedUserId)
        return UserHelper.userChipType(
            props.observation.acknowledgedUserId,
            UserHelper.userFullName(
                props.observation.acknowledgedUserGivenName,
                props.observation.acknowledgedUserSurname,
            ),
        );
    if (props.observation.cancelledUserId)
        return UserHelper.userChipType(
            props.observation.cancelledUserId,
            UserHelper.userFullName(props.observation.cancelledUserGivenName, props.observation.cancelledUserSurname),
        );
    if (props.observation.lastModifiedUserId)
        return UserHelper.userChipType(
            props.observation.lastModifiedUserId,
            UserHelper.userFullName(
                props.observation.lastModifiedUserGivenName,
                props.observation.lastModifiedUserSurname,
            ),
        );
    return UserHelper.userChipType(
        props.observation.recordedUserId,
        UserHelper.userFullName(props.observation.recordedUserGivenName, props.observation.recordedUserSurname),
    );
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.list-item-container {
    width: 225px;
    height: 260px;
    border-radius: 3px;
    border: 1px solid $swatchG7;
    background-color: $swatchWHT;
    margin-bottom: 10px;

    &.selected,
    &:hover {
        background-color: rgba($swatchB7, 0.5);
        border: 1px solid $swatchB6;
        cursor: pointer;
    }

    .start-circle {
        background-color: $swatchB3;
        border-radius: 50%; /* Makes the element a circle */
        font-size: 1.9em;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .future-dates {
        width: 24px;
        height: 21px !important; /* Ensure height is in pixels */
        display: flex; /* Use Flexbox to center the content */
        align-items: center; /* Vertically center */
        justify-content: center; /* Horizontally center */
    }

    .menu-hover:hover {
        background-color: $swatchB6;
        color: $swatchWHT;
        cursor: pointer;
    }

    .v-row {
        margin: 0;
    }

    .date-chip {
        height: 25px;
        width: 25px;
    }

    .task-type-code {
        height: 45px;
        align-content: center;
        font-size: large;
        font-weight: bold;
    }

    .task-type-title {
        width: 118px;
        padding: 0px;
        margin: 0px;
        align-content: center;
        text-align: center;
    }

    .task-date {
        height: 30px;
        align-content: center;
        padding-left: 5px;
    }

    .task-instructions {
        min-height: 30px;
        max-height: 4.5em;
        font-weight: bold;
    }

    .task-menu-button {
        padding: 0;
        width: 28px;
        height: 32px;
        min-width: 28px;
        min-height: 32px;
        max-width: 28px;
        max-height: 32px;
    }

    .user-circle {
        width: 28px; /* Width of the circle */
        height: 28px; /* Height of the circle */
        background-color: $swatchG4;
        border-radius: 50%; /* Makes the element a circle */
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Open Sans Condensed', sans-serif;
    }

    .status-chip-container {
        position: absolute;
        right: 3px;
        top: 50%; /* Center vertically */
        transform: translateY(-50%); /* Adjust to center */
    }
}
</style>
