import {ProductSubtypeCode} from '@/enums/product-subtype-code';
import {ProductTypeCode} from '@/enums/product-type-code';
import {GetPredecessorWorkTasksRequest} from '@/models/api/requests/work-tasks/GetPredecessorWorkTasksRequest';
import {GetMixturesResponse} from '@/models/api/responses/data-source/GetMixturesResponse';
import {GetProductsResponse} from '@/models/api/responses/data-source/GetProductsResponse';
import {GetPredecessorWorkTasksResponse} from '@/models/api/responses/work-tasks/GetPredecessorWorkTasksResponse';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {WorkTaskMixtureForm} from '@/models/work-tasks/WorkTaskMixtureForm';
import ApiService from '@/services/api-service';

class ManageWorkTaskHelper {
    /**
     * Get mixture product details.
     */
    public static getMixtureProductDetails(mixture: WorkTaskMixtureForm, mixtures: GetMixturesResponse[]) {
        if (!mixture.mixtureId) return;

        // Store the products associated with this mixture against the form model
        mixture.mixtureProducts = this.getMixtureDetails(mixture.mixtureId, mixtures)?.mixtureProducts || [];

        // Get mixture product subtypes and how many products they have in this mixture.
        this.getMixtureProductSubtypes(mixture);
    }

    /**
     * Get the unique list of product subtypes and the number of products that they have within this mixture.
     */
    private static getMixtureProductSubtypes(mixture: WorkTaskMixtureForm) {
        mixture.mixtureProductSubtypes = mixture.mixtureProducts.reduce((acc, product) => {
            const key = `${product.productTypeCode}-${product.productSubtypeCode}`;

            if (!acc[key]) {
                acc[key] = {
                    productTypeCode: product.productTypeCode,
                    productSubtypeCode: product.productSubtypeCode,
                    count: 0,
                };
            }

            acc[key].count += 1;

            return acc;
        }, {} as Record<string, {productTypeCode: ProductTypeCode; productSubtypeCode: ProductSubtypeCode; count: number}>);
    }

    /**
     * Get details of the specified mixture from the full list of data source mixtures.
     */
    public static getMixtureDetails(mixtureId: number, mixtures: GetMixturesResponse[]): GetMixturesResponse | null {
        const mixture = mixtures.filter((m) => m.mixtureId == mixtureId);
        if (mixture.length == 1) {
            return mixture[0];
        }
        return null;
    }

    /**
     * Get details of the specified product from the full list of data source products.
     */
    public static getProductDetails(
        productId: number | null,
        products: GetProductsResponse[],
    ): GetProductsResponse | null {
        const product = products.filter((p) => p.productId == productId);
        if (product.length == 1) {
            return product[0];
        }
        return null;
    }

    /**
     * Get all work tasks that could be a predecessor for this task when the due date is dependent upon
     * another tasking being completed.
     */
    public static async getPredecessorWorkTask(
        workTask: WorkTaskSearchResultDto,
        apiService: ApiService,
    ): Promise<GetPredecessorWorkTasksResponse | null> {
        // Call API to get predecessor tasks
        const request: GetPredecessorWorkTasksRequest = {
            dependentWorkTaskId: null,
            farmFieldId: null,
            workTaskId: workTask.dueAfterWorkTaskId,
        };
        const response = (await apiService.get(
            'work-tasks/predecessor-work-tasks',
            request,
        )) as GetPredecessorWorkTasksResponse[];

        if (response.length == 1) {
            return response[0];
        }

        return null;
    }
}

export {ManageWorkTaskHelper};
export default ManageWorkTaskHelper;
