<template>
    <v-chip
        :closable="closable"
        @click.prevent.stop="showLink && userHasAccess && handleResultClick(selectedUser.userId)"
        @click:close="showLink && emitClose"
        class="pl-0"
        :class="closable ? '' : 'pr-0'"
        :style="{height: iconSize + 'px'}">
        <v-avatar left class="custom-avatar" :size="iconSize">
            <template v-if="selectedUser.profilePicture">
                <v-img :src="selectedUser.profilePicture"></v-img>
            </template>
            <template v-else>
                <span class="initials">{{ getInitials(selectedUser.username) }}</span>
            </template>
        </v-avatar>
        <slot name="username" v-if="!selectedUser.username && !iconOnly"></slot>
        <span class="pr-2 pl-2" v-else-if="!iconOnly">{{ selectedUser.username }}</span>
    </v-chip>
</template>
<script lang="ts">
import {defineComponent, PropType, inject, onMounted, ref, computed} from 'vue';
import {UserChipType} from '../models/users/UserChipType';
import {useManageUserModalStore} from '@/stores/modals/manage-user-modal-store';
import {ManageUserModalMode} from '@/enums/modal-modes/manage-user-modal-mode';
import ApiService from '@/services/api-service.js';
import {GetUserPhoto} from '@/models/api/responses/users/GetUserPhoto';
import {useUserStore} from '@/stores/user-store';
import {RoleCode} from '@/enums/role-code';

export default defineComponent({
    name: 'UserChip',
    props: {
        user: {
            type: Object as PropType<UserChipType>,
        },
        closable: {
            type: Boolean,
            default: false,
        },
        iconSize: {
            type: String,
            default: '26',
        },
        iconOnly: {
            type: Boolean,
            default: false,
        },
        showLink: {
            type: Boolean,
            default: true,
        },
        getUserPhoto: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['close-chip'],
    setup(props, {emit}) {
        const apiService = inject('apiService') as ApiService;
        function getInitials(name: string) {
            const words = name.split(' ').filter((word) => word);
            if (words.length === 0) return '';
            else if (words.length === 1) {
                return words[0][0];
            }
            return words[0][0] + words[words.length - 1][0];
        }

        const userStore = useUserStore();
        const userHasAccess = computed(
            () =>
                userStore.hasRoleLevel(RoleCode.System, 1) ||
                (userStore.user?.userId && userStore.user?.userId === props.user?.userId),
        );

        const selectedUser = ref<UserChipType>(props.user as UserChipType);
        const manageUserModalStore = useManageUserModalStore();

        /**
         * Handle click event on a result.
         */
        const handleResultClick = (userId: number) => {
            manageUserModalStore.open(ManageUserModalMode.View, userId);
        };

        /**
         * Emit to handle closing of chip.
         */
        const emitClose = () => {
            emit('close-chip', props.user?.userId);
        };

        onMounted(async () => {
            // Get user photo
            if (
                selectedUser.value &&
                selectedUser.value.userId &&
                (!selectedUser.value.profilePicture || selectedUser.value.profilePicture === '')
            ) {
                const userInfo = (await apiService.post('search/user-id', selectedUser.value.userId)) as GetUserPhoto;
                if (userInfo) {
                    selectedUser.value.profilePicture = userInfo.userPhotoUrl ?? selectedUser.value.profilePicture;

                    if (selectedUser.value.username === '') selectedUser.value.username = userInfo.userDisplayName;
                }
            }
        });

        return {
            getInitials,
            selectedUser,
            handleResultClick,
            emitClose,
            userHasAccess,
        };
    },
});
</script>

<style lang="scss" scoped>
@import '../assets/scss/swatches.scss';

.custom-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $swatchA1;
    color: $swatchWHT;
    border: 1px solid $swatchA1;
}
</style>
