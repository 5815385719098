import {WorkTaskTypeCode} from '@/enums/work-task-type-code';

export class SearchFarmFieldsRequest {
    isQuickSearch: boolean = false;
    quickSearchText: string | null = null;
    farmFieldId: number | null = null;
    farmFieldIdList: number[] | null = null;
    farmFieldName: string | null = null;
    farmSiteId: number | null = null;
    farmSiteIdList: number[] | null = null;
    cropTypeIdList: number[] | null = null;
    cropId: number | null = null;
    cropIdList: number[] | null = null;
    cropVarietyName: string | null = null;
    cropVarietyIdList: number[] | null = null;
    cropYear: number | null = null;
    productName: string | null = null;
    workTaskTypeCodeList: WorkTaskTypeCode[] | null = null;
    workTaskStatus: string | null = null;
    nextWorkTaskTypeCode: WorkTaskTypeCode | null = null;
    lastWorkTaskTypeCode: WorkTaskTypeCode | null = null;
    currentSeasonId: number | null = null;
    includeCropVarieties = false;
    includeFarmBlocks = false;
    includeOpenObservations = false;
    includeCurrentSeasonWorkTasksOnly = false;
    includeFarmSeasons = false;
    sortOrder: string | null = null;

    constructor(data?: Partial<SearchFarmFieldsRequest>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
