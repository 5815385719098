<template>
    <div
        v-if="svgContent"
        v-html="svgContent"
        style="display: flex; align-items: center; justify-content: center"
        :style="{width: '100%', height: '100%'}" />
</template>

<script setup lang="ts">
import {ref, onMounted, watch} from 'vue';

interface Props {
    url: string;
    color: string;
}

const props = defineProps<Props>();

const svgContent = ref<string>('');

// Function to check if the path is a URL
const isUrl = (path: string): boolean => {
    try {
        new URL(path);
        return true;
    } catch {
        return false;
    }
};

// Function to resolve the path using `require` for CommonJS
const resolvePath = (path: string): string => {
    if (isUrl(path)) {
        return path;
    } else {
        return require(`@/assets/images/${path}`);
    }
};

// Function to fetch the SVG and apply the color
const fetchAndColorSvg = async () => {
    if (!props.url) return;

    const imageUrl = resolvePath(props.url);

    try {
        const response = await fetch(imageUrl);
        const svgText = await response.text();

        const parser = new DOMParser();
        const svgDocument = parser.parseFromString(svgText, 'image/svg+xml');
        const svgElement = svgDocument.querySelector('svg');

        if (svgElement) {
            // Apply the color to both fill and stroke
            svgElement.querySelectorAll('path').forEach((path) => {
                path.setAttribute('fill', props.color);
                path.setAttribute('stroke', props.color);
            });
            // Max height of 85% seems to work well for the Navigator Panel. Setting it to 100% causes display issues for some
            // reason (it makes its container div grow) We may need to revisit and implement a better solution if the 85% causes issues.
            svgElement.setAttribute('style', 'max-width: 100%; max-height: 85%;');
            svgContent.value = svgElement.outerHTML;
        }
    } catch (error) {
        console.error('Error fetching or processing the SVG:', error);
    }
};

onMounted(fetchAndColorSvg);

watch(() => [props.url, props.color], fetchAndColorSvg, {immediate: true});
</script>
