import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5af51c09"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chip-text" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_CircleIcon = _resolveComponent("CircleIcon")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_chip = _resolveComponent("v-chip")!

  return (_openBlock(), _createBlock(_component_v_chip, {
    variant: "flat",
    size: _ctx.size,
    density: "comfortable",
    color: _ctx.lighterColor,
    pill: "",
    class: "pr-5 noSelection"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_avatar, { start: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_CircleIcon, {
            style: {height: '40px', width: '40px'},
            class: _normalizeClass(_ctx.iconClass)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FontAwesomeIcon, {
                icon: ['fal', 'stopwatch'],
                size: _ctx.iconSize
              }, null, 8, ["size"])
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.numberOfDaysDueAfterPreviousTask != null)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.WorkTaskPlanHelper.getDueAfterText(_ctx.numberOfDaysDueAfterPreviousTask)), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["size", "color"]))
}