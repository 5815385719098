<template>
    <v-tooltip bottom>
        <template v-slot:activator="{props}">
            <div :class="chipClass">
                <CircleIcon v-bind="props" :style="iconStyle" :class="primaryIcon">
                    <FontAwesomeIcon
                        :icon="[iconFamily, iconCode]"
                        class="rounded-text"
                        v-if="productSubtypeCode !== 'Fertilizer'" />
                    <FontAwesomeLayers class="rounded-text fa-lg" v-else>
                        <FontAwesomeIcon :icon="['fas', 'box']" transform="left-3" />
                        <FontAwesomeIcon
                            :icon="['fas', 'seedling']"
                            class="swatchA4"
                            transform="shrink-9 left-4 down-2" />
                    </FontAwesomeLayers>
                </CircleIcon>

                <span
                    class="pr-2"
                    :class="showProductCount ? 'pl-2 pr-4' : ''"
                    style="padding-left: 5px"
                    v-if="displayText && displayText != ''"
                    >{{ displayText }}</span
                >
            </div>
        </template>
        <slot name="helpText"></slot>
        {{ name }}
    </v-tooltip>
</template>

<script lang="ts">
import {PropType, defineComponent, computed} from 'vue';
import {getProductSubtypeByCode, ProductSubtype} from '@/services/product-subtypes-service';
import {ProductTypeCode} from '@/enums/product-type-code';
import {ProductSubtypeCode} from '@/enums/product-subtype-code';

export default defineComponent({
    name: 'ProductSubtypeIcon',
    props: {
        productSubtypeCode: String as PropType<ProductSubtypeCode>,
        productTypeCode: String as PropType<ProductTypeCode>,
        productCount: {
            type: Number,
            default: null,
        },
        class: {
            type: String,
            default: '',
        },
        iconHeight: {
            type: String,
            default: '40',
        },
        iconWidth: {
            type: String,
            default: '40',
        },
        iconSize: {
            type: String,
            default: '18',
        },
    },
    setup(props) {
        const ProductSubtype = computed<ProductSubtype | undefined>(() => {
            return getProductSubtypeByCode(props.productSubtypeCode as ProductSubtypeCode);
        });

        const iconFamily = computed<string>(() => {
            return ProductSubtype.value?.iconFamily || 'fas';
        });

        const iconCode = computed<string>(() => {
            return ProductSubtype.value?.iconCode || 'question';
        });

        const color = computed<string>(() => {
            return ProductSubtype.value?.color || '#000000';
        });

        const iconStyle = computed(() => ({
            height: props.iconHeight + 'px',
            width: props.iconWidth + 'px',
            fontSize: props.iconSize + 'pt',
        }));

        const chipClass = computed(() => {
            if (props.productTypeCode === ProductTypeCode.Chemical)
                return ['rounded-text', 'chemical-background', props.class];
            else if (props.productTypeCode === ProductTypeCode.Fertilizer)
                return ['rounded-text', 'fertilizer-background', props.class];
            else return ['rounded-text', 'other-background', props.class];
        });

        const name = computed<string | null>(() => {
            if (ProductSubtype.value) {
                const productTypeName = ProductSubtype.value.getProductTypeName();
                const productSubtypeName = ProductSubtype.value.getProductSubtypeName();

                if (productTypeName && productTypeName != productSubtypeName) {
                    // Include product type name if there is one, and if it's different to the sub-type name
                    return `${productSubtypeName} (${productTypeName})`;
                } else {
                    // Just show the sub-type name
                    return productSubtypeName;
                }
            }
            return null;
        });

        const primaryIcon = computed<string[]>(() =>
            ProductSubtype.value && ProductSubtype.value.color ? [ProductSubtype.value.color + 'BG'] : [],
        );

        const displayText = computed<string>(() => {
            if (showProductCount.value) {
                // Display product count
                return props.productCount.toString();
            } else {
                // Display sub-type name
                const productSubtypeName = ProductSubtype.value?.getProductSubtypeName();
                return productSubtypeName && productSubtypeName != '' ? productSubtypeName : '';
            }
        });

        const iconClass = computed<string>(() => {
            return `product-icons bg-${color.value}`;
        });

        const showProductCount = computed<boolean>(() => {
            return props.productCount != null;
        });

        return {
            iconFamily,
            iconCode,
            name,
            iconClass,
            displayText,
            primaryIcon,
            ProductSubtype,
            iconStyle,
            chipClass,
            ProductSubtypeCode,
            showProductCount,
        };
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';
.rounded-text {
    display: inline-flex;
    align-items: center;
    border-radius: 40px;
    font-family: 'Open Sans Condensed', sans-serif;
}
.chemical-background {
    color: $swatchWHT;
    background-color: rgba($swatchB1, 0.8);
}
.fertilizer-background {
    color: $swatchWHT;
    background-color: rgba($swatchA4, 0.8);
}
.other-background {
    color: $swatchWHT;
    background-color: rgba($swatchG4, 0.8);
}
</style>
