<template>
    <v-dialog v-model="viewFarmSiteModalStore.isVisible" class="viewFarmSiteModal">
        <v-card style="overflow-y: hidden">
            <v-toolbar color="swatchA2">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'farm']" size="xl" class="mr-2" />
                    {{ $t('ViewFarmSite') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>
            <LoadingSymbol v-if="isLoading || localFarmSiteId == null" />
            <v-form v-else ref="form" class="pa-4" style="overflow-y: scroll">
                <div class="profile-detail pb-5" v-if="farmSite">
                    <div class="left-content">
                        <div class="title">
                            <span v-if="selectedCropCode">
                                <CropIcon
                                    :cropCode="selectedCropCode"
                                    :primary="true"
                                    icon-height="40"
                                    icon-width="40"
                                    icon-size="23"
                                    class="swatchA5" />
                            </span>
                            <span class="space-between-div paddingLeft">
                                <div>
                                    <span class="bold swatchA2 slightlyLarger"
                                        >{{ farmSite.farmSiteAbbreviation }} -
                                    </span>
                                    <span class="bold swatchA1 slightlyLarger" v-if="farmSite.farmSiteName">
                                        {{ farmSite.farmSiteName }}
                                    </span>
                                    <IdIcon v-if="farmSite.farmSiteId">{{ farmSite.farmSiteId }}</IdIcon>
                                </div>
                                <div v-if="farmSite.farmSiteDescription">
                                    <span><TranslatableTexts :text="farmSite.farmSiteDescription" /></span>
                                </div>
                            </span>
                        </div>
                        <div class="pt-3">
                            {{ $t('NumberOfFields') }}
                            <span class="swatchA1 pl-3"> {{ farmSite.associatedFarmFieldNumber }} </span>
                        </div>
                    </div>
                    <div class="map-container">
                        <div class="home-dashboard-map">
                            <l-map
                                :zoom="13"
                                :center="mapCenter"
                                style="height: 100%; width: 100%"
                                :scroll-wheel-zoom="false"
                                :double-click-zoom="false"
                                :touch-zoom="false"
                                :dragging="false"
                                :zoom-control="false"
                                :box-zoom="false"
                                :keyboard="false"
                                @ready="onMapReady"
                                :options="mapOptions">
                                <!-- Tile Layer -->
                                <l-tile-layer :url="url"></l-tile-layer>

                                <l-marker :lat-lng="markerCenter" :icon="fontAwesomeIcon"> </l-marker>
                            </l-map>
                        </div>
                    </div>
                </div>

                <v-card class="marginTop" v-if="farmSite && farmSite.userNameList && farmSite.userNameList.length > 0">
                    <v-card-text class="farmSiteViewConfirmation pa-0">
                        <v-row class="confirm-header pa-3 ma-0">
                            {{ $t('FarmSiteUserMatrix') }}
                        </v-row>

                        <div class="pt-5 pl-3" style="font-size: medium">
                            <v-row no-gutters class="pt-2">
                                <v-col cols="2">
                                    {{ $t('PlantationManager') }}
                                </v-col>
                                <v-col cols="10">
                                    <v-row no-gutters
                                        ><v-col
                                            cols="2"
                                            class="mt-1"
                                            v-for="user in userMatrix.get(UserFarmSitePositionCode.PlantationManager)"
                                            :key="user.userId">
                                            <UserChip
                                                :user="
                                                    UserHelper.userChipType(
                                                        user.userId,
                                                        UserHelper.userFullName(user.userGivenName, user.userSurname),
                                                    )
                                                " /> </v-col
                                    ></v-row>
                                </v-col>
                            </v-row>

                            <v-row no-gutters class="pt-2">
                                <v-col cols="2">
                                    {{ $t('FarmSiteManager') }}
                                </v-col>
                                <v-col cols="10">
                                    <v-row no-gutters
                                        ><v-col
                                            cols="2"
                                            class="mt-1"
                                            v-for="user in userMatrix.get(UserFarmSitePositionCode.FarmSiteManager)"
                                            :key="user.userId">
                                            <UserChip
                                                :user="
                                                    UserHelper.userChipType(
                                                        user.userId,
                                                        UserHelper.userFullName(user.userGivenName, user.userSurname),
                                                    )
                                                " /> </v-col
                                    ></v-row>
                                </v-col>
                            </v-row>

                            <v-row no-gutters class="pt-2">
                                <v-col cols="2">
                                    {{ $t('FarmSiteWorkers') }}
                                </v-col>
                                <v-col cols="10">
                                    <v-row no-gutters
                                        ><v-col
                                            cols="2"
                                            class="mt-1"
                                            v-for="user in userMatrix.get(UserFarmSitePositionCode.FarmSiteWorker)"
                                            :key="user.userId">
                                            <UserChip
                                                :user="
                                                    UserHelper.userChipType(
                                                        user.userId,
                                                        UserHelper.userFullName(user.userGivenName, user.userSurname),
                                                    )
                                                " /> </v-col
                                    ></v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG" v-if="!isLoading && localFarmSiteId != null">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import '@/assets/scss/farm/view-farm-site-modal.scss';
import 'leaflet/dist/leaflet.css';
import '@/assets/scss/home-dashboard/home-dashboard-map.scss';

import {useViewFarmSiteModalStore} from '@/stores/modals/view-farm-site-modal-store';
import {computed, watch, inject, ref, onUnmounted} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import CropIcon from '@/components/CropIcon.vue';
import {useCrops} from '@/composables/data-source/crops';
const {getCrops, crops} = useCrops();
import {SearchFarmSitesResponse} from '@/models/api/responses/search/SearchFarmSitesResponse';
import {ManageFarmSiteForm} from '@/models/farm/ManageFarmSiteForm';
import {CropCode} from '@/enums/crop-code';
import UserChip from '@/components/UserChip.vue';
import type {Icon, PointExpression, LatLngExpression, Map as LeafletMap} from 'leaflet';
import {LMap, LTileLayer, LMarker} from '@vue-leaflet/vue-leaflet';
import L from 'leaflet';
import {useConfigStore} from '@/stores/config-store';
const configStore = useConfigStore();

import {faFarm} from '@awesome.me/kit-b64aff0d40/icons/classic/light';
import {icon} from '@fortawesome/fontawesome-svg-core';
const farmIconHTML = icon(faFarm).html[0].replace('<svg', '<svg class="custom-icon"');
import UserHelper from '@/helpers/UserHelper';
import {UserFarmSitePositionCode} from '@/enums/user-farm-site-position-code';
import {UserNameDto} from '@/models/data-transfer-objects/users/UserNameDto';
// Form
let isLoading = ref<boolean>(false);

const viewFarmSiteModalStore = useViewFarmSiteModalStore();
const isConfirmed = ref<boolean>(false);
const localFarmSiteId = ref<number | null>(null);
const farmSite = ref<ManageFarmSiteForm | null>(null);

const userMatrix = computed(() => {
    let resultMap = new Map();
    for (const positionCode of Object.values(UserFarmSitePositionCode)) {
        resultMap.set(positionCode, []);
    }
    if (!farmSite.value || !farmSite.value.userNameList || farmSite.value.userNameList.length === 0) return resultMap;
    return farmSite.value.userNameList.reduce((acc, cur: UserNameDto) => {
        if (cur.userFarmSitePosition) acc.get(cur.userFarmSitePosition).push(cur);
        return acc;
    }, resultMap);
});

// Services
const apiService = inject('apiService') as ApiService;

//map
const mapOptions = ref({
    attributionControl: false,
});

const url = process.env.VUE_APP_AERIAL_IMAGERY_TILE_LAYER_URL!;

const mapCenter = ref<PointExpression>([configStore.defaultMapLatitude, configStore.defaultMapLongitude]);
const markerCenter = ref<LatLngExpression>([configStore.defaultMapLatitude, configStore.defaultMapLongitude]);
// Function to create observation icon
const fontAwesomeIcon = L.divIcon({
    html: `
            <div class="svgGlyphLossOfComms svgLight swatchWHT" style="height: 80%; width: 80%; z-index: 4; " title="1234">
                 ${farmIconHTML}
            </div>
            <div class="svgMapMarker swatchGRNBG"  title="1234"></div>
            <div class="svgMapMarkerTip swatchGRNBG"  title="1234"></div>`,
    className: 'svgMapMarkerContainer',
    iconSize: [40, 40],
    iconAnchor: [20, 40.3],
}) as Icon;

const onMapReady = (map: LeafletMap) => {
    map.dragging.disable(); // Disable dragging
    map.touchZoom.disable(); // Disable touch zoom
    map.doubleClickZoom.disable(); // Disable double click zoom
    map.scrollWheelZoom.disable(); // Disable scroll wheel zoom
    map.boxZoom.disable(); // Disable box zoom
    map.keyboard.disable(); // Disable keyboard interactions
    map.zoomControl.remove(); // Remove the zoom control buttons

    // Disable all other interactions
    map.options.minZoom = map.getZoom();
    map.options.maxZoom = map.getZoom();
};

const selectedCropCode = computed(() => {
    if (!crops.value || crops.value.length === 0) return undefined;
    if (!farmSite.value || !farmSite.value.defaultPrimaryCropId) return undefined;

    const selectedCrop = crops.value.filter((crop) => crop.cropId === farmSite.value?.defaultPrimaryCropId);
    if (selectedCrop.length > 0) {
        const cropCode = selectedCrop[0].cropCode;
        if (Object.values(CropCode).includes(cropCode as CropCode)) {
            return cropCode as CropCode;
        }
    }

    return undefined;
});

/**
 * Load data for the modal.
 */
const loadData = async (farmSiteId: number) => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // Set data
    localFarmSiteId.value = farmSiteId;
    await getCrops();

    // Get farm site details
    const searchResults = (await apiService.post('search/farm-sites-by-id', {
        farmSiteId: farmSiteId,
    })) as SearchFarmSitesResponse;

    if (searchResults.farmSites.length === 1) {
        farmSite.value = Object.assign(new ManageFarmSiteForm(), searchResults.farmSites[0]);
        mapCenter.value = farmSite.value.farmSiteCentroid
            ? [farmSite.value.farmSiteCentroid.coordinates[1], farmSite.value.farmSiteCentroid.coordinates[0]]
            : mapCenter.value;
        markerCenter.value = farmSite.value.farmSiteCentroid
            ? [farmSite.value.farmSiteCentroid.coordinates[1], farmSite.value.farmSiteCentroid.coordinates[0]]
            : markerCenter.value;
    }

    isLoading.value = false;
};

/**
 * Close the modal.
 */
const close = () => {
    viewFarmSiteModalStore.close();
};

onUnmounted(() => {
    viewFarmSiteModalStore.close();
});

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    farmSite.value = null;
    isConfirmed.value = false;
};

// Watch for changes to viewUserModalStore.userId
watch(
    () => viewFarmSiteModalStore.isVisible,
    async (val) => {
        if (val && viewFarmSiteModalStore.farmSiteId !== null) {
            await loadData(viewFarmSiteModalStore.farmSiteId);
        }
    },
);

import {useDeleteFarmFieldModalStore} from '@/stores/modals/delete-farm-field-modal-store';
const deleteFarmFieldModalStore = useDeleteFarmFieldModalStore();

import {useDeleteUserModalStore} from '@/stores/modals/delete-user-modal-store';
const deleteUserModalStore = useDeleteUserModalStore();

watch(
    [() => deleteFarmFieldModalStore.deletedCounter, () => deleteUserModalStore.deletedCounter],
    async () => {
        if (viewFarmSiteModalStore.isVisible && viewFarmSiteModalStore.farmSiteId !== null) {
            await loadData(viewFarmSiteModalStore.farmSiteId);
        }
    },
    {deep: true},
);
</script>
