import {GetProductTypesResponse} from '@/models/api/responses/data-source/GetProductTypesResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useProductTypes() {
    const apiService = inject('apiService') as ApiService;
    const productTypes = ref<GetProductTypesResponse[]>([]);
    const productTypesWithNull = ref<SelectListItem[]>([]);

    /**
     * Get list of product types.
     */
    const getProductTypes = async () => {
        productTypes.value = await apiService.get('data-source/product-types');

        // Add null value
        productTypesWithNull.value = productTypes.value.map((productType) => ({
            value: productType.productTypeId,
            title: productType.productTypeName,
        }));

        productTypesWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getProductTypes,
        productTypes,
        productTypesWithNull,
    };
}
