<template>
    <v-dialog v-model="advancedSearchModalStore.isVisible" height="100%">
        <v-card height="100%">
            <v-toolbar color="swatchG2">
                <v-tabs v-model="tab">
                    <v-tab value="SearchParameters">
                        <FontAwesomeIcon :icon="['fal', 'search']" size="xl" class="mr-2" />
                        {{ $t('SearchParameters') }}
                    </v-tab>
                    <v-tab value="SearchResults" v-show="hasSearchBeenRun">
                        <FontAwesomeIcon :icon="['fal', 'th']" size="xl" class="mr-2" />
                        {{ resultsTabTitle }}
                    </v-tab>
                </v-tabs>

                <v-spacer></v-spacer>
                <v-btn
                    v-if="searchMode == HeaderSearchMode.User"
                    @click="manageUserModalStore.open(ManageUserModalMode.Create)"
                    variant="outlined"
                    class="mr-4">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'user-plus']" size="xl" />
                    </template>
                    {{ $t('CreateUser') }}
                </v-btn>
                <v-btn
                    v-if="searchMode == HeaderSearchMode.FarmField"
                    @click="manageFarmFieldModalStore.open()"
                    variant="outlined"
                    class="mr-4">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'plus']" size="xl" />
                    </template>
                    {{ $t('Add') }}
                </v-btn>

                <v-btn
                    v-if="
                        searchMode &&
                        [HeaderSearchMode.Crop, HeaderSearchMode.ProductMixture, HeaderSearchMode.Product].includes(
                            searchMode,
                        )
                    "
                    :disabled="true"
                    variant="outlined"
                    class="mr-4">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'plus']" size="xl" />
                    </template>
                    {{ $t('Add') }}
                </v-btn>

                <v-btn
                    v-if="searchMode == HeaderSearchMode.WorkTask"
                    @click="manageWorkTaskModalStore.open(ManageWorkTaskModalMode.Create)"
                    variant="outlined"
                    class="mr-4">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'plus']" size="xl" />
                    </template>
                    {{ $t('Add') }}
                </v-btn>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <v-form ref="form" class="pa-4" style="overflow-y: scroll; height: 100%" @keyup.enter="search">
                <LoadingSymbol v-if="isLoading" />

                <v-tabs-window v-show="!isLoading" v-model="tab" style="overflow: visible">
                    <v-tabs-window-item value="SearchParameters" :transition="false">
                        <table style="width: 100%" class="tblForm">
                            <tr>
                                <td class="tableCell padding" style="width: 10%; min-width: 10rem">
                                    <div
                                        class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                        <span>{{ $t('SearchFor') }}</span>
                                        <HelpIcon :help-text="$t('AdvancedSearch_SearchFor_HelpText')" />
                                    </div>
                                </td>
                                <td style="width: 90%">
                                    <v-select
                                        v-model="searchMode"
                                        hide-details
                                        :items="searchModes"
                                        item-title="title"
                                        item-value="value"
                                        style="width: 300px"></v-select>
                                </td>
                            </tr>
                        </table>

                        <farm-field-search-parameters
                            v-if="searchMode == HeaderSearchMode.FarmField"
                            v-model:searchParameters="searchParametersFarmField" />

                        <mixture-search-parameters
                            v-if="searchMode == HeaderSearchMode.ProductMixture"
                            v-model:searchParameters="searchParametersMixture" />

                        <product-search-parameters
                            v-if="searchMode == HeaderSearchMode.Product"
                            v-model:searchParameters="searchParametersProduct" />

                        <observation-search-parameters
                            ref="refSearchParametersObservation"
                            v-if="searchMode == HeaderSearchMode.Observation"
                            :display-export-options="true"
                            v-model:searchParameters="searchParametersObservation" />

                        <crop-variety-search-parameters
                            ref="refSearchParametersObservation"
                            v-if="searchMode == HeaderSearchMode.Crop"
                            v-model:searchParameters="searchParametersCrop" />

                        <user-search-parameters
                            v-else-if="searchMode == HeaderSearchMode.User"
                            v-model:searchParameters="searchParametersUser" />

                        <work-task-search-parameters
                            ref="refSearchParametersWorkTask"
                            v-if="searchMode == HeaderSearchMode.WorkTask"
                            :display-export-options="true"
                            v-model:searchParameters="searchParametersWorkTask" />
                    </v-tabs-window-item>

                    <v-tabs-window-item value="SearchResults" :transition="false">
                        <farm-field-search-results
                            v-if="searchMode == HeaderSearchMode.FarmField"
                            @switch-to-search="selectSearchParametersTab"
                            @view-fields-on-home="viewFieldListOnHome"
                            @export-field-list="exportFieldList"
                            :is-filter-applied="isFilterApplied"
                            :show-farmSite="true"
                            :results-list="searchResultsFarmField" />
                        <product-search-results
                            v-if="searchMode == HeaderSearchMode.Product"
                            @switch-to-search="selectSearchParametersTab"
                            :is-filter-applied="isFilterApplied"
                            :results-list="searchResultsProduct" />
                        <observation-search-results
                            v-if="searchMode == HeaderSearchMode.Observation"
                            @switch-to-search="selectSearchParametersTab"
                            @export-observation-list="exportObservationList"
                            @view-observation-list-on-home="viewObservationListOnHome"
                            :is-filter-applied="isFilterApplied"
                            :results-list="searchResultsObservation"
                            :open-observation-default="defaultObservationTab" />
                        <user-search-results
                            v-if="searchMode == HeaderSearchMode.User"
                            :results-list="searchResultsUser" />
                        <work-task-search-results
                            v-if="searchMode == HeaderSearchMode.WorkTask"
                            @switch-to-search="selectSearchParametersTab"
                            @export-task-list="exportTaskList"
                            :open-task-default="defaultTaskTab"
                            :is-filter-applied="isFilterApplied"
                            :results-list="searchResultsWorkTask" />
                        <crop-variety-search-results
                            v-if="searchMode == HeaderSearchMode.Crop"
                            @switch-to-search="selectSearchParametersTab"
                            :is-filter-applied="isFilterApplied"
                            :results-list="searchResultsCrop" />
                        <mixture-search-results
                            v-if="searchMode == HeaderSearchMode.ProductMixture"
                            @switch-to-search="selectSearchParametersTab"
                            :is-filter-applied="isFilterApplied"
                            :results-list="searchResultsMixture" />
                    </v-tabs-window-item>
                </v-tabs-window>
            </v-form>

            <v-card-actions class="swatchG9BG">
                <v-btn v-if="isSearchParametersTabSelected" @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <v-btn v-else @click="selectSearchParametersTab">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Back') }}
                </v-btn>
                <v-btn @click="resetSearchParameters" v-if="isSearchParametersTabSelected && !isLoading">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'retweet']" size="xl" />
                    </template>
                    {{ $t('ResetParameters') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="search" :disabled="isLoading">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'search']" size="xl" />
                    </template>
                    {{ $t('Search') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import {useHeaderSearchModes} from '@/composables/header-search-modes';
import {computed, watch, ref, inject, onMounted} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import {SearchFarmFieldsRequest} from '@/models/api/requests/search/SearchFarmFieldsRequest';
import {SearchMixtureRequest} from '@/models/api/requests/search/SearchMixtureRequest';
import {SearchObservationsRequest} from '@/models/api/requests/search/SearchObservationsRequest';
import {SearchUsersRequest} from '@/models/api/requests/search/SearchUsersRequest';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {ProductDto} from '@/models/data-transfer-objects/search/product-search/ProductSearchResultDto';
import {ObservationSearchResultDto} from '@/models/data-transfer-objects/search/observation-search/ObservationSearchResultDto';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {CropVarietyDto} from '@/models/data-transfer-objects/search/crop-variety-search/CropVarietySearchDto';
import {SearchUsersResponse} from '@/models/api/responses/search/SearchUsersResponse';
import FarmFieldSearchParameters from '@/views/search/FarmFieldSearchParameters.vue';
import ProductSearchParameters from '@/views/search/ProductSearchParameters.vue';
import MixtureSearchParameters from '@/views/search/MixtureSearchParameters.vue';
import ObservationSearchParameters from '@/views/search/ObservationSearchParameters.vue';
import CropVarietySearchParameters from '@/views/search/CropVarietySearchParameters.vue';
import UserSearchParameters from '@/views/search/UserSearchParameters.vue';
import WorkTaskSearchParameters from '@/views/search/WorkTaskSearchParameters.vue';
import FarmFieldSearchResults from '@/views/search/FarmFieldSearchResults.vue';
import ProductSearchResults from '@/views/search/ProductSearchResults.vue';
import ObservationSearchResults from '@/views/search/ObservationSearchResults.vue';
import UserSearchResults from '@/views/search/UserSearchResults.vue';
import CropVarietySearchResults from '@/views/search/CropVarietySearchResults.vue';
import MixtureSearchResults from '@/views/search/MixtureSearchResults.vue';
import WorkTaskSearchResults from '@/views/search/WorkTaskSearchResults.vue';
import {subDays} from 'date-fns';
import {HeaderSearchMode} from '@/enums/header-search-mode';
import {ManageUserModalMode} from '@/enums/modal-modes/manage-user-modal-mode';
import {SearchCropVarietiesRequest} from '@/models/api/requests/search/SearchCropVarietiesRequest';
import {SearchCropVarietiesResponse} from '@/models/api/responses/search/SearchCropVarietiesResponse';
import {SearchProductRequest} from '@/models/api/requests/search/SearchProductRequest';
import {useDeleteFarmFieldModalStore} from '@/stores/modals/delete-farm-field-modal-store';
const deleteFarmFieldModalStore = useDeleteFarmFieldModalStore();
import {useFarmStore} from '@/stores/farm-store';
const farmStore = useFarmStore();
import {useManageObservationModalStore} from '@/stores/modals/manage-observation-modal-store';
const manageObservationModalStore = useManageObservationModalStore();
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
const homeDashboardStore = useHomeDashboardStore();

// Services
const apiService = inject('apiService') as ApiService;

// Stores
import {useAdvancedSearchModalStore} from '@/stores/modals/advanced-search-modal-store';
import {useDeleteUserModalStore} from '@/stores/modals/delete-user-modal-store';
import {useManageUserModalStore} from '@/stores/modals/manage-user-modal-store';
import i18n from '@/i18n';
import {SearchFarmFieldsResponse} from '@/models/api/responses/search/SearchFarmFieldsResponse';
import {SearchProductsResponse} from '@/models/api/responses/search/SearchProductsResponse';
import {SearchMixturesResponse} from '@/models/api/responses/search/SearchMixturesResponse';
import {SearchObservationsResponse} from '@/models/api/responses/search/SearchObservationsResponse';
import {SearchWorkTasksResponse} from '@/models/api/responses/search/SearchWorkTasksResponse';
import {useUserStore} from '@/stores/user-store';
import {useManageFarmFieldModalStore} from '@/stores/modals/manage-farm-field-modal-store';
const manageFarmFieldModalStore = useManageFarmFieldModalStore();
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
import {ManageWorkTaskModalMode} from '@/enums/modal-modes/manage-work-task-modal-mode';
import {MixtureSearchResultDto} from '@/models/data-transfer-objects/search/mixture-search/MixtureSearchResultDto';
import {useViewObservationModalStore} from '@/stores/modals/view-observation-modal-store';
const manageWorkTaskModalStore = useManageWorkTaskModalStore();
const advancedSearchModalStore = useAdvancedSearchModalStore();
const deleteUserModalStore = useDeleteUserModalStore();
const manageUserModalStore = useManageUserModalStore();
const viewObservationModalStore = useViewObservationModalStore();
const userStore = useUserStore();
import {NavigatorPanelTab} from '@/enums/home-dashboard/navigator-panel-tab';
import {ObservationStatusCode} from '@/enums/observaton-status-code';

// Form
const isLoading = ref<boolean>(false);
const tab = ref<string>('SearchParameters');
const resultsTabTitle = ref('Results');
const hasSearchBeenRun = ref<boolean>(false);
const searchMode = ref<HeaderSearchMode | null>(null);
const searchParametersFarmField = ref(new SearchFarmFieldsRequest());
const searchParametersObservation = ref(new SearchObservationsRequest());
const searchParametersUser = ref(new SearchUsersRequest());
const searchParametersWorkTask = ref(new SearchWorkTasksRequest());
const searchParametersCrop = ref(new SearchCropVarietiesRequest());
const searchResultsCrop = ref<CropVarietyDto[]>([]);
const searchParametersProduct = ref(new SearchProductRequest());
const searchParametersMixture = ref(new SearchMixtureRequest());
const searchResultsFarmField = ref<FarmFieldSearchResultDto[]>([]);
const searchResultsProduct = ref<ProductDto[]>([]);
const searchResultsMixture = ref<MixtureSearchResultDto[]>([]);
const searchResultsObservation = ref<ObservationSearchResultDto[]>([]);
const searchResultsUser = ref<SearchUsersResponse[]>([]);
const searchResultsWorkTask = ref<WorkTaskSearchResultDto[]>([]);
const {searchModes, defaultSearchMode, setSearchModes} = useHeaderSearchModes();
const refSearchParametersObservation = ref();
const refSearchParametersWorkTask = ref();
const snackbar = ref({
    show: false,
    text: '',
});
import {format} from 'date-fns';
import router from '@/router';

const isFilterApplied = computed((): boolean => {
    if (searchMode.value == HeaderSearchMode.Crop) {
        return !!(
            (searchParametersCrop.value.isQuickSearch &&
                (searchParametersCrop.value.quickSearchText === null ||
                    searchParametersCrop.value.quickSearchText === '')) ||
            (!searchParametersCrop.value.isQuickSearch &&
                searchParametersCrop.value.cropId === null &&
                (searchParametersCrop.value.cropVarietyName === null ||
                    searchParametersCrop.value.cropVarietyName === '') &&
                (searchParametersCrop.value.cropTypeIdList === null ||
                    searchParametersCrop.value.cropTypeIdList.length === 0) &&
                (searchParametersCrop.value.farmFieldIdList === null ||
                    searchParametersCrop.value.farmFieldIdList.length === 0) &&
                (searchParametersCrop.value.farmSiteIdList === null ||
                    searchParametersCrop.value.farmSiteIdList.length === 0))
        );
    }

    if (searchMode.value == HeaderSearchMode.FarmField) {
        return !!(
            (searchParametersFarmField.value.isQuickSearch &&
                (searchParametersFarmField.value.quickSearchText === null ||
                    searchParametersFarmField.value.quickSearchText === '')) ||
            (!searchParametersFarmField.value.isQuickSearch &&
                searchParametersFarmField.value.cropId === null &&
                (searchParametersFarmField.value.cropVarietyName === null ||
                    searchParametersFarmField.value.cropVarietyName === '') &&
                (searchParametersFarmField.value.farmFieldName === null ||
                    searchParametersFarmField.value.farmFieldName === '') &&
                (searchParametersFarmField.value.cropTypeIdList === null ||
                    searchParametersFarmField.value.cropTypeIdList.length === 0) &&
                (searchParametersFarmField.value.workTaskTypeCodeList === null ||
                    searchParametersFarmField.value.workTaskTypeCodeList.length === 0) &&
                (searchParametersFarmField.value.workTaskStatus === null ||
                    searchParametersFarmField.value.workTaskStatus === '') &&
                (searchParametersFarmField.value.productName === null ||
                    searchParametersFarmField.value.productName === '') &&
                searchParametersFarmField.value.cropYear === null &&
                searchParametersFarmField.value.farmSiteIdList &&
                searchParametersFarmField.value.farmSiteIdList.length === 1 &&
                searchParametersFarmField.value.farmSiteIdList[0] === farmStore.selectedSiteId &&
                searchParametersFarmField.value.currentSeasonId === null)
        );
    }

    if (searchMode.value == HeaderSearchMode.ProductMixture) {
        return !!(
            (searchParametersMixture.value.isQuickSearch &&
                (searchParametersMixture.value.quickSearchText === null ||
                    searchParametersMixture.value.quickSearchText === '')) ||
            (!searchParametersMixture.value.isQuickSearch &&
                (searchParametersMixture.value.mixtureName === null ||
                    searchParametersMixture.value.mixtureName === '') &&
                (searchParametersMixture.value.productName === null ||
                    searchParametersMixture.value.productName === '') &&
                (searchParametersMixture.value.farmFieldIdList === null ||
                    searchParametersMixture.value.farmFieldIdList.length === 0) &&
                (searchParametersMixture.value.productTypeCodeList === null ||
                    searchParametersMixture.value.productTypeCodeList.length === 0) &&
                (searchParametersMixture.value.productSubtypeCodeList === null ||
                    searchParametersMixture.value.productSubtypeCodeList.length === 0) &&
                (searchParametersMixture.value.farmSiteIdList === null ||
                    searchParametersMixture.value.farmSiteIdList.length === 0) &&
                (searchParametersMixture.value.dateRangeFrom === null ||
                    searchParametersMixture.value.dateRangeFrom === '') &&
                (searchParametersMixture.value.dateRangeTo === null ||
                    searchParametersMixture.value.dateRangeTo === ''))
        );
    }

    if (searchMode.value == HeaderSearchMode.WorkTask) {
        return !!(
            (searchParametersWorkTask.value.isQuickSearch &&
                (searchParametersWorkTask.value.quickSearchText === null ||
                    searchParametersWorkTask.value.quickSearchText === '')) ||
            (!searchParametersWorkTask.value.isQuickSearch &&
                (searchParametersWorkTask.value.farmFieldIdList === null ||
                    searchParametersWorkTask.value.farmFieldIdList.length === 0) &&
                (searchParametersWorkTask.value.workTaskDetails === null ||
                    searchParametersWorkTask.value.workTaskDetails === '') &&
                (searchParametersWorkTask.value.workTaskStatus === null ||
                    searchParametersWorkTask.value.workTaskStatus === '') &&
                (searchParametersWorkTask.value.workTaskTypeCodeList === null ||
                    searchParametersWorkTask.value.workTaskTypeCodeList.length === 0) &&
                (searchParametersWorkTask.value.userIdList === null ||
                    searchParametersWorkTask.value.userIdList.length === 0) &&
                searchParametersWorkTask.value.farmSiteIdList !== null &&
                searchParametersWorkTask.value.farmSiteIdList.length === 1 &&
                searchParametersWorkTask.value.farmSiteIdList[0] === farmStore.selectedSiteId &&
                (searchParametersWorkTask.value.dateRaisedFrom === null ||
                    searchParametersWorkTask.value.dateRaisedFrom === '') &&
                (searchParametersWorkTask.value.dateRaisedTo === null ||
                    searchParametersWorkTask.value.dateRaisedTo === '') &&
                (searchParametersWorkTask.value.dateDueFrom === null ||
                    searchParametersWorkTask.value.dateDueFrom === '') &&
                (searchParametersWorkTask.value.dateDueTo === null ||
                    searchParametersWorkTask.value.dateDueTo === '') &&
                (searchParametersWorkTask.value.dateConcludedFrom === null ||
                    searchParametersWorkTask.value.dateConcludedFrom === '') &&
                (searchParametersWorkTask.value.dateConcludedTo === null ||
                    searchParametersWorkTask.value.dateConcludedTo === '') &&
                searchParametersWorkTask.value.seasonId === null)
        );
    }

    if (searchMode.value == HeaderSearchMode.Observation) {
        const isQuickSearchEmpty =
            searchParametersObservation.value.isQuickSearch &&
            (searchParametersObservation.value.quickSearchText === null ||
                searchParametersObservation.value.quickSearchText === '');

        const areOtherFieldsEmpty =
            !searchParametersObservation.value.isQuickSearch &&
            (searchParametersObservation.value.observationName === null ||
                searchParametersObservation.value.observationName === '') &&
            (searchParametersObservation.value.userIdList === null ||
                searchParametersObservation.value.userIdList.length === 0) &&
            searchParametersObservation.value.observationStatusCode === null &&
            (searchParametersObservation.value.farmFieldIdList === null ||
                searchParametersObservation.value.farmFieldIdList.length === 0) &&
            (searchParametersObservation.value.observationTypeCodeList === null ||
                searchParametersObservation.value.observationTypeCodeList.length === 0) &&
            searchParametersObservation.value.farmSiteIdList !== null &&
            searchParametersObservation.value.farmSiteIdList.length === 1 &&
            searchParametersObservation.value.farmSiteIdList[0] === farmStore.selectedSiteId &&
            (searchParametersObservation.value.dateRaisedFrom === null ||
                searchParametersObservation.value.dateRaisedFrom === '') &&
            (searchParametersObservation.value.dateRaisedTo === null ||
                searchParametersObservation.value.dateRaisedTo === '') &&
            (searchParametersObservation.value.dateLastActivityFrom === null ||
                searchParametersObservation.value.dateLastActivityFrom === '') &&
            (searchParametersObservation.value.dateLastActivityTo === null ||
                searchParametersObservation.value.dateLastActivityTo === '') &&
            (searchParametersObservation.value.dateConcludedFrom === null ||
                searchParametersObservation.value.dateConcludedFrom === '') &&
            (searchParametersObservation.value.dateConcludedTo === null ||
                searchParametersObservation.value.dateConcludedTo === '');

        return isQuickSearchEmpty || areOtherFieldsEmpty;
    }
    if (searchMode.value == HeaderSearchMode.Product) {
        return !!(
            (searchParametersProduct.value.isQuickSearch &&
                (searchParametersProduct.value.quickSearchText === null ||
                    searchParametersProduct.value.quickSearchText === '')) ||
            (!searchParametersProduct.value.isQuickSearch &&
                (searchParametersProduct.value.productName === null ||
                    searchParametersProduct.value.productName === '') &&
                (searchParametersProduct.value.productBrandName === null ||
                    searchParametersProduct.value.productBrandName === '') &&
                (searchParametersProduct.value.farmFieldIdList === null ||
                    searchParametersProduct.value.farmFieldIdList.length === 0) &&
                (searchParametersProduct.value.productTypeCodeList === null ||
                    searchParametersProduct.value.productTypeCodeList.length === 0) &&
                (searchParametersProduct.value.farmSiteIdList === null ||
                    searchParametersProduct.value.farmSiteIdList.length === 0) &&
                (searchParametersProduct.value.dateRangeFrom === null ||
                    searchParametersProduct.value.dateRangeFrom === '') &&
                (searchParametersProduct.value.dateRangeTo === null ||
                    searchParametersProduct.value.dateRangeTo === ''))
        );
    }

    return false;
});
/**
 * Close the modal.
 */
const close = () => {
    advancedSearchModalStore.close();
};

//Export Task List to CSV file
const exportTaskList = async () => {
    try {
        const fileName = i18n.global.t('Tasks') + '_' + format(new Date(), 'yyyyMMddHHmm') + '.csv';
        await apiService.downloadFilePost('search/work-tasks/export', searchParametersWorkTask.value, null, fileName);
    } catch (error) {
        // Show fail feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ExportCSVError');
        isLoading.value = false;
    }
};

const defaultObservationTab = ref<boolean>(true);
const defaultTaskTab = ref<boolean>(true);

const exportObservationList = async () => {
    try {
        const fileName = i18n.global.t('Observations') + '_' + format(new Date(), 'yyyyMMddHHmm') + '.csv';
        await apiService.downloadFilePost(
            'search/observations/export',
            searchParametersObservation.value,
            null,
            fileName,
        );
    } catch (error) {
        // Show fail feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ExportCSVError');
        isLoading.value = false;
    }
};

const exportFieldList = async () => {
    try {
        const fileName = i18n.global.t('FarmField') + '_' + format(new Date(), 'yyyyMMddHHmm') + '.csv';
        await apiService.downloadFilePost('search/farm-fields/export', searchParametersFarmField.value, null, fileName);
    } catch (error) {
        // Show fail feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ExportCSVError');
        isLoading.value = false;
    }
};

const viewFieldListOnHome = () => {
    homeDashboardStore.resetFarmFieldSearchParameters();
    homeDashboardStore.navigatorPanelActiveTab = NavigatorPanelTab.FarmFields;
    homeDashboardStore.isMultiSelectFarmFieldsMode =
        searchResultsFarmField.value && searchResultsFarmField.value.length > 1;
    homeDashboardStore.selectedFarmFieldsLevel1.splice(
        0,
        homeDashboardStore.selectedFarmFieldsLevel1.length,
        ...searchResultsFarmField.value,
    );

    userStore.closeAllPopups();

    if (!homeDashboardStore.isHomeDashboardOpen) {
        router.push({name: 'HomeDashboard'});
    }
};

const viewObservationListOnHome = (isOpenObs: boolean) => {
    homeDashboardStore.resetObservationSearchParameters(true);
    homeDashboardStore.navigatorPanelActiveTab = NavigatorPanelTab.Observations;
    homeDashboardStore.observations = searchResultsObservation.value;
    homeDashboardStore.isObservationOpenMode = isOpenObs;
    homeDashboardStore.observationPanelVersion += 1;

    advancedSearchModalStore.close();

    if (!homeDashboardStore.isHomeDashboardOpen) {
        homeDashboardStore.observationPreventInitialLoading = true;
        router.push({name: 'HomeDashboard'});
    }
};

/**
 * Save the modal.
 */
const search = async () => {
    isLoading.value = true;

    // Get search results
    let numberOfResults = null;
    let isExportCSV = false;

    switch (searchMode.value) {
        case HeaderSearchMode.FarmField: {
            const response: SearchFarmFieldsResponse = await apiService.post(
                'search/farm-fields',
                searchParametersFarmField.value,
            );
            searchResultsFarmField.value = response.farmFields;
            numberOfResults = searchResultsFarmField.value.length.toLocaleString();

            break;
        }
        case HeaderSearchMode.Product: {
            const response: SearchProductsResponse = await apiService.post(
                'search/products',
                searchParametersProduct.value,
            );
            searchResultsProduct.value = response.products;
            numberOfResults = searchResultsProduct.value.length.toLocaleString();

            break;
        }

        case HeaderSearchMode.ProductMixture: {
            const response: SearchMixturesResponse = await apiService.post(
                'search/mixtures',
                searchParametersMixture.value,
            );
            searchResultsMixture.value = response.mixtures;
            numberOfResults = searchResultsMixture.value.length.toLocaleString();

            break;
        }

        case HeaderSearchMode.Observation: {
            if (!searchParametersObservation.value.isExportCSV) {
                const response: SearchObservationsResponse = await apiService.post(
                    'search/observations',
                    searchParametersObservation.value,
                );
                searchResultsObservation.value = response.observations;
                numberOfResults = searchResultsObservation.value.length.toLocaleString();
                if (searchParametersObservation.value.observationStatusCode) {
                    const openStatuses = [
                        ObservationStatusCode.Acknowledged,
                        ObservationStatusCode.InProgress,
                        ObservationStatusCode.Open,
                    ];
                    const closedStatuses = [
                        ObservationStatusCode.Archived,
                        ObservationStatusCode.Cancelled,
                        ObservationStatusCode.Resolved,
                    ];
                    if (openStatuses.includes(searchParametersObservation.value.observationStatusCode))
                        defaultObservationTab.value = true;
                    else if (closedStatuses.includes(searchParametersObservation.value.observationStatusCode))
                        defaultObservationTab.value = false;
                }
            } else {
                isExportCSV = true;
                await exportObservationList();
            }

            break;
        }
        case HeaderSearchMode.Crop: {
            const response: SearchCropVarietiesResponse = await apiService.post(
                'search/crop-varieties',
                searchParametersCrop.value,
            );
            searchResultsCrop.value = response.crops;
            numberOfResults = searchResultsCrop.value.length.toLocaleString();

            break;
        }

        case HeaderSearchMode.User: {
            searchResultsUser.value = await apiService.post('search/users', searchParametersUser.value);
            numberOfResults = searchResultsUser.value.length.toLocaleString();

            break;
        }
        case HeaderSearchMode.WorkTask: {
            if (!searchParametersWorkTask.value.isExportCSV) {
                const response: SearchWorkTasksResponse = await apiService.post(
                    'search/work-tasks',
                    searchParametersWorkTask.value,
                );
                searchResultsWorkTask.value = response.workTasks;
                numberOfResults = searchResultsWorkTask.value.length.toLocaleString();
                if (searchParametersWorkTask.value && searchParametersWorkTask.value.workTaskStatus) {
                    if (
                        searchParametersWorkTask.value.workTaskStatus.includes('Completed') ||
                        searchParametersWorkTask.value.workTaskStatus.includes('Cancelled')
                    )
                        defaultTaskTab.value = false;
                    else defaultTaskTab.value = true;
                }
            } else {
                isExportCSV = true;
                await exportTaskList();
            }
            break;
        }
    }

    if (!isExportCSV) {
        // Switch to results tab
        tab.value = 'SearchResults';
        // Record the search has been run, so that the results tab can be shown
        hasSearchBeenRun.value = true;

        // Get search mode text
        let searchModeText = '';
        let selectedSearchMode = searchModes.value.filter((item) => item.value === searchMode.value);
        if (selectedSearchMode.length > 0) {
            searchModeText = selectedSearchMode[0].title;
        }

        // Set results tab title (must be done here instead of a computed function because we also want to include the search mode)
        resultsTabTitle.value = i18n.global.t('SearchResults_WithSearchModeAndNumberOfResults', {
            searchMode: searchModeText,
            numberOfResults: numberOfResults,
        });
    } else {
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ExportCSVSuccess');
    }

    isLoading.value = false;
};

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    tab.value = 'SearchParameters';
    hasSearchBeenRun.value = false;
    searchMode.value = defaultSearchMode.value;

    resetSearchParameters();
    resetSearchResults();
};

/**
 * Reset search parameters to their default state.
 */
const resetSearchParameters = () => {
    searchParametersFarmField.value = new SearchFarmFieldsRequest({
        isQuickSearch: false,
        includeFarmBlocks: true,
        includeOpenObservations: true,
        includeFarmSeasons: true,
    });
    searchParametersProduct.value = new SearchProductRequest({
        isQuickSearch: false,
    });
    searchParametersMixture.value = new SearchMixtureRequest({
        isQuickSearch: false,
    });
    searchParametersObservation.value = new SearchObservationsRequest({
        isQuickSearch: false,
    });
    searchParametersCrop.value = new SearchCropVarietiesRequest({
        isQuickSearch: false,
    });
    searchParametersUser.value = new SearchUsersRequest({
        isQuickSearch: false,
        recentActivitySinceDate: subDays(new Date(), 7),
    });
    searchParametersWorkTask.value = new SearchWorkTasksRequest({
        isQuickSearch: false,
    });

    // Set "farm sites" parameter to the currently selected farm site by default
    const siteId = userStore.user?.farmSiteId;
    if (siteId) {
        searchParametersFarmField.value.farmSiteIdList = [siteId];
        searchParametersObservation.value.farmSiteIdList = [siteId];
        searchParametersUser.value.farmSiteIdList = [siteId];
        searchParametersWorkTask.value.farmSiteIdList = [siteId];
    }

    if (searchMode.value == HeaderSearchMode.Observation && refSearchParametersObservation.value) {
        refSearchParametersObservation.value.resetLocalParameters();
    }

    if (searchMode.value == HeaderSearchMode.WorkTask && refSearchParametersWorkTask.value) {
        refSearchParametersWorkTask.value.resetLocalParameters();
    }
};

/**
 * Reset search results to empty arrays.
 */
const resetSearchResults = () => {
    searchResultsFarmField.value = [];
    searchResultsObservation.value = [];
    searchResultsUser.value = [];
    searchResultsWorkTask.value = [];
};

/**
 * Select the search parameters tab.
 */
async function selectSearchParametersTab() {
    // Switch to search paramters tab
    tab.value = 'SearchParameters';
}

/**
 * Flag to indicate if the Search Parameters tab is selected.
 */
const isSearchParametersTabSelected = computed(() => {
    return tab.value == 'SearchParameters';
});

/**
 * Watch for the Advanced Search modal being opened.
 */
watch(
    () => advancedSearchModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            // Reset form
            resetForm();

            // Set parameters
            searchMode.value = advancedSearchModalStore.searchMode;
            searchParametersFarmField.value.isQuickSearch = advancedSearchModalStore.isQuickSearch;
            searchParametersCrop.value.isQuickSearch = advancedSearchModalStore.isQuickSearch;
            searchParametersProduct.value.isQuickSearch = advancedSearchModalStore.isQuickSearch;
            searchParametersMixture.value.isQuickSearch = advancedSearchModalStore.isQuickSearch;
            searchParametersObservation.value.isQuickSearch = advancedSearchModalStore.isQuickSearch;
            searchParametersUser.value.isQuickSearch = advancedSearchModalStore.isQuickSearch;
            searchParametersWorkTask.value.isQuickSearch = advancedSearchModalStore.isQuickSearch;
            searchParametersFarmField.value.quickSearchText = advancedSearchModalStore.quickSearchText;
            searchParametersProduct.value.quickSearchText = advancedSearchModalStore.quickSearchText;
            searchParametersMixture.value.quickSearchText = advancedSearchModalStore.quickSearchText;
            searchParametersObservation.value.quickSearchText = advancedSearchModalStore.quickSearchText;
            searchParametersCrop.value.quickSearchText = advancedSearchModalStore.quickSearchText;
            searchParametersUser.value.quickSearchText = advancedSearchModalStore.quickSearchText;
            searchParametersWorkTask.value.quickSearchText = advancedSearchModalStore.quickSearchText;

            const siteId = userStore.user?.farmSiteId;
            if (advancedSearchModalStore.filterBySite && siteId) {
                searchParametersFarmField.value.farmSiteId = siteId;
                searchParametersObservation.value.farmSiteId = siteId;
                searchParametersWorkTask.value.farmSiteId = siteId;
            }

            if (advancedSearchModalStore.isQuickSearch) {
                // Automatically run quick search
                await search();

                // Clear quick search text after running the search (so that the user can go to the search parameters and perform an advanced search if needed)
                searchParametersFarmField.value.isQuickSearch = false;
                searchParametersObservation.value.isQuickSearch = false;
                searchParametersUser.value.isQuickSearch = false;
                searchParametersWorkTask.value.isQuickSearch = false;
                searchParametersProduct.value.isQuickSearch = false;
                searchParametersProduct.value.quickSearchText = null;
                searchParametersMixture.value.isQuickSearch = false;
                searchParametersMixture.value.quickSearchText = null;
                searchParametersFarmField.value.quickSearchText = null;
                searchParametersCrop.value.isQuickSearch = false;
                searchParametersCrop.value.quickSearchText = null;
                searchParametersObservation.value.quickSearchText = null;
                searchParametersUser.value.quickSearchText = null;
                searchParametersWorkTask.value.quickSearchText = null;
                searchParametersFarmField.value.farmSiteId = null;
                searchParametersObservation.value.farmSiteId = null;
                searchParametersWorkTask.value.farmSiteId = null;
            }
        }
    },
);

import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();
/**
 * Watch for changes in search result changes
 */
watch(
    [
        () => manageFarmFieldModalStore.savedCounter,
        () => deleteFarmFieldModalStore.deletedCounter,
        () => deleteUserModalStore.deletedCounter,
        () => manageUserModalStore.savedCounter,
        () => viewObservationModalStore.savedCounter,
        () => deleteOrCancelWorkTaskModalStore.savedCounter,
    ],
    (newVal, oldVal) => {
        if (hasSearchBeenRun.value == true && newVal > oldVal) {
            search();
        }
    },
    {deep: true},
);

watch(
    () => manageObservationModalStore.savedCounter,
    (newVal, oldVal) => {
        if (hasSearchBeenRun.value == true && newVal > oldVal) {
            search();
        }
    },
);

//hide search results if the search mode has been changed
watch(
    () => searchMode.value,
    () => {
        if (hasSearchBeenRun.value) {
            tab.value = 'SearchParameters';
            hasSearchBeenRun.value = false;

            resetSearchParameters();
            resetSearchResults();
        }
    },
);

onMounted(async () => {
    // The timeout is used because there is a delay in the i18n locale being updated, which was causing incorrect translations
    // to be used. And i18n.global.locale does not seem to be reactive so we can't watch for it. There may be a better solution
    // that can be implemented later, but for now this fixes the issue.
    setTimeout(() => {
        // Set search modes in the correct language
        setSearchModes();
    }, 1000);
});
</script>
