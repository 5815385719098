import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a930236"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ChangePassword" }
const _hoisted_2 = {
  key: 0,
  class: "pa-4"
}
const _hoisted_3 = {
  key: 1,
  class: "pa-4"
}
const _hoisted_4 = {
  style: {"width":"80%"},
  class: "tblForm"
}
const _hoisted_5 = {
  class: "tableCell padding",
  style: {"width":"40%"}
}
const _hoisted_6 = { class: "flex-layout align-items-center justify-content-space-between flex-wrap-nowrap" }
const _hoisted_7 = { style: {"width":"60%"} }
const _hoisted_8 = { class: "tableCell padding" }
const _hoisted_9 = { class: "flex-layout align-items-center justify-content-space-between flex-wrap-nowrap" }
const _hoisted_10 = { class: "tableCell padding" }
const _hoisted_11 = { class: "flex-layout align-items-center justify-content-space-between flex-wrap-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_HelpIcon = _resolveComponent("HelpIcon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, {
      outlined: "",
      class: "transparent-card"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_toolbar, {
          flat: "",
          color: "primary"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_toolbar_title, null, {
              default: _withCtx(() => [
                _createVNode(_component_FontAwesomeIcon, {
                  icon: ['fal', 'unlock'],
                  size: "xl",
                  class: "mr-2"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.panelTitle), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.panelMessage && _ctx.panelMessage !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.panelMessage), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_v_form, {
                modelValue: _ctx.valid,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.valid) = $event))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("table", _hoisted_4, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('CurrentPassword')), 1),
                          _createVNode(_component_HelpIcon, {
                            "help-text": _ctx.$t('EnterYourCurrentPassword')
                          }, null, 8, ["help-text"])
                        ])
                      ]),
                      _createElementVNode("td", _hoisted_7, [
                        _createVNode(_component_v_text_field, {
                          variant: "outlined",
                          modelValue: _ctx.oldPassword,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.oldPassword) = $event)),
                          required: "",
                          type: "password",
                          rules: _ctx.oldPasswordRules
                        }, null, 8, ["modelValue", "rules"])
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('NewPassword')), 1),
                          _createVNode(_component_HelpIcon, null, {
                            helpText: _withCtx(() => [
                              _createElementVNode("div", null, _toDisplayString(_ctx.$t('ChangePassword_NewPassword_HelpText')), 1),
                              _createElementVNode("ul", null, [
                                _createElementVNode("li", null, _toDisplayString(_ctx.passwordValidator.getLengthRequirements()), 1),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.passwordValidator.getCharacterRequirements(), (requirement) => {
                                  return (_openBlock(), _createElementBlock("li", { key: requirement }, _toDisplayString(requirement), 1))
                                }), 128))
                              ])
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _createElementVNode("td", null, [
                        _createVNode(_component_v_text_field, {
                          variant: "outlined",
                          modelValue: _ctx.newPassword,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newPassword) = $event)),
                          required: "",
                          type: "password",
                          rules: _ctx.newPasswordRules
                        }, null, 8, ["modelValue", "rules"])
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('ReEnterNewPassword')), 1),
                          _createVNode(_component_HelpIcon, {
                            "help-text": _ctx.$t('RepeatYourPassword')
                          }, null, 8, ["help-text"])
                        ])
                      ]),
                      _createElementVNode("td", null, [
                        _createVNode(_component_v_text_field, {
                          variant: "outlined",
                          modelValue: _ctx.repeatPassword,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.repeatPassword) = $event)),
                          required: "",
                          type: "password",
                          rules: _ctx.reapeatPasswordRules
                        }, null, 8, ["modelValue", "rules"])
                      ])
                    ])
                  ]),
                  (_ctx.errorMessage)
                    ? (_openBlock(), _createBlock(_component_v_alert, {
                        key: 0,
                        type: "error",
                        value: true
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue"])
            ])),
        _createVNode(_component_v_card_actions, { class: "justify-space-between swatchG9BG" }, {
          default: _withCtx(() => [
            _createVNode(_component_BackButton),
            (!_ctx.panelMessage || _ctx.panelMessage === '')
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  color: "primary",
                  onClick: _ctx.changePassword,
                  disabled: !_ctx.valid
                }, {
                  prepend: _withCtx(() => [
                    _createVNode(_component_FontAwesomeIcon, {
                      icon: ['fal', 'floppy-disk'],
                      size: "xl",
                      value: 2134
                    })
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('SavePassword')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}