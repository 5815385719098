import {GetFarmSiteNamingConventionsResponse} from '@/models/api/responses/data-source/GetFarmSiteNamingConventionsResponse';
import {SelectListItem} from '@/models/generic/SelectListItem';
import ApiService from '@/services/api-service';
import {inject, ref} from 'vue';

export function useFarmSiteNamingConventions() {
    const apiService = inject('apiService') as ApiService;
    const farmSiteNamingConventions = ref<GetFarmSiteNamingConventionsResponse[]>([]);
    const farmSiteNamingConventionsWithNull = ref<SelectListItem[]>([]);

    /**
     * Get list of crops.
     */
    const getFarmSiteNamingConventions = async () => {
        farmSiteNamingConventions.value = await apiService.get('data-source/farm-site-naming-conventions');

        // Add null value
        farmSiteNamingConventionsWithNull.value = farmSiteNamingConventions.value.map((fsnc) => ({
            value: fsnc.farmSiteNamingConventionId,
            title: fsnc.farmSiteNamingConventionName,
        }));

        farmSiteNamingConventionsWithNull.value.unshift({
            value: null,
            title: '',
        });
    };

    return {
        getFarmSiteNamingConventions,
        farmSiteNamingConventions,
        farmSiteNamingConventionsWithNull,
    };
}
