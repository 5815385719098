import {UploadedArtifactDto} from '@/models/data-transfer-objects/system/UploadedArtifactDto';

/**
 * Generates a FormData object which can then be used to send uploaded files to an API.
 */
class FileUploadHelper {
    /**
     * Checks to see if the value is an uploaded artifact.
     */
    private static isUploadedArtifactDto(value: unknown): value is UploadedArtifactDto {
        return value != null && typeof value === 'object' && 'file' in value && value.file instanceof File;
    }

    /**
     * Append a property to the form data.
     */
    private static appendToFormData(formData: FormData, key: string, value: unknown, parentKey?: string): void {
        const fullKey = parentKey ? (key ? `${parentKey}.${key}` : parentKey) : key;

        if (value === null || value === undefined) {
            // If the value is null do not add it to the form data
            return;
        } else if (this.isUploadedArtifactDto(value)) {
            // If the value is an uploaded file, append it to the form data using different logic
            this.appendArtifactToFormData(formData, fullKey, value);
        } else if (Array.isArray(value)) {
            // If the value is an array, check each item
            value.forEach((item, index) => {
                const arrayKey = `${fullKey}[${index}]`;
                if (this.isUploadedArtifactDto(item)) {
                    // If the item is an uploaded file, append it to the form data using different logic
                    this.appendArtifactToFormData(formData, arrayKey, item);
                } else {
                    // If the item is not an uploaded file, recursively append it to the form data
                    this.appendToFormData(formData, '', item, arrayKey);
                }
            });
        } else if (typeof value === 'object' && !(value instanceof File)) {
            // If the value is an object, recursively append each nested property to the form data
            Object.entries(value).forEach(([nestedKey, nestedValue]) => {
                this.appendToFormData(formData, nestedKey, nestedValue, fullKey);
            });
        } else {
            // If the value is a simple type, append it directly to the form data
            formData.append(fullKey, value.toString());
        }
    }

    /**
     * Append an uploaded artifact to the format data.
     */
    private static appendArtifactToFormData(formData: FormData, key: string, artifact: UploadedArtifactDto): void {
        formData.append(`${key}.file`, artifact.file);
        formData.append(`${key}.artifactFolder`, artifact.artifactFolder);
        formData.append(`${key}.artifactTypeCode`, artifact.artifactTypeCode);
        if (artifact.observationId) formData.append(`${key}.observationId`, artifact.observationId.toString());
        if (artifact.observationActionId)
            formData.append(`${key}.observationActionId`, artifact.observationActionId.toString());
        if (artifact.userId) formData.append(`${key}.userId`, artifact.userId.toString());
        if (artifact.workTaskId) formData.append(`${key}.workTaskId`, artifact.workTaskId.toString());
        if (artifact.workSubTaskId) formData.append(`${key}.workSubTaskId`, artifact.workSubTaskId.toString());
    }

    /**
     * Create a FormData object from the specified object. This is used to include objects in an API request
     * when they contain uploaded files.
     */
    public static createFormDataObject(requestObject: object): FormData {
        const formData = new FormData();

        Object.entries(requestObject).forEach(([key, value]) => {
            this.appendToFormData(formData, key, value);
        });

        return formData;
    }
}

export {FileUploadHelper};
export default FileUploadHelper;
