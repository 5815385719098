<template>
    <div class="footerRow" v-if="!isUserLoggedIn">
        <div class="table" style="margin-left: auto; margin-right: auto">
            <div class="tableCell">
                <div>
                    <router-link to="info?page=privacy">{{ $t('Privacy') }}</router-link>
                    |
                    <router-link to="info?page=about">
                        {{ $t('AboutSystemName', {systemName: configStore.systemName}) }}
                    </router-link>
                </div>
                <div style="margin-top: 5px">
                    <router-link to="info?page=copyright">{{ $t('Copyright') }} &copy;</router-link>
                    2024
                    <span class="utilityName">{{ configStore.utilityNameFull }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="swatchG1BG" style="height: 2px" v-else></div>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue';
import {useConfigStore} from '@/stores/config-store';
import {useUserStore} from '@/stores/user-store';

export default defineComponent({
    setup() {
        const configStore = useConfigStore();
        const userStore = useUserStore();
        const isUserLoggedIn = computed(() => userStore.isLoggedIn);

        return {
            configStore,
            isUserLoggedIn,
        };
    },
});
</script>

<style lang="scss">
@import '@/assets/scss/footer.scss';
</style>
